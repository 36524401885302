import styles from './components.module.css';
import { useEffect, useState } from "react";
import { reservasTrabajadadoresNoAsignadosRecepcionistaColumns } from "../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import CustomToolbar from '../../components/SlotsToolBar';

const TableNoAsignaciones = ({ asignaciones, setSelectedPabellon, setSelectedWing, setSelectedRut, setPabellonWing, habitaciones, setHabitacionesList, setSelectedHabitacion, setSelectedPiso, selectedRut }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reservasTrabajadadoresNoAsignadosRecepcionistaColumns(setOpen, setSeleItem, setOpenEdit);

  const handleRowSelectionChange = (selection) => {
    const selectedID = selection[0];
    console.log(selectedID)
    const selectedData = asignaciones.find(row => row.trabajador.rut === selectedID);
    console.log('Selected Data:', selectedData);
    if(selectedRut == {}){}
    setSelectedRut({
      ...selectedRut,
        rut: selectedData?.trabajador?.rut, 
        jornada: selectedData?.turno?.tipoJornada,
        genero: selectedData?.trabajador?.genero,
        cargo: selectedData?.cargo,
        cargoId: selectedData?.cargoId,
        contrato: selectedData?.contrato,
        empresaId: selectedData?.empresaId,
        acreditado: selectedData?.acreditado,
        nombre: selectedData?.trabajador.nombre} || {});
  };

  return (
    <DataGrid
        getRowId={row => row.trabajador.rut || Math.random()}
        components={{
          Toolbar: CustomToolbar
        }}
        rows={asignaciones}
        columns={finalColumns}
        disableMultipleSelection
        onRowSelectionModelChange={handleRowSelectionChange}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
        }}
        classes={{ menuIcon: styles.menuIcon }}
        style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
        sx={{ 
          '& .MuiDataGrid-cell': {
            color: '#FFF',
            borderBottom: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-columnHeader': {
            color: '#FFF',
            borderBottom: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-footerContainer': {
            color: '#FFF',
            borderTop: '1px solid #c6c6c6',
          },
          '& .MuiTablePagination-root': {
            color: '#FFF',
            borderTop: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-toolbarContainer': {
            color: '#FFF',
          },
          '& .MuiDataGrid-menu': {
            color: '#FFF',
          },
          '& .MuiTablePagination-actions': {
            color: '#FFF', // Color for pagination actions
          },
          '& .MuiTablePagination-select': {
            color: '#FFF', // Color for pagination select
          },
          '& .MuiTablePagination-selectLabel': {
            color: '#FFF', // Color for pagination select label
          },
          '& .MuiTablePagination-displayedRows': {
            color: '#FFF', // Color for displayed rows text
          },
         }}
        slotProps={{
          toolbar:{
            sx:{
              color:'#FFF'
            }
          },
          pagination: {
            sx: {
              color: '#FFF',
            },
          },
          menu: {
            sx: {
              color: '#FFF',
            },
          },
        }}
    />
  );
}

export default TableNoAsignaciones;
