import styles from './components.module.css';
import { useState } from "react";
import { aseoDashboardColumns } from "../../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card } from '@mui/material';
import SkeletonTable from '../../../components/Skeleton'
import Edit from './Edit';
import { useEffect } from 'react';

const Table = ({rows, addRequestStatus, setOpen3, personal}) => {
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [openEdit, setOpenEdit] = useState(false);
    
    const finalColumns = aseoDashboardColumns(setOpen, setSeleItem, setOpenEdit);

  return (
    <div style={{ width:'100%' }}>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        : <Card style={{ backgroundColor:'var(--inst-card-bg)', color:'#FFF' }}>
            <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem}
             setOpen3={setOpen3} personal={personal}/>
                <DataGrid
                  lots={{ toolbar: GridToolbar }}
                  rows={rows}
                  columns={finalColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                      labelRowsPerPage: "Filas por página:",
                  }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#FFF',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#FFF',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#FFF', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#FFF', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#FFF', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#FFF', // Color for displayed rows text
                  },
                 }}
                slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                }}
            />
        </Card>
     }
    </div>
  )
}

export default Table