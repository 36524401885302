import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";

const ESTANDARES_URL = '/api/v1/Standard';
const GET_ESTANDAR_URL = '/api/v1/Standard/';
const DELETE_ESTANDAR_URL = '/api/v1/Standard/';
const UPDATE_ESTANDAR_URL = '/api/v1/Standard/'
const ADD_ESTANDAR_URL = '/api/v1/Standard';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchEstandares = createAsyncThunk("estandares/fetchEstandares", async ()=> {
    try {
        const response = await axiosInstance.get(ESTANDARES_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchEstandar = createAsyncThunk("estandar/fetchEstandar", async (id)=> {
    try {
        const response = await axiosInstance.get(GET_ESTANDAR_URL+id);        
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteStandar = createAsyncThunk("estandares/deleteStandar", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_ESTANDAR_URL+id);
        
        if (response.status === 200 ) {
            toast.success('Estandar de habitación eliminado correctamente.');
            dispatch(fetchEstandares());
            return { data: {id}, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el estandar de habitación: ' + e.response.data);
        return e.message;
    }
})
export const updateEstandar = createAsyncThunk("estandares/updateEstandar", async (upSector, {dispatch})=> {
    console.log('standard', upSector);
    try {
        const response = await axiosInstance.put(UPDATE_ESTANDAR_URL+upSector.id, {
            Nombre: upSector.nombre,
            descripcion: upSector.descripcion,
            campamentoId: upSector.campamentoId,
        });
        
        if (response.status === 200 ) {
            toast.success('Estandar de habitación actualizado correctamente.');
            dispatch(fetchEstandares());
            return { data:response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el estandar de habitación: ' + e.response.data);
        return e.message;
    }
})

export const addNewEstandar = createAsyncThunk("estandares/createSectores", async (initialCampamento, { dispatch }) => {
    try {
        const response = await axiosInstance.post(ADD_ESTANDAR_URL, {
            nombre: initialCampamento.nombre,
            descripcion: initialCampamento.descripcion,
            campamentoId: initialCampamento.campamento,
            });
        if (response.status === 200) {
            toast.success('Estandar de habitación creado correctamente.');
            dispatch(fetchEstandares());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear el estandar de habitación: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})



// idle : no hay operaciones en curso
const initialState = {
    estandares: [],
    estandar: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const estandarSlice = createSlice({
    name:"estandar",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchEstandares.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchEstandares.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.estandares) !== JSON.stringify(action.payload)) { //fetch
                state.estandares = action.payload;
            }
        })
        .addCase(fetchEstandares.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchEstandar.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchEstandar.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.estandar) !== JSON.stringify(action.payload)) { //fetch
                state.estandar = action.payload;
            }
        })
        .addCase(fetchEstandar.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewEstandar.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){
                state.status = 'succeeded'
                state.estandares.push(action.payload.data);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteStandar.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteStandar.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.estandares = state.estandares.filter(estandar => estandar.id !== action.payload.data.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteStandar.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateEstandar.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateEstandar.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.estandares = state.estandares.map(estandar => {
                    if (estandar.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return estandar;
                });
            }else{
                state.status = 'failed';
            }
        })
        .addCase(updateEstandar.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllEstandares = (state) => state.estandares.estandares;
export const getEstandar = (state) => state.estandares.estandar;
export const getEstandarStatus = (state) => state.estandares.status;
export const getEstandarError = (state) => state.estandares.error;

export const {} = estandarSlice.actions;
export default estandarSlice.reducer;