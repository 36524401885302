import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Backdrop, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deleteCampamento } from '../../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';

export default function Delete({ open, setOpen, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const dispatch = useDispatch();
  
  useEffect(()=>{console.log(open)},[])

    const handleDelete = async () => {
      try {
        setOpen3(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(deleteCampamento(seleItem.id))
        if(resp.status === 'success'){
          setOpen(false);
        }
      } catch (error) {
        toast.error('Error al eliminar el campamento.');
        console.error('Error al eliminar el campamento', error.message);
      } finally{
        setAddRequestStatus('idle');
        setOpen3(false);
      }
    }

  return (
    <div>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={() => setOpen(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} className={styles.boxDelete}>
                <Container className={styles.containerDelete}>
                    <div className={styles.textContainer}> 
                        <Typography variant="h6" className={styles.textIconDelete}>
                          <ErrorIcon className={styles.iconBg}/>
                          Estás a punto de eliminar el campamento {seleItem?.nombre}</Typography>
                          <Typography variant='span' className={styles.paragrap}>Está acción es irreversible, el registro será removido de la base de datos, <strong>¿Estas seguro de proceder?</strong>
                        </Typography>
                    </div>
                    <Grid container={true} spacing={1} mt={1}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerDelete}>
                            <Button
                                onClick={handleDelete}
                                className={styles.bgColorDelete}
                                variant="contained" 
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Eliminar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpen(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>

                </Container>                
            </Box>
        )}
      </Drawer>
    </div>
  );
}