import styles from './admAplicacion.module.css'
import { Box, Card, Container, Grid,  Typography } from "@mui/material"
import CardSlider from './components/Slider';
import { useAuth } from '../../context/AppContext'
import Hero from './components/Hero'
import HistorialReclamos from './components/HistorialReclamos';
import ProximaSubido from './components/ProximaSubido';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReclamosSugerenciasHuesped, fetchReclamosSugerenciasHuespedData, getAllReclamosSugerencias, getAllReclamosSugerenciasHuesped, getReclamosSugerenciasStatus } from '../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice';
import { useEffect } from 'react';
import { fetchReservasHuesped, getAllReservasHuesped, getReservasStatus } from '../../redux/features/reservas/reservaSlice';
import { fetchEspacios, getAllEspacios, getEspacioStatus } from '../../redux/features/guardarropia/guardarropiaSlice';

const DashboardHuesped = () => {

  const reclamosSugerencias = useSelector(getAllReclamosSugerenciasHuesped);
  const status = useSelector(getReclamosSugerenciasStatus);
  const reservas = useSelector(getAllReservasHuesped);
  const espacios = useSelector(getAllEspacios);
  const espaciosStatus = useSelector(getEspacioStatus);
  const statusRes = useSelector(getReservasStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchReclamosSugerenciasHuesped());
    }
    if(statusRes == 'idle'){
      dispatch(fetchReservasHuesped());
    }
    if(espaciosStatus == 'idle'){
      dispatch(fetchEspacios());
    }
  }, [])
  

  return (
    <Box width={1} mt={0} mb={2}>
        <Grid container={true} className={styles.container} style={{ padding:0 }} spacing={1}>
            {/* <Hero/> */}
            <Grid item  xs={12} sm={3}>
              <ProximaSubido reservas={reservas.reservas ? reservas.reservas : []}/>
              <CardSlider espacios={espacios}/> 
            </Grid>
            <Grid item  xs={12} sm={9}>
              <HistorialReclamos reclamosSugerencias={reclamosSugerencias}/>
            </Grid>
        </Grid>
    </Box>
  )
}

export default DashboardHuesped