import styles from './components.module.css'
import Slider from "react-slick";
import { Button, Card, Grid, Typography, CircularProgress } from '@mui/material';

let settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};

const CardSlider = ({ campamentos, addRequestStatus, handleSearch }) => {
    

    return (
        <Grid container={true} style={{ width:'100%' }}>
            <Grid item sm={12} style={{ width:'100%' }}>
            { campamentos && 
                <div className="slider-container" style={{ width:'100%' }}>
                    <Slider {...settings}>
                    { campamentos.map((campamento)=>(
                        <div key={campamento.id}>
                            <Card className={styles.card}>
                                <Typography style={{ textAlign:'center' }}>{campamento.nombre}</Typography>
                                <Typography>Ocupación: {campamento.ocupacion}%</Typography>
                                <Typography>Camas Disponibles: {campamento.camas_disponibles}</Typography>
                                <Typography>Habitaciones Disponibles: {campamento.habitaciones_disponibles}</Typography>
                                <Typography>Reclamos: {campamento.reclamos}</Typography>
                                <Typography>Sugerencias: {campamento.sugerencias}</Typography>
                                <Typography>Tickets Activos: {campamento.tickes_activos}</Typography>
                                <Button
                                    onClick={()=> handleSearch(campamento.id)}
                                    className={styles.bgSliderColorButton}
                                    variant="contained" >
                                    {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Ver detalles'}
                                </Button>
                            </Card>
                        </div>
                    )) }
                    </Slider>
                </div>
            }
            </Grid>
        </Grid>
  )
}

export default CardSlider