import { Backdrop, Box, Container, Tab, Tabs } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { fetchAsignadas, getAllAsignadas, getAsignadasStatus } from "../../../redux/features/aseo/aseoSlice";
import PropTypes from 'prop-types';
import TableEspe from "./components/TablePlanEspe";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Asignadas = () => {

  const dispatch = useDispatch();
  const registros = useSelector(getAllAsignadas);
  const status = useSelector(getAsignadasStatus);
  const [open3, setOpen3] = useState(false);
  const [value, setValue] = useState(0);
  const [open4, setOpen4] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchAsignadas());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestión de habitaciones asignadas"
          subtitle="En este módulo, podrás ver un listado de habitaciones que te han sido asignadas junto con las tareas relacionadas a la habitación.">
          {<ChecklistIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
                "& .MuiTab-root": { color: "gray", },
                "& .MuiTab-root.Mui-selected": { color: "#FFF" },
                "& .MuiTabs-indicator": { bgcolor: "#FFF" }
               }}>
                <Tab label="Planificaciones diarias" {...a11yProps(0)} />
                <Tab label="Planificaciones específicas" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              { status == 'loading' ? <SkeletonTable/> : <Table registros={registros?.planificacionesDiarias ? registros?.planificacionesDiarias : [] } setOpen3={setOpen3} open3={open3}/> }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              { status == 'loading' ? <SkeletonTable/> : <TableEspe registros={registros?.planificacionesEspecificas ? registros?.planificacionesEspecificas : [] } setOpen3={setOpen4} open3={open4}/> }
            </CustomTabPanel>
          </Box>
        </TableCard>
      </Container>
    </Box>
  )
}

export default Asignadas