import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { liveSearchByRut } from '../../../redux/features/users/userSlice';
import { addNewSalida } from '../../../redux/features/bodegas/bodegaSlice';
import { createAxiosInstance } from '../../../api/axios';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  rutRetira: "",
  bodegaId: '',
  tipoDocumento: '',
  montoTotal: 0,
  fechaSalida: '',
  salidaArticulos: [],
  observaciones: ''
}

const Create = ({ open2, setOpen2, campamentos, bodegas }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [itemsNew, setItemsNew] = useState([]);
  const [ruts, setRuts] = useState([])
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [filterBodegas, setFilterBodegas] = useState([]);
  const [searching, setSearching] = useState(false);
  const [listItems, setListItems] = useState([])
  const [listItems2, setListItems2] = useState([])
  const [actualDate, setActualDate] = useState([])
  const [selectedItem2, setSelectedItem2] = useState(null)
  const [bodegasItems, setBodegasItems] = useState([])
  const [totalPrice, setTotalPrice] = useState(0);

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    setSelectedBodega(null)
    setFormData({...formData, bodegaId: null});
    const filter = bodegas.filter(bod => bod.campamento.id == newValue.id);
    setFilterBodegas(filter)
  };

  const now = new Date();
  const horaChile = now.toLocaleTimeString('es-CL', { timeZone: 'America/Santiago' });

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const getProducts = async () => {
    try {
      const response = await axiosInstance.get('/api/v1/producto');
      console.log(response);
      setListItems2(response.data);
    } catch (error) {
      console.log('Error al traer los items: ',error)
    }
  }
  const getBodegas = async (bodega) => {
    try {
      const response = await axiosInstance.get('/api/v1/Inventario/productos-bodega/'+bodega);
      console.log('bodegas productos: ',response.data);
      // setBodegasItems(response.data);
      setListItems(response.data);
      return response.data
    } catch (error) {
      console.log('Error al traer los items: ',error)
    }
  }
  useEffect(() => {
    setActualDate(new Date().toISOString().split('T')[0]);
    setFormData(prev => ({ ...prev, fecha: new Date().toISOString().split('T')[0], hora: new Date().toLocaleTimeString('es-CL', { timeZone: 'America/Santiago' }) }))
    getProducts();
  }, [])
  useEffect(() => {
  console.log(formData)
  }, [formData])

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value))
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }
  const onChangeRutSelect = async (e, newValue, index) => {
    if (newValue != null) {
      setFormData({...formData, rutRetira: newValue})
    }
}

useEffect(() => {
  const updatedSalidaArticulos = itemsNew.map(item => ({
      ...item,
      rutRetira: item.rut // Agregar el campo rut a cada item del arreglo
  }));

  setFormData(prevFormData => ({
      ...prevFormData,
      salidaArticulos: updatedSalidaArticulos
  }));
}, [itemsNew]);

  const handleChangeBodega = async (event, newValue) => {
    setSelectedBodega(newValue);
    if(newValue != null){
      try {
        const response = await getBodegas(newValue.id);
        setBodegasItems(response);
        console.log('items::', response)
        setFormData(data)
        setItemsNew([])
        setItems([])
        setTotalPrice()
        // setTotalPrice(null)
        // setEnterPrice(null)
        // setSelectedItem2(null)
      } catch (error) {
        console.log('error al traer los árticulos de la bodega', error);
      }

      setFormData({...formData, bodega: newValue.id});
    } else{
      setFormData({...formData, bodega: 0})
    }
    
  };

  const onChange2 = (e, index) => {
    const { name, value } = e.target;
    setItemsNew(prevItems => {
      const updatedItems = [...prevItems];
      updatedItems[index][name] = value;
      return updatedItems;
    });
  };
  
  const axiosInstance = createAxiosInstance();
  const search = async (e, newValue) => {
    try {
      if(newValue){
        console.log('aqui');
        setSearching(true);
        const filterItem = Array.isArray(bodegasItems) && bodegasItems.find(item => item.codigo === newValue.codigo);
        console.log(filterItem);
        console.log('filterItem',filterItem)
        if (filterItem) {
          const alreadyIn = itemsNew.find(item => item.codigo === filterItem.codigo);
          if(alreadyIn){
            toast.error('El artículo ya esta agregado.');  
          }else{
            setItemsNew([...itemsNew, newValue]);
            setItems([...items, newValue]);
            // setNewItem('');
            console.log('newValue',newValue)
            setSelectedItem2(newValue)
          }
        }else{
          setSelectedItem2(null)
          toast.error('No se ha encontrado el artículo.');
        }
      }
    } catch (error) {
        console.log('error en el search: ',error);
    } finally{
      setSearching(false);
    }
};

  const canSave = [formData.bodega, formData.montoTotal, formData.salidaArticulos.length > 0].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        console.log('envio:', formData);
        let resp = await dispatch(addNewSalida(formData));
        console.log('resp', resp);
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
          setBodegasItems([])
          setItems([])
          setListItems([])
          setListItems2([])
          setNewItem('')
          setFilterBodegas([])

          setFormData(data);
          setOpen(false);
          setSelectedBodega()
          setSearching()
          setItemsNew([])
          setTotalPrice()
        }
      } catch (error) {
        console.error('Error al registrar la salida de bodega', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }

  useEffect(()=>{
    if(itemsNew.length != 0 ){
      handleTotalPrice();
    }
    setFormData(prev => ({...prev, salidaArticulos: itemsNew}))
  },[itemsNew])

  const handleTotalPrice = ( ) => {
    const totalPrice = itemsNew.reduce((total, item) => total + (item.precio * item.cantidadDelDocumento), 0);
    setTotalPrice(totalPrice)
    setFormData(prev => ({...prev, montoTotal: totalPrice}))
  }

  const handleRemove = (index) => {
    const newItems = [...itemsNew];
    newItems.splice(index, 1);
    setItemsNew(newItems);
    
    const newFormData = { ...formData };
    newFormData.salidaArticulos = newItems;
    setFormData(newFormData);
  };
  
  const handleInputChange = (event, newInputValue) => {
    if (!newInputValue) {
      setFormData({...formData, rutRetira: ''});
    }
  }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para ingresar las salida de los artículos.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> {setOpen(true); setFormData(data);}}><CircleIcon className={styles.iconButton}/>Ingresar registro</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <ExitToAppIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Registro de salida de artículo en bodega</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para ingresar el registro.</span>
          </div>
        </DialogTitle>
        {/* {JSON.stringify(formData)} */}
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Campamentos</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#FFF',
                      },
                    }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                name="campamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />      
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Bodega</label>
                 <Autocomplete
                  className={styles.textField}
                  sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#FFF',
                        },
                      }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  options={filterBodegas}
                  name="bodegaId"
                  value={selectedBodega || null}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value)=> handleChangeBodega(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona una bodega" />}
                /> 
            </Grid>
            <Grid item  xs={12} sm={3}>
              <label className={styles.label}>Fecha</label>
              <TextField
                className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                  }}
                variant="outlined"
                fullWidth
                name='fecha'
                type="date"
                // disabled
                value={actualDate}
                // onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={2}>
              <label className={styles.label}>Hora</label>
              <TextField
                className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                  }}
                variant="outlined"
                fullWidth
                name='hora'
                type="time"
                // disabled
                value={horaChile}
                // onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>Trabajador</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                name="receptor"
                options={ruts ? ruts : []}
                getOptionLabel={(option) => option && option.rut && option.nombre ? option.rut + ' ' + option.nombre : ''}
                onChange={onChangeRutSelect}
                onInputChange={handleInputChange}
                renderInput={(params) => <TextField {...params} label="Selecciona un rut" onChange={onChangeRut}/>}
              />
            </Grid>
            {/*  ------------- LISTA DE ARTICULOS -------------  */}
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Observaciones (opcional)</label>
              <TextField
                className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                  }}
                variant="outlined"
                fullWidth
                name='observaciones'
                value={formData?.observaciones}
                type="text"
                multiline
                rows={2}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
                <label className={styles.label}>Lista de artículos (Busca artículos por nombre o por código)</label>
                <div className={styles.inventario}>
                  <Autocomplete
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#FFF',
                        },
                        }}
                        disablePortal
                        disableClearable
                        disabled={!selectedBodega}
                        id="bodegas-autocomplete"
                        options={listItems}
                        value={selectedItem2}
                        name="idBodega"
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, value) => search(event, value)}
                        renderInput={(params) => <TextField {...params} label="Busca y selecciona una árticulo" />}
                      /> 
                </div>
            </Grid>
            </Grid>

            <Grid container={true} spacing={1} style={{ maxHeight:'500px', overflowY:'auto', border:'1px solid #777', borderRadius:'5px', padding:'5px', margin:'0', marginTop:'20px', }}> 
              {itemsNew.length != 0 ? itemsNew?.map((item, index) => (
                <>
                <Grid item xs={12} md={1} key={index}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                  <Button 
                    variant='contained' 
                    className={styles.button} 
                    onClick={() => handleRemove(index)}
                    style={{ 
                      width: '90px',
                      backgroundColor: 'red'
                    }}
                  >
                    Remover
                  </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Código Artículo {index + 1}</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='codigo'
                      value={item.codigo}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3} key={index}>
                    <label className={styles.label}>Descripción</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='descripcion'
                      value={item.descripcion}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Precio (CLP)</label>
                    <TextField
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                        }}
                      variant="outlined"
                      fullWidth
                      name='precio'
                      value={item.precio}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Cantidad del documento</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='cantidadDelDocumento'
                      value={item.cantidadDelDocumento}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Cantidad en bodega</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='stock'
                      value={item.stock}
                      type="number"
                      // onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                </>
              )) : <span>Buscar artículos para poder ver su información.</span>}
            </Grid>
            <Grid container={true} spacing={1} mt={1}>
              <Grid item  xs={12} sm={3}>
                <label className={styles.label}>Monto total (CLP)</label>
                <TextField
                  className={styles.textField}
                  sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                  }}
                  value={totalPrice}
                  variant="outlined"
                  fullWidth
                  name='montoTotal'
                  type="number"
                  // onChange={(e) => onChange(e)}
                />
              </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}>
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Registrar'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
            </DialogActions>
      </Dialog>
    </div>
  )
}

export default Create