import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";

const ROLES_URL = '/api/v1/Auth/GetRoles';
const ADD_ROL_URL = '/api/v1/Auth/register';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchRoles = createAsyncThunk("roles/fetchRoles", async ()=> {
    try {
        const response = await axiosInstance.get(ROLES_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

// idle : no hay operaciones en curso
const initialState = {
    roles: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const rolSlice = createSlice({
    name:"rol",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchRoles.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchRoles.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.roles) !== JSON.stringify(action.payload)) { //fetch
                state.roles = action.payload;
            }
        })
        .addCase(fetchRoles.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        // .addCase(addNewRol.fulfilled, (state, action) => { //ADD
        //     state.status = 'succeeded'
        //     state.roles.push(action.payload);
        // })
    }
})

export const getAllRoles = (state) => state.roles.roles;
export const getRolesStatus = (state) => state.roles.status;
export const getRolesError = (state) => state.roles.error;

export const {} = rolSlice.actions;
export default rolSlice.reducer;