import styles from './components.module.css';
import { useState } from "react";
import { planificacionDashboardColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';

const TableDash = ({ registros, setSelectedItems, selectedItems}) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [openReset, setOpenReset] = useState(false);
  const [openDatos, setOpenDatos] = useState(false);
  const [verCalendario, setVerCalendario] = useState(false);
  const [editOrCreate, setEditOrCreate] = useState(false);

  const finalColumns = planificacionDashboardColumns(setOpen, setSeleItem, setOpenEdit, setOpenReset, setOpenDatos, setEditOrCreate, setVerCalendario, verCalendario);

  const handleRowSelectionModelChange = (selectionModel) => {
    console.log(selectionModel);
    
    const selectedRows = selectionModel.map((id) => {
      return registros.find((row) => row.id === id);
    });
    
    setSelectedItems(selectedRows.map((row) => ({ ...row })));
  };

  return (
    <div>
        
      <DataGrid
            getRowId={row=>row.id ? row.id : Math.random() }
            lots={{ toolbar: GridToolbar }}
            rows={registros}
            checkboxSelection
            rowSelectionModel={selectedItems.map(item => item.id)}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#FFF',
              },
              '& .MuiDataGrid-menu': {
                color: '#FFF',
              },
              '& .MuiTablePagination-actions': {
                color: '#FFF', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#FFF', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#FFF', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#FFF', // Color for displayed rows text
              },
             }}
            slotProps={{
              toolbar:{
                sx:{
                  color:'#FFF'
                }
              },
              pagination: {
                sx: {
                  color: '#FFF',
                },
              },
              menu: {
                sx: {
                  color: '#FFF',
                },
              },
            }}
        />
    </div>
  )
}

export default TableDash