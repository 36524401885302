import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const RenderCell5 = ({ row, setOpen, setSeleItem, setOpenEdit, setOpenModal }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAsignar = () => {
    setSeleItem(row);
    setOpenEdit(true);
  };
  const handleClickVerDetalles = () => {
    setSeleItem(row);
    setOpenModal(true);
  };

  return (
    <div>
      <IconButton style={{ color:'#FFF' }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=>handleClickVerDetalles()}>
          <VisibilityIcon style={{ marginRight: 8 }} />
          Ver detalles
        </MenuItem>
        <MenuItem onClick={()=>handleClickAsignar()}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Usar datos
        </MenuItem>
      </Menu>
    </div>
  );
};