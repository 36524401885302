import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosFormDataInstance, createAxiosInstance } from "../../../api/axios";

const RESERVA_URL = '/api/v1/Solicitud';
const RESERVA_HUESPED_URL = '/api/v1/Huesped/info';
const TRABAJADORES_URL = '/api/v1/Solicitud/TrabajadoresSolicitud/';
const TRABAJADORES_ALL_URL = '/api/v1/Solicitud/Trabajadores';
const ADD_RESERVA_URL = '/api/v1/Solicitud/ESED';
const ASIGNAR_HABITACIONES_URL = '/api/v1/Solicitud/SolictudHabitacion';
const CHANGE_STATUS_URL = '/api/v1/Solicitud/CambioEstadoSolicitud';
const REASIGNAR_URL = '/api/v1/Solicitud/ReasignacionMasiva';
const CANCELAR_SOLICITUD = '/api/v1/Solicitud/CancelarSolicitud';
const GENERAR_PROPUESTA_URL = '/api/v1/Solicitud/GenerarPropuesta?IdSolicitud=';
const ASIGNAR_HABITACIONES = '/api/v1/Solicitud/ModificarAsignacion/';
const CARGOS_URL = '/api/v1/Solicitud/Cargos';
const TRABAJADORES_REASIGNACION = '/api/v1/Solicitud/TrabajadoresAsignados';

const axiosInstance = createAxiosInstance();
const axiosFormDataInstance = createAxiosFormDataInstance()

// ACTIONS
export const fetchTrabajadoresReasignacion = createAsyncThunk("habitaciones/fetchTrabajadoresReasignacion", async (data) => {
    console.log('llega', data);
    const { pabellonId, wingId, piso, fechaIngreso } = data;
    
    try {
        let url = TRABAJADORES_REASIGNACION + '?';
        
        if (wingId) {
            url += `WingId=${wingId}`;
        } else {
            url += `PabellonId=${pabellonId}`;
        }
        
        url += `&Piso=${piso}&FechaIngreso=${fechaIngreso}`;
        
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (e) {
        return e.message;
    }
});


export const fetchReservasHuesped = createAsyncThunk("reservas/fetchReservasHuesped", async ()=> {
    try {
        const response = await axiosInstance.get(RESERVA_HUESPED_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchCargos = createAsyncThunk("reservas/fetchCargosReservas", async ()=> {
    try {
        const response = await axiosInstance.get(CARGOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchReservas = createAsyncThunk("reservas/fetchReservas", async ()=> {
    try {
        const response = await axiosInstance.get(RESERVA_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchTrabajadores = createAsyncThunk("reservas/fetchTrabajadores", async (id) => {
    try {
        const response = await axiosInstance.get(TRABAJADORES_URL + id);
        console.log('trabajadores',response)
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchTrabajadoresAll = createAsyncThunk("reservas/fetchTrabajadoresAll", async ({ contrato, rut, nombre }) => {
      try {
        const params = new URLSearchParams();
  
        if (contrato) params.append('contrato', contrato);
        if (rut) params.append('rut', rut);
        if (nombre) params.append('nombre', nombre);
  
        const response = await axiosInstance.get(`${TRABAJADORES_ALL_URL}?${params.toString()}`);
        console.log('trabajadores', response);
        return response.data;
      } catch (e) {
        return e.message;
      }
    }
  );
export const generarPropuesta = createAsyncThunk("reservas/generarPropuesta", async (id, {dispatch}) => {
    try{
        const resp = await axiosInstance.post(GENERAR_PROPUESTA_URL+id);
        // toast.warning(resp.data.message);
        if (resp.status == 200) {
            toast.success('Propuesta generada exitosamente.');
            dispatch(fetchReservas())
            return {data: resp.data, status: 'success'};
        }
        return resp.data;
    } catch (e) {
        return {message: e.message, status: 'error'};
    }
})
export const addNewReserva = createAsyncThunk("reservas/createReservas", async (initialReserva) => {
    console.log('lo que llega', initialReserva)
    const formatISODate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toISOString();
      };
    const formData = new FormData();
    const mappedTrabajadoresExternoTrue = initialReserva.trabajadores
        .filter(item => item.externo === true || item.externo == null || item.externo == undefined)
        .map(item => ({
            edad: parseInt(item.edad),
            fechaSalida: formatISODate(item.salida),
            tipoCargo: item.tipo_cargo,
            nombres: item.nombres ?? item.nombre,
            turnoId: item.turno.idTurno, // arreglar el turno que no esta llegando para los externos
            direccion: item.direccion,
            rut: item.rut,
            genero: item.genero,
            fechaIngreso: formatISODate(item.llegada),
            telefono: item.telefono,
            apellidos: item.apellidos ?? item.apellido,
            cargoId: item.cargo.idCargo ?? item.cargo,
            email: item.email,
            jornada: item.jornada,
        }));

    const mappedTrabajadoresExternoFalse = initialReserva.trabajadores
        .filter(item => item.externo === false)
        .map(item => ({
            trabajadorESEDId: item.trabajadorESEDId,
            email: item.email,
            rut: item.rut,
            turnoId: item.turnoId,
            fechaIngreso: formatISODate(item.llegada),
            fechaSalida: formatISODate(item.salida),
            jornada: item.jornada,
            tipoCargo: item.tipo_cargo,
        }));
        console.log('mappedExternos', mappedTrabajadoresExternoTrue)
    try {

        formData.append('EmpresaId', initialReserva.empresa);
        formData.append('Gerencia', initialReserva.gerencia);
        formData.append('Vicepresidencia', initialReserva.vicepresidencia);
        formData.append('SI_Area', initialReserva.siArea);
        formData.append('Contrato', initialReserva.contrato);
        formData.append('TrabajadoresTotales', initialReserva.trabajadoresTotales);
        formData.append('TrabajadoresDia', 0);
        formData.append('TrabajadoresNoche', 0);
        formData.append('FechaIngreso', formatISODate(initialReserva.fechaIngreso));
        formData.append('FechaSalida', formatISODate(initialReserva.fechaSalida));
        formData.append('Observaciones', initialReserva.descripcion);
        const trabajadores = JSON.stringify(mappedTrabajadoresExternoFalse);
        const trabajadoresExternos = JSON.stringify(mappedTrabajadoresExternoTrue);
        formData.append('Trabajadores', trabajadores);
        formData.append('TrabajadoresExternos', trabajadoresExternos);
        console.log('trabajadores', trabajadores)
        console.log('trabajadoresExternos', trabajadoresExternos)
        const response = await axiosFormDataInstance.post(ADD_RESERVA_URL, formData)
        if (response.status == 200) {
            toast.success('Reserva creada correctamente.');
            return {data: response.data, status: 'success'};
        }
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al crear la reserva: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})
export const asignarHabitacionesRecepcionista = createAsyncThunk("reservas/asignarHabitaciones", async (data, {dispatch}) => {
    console.log('lo que llega', data)
    // return;
    // const idSolicitud = data.asignaciones[0].idSolicitud;
    const idSolicitud = data[0].idSolicitud;
    const mapped = data.map(item => ({
        rut: item.rut,
        // idHabitacion: item.habitacion.id
        idHabitacion: item.idHabitacion
    }))
    console.log('mapped', mapped);
    try {
        const response = await axiosInstance.post(ASIGNAR_HABITACIONES+idSolicitud, mapped);

        if (response.status == 200) {
            toast.success('Habitaciones asignadas correctamente.');
            dispatch(fetchReservas());
            return {data: response.data, status: 'success'};
        }
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al asignar las habitaciones: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})
export const addReasignar = createAsyncThunk("reservas/addReasignar", async (data, { dispatch }) => {
    console.log(data);
    
    try {
        const response = await axiosInstance.post(REASIGNAR_URL, data);
        console.log('response: ',response);
        if (response?.status === 200) {
            toast.success('Reasignación exitosa.');
            dispatch(fetchReservas())
            return { data: response.data, status: 'success' };
        } else {
            toast.error('Error: Respuesta inesperada del servidor.');
            return { status: 'error' };
        }
    } catch (e) {
        toast.error(`Error al actualizar el estado de la reserva`);
        console.error('Error en la reasignación:', e);
        return { message: e, status: 'error' };
    }
});

export const rechazarSolicitud = createAsyncThunk("reservas/rechazarSolicitud", async (initialReserva,{dispatch}) => {
    console.log('lo que llega')

    try {
            const response = await axiosInstance.post(CHANGE_STATUS_URL, {
                id: initialReserva.id,
                // estado: initialReserva.estado,
                estado: 'Rechazada',
                RazonRechazo: initialReserva.razonRechazo,
            });

            if (response.status == 200) {
                toast.success(`Solicitud rechazada correctamente.`);
                dispatch(fetchReservas())
                return {data: response.data, status: 'success'};
            }
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el estado de la reserva: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})
export const cancelarSolicitud = createAsyncThunk("reservas/cancelarSolicitud", async (initialReserva , {dispatch}) => {
    console.log(initialReserva)
    try {
            const response = await axiosInstance.post(CANCELAR_SOLICITUD, {
                id: initialReserva.id,
                razon: initialReserva.razonRechazo,
            });

            if (response.status == 200) {
                toast.success('Solicitud cancelada correctamente.');
                dispatch(fetchReservas())
                return {data: response.data, status: 'success'};
            }
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al rechazar la reserva: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})
export const asignarHabitaciones = createAsyncThunk("reservas/asignarHabitaciones", async (initialReserva , {dispatch}) => {
    console.log(initialReserva)
    try {
            const response = await axiosInstance.post(ASIGNAR_HABITACIONES_URL, {
                id: initialReserva.id,
                razon: initialReserva.razonRechazo,
            });

            if (response.status == 200) {
                toast.success('Solicitud cancelada correctamente.');
                dispatch(fetchReservas())
                return {data: response.data, status: 'success'};
            }
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al cancelar la reserva: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})

// idle : no hay operaciones en curso
const initialState = {
    reservas: [],
    propuesta: [],
    cargos: [],
    asignaciones: [],
    reservasHuesped: [],
    trabajadores: [],
    trabajadoresAll: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusAsignaciones: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusPropuesta: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    trabajadoresStatus: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    trabajadoresAllStatus: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    cargosStatus: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const reservaSlice = createSlice({
    name:"reserva",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchCargos.pending, (state, action) => {state.cargosStatus = 'loading'} ) //fetch
        .addCase(fetchCargos.fulfilled, (state, action) => {
            state.cargosStatus = 'succeeded';
            if (JSON.stringify(state.cargosStatus) !== JSON.stringify(action.payload)) { //fetch
                state.cargos = action.payload;
            }
        })
        .addCase(fetchCargos.rejected, (state, action) => { //fetch
            state.cargosStatus = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchReservas.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchReservas.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.reservas) !== JSON.stringify(action.payload)) { //fetch
                state.reservas = action.payload;
            }
        })
        .addCase(fetchReservas.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(generarPropuesta.pending, (state, action) => {state.statusPropuesta = 'loading'} ) //fetch
        .addCase(generarPropuesta.fulfilled, (state, action) => {
            state.statusPropuesta = 'succeeded';
            if (JSON.stringify(state.reservas) !== JSON.stringify(action.payload)) { //fetch
                state.propuesta = action.payload;
            }
        })
        .addCase(generarPropuesta.rejected, (state, action) => { //fetch
            state.statusPropuesta = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchReservasHuesped.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchReservasHuesped.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.reservasHuesped) !== JSON.stringify(action.payload)) { //fetch
                state.reservasHuesped = action.payload;
            }
        })
        .addCase(fetchReservasHuesped.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchTrabajadores.pending, (state, action) => {state.trabajadoresStatus = 'loading'} ) //fetch
        .addCase(fetchTrabajadores.fulfilled, (state, action) => {
            state.trabajadoresStatus = 'succeeded';
            if (JSON.stringify(state.trabajadores) !== JSON.stringify(action.payload)) { //fetch
                state.trabajadores = action.payload;
            }
        })
        .addCase(fetchTrabajadores.rejected, (state, action) => { //fetch
            state.trabajadoresStatus = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchTrabajadoresAll.pending, (state, action) => {state.trabajadoresAllStatus = 'loading'} ) //fetch
        .addCase(fetchTrabajadoresAll.fulfilled, (state, action) => {
            state.trabajadoresAllStatus = 'succeeded';
            if (JSON.stringify(state.trabajadoresAll) !== JSON.stringify(action.payload)) { //fetch
                state.trabajadoresAll = action.payload;
            }
        })
        .addCase(fetchTrabajadoresAll.rejected, (state, action) => { //fetch
            state.trabajadoresAllStatus = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewReserva.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){    
                state.status = 'succeeded'
                state.reservas.push(action.payload);
            } else{ 
                state.status = 'failed'    
            }
        })
        .addCase(asignarHabitacionesRecepcionista.fulfilled, (state, action) => { //ADD
            if(action.payload.statusAsignaciones === 'success'){    
                state.statusAsignaciones = 'succeeded'
                state.asignaciones.push(action.payload);
            } else{ 
                state.statusAsignaciones = 'failed'    
            }
        })
        .addCase(rechazarSolicitud.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addReasignar.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
    }
})

export const getAllreservas = (state) => state.reservas.reservas;
export const getAllCargos = (state) => state.reservas.cargos;
export const getCargosStatus = (state) => state.reservas.cargosStatus;
export const getAllAsignaciones = (state) => state.reservas.asiganciones;
export const getAsignacionesStatus = (state) => state.reservas.statusAsignaciones;
export const getPropuesta = (state) => state.reservas.propuesta;
export const getAllReservasHuesped = (state) => state.reservas.reservasHuesped;
export const getAllTrabajadores = (state) => state.reservas.trabajadores;
export const getTrabajadoresStatus = (state) => state.reservas.trabajadoresStatus;
export const getTrabajadoresAll = (state) => state.reservas.trabajadoresAll;
export const getTrabajadoresAllStatus = (state) => state.reservas.trabajadoresAllStatus;
export const getReservasStatus = (state) => state.reservas.status;
export const getReservasError = (state) => state.reservas.error;

export const {} = reservaSlice.actions;
export default reservaSlice.reducer;