import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";

const WINGS_URL = '/api/v1/Wing';
const ADD_WING_URL = '/api/v1/Wing';
const UPDATE_WING_URL = '/api/v1/Wing/';
const DELETE_WING_URL = '/api/v1/Wing/';
const GET_WING_URL = '/api/v1/Wing/';

const axiosInstance = createAxiosInstance();
// ACTIONS
export const fetchWings = createAsyncThunk("wings/fetchWings", async ()=> {
    try {
        const response = await axiosInstance.get(WINGS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchWing = createAsyncThunk("wings/fetchWing", async (id)=> {
    try {
        const response = await axiosInstance.get(GET_WING_URL+id);        
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const addNewWing = createAsyncThunk("wings/createWings", async (initialCampamento, { dispatch }) => {
    try {
        console.log("llega",initialCampamento);
        const response = await axiosInstance.post(ADD_WING_URL, {
            nombre: initialCampamento.nombre,
            descripcion: initialCampamento.descripcion,
            numeroPisos: initialCampamento.piso,
            numeroHabitaciones: initialCampamento.n_habitaciones,
            campamentoId: initialCampamento.idCampamento,
            generoHuesped: initialCampamento.generoHuesped,
            standardId: initialCampamento.standard,
            });
        if (response.status === 200) {
            toast.success('Wing creado correctamente.');
            dispatch(fetchWings());
            return {data: response.data, status: 'success'};
        }
        return {status: 'error'};
      } catch (e) {
        toast.error('Error al crear el Wing: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})

export const deleteWing = createAsyncThunk("wings/deleteWing", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_WING_URL+id);
        
        if (response.status === 200) {
            toast.success('Wing eliminado correctamente.');
            dispatch(fetchWings());
            return { data: {id}, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el Wing: ' + e.response.data);
        return e.message;
    }
})
export const updateWing = createAsyncThunk("wings/updateWing", async (upWing, {dispatch})=> {
    console.log('wing', upWing);
    try {
        const response = await axiosInstance.put(UPDATE_WING_URL+upWing.id, {
            nombre: upWing.nombre,
            descripcion: upWing.descripcion,
            numeroPisos: upWing.numeroPisos,
            numeroHabitaciones: upWing.numeroHabitaciones,
            campamentoId: upWing.campamentoId,
            generoHuesped: upWing.generoHuesped,
            standardId: upWing.standardId,
        });
        
        if (response.status === 200) {
            toast.success('Wing actualizado correctamente.');
            dispatch(fetchWings());
            return { status: 'success'};
        } 
        return { data: response.data, status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el wing: ' + e.response.data);
        return e.message;
    }
})


// idle : no hay operaciones en curso
const initialState = {
    wings: [],
    wing: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const wingSlice = createSlice({
    name:"wing",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchWings.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchWings.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.wings) !== JSON.stringify(action.payload)) { //fetch
                state.wings = action.payload;
            }
        })
        .addCase(fetchWings.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchWing.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchWing.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.wing) !== JSON.stringify(action.payload)) { //fetch
                state.wing = action.payload;
            }
        })
        .addCase(fetchWing.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewWing.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){
                state.status = 'succeeded'
                state.wings.push(action.payload);
            } else{ 
                state.status = 'failed'    
            }
        })
        .addCase(deleteWing.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteWing.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.wings = state.wings.filter(wing => wing.id !== action.payload.data.id);
            } else{ 
                state.status = 'failed'    
            }
        })
        .addCase(deleteWing.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateWing.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateWing.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.wings = state.wings.map(wing => {
                    if (wing.id === action.payload.id) {
                        return action.payload;
                    }
                    return wing;
                });
            } else{ 
                state.status = 'failed'    
            }
        })
        .addCase(updateWing.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllWings = (state) => state.wings.wings;
export const getWingsStatus = (state) => state.wings.status;
export const getWingsError = (state) => state.wings.error;

export const {} = wingSlice.actions;
export default wingSlice.reducer;