// AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios, { createAxiosInstance } from '../api/axios';
import { useNavigate } from "react-router-dom";
import { getRole } from '../utils/validate';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [rol, setRol] = useState(null);
    const [token, setToken] = useState(null);
    const [info, setInfo] = useState(null);
    const Navigate = useNavigate();

    const login = async ({ email, password }) => {
        let auth_token = await axios.post('/api/v1/Auth/Login', { "Username": email, "Password": password });
        if (auth_token.data.message !== "Success") {
            console.log('response',auth_token)
            return {
                success: false,
                message: auth_token.data
            };
        } else {
            setToken(auth_token.data.token);
            const decoded = jwtDecode(auth_token.data.token);
            setUser(decoded);
            localStorage.setItem('token', auth_token.data.token);
            const role = getRole();
            setRol(role);

            await infoUser();

            if (role.includes('Supervisor de Bodega')) {
                Navigate('/notificaciones');
            } else {
                Navigate('/inicio');
            }
            
            return {
                success: true,
            };
        }
    };

    const axiosInstance = createAxiosInstance();
    const infoUser = async () => {
    
        try {
            let usuario = await axiosInstance.get('/api/v1/Auth/GetInfo', {});
            setInfo(usuario.data);
            return { success: true };
        } catch (e) {
            console.log('error al obtener información del usuario', e.message);
        }
    }

    const logout = () => { //modificar función
        setUser(null);
        setRol(null)
        setToken(null);
        localStorage.removeItem('token');
        window.location.href = '/';
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userInfo = async () => {
            try {
                await infoUser();
            } catch (error) {
                
            }
        }
        if (token) {
            const decoded = jwtDecode(token);
            setToken(token);
            setUser(decoded);
            setRol(getRole());
            userInfo();
        } else {

        }
    }, []);



    const isAuthenticated = () => !!user;


    return (
        <AuthContext.Provider value={{ user, info, infoUser, login, logout, isAuthenticated, rol, setUser, setRol, token }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
