import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, Grid, TextField, Typography, MenuItem, Popper, Select, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { updateSector } from '../../../../redux/features/sectores/sectorSlice';
import { getAllWings } from '../../../../redux/features/wings/wingSlice';
import { getAllEdificios } from '../../../../redux/features/edificios/edificioSlice';
import { liveSearchByRut } from '../../../../redux/features/users/userSlice';
import { updateOficina } from '../../../../redux/features/oficinas/oficinaSlice';

export default function Edit({ open2, setOpen2, openEdit, setOpenEdit, seleItem, setOpen3, campamentos, wings, pabellones, otros }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [selectedCampamento, setSelectedCampamento] = useState(null);
    const [selectedWing, setSelectedWing] = useState(null);
    const [selectedPabellon, setSelectedPabellon] = useState(null);
    const [selectedOtro, setSelectedOtro] = useState(null);
    const [pabellonWing, setPabellonWing] = useState(0);

    useEffect(() => {
      if (seleItem) {
        setFormData(seleItem);
        
        if (seleItem?.pabellon) {
          setPabellonWing(1);
          setFormData(prevFormData => ({
            ...prevFormData,
            pabellonId: seleItem?.pabellon.id
          }));
          setSelectedPabellon(seleItem?.pabellon);
        } else if (seleItem?.wing) {
          setPabellonWing(2);
          setFormData(prevFormData => ({
            ...prevFormData,
            wingId: seleItem?.wing
          }));
          setSelectedWing(seleItem?.wing);
        } else {
          setPabellonWing(3);
          setFormData(prevFormData => ({
            ...prevFormData,
            edificioId: seleItem?.edificio
          }));
          setSelectedOtro(seleItem?.edificio);
        }
      }
    }, [seleItem]);
    
  

    const handleChangePW = (event) => {
        setPabellonWing(event.target.value);
      };
    
      const handleChangeCampamento = (event, newValue) => {
        setSelectedCampamento(newValue);
        if(newValue != null){
          setFormData({...formData, campamento: newValue, });
          setSelectedWing(null);
          setSelectedPabellon(null);
        } else{
          setFormData({...formData, campamento: 0})
        }
      };
      const handleChangePabellon = (event, newValue) => {
        if(newValue != null){
          setSelectedWing(null);
          setFormData({...formData, pabellonId: newValue.id, wingId: null, edificioId: null, pabellon: newValue })
          setSelectedPabellon(newValue);
        } else{
          setFormData({...formData, pabellonId: 0, pabellon: null})
        }
      };
      const handleChangeWing = (event, newValue) => {
        if(newValue != null){
          setSelectedPabellon(null);
          setFormData({...formData, wingId: newValue.id, pabellonId: null, edificioId:null, wing:newValue, wing:null })
          setSelectedWing(newValue);
        } else{
          setFormData({...formData, wingId: 0})
        }
      };

      const handleChangeOtro = (event, newValue) => {
        if(newValue != null){
          setSelectedPabellon(null);
          setSelectedWing(null);
          setFormData({...formData, wingId: null, pabellonId: null, edificioId: newValue.id, edificio: newValue })
          setSelectedOtro(newValue);
        } else{
          setFormData({...formData, edificioId: 0, edificio: null})
        }
      };
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const canSave = [(formData?.pabellonId || formData?.wingId || formData?.edificioId), formData?.piso, formData?.numero, formData?.descripcion, formData?.chapa].every(Boolean) && addRequestStatus === 'idle';

  const handleEdit = async () => {
    setOpen3(true);
    try {
      setAddRequestStatus('loading');
      let resp = await dispatch(updateOficina(formData))
      if(resp.payload.status === 'success'){
          setOpenEdit(false);
      }
    } catch (error) {
        console.error('Error al actualizar la oficina', error);
    } finally{
        setAddRequestStatus('idle');
        setOpen3(false);
    }
  }

  return (
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={
          {
            sx: {
              width: { xs: '100%', sm: '75%', md: '50%' },
              backgroundColor:'#2d3037',
              color:'#FFF'
            }
          }
        }
      >
        {seleItem && (
            <>
              <DialogTitle>
                  <Grid container={true} spacing={1} p={2}>
                      <Grid item  xs={12} sm={12} mb={2} style={{ display:'flex', gap:'5px'}}>
                          <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                          <div style={{ display:'flex', flexDirection:'column' }}>
                              <Typography variant="h6" className={styles.textIconEdit}>Actualizar oficina {seleItem?.numero}</Typography>
                              <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar la oficina.</Typography>
                          </div>
                      </Grid>
                  </Grid>
              </DialogTitle> 
                <DialogContent>
                    <Grid container={true} spacing={1} p={1}>
                      <Grid item  xs={12} sm={6}>
                        <label className={styles.label}>Numero</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                          variant="outlined"
                          fullWidth
                          name='numero'
                          value={formData?.numero || null}
                          type="number"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={6}>
                        <label className={styles.label}>Piso</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                          variant="outlined"
                          fullWidth
                          name='piso'
                          value={formData?.piso || null}
                          type="number"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>

                      <Grid item  xs={12} sm={4}>
                        <label className={styles.label}>Campamento</label>
                        <Autocomplete
                        className={styles.textField}
                        sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                          disablePortal
                          disableClearable
                          id="campamentos-autocomplete"
                          name="campamento"
                          value={formData?.campamento || null}
                          options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                          getOptionLabel={(option) => option.nombre}
                          onChange={(e, value)=> handleChangeCampamento(e, value)}
                          renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                        /> 
                      </Grid>

                        <Grid item  xs={12} sm={4}>
                          <label className={styles.label}>Pabellón, Wing o Recinto</label>
                          <Select
                          className={styles.textField}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#FFF',
                            },
                          }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pabellonWing}
                            label="Age"
                            onChange={handleChangePW}
                            style={{ width:'100%' }}
                          >
                            <MenuItem value={1}>Pabellón</MenuItem>
                            <MenuItem value={2}>Wing</MenuItem>
                            <MenuItem value={3}>Recinto</MenuItem>
                          </Select>
                        </Grid>
                      {/* {selectedSector && ( */}
                      {pabellonWing == 1 && ( 
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>Pabellón</label>
                            <Autocomplete
                            className={styles.textField}
                            sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                              disablePortal
                              disableClearable
                              id="sectores-autocomplete"
                              name="wing"
                              value={selectedPabellon}
                              options={Array.isArray(pabellones) ? pabellones : [] }
                              getOptionLabel={(option) => option.identificador}
                              onChange={(e, value) => handleChangePabellon(e, value)}    
                              renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                            />

                        </Grid>
                      )} 
                      {/* {selectedCampamento && ( */}
                      {pabellonWing == 2 && ( 
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>Wing</label>
                            <Autocomplete
                            className={styles.textField}
                            sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                              disablePortal
                              disableClearable
                              id="sectores-autocomplete"
                              name="wing"
                              value={selectedWing}
                              options={wings ?  wings : [] }
                              getOptionLabel={(option) => option.nombre}
                              onChange={(e, value) => handleChangeWing(e, value)}    
                              renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                          />
                        </Grid>
                      )} 
                      {pabellonWing == 3 && ( 
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>Recinto</label>
                            <Autocomplete
                            className={styles.textField}
                            sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                              disablePortal
                              disableClearable
                              id="sectores-autocomplete"
                              name="wing"
                              value={selectedOtro || null}
                              options={otros ?  otros : [] }
                              getOptionLabel={(option) => option.nombre}
                              onChange={(e, value) => handleChangeOtro(e, value)}    
                              renderInput={(params) => <TextField {...params} label="Selecciona un recinto" />}
                          />
                        </Grid>
                      )}
                      <Grid item  xs={12} sm={12}>
                        <label className={styles.label}>Descripción (opcional)</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                          variant="outlined"
                          fullWidth
                          name='descripcion'
                          type="text"
                          value={formData?.descripcion || null}
                          multiline
                          rows={2}
                          maxRows={4}
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <label className={styles.label}>Chapa electrónica (opcional)</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                          variant="outlined"
                          fullWidth
                          name='chapa'
                          value={formData?.chapa || null}
                          type="text"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid container={true}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColorEdit}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                      </Grid>
                  </DialogActions>
            </>
        )}
      </Drawer>
  );
}