import styles from './components.module.css'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Popper, TextField, Typography } from '@mui/material';
import { trabajadoresAseoCreateColumns } from '../../../../lib/columns';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { getInfoByRut, liveSearchByRut } from '../../../../redux/features/users/userSlice';
import { addPersonalDeAseo } from '../../../../redux/features/aseo/aseoSlice';
import PersonIcon from '@mui/icons-material/Person';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  nombre: '',
  descripcion: '',
  campamento: 0,
}
const Create = ({ open2, setOpen2 }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [seleItem, setSeleItem] = useState();
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [ruts, setRuts] = useState([])
  const [selectedRut, setSelectedRut] = useState(null);
  const [selectedRuts, setSelectedRuts] = useState([])
  const [items, setItems] = useState([]);
  
  const [newItem, setNewItem] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value))
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }

  const onChangeRutSelect = async (e,newValue) => {
    if(newValue != null){
      console.log('value',newValue)
      setNewItem(newValue);
      setSelectedRuts([...selectedRuts, newValue.rut]);
      try {
        setOpen2(true);
        const resp = await dispatch(getInfoByRut(newValue.rut))
        console.log('info usuario',resp);
        setSelectedRut(resp.payload.data);
      } catch (error) {
        console.log(error)
      } finally{
        setOpen2(false);
      }
    } else{
      setFormData({...formData, rut: null})
    }
  }
  

  const addWorker = () => {
    const exists = items.some(item => item.rut === selectedRut.rut);
    if (!exists) {
      setItems([...items, selectedRut]);
    }
    setSelectedRut(null);
  };
  

  const handleDeleteItem = (item) => {
    console.log(item)
    setItems(items.filter((i) => i.rut !== item));
  };
  
  const finalColumns = trabajadoresAseoCreateColumns(setOpen, setSeleItem, handleDeleteItem);

  // const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const onChange = (e) => setSelectedRut({...selectedRut, [e.target.name]: e.target.value });

  const canSave = [items.length > 0].every(Boolean) && addRequestStatus === 'idle';
  

  const handleSubmit = async () => {
    if (canSave) {
        try {
            setOpen2(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(addPersonalDeAseo(items));

            if (resp && resp.payload && resp.payload.status === 'success') {
                setFormData(data);
                setOpen(false);
            } else {
                console.error('Error: Respuesta inesperada', resp);
            }
        } catch (error) {
            console.error('Error al asignar al personal', error);
        } finally {
            setOpen2(false);
            setAddRequestStatus('idle');
        }
    }
};



  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te permitirá asignar trabajadores al personal de aseo.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>Asignar</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
          <DialogTitle style={{ display:'flex', gap:'5px'}}>
            <PersonIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
            <div style={{ display:'flex', flexDirection:'column' }}>
              <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Asignar trabajadores al personal de aseo</Typography>
              <span className={styles.subTitle}>Busca trabajadores por rut y asignalos al personal de aseo.</span>
            </div>
        </DialogTitle>
        {/*{JSON.stringify(items)} */}
        <DialogContent>
          <Grid container={true} spacing={1} mt={1}>
            <Grid item xs={12} sm={4}>
              <div style={{ display:'flex', alignItems:'center', gap:'5px' }} className={styles.spaceRut}>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="rut"
                  value={selectedRut || null}
                  options={ruts ? ruts : [] }
                  style={{ width:'100%' }}
                  getOptionLabel={(option) => option.rut + ' ' + option.nombre}
                  onChange={(e, newValue) => onChangeRutSelect(e, newValue)}
                  renderInput={(params) => <TextField {...params} label="Busca y selecciona un rut" onChange={(e) => onChangeRut(e)}/>}
                />
              </div>

              <div style={{ marginTop:'5px'}} className={styles.cont}>
                <div>
                  <label className={styles.label}>Nombre</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='nombre'
                    type="text"
                    value={selectedRut?.nombre|| ''}
                    // onChange={(e) => onChange(e)}
                  />
                </div>
                <div>
                  <label className={styles.label}>Apellido</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='apellido'
                    type="text"
                    value={selectedRut?.apellido|| ''}
                    // onChange={(e) => onChange(e)}
                  />
                </div>
                <div>
                  <label className={styles.label}>Rut</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='nombre'
                    value={selectedRut?.rut|| ''}
                    type="text"
                    // onChange={(e) => onChange(e)}
                  />
                </div>
                <div>
                  <label className={styles.label}>Email</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='email'
                    value={selectedRut?.email|| ''}
                    type="text"
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div>
                  <label className={styles.label}>Cargo</label>
                  <TextField
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    name='nombre'
                    value={selectedRut?.nCargo|| ''}
                    type="text"
                    // onChange={(e) => onChange(e)}
                  />
                </div>
                <div style={{ marginTop:'10px' }}>
                  {selectedRut &&
                    <Button 
                      onClick={addWorker}
                      className={styles.bgColor}
                      variant="contained"
                      style={{ width:'100%' }}
                      >
                      Añadir a la solicitud
                    </Button>
                  }
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={8}>
              <DataGrid
                getRowId={row=>row.rut ? row.rut : Math.random() }
                lots={{ toolbar: GridToolbar }}
                rows={items}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#FFF',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#FFF',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#FFF', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#FFF', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#FFF', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#FFF', // Color for displayed rows text
                  },
                }}
                slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={styles.buttonContainer} style={{ width:'100%' }}>
              <div style={{ display:'flex',justifyContent:'end',alignItems:'end', width:'100%' }}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}
                  >
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Asignar trabajadores'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create