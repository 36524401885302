import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import { fetchWings, getAllWings, getWingsStatus } from "../../../redux/features/wings/wingSlice";
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from "../../../redux/features/edificios/edificioSlice";
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from "../../../redux/features/habitaciones/habitacionSlice";
import NightShelterIcon from '@mui/icons-material/NightShelter';
import { fetchOtros, getAllOtros, getOtrosStatus } from "../../../redux/features/otros/otroSlice";
import { fetchGruposAseo, getAllGruposAseos, getGruposAseoStatus } from "../../../redux/features/aseo/aseoSlice";

const Grupos = () => {

  const dispatch = useDispatch();
  const grupos = useSelector(getAllGruposAseos);
  const status = useSelector(getGruposAseoStatus);
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const wings = useSelector(getAllWings);
  const winStatus = useSelector(getWingsStatus);
  const pabellones = useSelector(getAllEdificios);
  const pabStatus = useSelector(getEdificiosStatus);
  const otros = useSelector(getAllOtros);
  const otStatus = useSelector(getOtrosStatus);
  const habitaciones = useSelector(getAllHabitaciones);
  const statusHabitaciones = useSelector(getHabitacionesStatus);
  const [selectedHabitacionIds, setSelectedHabitacionIds] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchGruposAseo());
    }
    if(camStatus == 'idle'){
      dispatch(fetchCampamentos());
    }
    if(winStatus == 'idle'){
      dispatch(fetchWings());
    }
    if(pabStatus == 'idle'){
      dispatch(fetchEdificios());
    }
    if(otStatus == 'idle'){
      dispatch(fetchOtros()); 
    }
    if(statusHabitaciones =='idle'){
      dispatch(fetchHabitaciones());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de grupos de habitaciones"
          subtitle="En este módulo, podrás crear grupos de habitaciones para la asignaciòn del personal aseo.">
          {<NightShelterIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>
        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} campamentos={campamentos} setOpen3={setOpen3} wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros}/>
          { status == 'loading' ? <SkeletonTable/> : <Table selectedHabitacionIds={selectedHabitacionIds} setSelectedHabitacionIds={setSelectedHabitacionIds} registros={grupos} setOpen3={setOpen3} open3={open3} habitaciones={habitaciones}/> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Grupos