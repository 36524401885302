import styles from './components.module.css';
import { Box, Tab, Tabs } from "@mui/material"
import PropTypes from 'prop-types';
import { Reservas } from './Reservas';
import Detalle from './Detalle';
import Habitaciones from './Habitaciones';
import VistaPrevia from './VistaPrevia';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const TabsContainer = ({ setValue, value, seleItem, checkedState, check, checkedHabitaciones}) =>{

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="scrollable auto tabs example">
        <Tab label="Reservas" {...a11yProps(0)} />
        <Tab label="Detalle Reserva" {...a11yProps(1)} disabled={!seleItem} />
        <Tab label="Habitaciones" {...a11yProps(2)} disabled={checkedState.length == 0}/>
        <Tab label="Vista Previa" {...a11yProps(3)} disabled={!check || checkedHabitaciones.length === 0} />
      </Tabs>
    </Box>
  )
}

const Table = ({ value, setValue, setFormData, formData, solicitudes, seleItem, setSeleItem, setOpenDetalle, trabajadores, checkedState, setCheckedState, checkedHabitaciones, setCheckedHabitaciones, asignar, asignacionHabitacionesTrabajadores, setAsignacionHabitacionesTrabajadores, setCheck, check, setEenvioS, envioS, formatFecha}) => {
  return (
    <>
        <CustomTabPanel value={value} index={0}>
            <Reservas seleItem={seleItem} setSeleItem={setSeleItem} solicitudes={solicitudes} setOpenDetalle={setOpenDetalle} setValue={setValue}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Detalle 
            formatFecha={formatFecha}
            reserva={seleItem} 
            setAsignacionHabitacionesTrabajadores={setAsignacionHabitacionesTrabajadores}
            trabajadores={trabajadores}
            checkedState={checkedState} 
            setCheckedState={setCheckedState}
            setCheckedHabitaciones={setCheckedHabitaciones}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
            <Habitaciones 
              asignacionHabitacionesTrabajadores={asignacionHabitacionesTrabajadores}
              seleItemSolicitud={seleItem}
              seleccionados={checkedState} 
              checkedHabitaciones={checkedHabitaciones} 
              setCheckedHabitaciones={setCheckedHabitaciones}
              asignar={asignar}
              setCheck={setCheck}
              check={check}
              setEenvioS={setEenvioS}
              envioS={envioS}
              setAsignacionHabitacionesTrabajadores={setAsignacionHabitacionesTrabajadores}
            />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <VistaPrevia formatFecha={formatFecha} checkedState={checkedState} reserva={seleItem} checkedHabitaciones={checkedHabitaciones} envioS={envioS}/>
        </CustomTabPanel>

    </>
  )
}

export default Table