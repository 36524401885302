import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";

const SECTORES_URL = '/api/v1/Sector';
const SECTOR_URL = '/api/v1/Sector/';
const DELETE_SECTOR_URL = '/api/v1/Sector/';
const UPDATE_SECTOR_URL = '/api/v1/Sector/'
const ADD_SECTOR_URL = '/api/v1/Sector';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchSectores = createAsyncThunk("sectores/fetchSectores", async ()=> {
    try {
        const response = await axiosInstance.get(SECTORES_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchSector = createAsyncThunk("sector/fetchSector", async (id)=> {
    try {
        const response = await axiosInstance.get(SECTOR_URL+id);        
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteSector = createAsyncThunk("sectores/deleteSector", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_SECTOR_URL+id);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Sector eliminado correctamente.');
            dispatch(fetchSectores());
            return { data: {id} ,status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el sector: ' + e.response.data);
        return e.message;
    }
})
export const updateSector = createAsyncThunk("sectores/updateSector", async (upSector, {dispatch})=> {
    console.log('sector', upSector);
    try {
        const response = await axiosInstance.put(UPDATE_SECTOR_URL+upSector.id, {
            Nombre: upSector.nombre,
            // campamentoId: upSector.campamentoId,
            descripcion: upSector.descripcion,
        });
        
        if (response.status === 200) {
            toast.success('Sector actualizado correctamente.');
            dispatch(fetchSectores());
            return { data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el sector: ' + e.response.data);
        return e.message;
    }
})

export const addNewSector = createAsyncThunk("sectores/createSectores", async (initialCampamento, { dispatch }) => {
    try {
        const response = await axiosInstance.post(ADD_SECTOR_URL, {
            nombre: initialCampamento.nombre,
            // campamentoId: initialCampamento.campamento,
            descripcion: initialCampamento.descripcion,
            });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Sector creado correctamente.');
            dispatch(fetchSectores());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear el sector: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})



// idle : no hay operaciones en curso
const initialState = {
    sectores: [],
    sector: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const sectorSlice = createSlice({
    name:"sector",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchSectores.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchSectores.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.sectores) !== JSON.stringify(action.payload)) { //fetch
                state.sectores = action.payload;
            }
        })
        .addCase(fetchSectores.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchSector.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchSector.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.sector) !== JSON.stringify(action.payload)) { //fetch
                state.sector = action.payload;
            }
        })
        .addCase(fetchSector.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewSector.fulfilled, (state, action) => { //ADD
            state.status = 'succeeded'
            state.sectores.push(action.payload);
        })
        .addCase(deleteSector.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteSector.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.sectores = state.sectores.filter(sector => sector.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteSector.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateSector.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateSector.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.sectores = state.sectores.map(sector => {
                    if (sector.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return sector;
                });
            }else{
                state.status = 'failed';    
            }
        })
        .addCase(updateSector.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllSectores = (state) => state.sectores.sectores;
export const getSector = (state) => state.sectores.sector;
export const getSectoresStatus = (state) => state.sectores.status;
export const getSectoresError = (state) => state.sectores.error;

export const {} = sectorSlice.actions;
export default sectorSlice.reducer;