import styles from './components/components.module.css';
import { useState } from "react";
import { habitacionesNoAsignadasColumns } from "../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card } from '@mui/material';
import SkeletonTable from '../../components/Skeleton'
import Asignar from './Asignar';

const TableHabNoAsignadas = ({rows, addRequestStatus, setOpen3, grupos, personal, selectedItems, setSelectedItems, open3, planificaciones}) => {
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [openEdit, setOpenEdit] = useState(false);
    const finalColumns = habitacionesNoAsignadasColumns(setOpen, setSeleItem, setOpenEdit);

    const handleRowSelectionModelChange = (selectionModel) => {
      // const selectedRows = selectionModel.map((id) => {
      //   return rows.find((row) => row.id === id);
      // });
      // setSelectedItems(selectedRows.map((row) => ({ ...row.id })));
      setSelectedItems(selectionModel);
    };

  return (
    <div style={{ width:'100%' }}>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        : <Card style={{ padding: '16px', backgroundColor:'var(--inst-card-bg)', color:'#FFF' }}>
              
            <Asignar openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3} grupos={grupos} personal={personal} selectedItems={selectedItems} planificaciones={planificaciones}/>
               
                <DataGrid 
                checkboxSelection
                // rowSelectionModel={selectedItems.map(item => item.id)}
                rowSelectionModel={selectedItems}
                onRowSelectionModelChange={handleRowSelectionModelChange}
                getRowId={row=>row.id ? row.id : Math.random() }
                lots={{ toolbar: GridToolbar }}
                rows={rows}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                  sx={{ 
                    '& .MuiDataGrid-cell': {
                      color: '#FFF',
                      borderBottom: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-columnHeader': {
                      color: '#FFF',
                      borderBottom: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderBottom: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-footerContainer': {
                      color: '#FFF',
                      borderTop: '1px solid #c6c6c6',
                    },
                    '& .MuiTablePagination-root': {
                      color: '#FFF',
                      borderTop: '1px solid #c6c6c6',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                      color: '#FFF',
                    },
                    '& .MuiDataGrid-menu': {
                      color: '#FFF',
                    },
                    '& .MuiTablePagination-actions': {
                      color: '#FFF', // Color for pagination actions
                    },
                    '& .MuiTablePagination-select': {
                      color: '#FFF', // Color for pagination select
                    },
                    '& .MuiTablePagination-selectLabel': {
                      color: '#FFF', // Color for pagination select label
                    },
                    '& .MuiTablePagination-displayedRows': {
                      color: '#FFF', // Color for displayed rows text
                    },
                  }}
                  slotProps={{
                    toolbar:{
                      sx:{
                        color:'#FFF'
                      }
                    },
                    pagination: {
                      sx: {
                        color: '#FFF',
                      },
                    },
                    menu: {
                      sx: {
                        color: '#FFF',
                      },
                    },
                  }}
            />
        </Card>
     }
    </div>
  )
}

export default TableHabNoAsignadas