import styles from './components.module.css';
import { useEffect, useState } from "react";
import { reservasCMDICColumns } from "../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import CustomToolbar from '../../components/SlotsToolBar';

const TableAsignaciones = ({ asignaciones, setSelectedPabellon, setSelectedWing, setSelectedRut, setPabellonWing, habitaciones, setHabitacionesList, setSelectedHabitacion, setSelectedPiso, setSelectedCampamento, selectedRut, setfilterHabitaciones, filterHabitaciones, setInfoHabitaciones }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reservasCMDICColumns(setOpen, setSeleItem, setOpenEdit);

  const handleRowSelectionChange = (selection) => {
    console.log('entra aqui -->');
    const selectedID = selection[0];
    const selectedData = asignaciones.find(row => row.trabajador.rut === selectedID);
    console.log('Selected Data:', selectedData);
    // console.log('Selected Data HABITACIÓN:', selectedData.habitacion);
    console.log('Selected Data HABITACIÓN:', selectedData.habitacion );

    if (!selectedData) {
        console.log('No data found for the selected ID');

        setSelectedRut(null);
        setPabellonWing(0);
        setSelectedPabellon(null);
        setSelectedWing(null);
        setSelectedHabitacion(null);
        setSelectedPiso(null);
        setHabitacionesList([]);
        return;
    }

    setSelectedRut(selectedData);
    setSelectedCampamento(selectedData.campamento ?? null)
    setPabellonWing(selectedData.pabellon ? 1 : selectedData.wing ? 2 : 0);
    setSelectedPabellon(selectedData.pabellon ?? null);
    setSelectedWing(selectedData.wing ?? null);
    setSelectedHabitacion(selectedData.habitacion ?? null);
    setSelectedPiso(selectedData.habitacion.piso);

    const filterHabitacio = filterHabitaciones.filter(item => {
      // Si existe el pabellonId, comparamos con el pabellonId del selectedRut
      if (item.pabellon && selectedData.pabellon) {
          return item.pabellon.id === selectedData.pabellon.id;
      }
      // Si existe el wingId, comparamos con el wingId del selectedData
      if (item.wing && selectedData.wing) {
          return item.wing.id === selectedData.wing.id;
      }
      // Si no cumple ninguna de las anteriores, no incluimos el item en el resultado
      return false;
  });
  
  setInfoHabitaciones(filterHabitacio);


    const filterHabitacion = habitaciones.filter(item => {
      const selectedPabellonId = selectedData.pabellon?.id;
      const selectedWingId = selectedData.wing?.id;
      const itemPabellonId = item.pabellon?.id;
      const itemWingId = item.wing?.id;
    
      return (selectedPabellonId && itemPabellonId === selectedPabellonId) ||
             (selectedWingId && itemWingId === selectedWingId);
    });
    console.log('filterHabitacio:',filterHabitacio)

    setHabitacionesList(filterHabitacion);
};


  return (
    <DataGrid
        getRowId={row => row.trabajador.rut || Math.random()}
        components={{
          Toolbar: CustomToolbar
        }}
        rows={asignaciones}
        columns={finalColumns}
        disableMultipleSelection
        onRowSelectionModelChange={handleRowSelectionChange}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
        }}
        classes={{ menuIcon: styles.menuIcon }}
        style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
        sx={{ 
          '& .MuiDataGrid-cell': {
            color: '#FFF',
            borderBottom: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-columnHeader': {
            color: '#FFF',
            borderBottom: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-footerContainer': {
            color: '#FFF',
            borderTop: '1px solid #c6c6c6',
          },
          '& .MuiTablePagination-root': {
            color: '#FFF',
            borderTop: '1px solid #c6c6c6',
          },
          '& .MuiDataGrid-toolbarContainer': {
            color: '#FFF',
          },
          '& .MuiDataGrid-menu': {
            color: '#FFF',
          },
          '& .MuiTablePagination-actions': {
            color: '#FFF', // Color for pagination actions
          },
          '& .MuiTablePagination-select': {
            color: '#FFF', // Color for pagination select
          },
          '& .MuiTablePagination-selectLabel': {
            color: '#FFF', // Color for pagination select label
          },
          '& .MuiTablePagination-displayedRows': {
            color: '#FFF', // Color for displayed rows text
          },
         }}
        slotProps={{
          toolbar:{
            sx:{
              color:'#FFF'
            }
          },
          pagination: {
            sx: {
              color: '#FFF',
            },
          },
          menu: {
            sx: {
              color: '#FFF',
            },
          },
        }}
    />
  );
}

export default TableAsignaciones;
