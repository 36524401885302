import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { liveSearchByRut } from '../../../../redux/features/users/userSlice';
import { addNewPlanificacionEspecificaAseo } from '../../../../redux/features/aseo/aseoSlice';
import { format, addMonths, isBefore, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import CircleIcon from '@mui/icons-material/Circle';


const data = {
  nombre: '',
  descripcion: '',
  wing: [],
  pabellon: [],
  edificio: [],
  hora: '',
  piso: '',
  trabajadoresId: [],
}
const Create = ({ open2, setOpen2, campamentos, wings, pabellones, otros, personal }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedCampamento, setSelectedCampamento] = useState('');
  const [selectedWing, setSelectedWing] = useState([]);
  const [selectedOtro, setSelectedOtro] = useState([]);
  const [selectedPabellon, setSelectedPabellon] = useState([]);
  const [pabellonWing, setPabellonWing] = useState(0);
  const [ruts, setRuts] = useState([])
  const [selectedRuts, setSelectedRuts] = useState([])
  const [selectedDays, setSelectedDays] = useState([]);
  
  const [alarmaFecha, setAlarmaFecha] = useState('');
  const [alarmaHora, setAlarmaHora] = useState('');
  const [error, setError] = useState('');

  const handleAlarmaFechaChange = (event) => {
    setAlarmaFecha(event.target.value);
    setFormData({...formData, fechaAlarma: event.target.value });
  };

  const handleAlarmaHoraChange = (event) => {
    setAlarmaHora(event.target.value);
    setFormData({...formData, horaAlarma: event.target.value });
  };

  const handleAlarm = (event) => {
    event.preventDefault();

    const formFechaHora = parseISO(`${formData.fecha}T${formData.hora}`);
    const alarmaFechaHora = parseISO(`${alarmaFecha}T${alarmaHora}`);
    const fechaLimite = addMonths(formFechaHora, -1);

    if (isBefore(alarmaFechaHora, formFechaHora) && !isBefore(alarmaFechaHora, fechaLimite)) {
      setError('');
      // Aquí puedes manejar el caso cuando la alarma es válida
      toast.success('Alarma válida:', alarmaFechaHora);
    } else {
      toast.error('La alarma debe ser hasta 1 mes antes de la fecha y hora proporcionadas.');
    }
  };

  const handleChangePW = (event) => setPabellonWing(event.target.value)

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, campamento: newValue.id, });
      setSelectedWing([]);
      setSelectedPabellon([]);
    } else{
      setFormData({...formData, campamento: 0})
    }
  };
  
  const handleChangePabellon = (event, newValue) => {
    if(newValue != null){
      setSelectedWing([]);
      setFormData({...formData, pabellon: newValue.map(item => item.id), wing: [], otro: [] })
      setSelectedPabellon(newValue);
    } else{
      setFormData({...formData, pabellon: 0})
    }
  };
  const handleChangeWing = (event, newValue) => {
    if(newValue != null){
      setSelectedPabellon([]);
      setFormData({...formData, wing:  newValue.map(wing => wing.id), pabellon: [], otro: [] })
      setSelectedWing(newValue);
    } else{
      setFormData({...formData, wing: 0})
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if(newValue != null){
      setSelectedPabellon([]);
      setSelectedWing([]);
      setFormData({...formData, wing: [], pabellon: [], otro: newValue.map(wing => wing.id) })
      setSelectedOtro(newValue);
    } else{
      setFormData({...formData, wing: 0})
    }
  };

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value))
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }
  const onChangeRutSelect = async (e,newValue) => {
    
    if(newValue != null){
      setFormData({...formData, trabajadoresId: newValue.map(item => item.rut)})
      setSelectedRuts({...selectedRuts, newValue});

    } else{
      setFormData({...formData, trabajadoresId: null})
    }
  }
  
  const onChange = (e) => {
      setSelectedDays([]);
      setFormData({...formData, [e.target.name]: e.target.value });
  }

    const handleDayChange = (event) => {
      const { value, checked } = event.target;
      if (checked) {
        setSelectedDays((prevSelected) => [...prevSelected, value]);
      } else {
        setSelectedDays((prevSelected) => prevSelected.filter((day) => day !== value));
      }
    };

    useEffect(() => {
      setFormData(prev => ({...prev, days: selectedDays}))
    }, [selectedDays])
    

  const canSave = [formData.nombre, (formData.wing.length > 0 || formData.pabellon.length > 0 || formData.edificio.length > 0), formData.fecha, formData.trabajadoresId, formData.piso].every(Boolean) && addRequestStatus === 'idle';
  

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewPlanificacionEspecificaAseo(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setSelectedCampamento([])
          setSelectedPabellon([])
          setSelectedWing([])
          setSelectedRuts([])
          setSelectedOtro([])
          setPabellonWing(0)
          setSelectedDays([])
          setOpen(false);
        }
      } catch (error) {
        console.error('Error al crear la planificación especifica', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder crear una planificación.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>  Crear registro</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <BookmarkAddIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Crear planificación</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear la planificación.</span>
          </div>
        </DialogTitle>
        {/*{JSON.stringify(formData)} */}
        <DialogContent>
          <Grid container={true} spacing={1}>

          <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Nombre</label>
              <TextField
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                variant="outlined"
                fullWidth
                name='nombre'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Fecha</label>
              <TextField
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                variant="outlined"
                fullWidth
                name='fecha'
                type="date"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Hora</label>
              <TextField
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                variant="outlined"
                fullWidth
                name='hora'
                type="time"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                variant="outlined"
                fullWidth
                name='descripcion'
                type="text"
                multiline
                rows={2}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
               className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                name="campamento"
                options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField
                  className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }} {...params} label="Selecciona un campamento" />}
              />
            </Grid>
            {/* { selectedCampamento &&  */}
            
              {/*<Grid item  xs={12} sm={12}>
                <Typography><small><strong>Busca y selecciona un pabellón o un wing.</strong></small></Typography>
              </Grid> */}

              <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Pabellón, Wing, recinto</label>
              <Select
              className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pabellonWing}
                label="Age"
                onChange={handleChangePW}
                style={{ width:'100%' }}
              >
                <MenuItem value={1}>Pabellón</MenuItem>
                <MenuItem value={2}>Wing</MenuItem>
                <MenuItem value={3}>Recinto</MenuItem>
              </Select>
            </Grid>
            {/* {selectedSector && ( */}
            {pabellonWing == 0 && ( 
              <Grid item  xs={12} sm={10}>
                  <label className={styles.label}>---</label>
                  <Autocomplete
                   className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="pabellon"
                    multiple
                    disabled
                    value={selectedPabellon}
                    options={pabellones ? pabellones : [] }
                    getOptionLabel={(option) => option.identificador}
                    onChange={handleChangePabellon}    
                    renderInput={(params) => <TextField
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }} {...params} label="" />}
                />
              </Grid>
             )}
            {pabellonWing == 1 && ( 
              <Grid item  xs={12} sm={10}>
                  <label className={styles.label}>Pabellón</label>
                  <Autocomplete
                   className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="pabellon"
                    multiple
                    value={selectedPabellon}
                    options={pabellones ? pabellones : [] }
                    getOptionLabel={(option) => option.identificador}
                    onChange={handleChangePabellon}    
                    renderInput={(params) => <TextField
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }} {...params} label="Selecciona un pabellón" />}
                />
              </Grid>
             )} 
            {/* {selectedCampamento && ( */}
            {pabellonWing == 2 && ( 
              <Grid item  xs={12} sm={10}>
                  <label className={styles.label}>Wing</label>
                  <Autocomplete
                   className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                    multiple
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedWing}
                    options={wings || []}
                    getOptionLabel={(option) => option.nombre}
                    onChange={handleChangeWing}
                    renderInput={(params) => <TextField
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }} {...params} label="Selecciona un wing" />}
                  />
              </Grid>
            )} 
            {pabellonWing == 3 && ( 
              <Grid item  xs={12} sm={10}>
                  <label className={styles.label}>Recinto</label>
                  <Autocomplete
                   className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                    disablePortal
                    multiple
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedOtro}
                    options={otros ?  otros : [] }
                    getOptionLabel={(option) => option.nombre}
                    onChange={handleChangeOtro}    
                    renderInput={(params) => <TextField
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }} {...params} label="Selecciona un recinto" />}
                />
              </Grid>
            )} 
            <Grid item  xs={12} sm={2}>
              <label className={styles.label}>Piso</label>
              <TextField
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
              variant="outlined"
              fullWidth
              name='piso'
              type="number"
              onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Asignar trabajadores</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="rut"
                  value={formData?.rut}
                  options={personal ? personal : [] }
                  multiple
                  getOptionLabel={(option) => option.rut + ' ' + option.nombre + ' ' + option.apellido}
                  onChange={(e, value) => onChangeRutSelect(e, value)}
                  renderInput={(params) => 
                    <TextField
                      className={styles.textField}
                      sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color: '#FFF',
                            },
                      }} 
                      {...params} 
                      label="Busca trabajadores por rut"/>
                    }
                  />

            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Repetir planificación</label>
              <Select
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData?.frecuencia}
                label="Age"
                name='frecuencia'
                style={{ width:'100%' }}
                onChange={(e) => onChange(e)}
                
              >
                <MenuItem value="diariamente">Diariamente</MenuItem>
                <MenuItem value="Ciertos dias de la semana">Ciertos días de la semana</MenuItem>
                <MenuItem value="semanalmente">Semanalmente</MenuItem>
                <MenuItem value="mensualmente">Mensualmente</MenuItem>
                <MenuItem value="semestralmente">Semestralmente</MenuItem>
                <MenuItem value="anualmente">Anualmente</MenuItem>
              </Select>
            </Grid>
            {formData?.frecuencia === 'Ciertos dias de la semana' && (
              <Grid item xs={12} sm={12}>
                {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((day) => (
                  <FormControlLabel
                    key={day}
                    control={
                      <Checkbox
                        checked={selectedDays.includes(day)}
                        onChange={handleDayChange}
                        value={day}
                      />
                    }
                    label={day}
                  />
                ))}
                </Grid>
              )}
              {/*<Grid item  xs={12} sm={12}>
                <label className={styles.label}>Alarma (Opcional)</label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  label="Fecha de Alarma"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={alarmaFecha}
                  disabled={!formData?.fecha || !formData?.hora}
                  onChange={handleAlarmaFechaChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  label="Hora de Alarma"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  value={alarmaHora}
                  disabled={!formData?.fecha || !formData?.hora}
                  onChange={handleAlarmaHoraChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" className={ styles.bgColorBlue } onClick={handleAlarm}>
                  Establecer Alarma
                </Button>
              </Grid> */}
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create