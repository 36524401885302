import styles from './components/components.module.css'
import { Backdrop, Box, Card, Container, Typography, CircularProgress} from "@mui/material"
import Hero from './components/Hero';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CardSlider from './components/Slider';
import { useDispatch, useSelector } from "react-redux";
import { fetchCampamento, fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { useEffect, useState } from "react";
import Table from './components/Table';
import TableReclamos from './components/TableReclamos';
import Create from './components/Create';
import TableReserva from './components/TableReserva';
import CreateReserva from './components/CreateReserva';
import { fetchReclamosSugerencias, fetchReclamosSugerenciasHuesped, getAllReclamosSugerencias, getReclamosSugerenciasStatus } from '../../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice';
import { fetchMotivos, getAllMotivos, getMotivosStatus } from '../../../redux/features/motivos/motivoSlice';
import Header from '../../../components/header/Header';
import { fetchWings, getAllWings, getWingsStatus } from '../../../redux/features/wings/wingSlice';
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from '../../../redux/features/edificios/edificioSlice';
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../../redux/features/habitaciones/habitacionSlice';
import { fetchOtros, getAllOtros, getOtrosStatus } from '../../../redux/features/otros/otroSlice';
import TableOficinas from './components/TableOficinas';
import { fetchOficinas, fetchOficinasDisponibles, getAllOficinas, getAllOficinasDisponibles, getOficinasDisponiblesStatus, getOficinasStatus } from '../../../redux/features/oficinas/oficinaSlice';
import SkeletonTable from '../../../components/Skeleton';
import { fetchReservas, fetchTrabajadores, fetchTrabajadoresAll, getAllTrabajadores, getAllreservas, getReservasStatus, getTrabajadoresAll, getTrabajadoresAllStatus, getTrabajadoresStatus } from '../../../redux/features/reservas/reservaSlice';
import { fetchEmpresas, getAllEmpresas, getEmpresasStatus } from '../../../redux/features/empresas/empresaSlice';
import { fetchEspacios, getAllEspacios, getEspaciosStatus } from '../../../redux/features/espacios/espacioSlice';
import Reasignar from './components/Reasignar2';
import VerInfoTrabajador from './components/VerInfoTrabajador';
import VerDetallesSolicitud from './components/asignarHabitaciones/VerDetallesSolicitud';

const DashboardRecepcionista = () => {

    const campamentos = useSelector(getAllCampamentos);
    const camStatus = useSelector(getCampamentosStatus);
    
    const wings = useSelector(getAllWings);
    const winStatus = useSelector(getWingsStatus);
    const otros = useSelector(getAllOtros);
    const otStatus = useSelector(getOtrosStatus);
    const pabellones = useSelector(getAllEdificios);
    const pabStatus = useSelector(getEdificiosStatus);
    const habitaciones = useSelector(getAllHabitaciones);
    const habStatus = useSelector(getHabitacionesStatus);
    const espacios = useSelector(getAllEspacios);
    const espStatus = useSelector(getEspaciosStatus);
    const motivos = useSelector(getAllMotivos);
    const motStatus = useSelector(getMotivosStatus);
    const oficinas = useSelector(getAllOficinasDisponibles);
    const ofStatus = useSelector(getOficinasDisponiblesStatus);
    const reclamos_sugerencias = useSelector(getAllReclamosSugerencias);
    const status = useSelector(getReclamosSugerenciasStatus);
    const reservas = useSelector(getAllreservas);
    const resStatus = useSelector(getReservasStatus);
    const trabajadores = useSelector(getTrabajadoresAll);
    const traStatus = useSelector(getTrabajadoresAllStatus);

    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const [open, setOpen] = useState(false);
    const [openReasignar, setOpenReasignar] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    
    const [loaderList, setLoaderList] = useState(false);
    
    const [seleItem, setSeleItem] = useState();
    const dispatch = useDispatch();
    
    useEffect(()=>{
        if(status === 'idle'){
          dispatch(fetchReclamosSugerencias());
        }
        if(motStatus === 'idle'){
          dispatch(fetchMotivos());
        }
        if(ofStatus === 'idle'){
          dispatch(fetchOficinasDisponibles());
        }
        if(camStatus === 'idle'){
            dispatch(fetchCampamentos());
        }
        if(winStatus === 'idle'){
          dispatch(fetchWings());
        }
        if(pabStatus === 'idle'){
          dispatch(fetchEdificios());
        }
        if(habStatus === 'idle'){
          dispatch(fetchHabitaciones());
        }
        if(resStatus === 'idle'){
          dispatch(fetchReservas());
        }
        if(otStatus === 'idle'){
          dispatch(fetchOtros());
        }
        if(espStatus === 'idle'){
          dispatch(fetchEspacios());
        }
        if(traStatus === 'idle'){
          // dispatch(fetchTrabajadoresAll());
        }
      },[]);

  return (
    <div>
    <Backdrop
          sx={{ color: '#fff', zIndex: '100000' }}
          open={open3}
        >
          <CircularProgress color="inherit" />
      </Backdrop>

        <Box width={1} mt={0} mb={2}>
            <Container className={styles.container} style={{ padding:0 }}>
              {/* <Card style={{ width:'100%', padding:'16px', marginBottom:'10px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-orange)' }}>
                <Reasignar open2={openReasignar} setOpen2={setOpenReasignar} status={traStatus} trabajadores={trabajadores} pabellones={pabellones} wings={wings}/>
              </Card>
              <Card style={{ width:'100%', padding:'16px', marginBottom:'10px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-orange)' }}>
                <VerInfoTrabajador open2={openReasignar} setOpen2={setOpenReasignar} status={traStatus} trabajadores={trabajadores} pabellones={pabellones} wings={wings} criticidadRequerimientos={motivos} habitaciones={habitaciones}/>
              </Card> */}
                <Card style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', borderRadius: '0px' }}>
                    <div>
                        <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
                        {/* <TextsmsIcon style={{ fontSize:'1.5rem' }}/> */}
                        Reservas Actuales
                    </Typography>
                    <span>A continuación ver y modificar las reservas actuales en el sistema.</span>
                    </div>
                     <VerDetallesSolicitud setOpen2={setOpen4} open2={open4} open={open} setOpen={setOpen} seleItem={seleItem} campamentos={campamentos} wings={wings}  setSeleItem={setSeleItem} pabellones={pabellones} setLoaderList={setLoaderList} loaderList={loaderList}/>
                     {/*<CreateReserva setOpen2={setOpen4} open2={open4} open={open} setOpen={setOpen} seleItem={seleItem} campamentos={campamentos} wings={wings}  setSeleItem={setSeleItem} pabellones={pabellones} setLoaderList={setLoaderList} loaderList={loaderList}/> */}
                </Card>
                
                { resStatus == 'loading' ? <SkeletonTable/> :   <TableReserva reservas={reservas} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open={open} setOpen={setOpen} setSeleItem={setSeleItem}/> }

                {/* <Card mt={2} style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', marginTop:'16px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-blue)' }}>
                    <div>
                        <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
                        <TextsmsIcon style={{ fontSize:'1.5rem' }}/>
                        Oficinas disponibles
                    </Typography>
                      <span>A continuación podrás ver un listado de oficinas disponibles por cada campamento registrado.</span>
                    </div>

                </Card>
                { ofStatus == 'loading' ? <SkeletonTable/> :<TableOficinas oficinas={oficinas.data || []} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open3={open3}/>} */}
                
                <Card mt={2} style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', borderRadius: '0px' }}>
                    <div>
                        <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
                        {/* <TextsmsIcon style={{ fontSize:'1.5rem' }}/> */}
                        Reclamos y Sugerencias
                    </Typography>
                    <span>A continuación podrás ingresar un reclamo o sugerencia de un huesped.</span>
                    </div>
                    {/* <Create setOpen2={setOpen2} open2={open2} motivos={motivos} campamentos={campamentos} wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros} espacios={espacios}/> */}
                </Card>
                
                { status == 'loading' ? <SkeletonTable/> :<TableReclamos reclamos_sugerencias={reclamos_sugerencias} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open3={open3}/>}
                <Card mt={2} style={{ width:'100%', padding:'16px', backgroundColor: '#1d1d1b', color: '#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <span style={{ fontSize: '12px' }}>Powered by</span>
                  <img src={process.env.REACT_APP_LOGO_POWERED} alt="loginImg" style={{ width:'45px', marginLeft: '4px', filter: 'grayscale(100%)' }} />
                </div>
                </Card>
            </Container>
        </Box>
    </div>
  )
}

export default DashboardRecepcionista


// <div>
//       <Backdrop
//           sx={{ color: '#fff', zIndex: '100000' }}
//           open={open3}
//         >
//           <CircularProgress color="inherit" />
//       </Backdrop>

//       <Box sx={{ width: '100%', typography: 'body1' }}>
//             <TabContext value={value}>
//               <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                 <TabList onChange={handleChange} aria-label="lab API tabs example">
//                   <Tab label="Item One" value="1" tabIndex={-1} />
//                   <Tab label="Item Two" value="2" tabIndex={-1} />
//                   <Tab label="Item Three" value="3" tabIndex={-1} />
//                 </TabList>
//               </Box>
//               <TabPanel value="1">
//                 <Card style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap' }}>
//                     <div>
//                         <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
//                         <TextsmsIcon style={{ fontSize:'1.5rem' }}/>
//                         Reservas Actuales
//                     </Typography>
//                     <span>A continuación ver y modificar las reservas actuales en el sistema.</span>
//                     </div>

//                     <CreateReserva setOpen2={setOpen4} open2={open4} open={open} setOpen={setOpen} seleItem={seleItem} campamentos={campamentos} wings={wings} habitaciones={habitaciones} />
//                 </Card>
                
//                   <TableReserva rows={reservas} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open={open} setOpen={setOpen} setSeleItem={setSeleItem}/>
//               </TabPanel>
//               <TabPanel value="2">
//                 <Card mt={2} style={{ width:'100%', padding:'16px', display:'flex', alignItems:'center', justifyContent:'space-between', flexWrap:'wrap', marginTop:'16px' }}>
//                     <div>
//                         <Typography variant="h5" className={styles.header} style={{ display:'flex', alignItems:'center' }}>
//                         <TextsmsIcon style={{ fontSize:'1.5rem' }}/>
//                         Reclamos y Sugerencias
//                     </Typography>
//                     <span>A continuación podrás ingresar un reclamo o sugerencia de un huesped.</span>
//                     </div>
//                     <Create setOpen2={setOpen2} open2={open2} motivos={motivos} campamentos={campamentos} wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros}/>
//                 </Card>
                
//                 <TableReclamos reclamos_sugerencias={reclamos_sugerencias} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open3={open3}/>
//               </TabPanel>
//               <TabPanel value="3">Item Three</TabPanel>
//             </TabContext>
//           </Box>

//         <Box width={1} mt={0} mb={2}>
//             <Container className={styles.container} style={{ padding:0 }}>
                
//             </Container>
//         </Box>
//     </div>