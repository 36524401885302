import styles from './components.module.css';
import {  Backdrop, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid,  MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState, forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Slide from '@mui/material/Slide';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { addTareaAsignada } from '../../../../redux/features/aseo/aseoSlice.js';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const options = ["Habitación ocupada","Habitación sin uso","Habitación en mantención","Otro"];

export default function ModalChange({ openEdit, setOpenEdit, seleItem, setOpen3, open3, tareasList, planificacionId, setOpenModal, openModal }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    
    const [selectedItems, setSelectedItems] = useState([]);
    const [aseo, setAseo] = useState(true);
    
    const handleToggle = (item) => {
        setSelectedItems((prevSelectedItems) => {
          if (prevSelectedItems.includes(item)) {
            return prevSelectedItems.filter((selectedItem) => selectedItem !== item);
          } else {
            return [...prevSelectedItems, item];
          }
        });
    };

    const hizoAseo = (value) =>{
        setAseo(value);
        setFormData(prev => ({...prev, realizada:value }))
        if(value){
            setFormData(prev => ({...prev, item:[], observaciones: '' }))
            setSelectedItems([]);
        }
    }

    useEffect(() => {
        setAseo(true);
        if(seleItem){
            setFormData(prev => ({...prev, habitacionId: seleItem?.id, realizada:true, planificacionDiariaId: planificacionId, item:[], observaciones:''}))
            setSelectedItems([]);
        }
        console.log(seleItem)
    }, [seleItem])

    useEffect(() => {
        setFormData(prev => ({...prev, item: selectedItems}))
    }, [selectedItems])
    

    const onChange = (e) => {
        if(e.target.value != 'Otro'){
            setFormData(prev => ({...prev, [e.target.name]:e.target.value }))
        }else{
            setFormData(prev => ({...prev, otro: 'otro', observaciones: '' }))
        }
    }

    const handleChange = (e) => {
            setFormData(prev => ({...prev, [e.target.name]:e.target.value }))
    }

    const canSave = (formData?.habitacionId) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(addTareaAsignada(formData))
            if(resp.payload.status === 'success'){
                setOpenModal(false);
            }
        } catch (error) {
            console.error('Error al actualizar el registro', error.message);
        } finally{
            setAddRequestStatus('idle');            
            setOpen3(false);
        }
    }

  return (
    <div>
        <Dialog
            open={openModal}
            maxWidth="sm"
            PaperProps={{
                sx: {
                 backgroundColor:'#2d3037',
                 color:'#FFF',
                 borderRadius:'0'
                }
              }}
            TransitionComponent={Transition}
            onBackdropClick={() => {
                setOpenModal(false);
            }}
        >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
            <ChecklistIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
            <div style={{ display:'flex', flexDirection:'column' }}>
                <Typography variant='span' style={{ fontSize:'24px' }}>Listado de tareas habitación {seleItem?.numero}</Typography>
                <span className={styles.subTitle}>Al seleccionar la opción "No", marque las tareas que pudo completar, y luego seleccione el motivo por el cual no pudo terminar el aseo la habitación.</span>
            </div>
        </DialogTitle>
        <DialogContent>
            <Grid container={true} spacing={1} mt={1}>
                <Grid item xs={12} md={12}>
                    <label>¿Se hizo aseo?</label>
                    <FormControlLabel
                    style={{ marginLeft:'10px' }}
                    control={
                        <Checkbox
                        checked={aseo === true}
                        onChange={() => hizoAseo(true)}
                        />
                    }
                    label='Sí'
                    />
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={aseo === false}
                        onChange={() => hizoAseo(false)}
                        />
                    }
                    label='No'
                    />
                </Grid>
              {!aseo && 
                <>
                    <Grid item xs={12} md={12}>
                        <Typography  style={{ display:'flex', alignItems:'center', gap:'5px', fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>
                            Marque las tareas que si pudo completar
                        </Typography>
                        <FormGroup>
                        {tareasList.map((item) => (
                            <FormControlLabel
                            key={item.id}
                            control={
                                <Checkbox
                                checked={selectedItems.includes(item.id)}
                                onChange={() => handleToggle(item.id)}
                                />
                            }
                            label={item.nombre}
                            />
                        ))}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography  style={{ display:'flex', alignItems:'center', gap:'5px', fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>
                        Seleccione el motivo por el cual no se completó el aseo en la habitación.
                        </Typography>
                        <Select
                            className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: '#FFF',
                              },
                              '& .MuiInputLabel-root': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color: '#FFF',
                              },
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData?.observaciones}
                            name="observaciones"
                            onChange={(e) => onChange(e)}
                            style={{ width:'100%' }}
                        >
                            {options.map(item=>(
                                <MenuItem value={item} key={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {formData.otro == 'otro' && 
                    <Grid item xs={12} md={12} >
                        <label className={styles.label}>Ingrese el motivo</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                            variant="outlined"
                            name='observaciones'
                            type="text"
                            maxRows={4}
                            style={{ width:'100%' }}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    }
                </>
            }
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container={true} style={{ padding:'0 16px' }}>
                <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                    onClick={handleEdit}
                    className={styles.bgColorModal}
                    variant="contained" 
                    disabled={!canSave}>
                    {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                </Button>
                <Button 
                    onClick={()=>setOpenModal(false)}
                    className={styles.bgColorOutlined}
                    variant="outlined" 
                    >
                    Cancelar
                </Button>
                </Grid>
            </Grid>
        </DialogActions>
        </Dialog>
    </div>
  );
}