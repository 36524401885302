import styles from './components.module.css'
import { Card, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import MapIcon from '@mui/icons-material/Map';
import ExtensionIcon from '@mui/icons-material/Extension';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Outlet } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from "react";
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import HistoryIcon from '@mui/icons-material/History';
import HubIcon from '@mui/icons-material/Hub';
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../../context/AppContext';
import DashboardRecepcionista from '../../recepcionista/dashboard/Dashboard';
import DashboardHuesped from '../../huesped/DashboardHuesped';
import DashboardAseo from '../../aseo/DashboardAseo';
import DashboardGuardarropia from '../../Adm.Guardarropia/DashboardGuardarropia';
import DashboardPersonalAseo from '../../aseo/personal/components/dashboard/Dashboard';

const CardSlider = () => {
  const { info } = useAuth();
  const rol = info?.roles;
  console.log('roleee', rol)

  const GreenSquare = () => {
    const squareStyle = {
      width: '15px',
      height: '15px',
      backgroundColor: '#1b401d',
      display: 'inline-block',
    };  
    return <div style={squareStyle}></div>;
  };

    return (
        (rol?.includes('Administrador de Aplicaciones (AAP)') || rol?.includes('Administrador de Contrato ESED (ACE)') || rol?.includes('Administrador de Contrato CMDIC (ACC)') || rol?.includes('Huésped') || rol?.includes('Administrador de Inventario (AIN)') || rol?.includes('Recepcionista') || rol?.includes('Administrador de RRHH (ARH)') || rol?.includes('Administrador de Guardarropía (AGU)') || rol?.includes('Administrador de Aseo (AAS)') || rol?.includes('Administrador de Oficina') || rol?.includes('Personal de Aseo')|| rol?.includes('Supervisor de Bodega') || rol?.includes('Responsable ingreso de reservas ESED (RIR)')) &&
        <>
        <Card className={styles.card} style={{ display: (rol?.includes('Huésped') || rol?.includes('Administrador de Guardarropía (AGU)') || rol?.includes('Administrador de Aseo (AAS)')  ) ? 'none' : '' }}>
          
        <Grid container spacing={2} className={styles.sliderContainer}>
          
            {rol?.includes('Administrador de Aplicaciones (AAP)') && (
            <Fragment >     
              <Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                  {<Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px', color: '#9ca91c' }}>Gestión de Espacios Fisicos</Typography>}
                  
                  <NavLink className={styles.linkDecoration} to='/campamentos' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                        <GreenSquare />
                      </div>
                      <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Campamentos</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar campamentos</Typography>
                      </div>
                  </NavLink>
                  
                  <NavLink className={styles.linkDecoration} to='/sectores' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Sectores</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar Sectores</Typography>
                    </div>
                  </NavLink>

                  <NavLink className={styles.linkDecoration} to='/wings' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Wings</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar Wings</Typography>
                    </div>
                  </NavLink>

                  <NavLink className={styles.linkDecoration} to='/pabellones' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Pabellones</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar Pabellones</Typography>
                    </div>
                  </NavLink>

                  <NavLink className={styles.linkDecoration} to='/otros' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Otros</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar otros tipos de recintos</Typography>
                    </div>
                  </NavLink>

                  <NavLink className={styles.linkDecoration} to='/habitaciones' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Habitaciones</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar Habitaciones</Typography>
                    </div>
                  </NavLink>
                  
                  <NavLink className={styles.linkDecoration} to='/estandar' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Estandares</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar Estandares</Typography>
                    </div>
                  </NavLink>
                  
                  <NavLink className={styles.linkDecoration} to='/espacios' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Espacios Comunes</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear, editar y eliminar Espacios comunes</Typography>
                    </div>
                  </NavLink>

                  <NavLink className={styles.linkDecoration} to='/oficinas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                      <GreenSquare />
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Gestión de oficinas</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás crear, editar y remover oficinas de la plataforma.</Typography>
                    </div>
                  </NavLink>
                  
                </div>
              </Grid>

              <Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                  <Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px', color: '#9ca91c' }}>Gestión de Requerimientos</Typography>
                      <NavLink className={styles.linkDecoration} to='/criticidad_requerimientos' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Criticidad de Requerimientos</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear y eliminar requerimientos</Typography>
                        </div>
                      </NavLink>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px', color: '#9ca91c' }}>Gestión de Usuarios</Typography>
                      <NavLink className={styles.linkDecoration} to='/usuarios' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Administradores</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás crear usuarios administradores</Typography>
                        </div>
                      </NavLink>

                      <NavLink className={styles.linkDecoration} to='/responsables' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Responsables</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás asignar, editar y eliminar usuarios responsables a problemas.</Typography>
                        </div>
                      </NavLink>

                      <NavLink className={styles.linkDecoration} to='/huespedes' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Huéspedes</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar huespedes al sistema.</Typography>
                        </div>
                      </NavLink>
                    </div>
                  </Grid>
            </Fragment>
          )}
      
            {rol?.includes('Administrador de Contrato ESED (ACE)') && (
              <Fragment>
                
                {/*<Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>Reservas</Typography>
                    <NavLink className={styles.linkDecoration} to='/reservas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Reservas</Typography>
                    </NavLink>
                    <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar reservas al sistema.</Typography>
                  
                    <NavLink className={styles.linkDecoration} to='/estado' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Estado reservas</Typography>
                    </NavLink>
                    <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás modificar el estado de las reservas registradas.</Typography>

                  </div>
                </Grid> */}
              
                <Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>Forecast</Typography>
                    <NavLink className={styles.linkDecoration} to='/ingreso' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                        <GreenSquare />
                      </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0', marginLeft: '5px' }}>Ingreso</Typography>
                    </NavLink>
                    <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar un nuevo registro al forecast</Typography>

                    <NavLink className={styles.linkDecoration} to='/gestion' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0', marginLeft: '5px' }}>Gestión de Forecast</Typography>
                    </NavLink>
                    <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás visualizar y modificar los forecast registrados.</Typography>
                  </div>
                </Grid>

              </Fragment>
            )}
      
            {rol?.includes('Administrador de Contrato CMDIC (ACC)') && (
              <Fragment>
                
                <Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                      <Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>Forecast</Typography>
                      <NavLink className={styles.linkDecoration} to='/gestion' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0', marginLeft: '5px' }}>Gestión de Forecast</Typography>
                      </NavLink>
                    <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás visualizar y modificar los forecast registrados.</Typography>
                  </div>
                </Grid>
              </Fragment>
            )}
            
            {/*
            {rol?.includes('Huésped') && (
              <>
              <Grid item xs={12} sm={3} style={{ padding: '30px' }}>
                <NavLink className={styles.linkDecoration} to='/info-reservas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                  <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                  <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Información de Reservas</Typography>
                </NavLink>
              </Grid>
              <Grid item xs={12} sm={3} >
                <NavLink className={styles.linkDecoration} to='/reclamos-sugerencias' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                  <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                  <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Reclamos y Sugerencias</Typography>
                </NavLink>
              </Grid>
              </>
            )}
           */}
      
            {rol?.includes('Administrador de Inventario (AIN)') && (
              <Fragment>
                <Grid item xs={12} sm={6} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    {<Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>Gestión de Bodega</Typography>}
                    <NavLink className={styles.linkDecoration} to='/inventario' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display:'flex', flexDirection:'column', marginBottom: '6px', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Inventario</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar, editar y eliminar registros del inventarío</Typography>
                        </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/comparacion' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Toma de inventario</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás revisar items de una bodega y compararlos con los que están en el inventario.</Typography>
                      </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/merma' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Merma</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás gestionar la merma del inventario.</Typography>
                      </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/ingresos' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Ingresos</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar artículos al stock de bodegas.</Typography>
                      </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/salidas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Salidas</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar las salidas de los artículos de las bodegas.</Typography>
                      </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/traspaso' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Traspaso entre bodegas</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás traspasar el inventario de una bodega a otra.</Typography>
                      </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/notificaciones' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Notificaciones</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás gestionar las solicitudes de traspaso de inventario.</Typography>
                      </div>
                    </NavLink>
                </div>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    {<Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>Configuración de Bodega</Typography>}
                    <NavLink className={styles.linkDecoration} to='/categorias' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Categorías</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar, editar y eliminar categorías de articulos.</Typography>
                      </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/bodega' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Bodega</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás registrar la ubicación de una bodega.</Typography>
                      </div>
                    </NavLink>

                    <NavLink className={styles.linkDecoration} to='/espacios_bodega' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Espacios en bodega</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás asociar espacios a las bodegas registradas en la plataforma.</Typography>
                      </div>
                    </NavLink>
                  </div>
                </Grid>
                  
              {/*<Grid item xs={12} sm={3} style={{ padding: '30px' }}>
                <NavLink className={styles.linkDecoration} to='/articulos' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Articulos</Typography>
                </NavLink>
              </Grid> */}
              </Fragment>
            )}

            {rol?.includes('Administrador de RRHH (ARH)') && (
              <Fragment>
                <Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <NavLink className={styles.linkDecoration} to='/gestion' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Gestión de forecast</Typography>
                    </NavLink>
                    <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás ingresar una opinión de los registros del forecast.</Typography>
                    
                    {/*<NavLink className={styles.linkDecoration} to='/ingreso' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Ingreso de forecast</Typography>
                    </NavLink>
                    <Typography style={{ color: 'black', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar un nuevo registro al forecast</Typography>
                    
                    <NavLink className={styles.linkDecoration} to='/gestion' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Gestión de Forecast</Typography>
                    </NavLink>
                    <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás visualizar y modificar los forecast registrados.</Typography> */}

                  </div>
                </Grid>
              </Fragment>
            )}
            {rol?.includes('Personal de Aseo') && (
              <Fragment>
                <Grid item xs={12} sm={6} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <NavLink className={styles.linkDecoration} to='/asignadas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Habitaciones asignadas</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ver un listado de habitaciones que te han sido asignadas junto con las tareas relacionadas a la habitación.</Typography>
                      </div>
                    </NavLink>
                  </div>
                </Grid>
              </Fragment>
            )}
            {rol?.includes('Supervisor de Bodega') && (
              <Fragment>
                <Grid item xs={12} sm={6} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <NavLink className={styles.linkDecoration} to='/notificaciones' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Notificaciones</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>En este módulo, podrás administrar de manera eficiente las solicitudes de traspaso de inventario.</Typography>
                      </div>
                    </NavLink>
                  </div>
                </Grid>
              </Fragment>
            )}
            {rol?.includes('Responsable ingreso de reservas ESED (RIR)') && (
              <Fragment>
                <Grid item xs={12} sm={6} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <NavLink className={styles.linkDecoration} to='/reservas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                        <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Reservas</Typography>
                        <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar reservas al sistema.</Typography>
                      </div>
                    </NavLink>
                  </div>
                </Grid>
              </Fragment>
            )}
      
            {rol?.includes('Recepcionista') && (
              <Fragment>
                <Grid container={true} style={{ padding: '15px', padding: '30px' }}>
                  {/*<NavLink className={styles.linkDecoration} to='/solicitudes' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                    <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Solicitudes</Typography>
                  </NavLink>
                  <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás ingresar asignar habitaciones a los empleados inscritos en las reservas</Typography> */}
                  {/*<NavLink className={styles.linkDecoration} to='/reservas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                    <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', marginLeft: '5px' }}>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Reservas CMDIC</Typography>
                      <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás revisar las asignaciones hechas de forma automática por el sistema.</Typography>
                    </div>
                  </NavLink> */}
                    <Grid item xs={12} sm={4} style={{ padding: '20px' }}>
                      {/* <Typography style={{ fontWeight:'500', fontSize:'16px', marginBottom:'10px' }}>Reservas CMDIC</Typography> */}
                      <NavLink className={styles.linkDecoration} to='/reservascmdic' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Gestión reserva</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás visualizar y modificar las reservas CMDIC.</Typography>
                        </div>
                      </NavLink>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ padding: '20px' }}>
                      <NavLink className={styles.linkDecoration} to='/historial' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Historial</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás gestionar las solicitudes de reservas dentro del historial de reservas.</Typography>
                        </div>
                      </NavLink>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ padding: '20px' }}>
                      <NavLink className={styles.linkDecoration} to='/gestion' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                        <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                          <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Gestión de oficinas</Typography>
                          <Typography style={{ marginBottom:'10px',color: '#FFF', marginLeft: '0', fontSize:'12px', border:'none' }}>Aquí podrás visualizar y gestionar asignaciones de las oficinas registradas.</Typography>
                        </div>
                      </NavLink>
                    </Grid>
                </Grid>
              </Fragment>
            )}
      
            {/*{rol?.includes('Administrador de Guardarropía (AGU)') && (
              <Fragment>
                <Grid item xs={12} sm={4} style={{ padding: '30px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <NavLink className={styles.linkDecoration} to='/almacenamiento' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Almacenamiento</Typography>
                    </NavLink>
                    <NavLink className={styles.linkDecoration} to='/asignacion-espacios' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Asignaciòn de espacios</Typography>
                    </NavLink>
                  </div>
                </Grid>
              </Fragment>
            )} */}

          {/*
        {rol?.includes('Administrador de Aseo (AAS)') && (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <NavLink className={styles.linkDecoration} to='/grupos' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Grupos</Typography>
                    </NavLink>
                    <NavLink className={styles.linkDecoration} to='/horarios' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Horarios</Typography>
                    </NavLink>
                    <NavLink className={styles.linkDecoration} to='/planificacion' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Planificación diaria</Typography>
                    </NavLink>
                    <NavLink className={styles.linkDecoration} to='/planificacion_especifica' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Planificación especifica</Typography>
                    </NavLink>
                    <NavLink className={styles.linkDecoration} to='/asignadas' style={{ display:'flex', alignItems:'start',gap:'5px' }}>
                      <div style={{ flexShrink: 0 }}>
                          <GreenSquare />
                        </div>
                      <Typography className={styles.linkText} style={{ color: 'black', marginLeft: '0' }}>Habitaciones asignadas</Typography>
                    </NavLink>
                  </div>
                </Grid>
              </Fragment>
            )}
          */}
            
            
            </Grid>
          </Card>
        {/* <DashboardAseo/> */}

        { rol?.includes('Recepcionista') && <DashboardRecepcionista/> }
        { rol?.includes('Personal de Aseo') && <DashboardPersonalAseo/> }
        { rol?.includes('Administrador de Guardarropía (AGU)') && <DashboardGuardarropia/> }
        { rol?.includes('Administrador de Aseo (AAS)') && <DashboardAseo/> }
        { rol?.includes('Huésped') && <DashboardHuesped/> }
        </>
  )
}

export default CardSlider