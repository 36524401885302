import styles from './components.module.css'
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from '@mui/material'
import HistoryIcon from '@mui/icons-material/History';
import { forwardRef, useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { format } from 'date-fns';
import { historicoColumns, tramosColumns } from '../../../../lib/columns';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import GroupsIcon from '@mui/icons-material/Groups';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApartmentIcon from '@mui/icons-material/Apartment';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import LooksOneIcon from '@mui/icons-material/LooksOne';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Historico = ({ setOpen, open, seleItem, setOpen3 }) => {

  const [value, setValue] = useState('1');
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = tramosColumns();
  const finalHistoricoColumns = historicoColumns();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log('seleItem:', seleItem);  
  }, [seleItem])
  


  return (
    <Dialog
      open={open}
      maxWidth="lg"
      PaperProps={{
        sx: {
         backgroundColor:'#2d3037',
         color:'#FFF',
         borderRadius:'0'
        }
      }}
      TransitionComponent={Transition}
      onBackdropClick={() => {
        // setOpen(false);
      }}
    >
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
            <HistoryIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
            <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Historico de observaciones</Typography>
            <span className={styles.subTitle}>A continuación podrás vizualizar detalles sobre las distintas modificaciones que ha tenido un forecast.</span>
            </div>
        </DialogTitle>
        <DialogContent>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} sx={{
                "& .MuiTab-root.Mui-selected": { color: "#FFF" },
                "& .MuiTabs-indicator": { bgcolor: "var(--border-blue)" },
                "& .MuiButtonBase-root" : { color:'#aeaeae' }
               }}>
                <Tab label="Información" value="1" />
                <Tab label="Tramos" value="2" />
                <Tab label="Historico" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding:0, paddingTop:'10px' }}>
              <Card style={{ padding:'16px', margin:0, color:'white', backgroundColor:'var(--textfield-bg)' }}>
               <Grid container={true}>
                <Grid item xs={12} md={4}>
                  
                  <Typography style={{ fontSize:'12px', fontWeight:'600', display:'flex', alignItems:'center', gap:'3px', marginBottom:'5px', color:'var(--inst-orange)' }}>
                    <ApartmentIcon/> Empresa
                  </Typography>

                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', }}>
                    <TextRotationNoneIcon style={{ height:'18px', width:'18px' }}/> {seleItem?.empresa?.razonSocial}
                  </Typography>
                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', }}>
                    <MailIcon style={{ height:'18px', width:'18px' }}/> {seleItem?.empresa?.email}</Typography>
                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', }}>
                      <PersonIcon style={{ height:'18px', width:'18px' }}/> {seleItem?.empresa?.contacto}</Typography>
                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', }}>
                    <LocalPhoneIcon style={{ height:'18px', width:'18px' }}/> {seleItem?.empresa?.fono}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography style={{ fontSize:'14px', fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', marginBottom:'5px' }}>
                    <DonutLargeIcon/> Estado: {seleItem?.estado}
                  </Typography>
                  <Typography style={{ fontSize:'14px', fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', marginBottom:'5px' }}>
                    <CalendarMonthIcon/> Creado: {seleItem?.fechaCreacion ? format(seleItem?.fechaCreacion, 'dd-MM-yyy') : ''}
                  </Typography>
                  {(seleItem?.notas && seleItem?.notas.length > 0) && 
                    <>
                      <Typography style={{ fontSize:'16px', fontWeight:'600' }}>Observaciones</Typography>
                      <Typography style={{ fontSize:'16px', fontWeight:'600' }}>{seleItem?.notas.map(item => (
                        <Typography style={{ fontSize:'14px', fontWeight:'500' }}>{item}</Typography>
                        ))}
                      </Typography>
                    </>
                  }
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <Typography style={{ fontSize:'12px', fontWeight:'600', display:'flex', alignItems:'center', gap:'3px', marginBottom:'5px', color:'var(--inst-orange)' }}>
                    <PersonIcon/> Solicitante
                  </Typography>
                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', }}>
                    <TextRotationNoneIcon style={{ height:'18px', width:'18px' }}/> {seleItem?.usuario?.nombre} {seleItem?.usuario?.apellido}
                  </Typography>
                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', }}>
                    <MailIcon style={{ height:'18px', width:'18px' }}/> {seleItem?.usuario?.email}
                  </Typography>
                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px', }}>
                    <LooksOneIcon style={{ height:'18px', width:'18px' }}/>{seleItem?.usuario?.rut}
                  </Typography>
                  <Typography style={{ fontSize:'14px', marginBottom:'5px' ,fontWeight:'500', display:'flex', alignItems:'center', gap:'3px' }}>
                    <LocalPhoneIcon style={{ height:'18px', width:'18px' }}/>
                    {seleItem?.usuario?.phoneNumber}
                  </Typography>
                </Grid>
               </Grid>
              </Card>
            </TabPanel>
            <TabPanel value="2" style={{ padding:0, paddingTop:'10px' }}>
              <DataGrid
                getRowId={row => row.id ? row.id : Math.random()}
                lots={{ toolbar: GridToolbar }}
                rows={seleItem?.tramos}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                    labelRowsPerPage: "Filas por página:",
                  }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#FFF',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#FFF',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#FFF', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#FFF', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#FFF', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#FFF', // Color for displayed rows text
                  },
                 }}
                slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel value="3" style={{ padding:0, paddingTop:'10px' }}>
              <DataGrid
                getRowId={row => row.id ? row.id : Math.random()}
                lots={{ toolbar: GridToolbar }}
                rows={seleItem?.modificacionesForecasts}
                columns={finalHistoricoColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                    labelRowsPerPage: "Filas por página:",
                  }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                sx={{ 
                  '& .MuiDataGrid-cell': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    color: '#FFF',
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiTablePagination-root': {
                    color: '#FFF',
                    borderTop: '1px solid #c6c6c6',
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    color: '#FFF',
                  },
                  '& .MuiDataGrid-menu': {
                    color: '#FFF',
                  },
                  '& .MuiTablePagination-actions': {
                    color: '#FFF', // Color for pagination actions
                  },
                  '& .MuiTablePagination-select': {
                    color: '#FFF', // Color for pagination select
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: '#FFF', // Color for pagination select label
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: '#FFF', // Color for displayed rows text
                  },
                }}
                slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#FFF',
                    },
                  },
                }}
              />
            </TabPanel>
          </TabContext>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setOpen(false)}
            className={styles.bgColorOutlined}
            variant="outlined" 
            >
            Cancelar
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default Historico