import styles from './components.module.css';
import { useEffect, useState } from "react";
import { reservasAsignacionesColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Edit from './Edit'
import { Typography } from '@mui/material';

const TableAsignaciones = ({ asignaciones, selectCheck }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reservasAsignacionesColumns(setOpen, setSeleItem, setOpenEdit);
  const [checkedHabitaciones, setCheckedHabitaciones] = useState([]); //habitaciones
  
  const handleRowSelectionChange = (selection) => {
    setCheckedHabitaciones(selection.map((id) => ({ id })));
  };

  const setIds = asignaciones.map((item)=>item.id);

  return (
    <div style={{ marginTop:'16px' }}>
      {/* 
      {JSON.stringify(setIds)} 
    */}
    { selectCheck && <Typography>Id de la reserva: #3232ASD</Typography> }
        <DataGrid
            getRowId={row=>row.id ? row.id : Math.random() }
            lots={{ toolbar: GridToolbar }}
            rows={asignaciones}
            columns={finalColumns}
            pageSize={pageSize}
            checkboxSelection
            rowSelectionModel={ selectCheck ? setIds : checkedHabitaciones.map(item => item.id)}
            onRowSelectionModelChange={handleRowSelectionChange}
            disableSelectionOnClick={selectCheck}
            // onRowSelectionModelChange={handleRowSelectionModelChange}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}

        />
    </div>
  )
}

export default TableAsignaciones