import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Button, Grid, TextField } from "@mui/material";
import styles from './components.module.css';
import * as L from "leaflet";

const Map = ({ position, setPosition, formData, setFormData, seleItem }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const inputRef = useRef(null);
    const [icon, setIcon] = useState(null); // Estado para el icono personalizado
    const mapRef = useRef(null); // Cambiado a useRef(null)

    useEffect(() => {
        inputRef.current.focus(); // Mantener el enfoque en el input

        // Configurar el icono personalizado
        const LeafIcon = L.Icon.extend({
            options: {}
        });

        const blueIcon = new LeafIcon({
            iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
            shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png'
        });

        setIcon(blueIcon); // Establecer el icono personalizado en el estado
    }, []); // [] significa que este efecto se ejecuta solo una vez al montar el componente

    useEffect(() => {
        if (seleItem && mapRef.current) {
            // const { latitud, longitud } = seleItem;
            // mapRef.current.setView([parseFloat(latitud), parseFloat(longitud)]);
            handleResultClick({ lat: parseFloat(seleItem.latitud), lon: parseFloat(seleItem.longitud) });
        }
    }, [seleItem, mapRef.current]);

    const searchAddress = async () => {
        if (!searchQuery) return;

        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchQuery)}`);
        const data = await response.json();

        setSearchResults(data);
    };

    const handleSearch = () => {
        searchAddress();
    };

    const handleResultClick = (result) => {
        setPosition({ lat: parseFloat(result.lat), lng: parseFloat(result.lon) });
        setFormData({ ...formData, latitud: parseFloat(result.lat), longitud: parseFloat(result.lon) });
        setSearchResults([]);

        if (mapRef.current) {
            mapRef.current.setView([parseFloat(result.lat), parseFloat(result.lon)]);
        }
    };

    return (
        <Grid item xs={12} sm={12} mt={2}>
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', gap: '5px', width: '100%' }}>
                    <TextField
                        className={styles.textField}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                            },
                            '& .MuiInputBase-input': {
                            color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                            color: '#FFF',
                            },
                        }}
                        ref={inputRef}
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Buscar dirección..."
                        style={{ width: '100%', marginBottom: '8px' }}
                    />
                    <Button className={styles.bgColor}  style={{ marginBottom: '8px', padding: '0 20px' }} onClick={handleSearch}>Buscar</Button>
                </div>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {searchResults.map(result => (
                        <li key={result.place_id} style={{ cursor: 'pointer', borderBottom: '1px solid black' }} onClick={() => handleResultClick(result)}>
                            {result.display_name}
                        </li>
                    ))}
                </ul>
            </div>

            <label className={styles.label} style={{ display: 'flex', alignItems: 'center', gap: '4px', marginTop: '10px' }}>Seleccione el marcador y arrástrelo</label>

            <MapContainer center={position} zoom={13} scrollWheelZoom={true} style={{ height: "480px", width: "100%" }} ref={mapRef}>
                <TileLayer
                    attribution=''
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {icon && (
                    <Marker
                        position={position}
                        draggable={true}
                        eventHandlers={{
                            dragend: (event) => {
                                setPosition(event.target.getLatLng());
                            }
                        }}
                        icon={icon}
                    >
                        <Popup minWidth={90}>
                            <span>Arrastre el marcador</span>
                        </Popup>
                    </Marker>
                )}
            </MapContainer>
        </Grid>
    );
};

export default Map;
