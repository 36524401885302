import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const RenderCell4 = ({ row, setOpen, setSeleItem, setCheck }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAsignar = (number) => {
    setSeleItem(row);
    setOpen(true);
    setCheck(number);
  };

  return (
    <div>
      <IconButton style={{ color:'#FFF' }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=>handleClickAsignar(1)}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Asignar persona
        </MenuItem>
        <MenuItem onClick={()=>handleClickAsignar(2)}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Liberar oficina
        </MenuItem>
        <MenuItem onClick={()=>handleClickAsignar(3)}>
          <AssignmentIcon style={{ marginRight: 8 }} />
          Inhabilitar oficina
        </MenuItem>
      </Menu>
    </div>
  );
};