import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import PersonIcon from '@mui/icons-material/Person';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import { fetchPersonalAseo, getAllPersonal, getPersonalStatus } from "../../../redux/features/aseo/aseoSlice";

const Personal = () => {

  const dispatch = useDispatch();
  const registros = useSelector(getAllPersonal);
  const status = useSelector(getPersonalStatus);
  const [count, setCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchPersonalAseo());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de personal de aseo"
          subtitle="En este módulo, podrás agregar trabajadores al personal de aseo.">
          {<PersonIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2}/>
          { status == 'loading' ? <SkeletonTable/> : <Table registros={registros} setOpen3={setOpen3} /> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Personal