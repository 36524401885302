import styles from './components.module.css'
import { Autocomplete, Backdrop, Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography, CircularProgress, Stack, Alert } from '@mui/material';
import { NavLink } from 'react-router-dom'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Guarderia from './Guarderia';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewIngreso, addNewIngresoDesdeHuesped, fetchItemsHuesped, getAllItems, getEspacioStatus } from '../../../redux/features/guardarropia/guardarropiaSlice';
import Slide from '@mui/material/Slide';
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from '../../../redux/features/campamentos/campamentoSlice';
import { useAuth } from '../../../context/AppContext';
import { toast } from 'react-toastify';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const elementos = [
  "Lentes",
  "Cascos",
  "Zapatos de seguridad",
  "Máscaras de aire",
  "Máscaras de soldador (Sin vidrios)",
  "Bloqueador solar",
  "Guantes de todo tipo",
  "Protector de oídos",
  "Trajes de agua",
  "Buzo Tíbet (blanco de papel)",
  "Rodilleras de soldador",
  "Traje de soldador",
  "Otro",
];

const Modal = ({open, setOpen, campamentos, selectedCampamento, setSelectedCampamento, setFormData, formData, elemtosList, setElemtosList, checkedItems, setCheckedItems, setOpen3, espacios, selectedEspacio, setSelectedEspacio}) => {
  
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const { info } = useAuth();
  const rut = info?.rut;
  console.log('rut: desde modal guardarropia', rut);
  const dispatch = useDispatch();
  const [espaciosFilt, setEspaciosFilt] = useState([])

  const [showAlert, setShowAlert] = useState(true);
  const [showText, setShowText] = useState(false);
  const [value, setValue] = useState('');

  const handleClose = () => {
    setShowAlert(false);
  };

  const handleChange = (itemName) => {
    setCheckedItems(prevState => {
      const isChecked = prevState.includes(itemName);
  
      if (itemName === 'Otro') {
        if (isChecked) {
          setShowText(false);
          const a = prevState.filter(item => item !== value && item !== itemName);
          setValue('');
          return a;
        } else {
          setShowText(true);
          return [...prevState, itemName];
        }
      } else {
        // Manejar otros casos
        if (isChecked) {
          return prevState.filter(item => item !== itemName);
        } else {
          return [...prevState, itemName];
        }
      }
    });
  };
  

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    setSelectedEspacio(null);
    if(newValue != null){
      const filterEspacios = Array.isArray(espacios) ? espacios.filter(item => item.campamento.id == newValue.id && item.estado != 'Ocupado') : [];
      if(filterEspacios.length === 0 ) toast.warning('No hay espacios disponibles')
      setFormData({...formData, campamentoId: newValue.id});
      setEspaciosFilt(filterEspacios);
    } else{
      setFormData({...formData, campamentoId: 0})
    }  
  };

  const handleChangeEspacios = (event, newValue) => {
    setSelectedEspacio(newValue);
    if(newValue != null){
      setFormData({...formData, espacioId: newValue.id});
    } else{
      setFormData({...formData, espacioId: 0})
    }
    
  };

  const onChange = (e) => setValue(e.target.value)
  
  const addItem = (e) => {
    if(value !== '') setCheckedItems(prevState => [...prevState, value])
  }

  const handleSubmit = async () =>{
    try {
      setOpen3(true)
      setAddRequestStatus('loading');
      let resp = await dispatch(addNewIngresoDesdeHuesped(formData));
      console.log('resp',resp);
        if(resp.payload.status === 'success'){
          setFormData({ campamentoId:'', items: [], rutTrabajador: '' });
          setOpen(false);
      }
    } catch (error) { console.log(error) }
    finally{
      setOpen3(false)
      setAddRequestStatus('idle');
    }
  }

  useEffect(() => {
    setFormData({...formData, items: checkedItems})
  }, [checkedItems])

  useEffect(() => {
    if(rut){
      setFormData({...formData, rutTrabajador: rut})
    }
  }, [rut])

  return(
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={()=>setOpen(false)} aria-describedby="alert-dialog-slide-description"
    maxWidth="md"
    PaperProps={{
      sx: {
       backgroundColor:'#2d3037',
       color:'#FFF',
       borderRadius:'0'
      }
    }}
     >
      <DialogTitle style={{ alignItems:'start', display:'flex', gap:'5px' }}>
        <EventAvailableIcon sx={{ fontSize:'50px', color:'#f19d38', marginRight:'2px', marginTop:'5px' }}/>
        <div style={{ display:'flex', flexDirection:'column' }}>
          <label className={styles.label} style={{ fontSize:'20px' }}>Solicitar espacio de almacenamiento</label>
          <p style={{ fontSize:'14px', fontWeight:400 }}>Selecciona los items que desees almacenar, el sistema te asignará un espacio de almacenamiento.</p>
        </div>
      </DialogTitle>
      <DialogContent style={{ minHeight:'250px' }}>
      {showAlert && (
        <Alert severity="warning" onClose={handleClose}>
          <img
            style={{ height: '95%', width: '95%' }}
            src={`${process.env.PUBLIC_URL}/huespedGuardarropia.png`}
            alt="Huesped Guardarropia"
          />
        </Alert>
      )}
        <Grid container={true} spacing={2}>
        {/*
          <Grid item xs={12} md={6}>
            <label className={styles.label}>Campamento</label>
            <Autocomplete
                className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#FFF',
                        },
                      }}
              disablePortal
              disableClearable
              id="campamentos-autocomplete"
              options={campamentos || []}
              name="idCampamento"
              value={selectedCampamento || null}
              getOptionLabel={(option) => option.nombre || ''}
              onChange={handleChangeCampamento}
              renderInput={(params) => (
                <TextField {...params} label="Busca y selecciona un campamento" />
              )}
            />
          </Grid>
          {
            selectedCampamento ? 
            <Grid item xs={12} md={6}>
              <label className={styles.label}>Espacios disponibles</label>
              <Autocomplete
                  className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#FFF',
                        },
                      }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={espaciosFilt || []}
                name="idCampamento"
                value={selectedEspacio || null}
                getOptionLabel={(option) => option.numero || ''}
                onChange={handleChangeEspacios}
                renderInput={(params) => (
                  <TextField {...params} label="Busca y selecciona un espacio" />
                )}
              />
            </Grid> : 
            <Grid item xs={12} md={6}>
              <label className={styles.label}>Selecciona un campamento para ver los espacios</label>
              <Autocomplete
                  className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#FFF',
                        },
                      }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={[]}
                name="idCampamento"
                value={null}
                getOptionLabel={''}
                onChange={()=> {}}
                renderInput={(params) => (
                  <TextField {...params} label="" />
                )}
              />
            </Grid>
          }
             */}
          <Grid item xs={12} md={12} mt={2}>
            <label className={styles.label} style={{ display:'block', fontSize:'16px', fontWeight:'600' }}>Ingreso de items</label>
            <label className={styles.label} style={{ display:'block', fontSize:'14px', fontWeight:'400' }}>Selecciona los items que vas a ingresar.</label>
            <Box className={styles.box}>
              {elemtosList.map((item, index) => (
                <Box key={index} flexBasis="30%" margin="8px 0">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item) || false}
                        onChange={() => handleChange(item)}
                        color="primary"
                      />
                    }
                    label={item}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
          {showText && <Grid item xs={12} md={12}>
              <label className={styles.label}>Ingresa el otro item</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='otro'
                type="text"
                onChange={(e) => onChange(e)}
              />
              <Button variant='contained' onClick={addItem} className={styles.bgOrange}>Agregar</Button>
            </Grid> }
        </Grid>
      </DialogContent>
      <DialogActions style={{ display:'flex', justifyContent:'end', flexWrap:'wrap'}}>
        <Button 
          variant='contained' 
          disabled={formData.items.length <= 0 || !formData.rutTrabajador || addRequestStatus === 'loading'}
          className={styles.bgColorModal}
          onClick={handleSubmit}>
            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Solicitar espacio'}
          </Button>
        <Button variant='outlined' className={styles.bgColorOutlined} style={{ border:'none' }} onClick={()=> setOpen(false)}>Cerrar</Button>
      </DialogActions>
    </Dialog>  
  )
}

const CardSlider = ({ espacios }) => {
    const [open, setOpen] = useState(false);
    const items = useSelector(getAllItems)
    const status = useSelector(getEspacioStatus)
    const statusCamp = useSelector(getCampamentosStatus)
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos)
    const [elemtosList, setElemtosList] = useState(elementos);
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedCampamento, setSelectedCampamento] = useState('');
    const [selectedEspacio, setSelectedEspacio] = useState('');
    const [formData, setFormData] = useState({campamentoId:'', items: [], rutTrabajador: ''});
    const [open3, setOpen3] = useState(false);

    useEffect(() => {
      if(status == 'idle'){
        dispatch(fetchItemsHuesped())
      }
      if(statusCamp == 'idle'){
        dispatch(fetchCampamentos())
      }
    }, [])
    

    return (
      <>
        <Guarderia items={items}/>
        <Button variant="contained" className={styles.bgColor} style={{ fontSize:'14px', width:'100%' }} onClick={()=>setOpen(true)} disabled={items.length > 0}>Solicitar espacio</Button>
        <Modal setOpen={setOpen} open={open} campamentos={campamentos} selectedCampamento={selectedCampamento} setSelectedCampamento={setSelectedCampamento} formData={formData} setFormData={setFormData} elemtosList={elemtosList} setElemtosList={setElemtosList} checkedItems={checkedItems} setCheckedItems={setCheckedItems} setOpen3={setOpen3} espacios={espacios} selectedEspacio={selectedEspacio} setSelectedEspacio={setSelectedEspacio}/>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>


        <Card className={styles.card} style={{ backgroundColor:'var(--inst-card-bg)', padding:'16px', color:'#FFF', margin:'10px 0' }}>
            <NavLink to="/info-reservas" style={{ textDecoration:'none', color:'#FFF' }}>
              <div style={{ padding:'10px', display:'flex', flexDirection:'column', cursor:'pointer' }}>
                <div style={{ display:'flex', alignItems:'center' }}>
                  <EventAvailableIcon sx={{ fontSize:'20px', color:'#f19d38', marginRight:'2px' }}/>
                  <label className={styles.label} style={{ fontSize:'14px' }}><strong>Reservas</strong></label>
                </div>
                <Typography style={{ color: '#FFF', marginLeft: '0', fontSize:'14px', border:'none' }}>Aquí podrás visualizar un historial de tus reservas.</Typography>
              </div>
            </NavLink>
          
            <NavLink to="/reclamos-sugerencias" style={{ textDecoration:'none', color:'#FFF'  }}>
              <div style={{ padding:'10px', display:'flex', flexDirection:'column', cursor:'pointer' }}>
                <div style={{ display:'flex', alignItems:'center' }}>
                  <TextsmsIcon sx={{ fontSize:'20px', color:'#f19d38', marginRight:'2px' }}/>
                  <label className={styles.label} style={{ fontSize:'14px' }}><strong>Reclamos y sugerencias</strong></label>
                </div>
                <Typography style={{ color: '#FFF', marginLeft: '0', fontSize:'14px', border:'none' }}>Aquí podrás visualizar y crear reclamos y sugerencias.</Typography>
              </div>
            </NavLink>
            </Card>
            
      </>
  )
}

export default CardSlider