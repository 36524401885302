import styles from './components.module.css';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Button, CircularProgress, Grid, TextField, Typography, Popper, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllCampamentos } from '../../../../redux/features/campamentos/campamentoSlice';
import { updateOtro } from '../../../../redux/features/otros/otroSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos);
    const [selectedCampamento, setSelectedCampamento] = useState(null);

    useEffect(() => {
        setFormData(seleItem);
        setSelectedCampamento(seleItem?.campamentoId)
    }, [seleItem])

    const handleChangeCampamento = (event, newValue) => {
        setSelectedCampamento(newValue);
        if(newValue != null){
          setFormData({...formData, campamentoId: newValue.id });
        } else{
          setFormData({...formData, campamentoId: 0})
        }
      };
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const canSave = [formData?.nombre, formData?.campamentoId, (formData?.numeroPisos || formData?.numeroPisos == 0)].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateOtro(formData));
            if(resp.payload.status == 'success'){
                setOpen3(false);
            }
        } catch (error) {
            console.error('Error al actualizar el recinto', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpenEdit(false);
        }
    }

  return (
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={
            {
              sx: {
                width: { xs: '100%', sm: '75%', md: '50%' },
                backgroundColor:'#2d3037',
                color:'#FFF'
              }
            }
        }
      >
        {seleItem && (
            <>                 
                <DialogTitle>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2} style={{ display:'flex', gap:'5px'}}>
                            <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                            <div style={{ display:'flex', flexDirection:'column' }}>
                                <Typography variant="h6" className={styles.textIconEdit}>Actualizar recinto {seleItem?.nombre}</Typography>
                                <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el recinto.</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </DialogTitle>     
                <DialogContent>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Campamento</label>
                            <Autocomplete
                                disablePortal
                                className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                disableClearable
                                id="sectores-autocomplete"
                                options={campamentos ? campamentos : [] }
                                name="sectorId"
                                getOptionLabel={(option) => option.nombre}
                                value={campamentos.find(option => option.id === formData?.campamentoId) || null}
                                onChange={(e, value) => handleChangeCampamento(e, value)}
                                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={8}>
                            <label className={styles.label}>Nombre</label>
                            <TextField
                            className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                variant="outlined"
                                fullWidth
                                name='nombre'
                                type="text"
                                value={formData?.nombre}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>N°Pisos</label>
                            <TextField
                            className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                variant="outlined"
                                fullWidth
                                name='numeroPisos'
                                type="number"
                                value={formData?.numeroPisos}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                    </Grid>    
                </DialogContent>
                <DialogActions>
                    <Grid container={true}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColorEdit}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </>
        )}
      </Drawer>
  );
}