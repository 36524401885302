import React, { useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización de moment en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment-timezone'; // Importa moment-timezone
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { planificacionPersona } from '../../../../redux/features/aseo/aseoSlice';
import { format, parseISO } from 'date-fns';

const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
};

// Configura el localizador de fechas usando moment.js
const localizer = momentLocalizer(moment);

const CalendarioCreateRuts = ({ events, ruts, setCargando }) => {
  // Mapea los eventos en un formato compatible con react-big-calendar
  const localTimeZone = 'America/Santiago';
  const [eventos, setEventos] = useState([])
  const dispatch = useDispatch();
  
  // useEffect(() => {
  //   console.log('ruts', ruts);
  //   const fetchData = async (rut) => {
  //     try {
  //       setCargando(true);
  //       const resp = await dispatch(planificacionPersona(rut));
  //       if (resp.payload) {
  //         const planificacionesData = resp.payload;
  //         if (Array.isArray(planificacionesData)) {
  //           setEventos(prevEventos => [...prevEventos, ...planificacionesData]);
  //         } else {
  //           console.error("planificacionesData no es un array");
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     } finally {
  //       setCargando(false);
  //     }
  //   };
  
  //   if (ruts.length > 0) {
  //     ruts.forEach(rut => fetchData(rut));
  //   } else {
  //     setEventos([]);
  //   }
  // }, [ruts]); // Solo se ejecutará cuando ruts cambie
  
    

  const mappedEvents = events.map(evento => {
    // Ajusta la fecha y las horas de inicio y término
    const fechaInicio = moment.tz(evento.desde.split('T')[0], localTimeZone).toDate();
    const fechaTermino = moment.tz(evento.hasta.split('T')[0], localTimeZone).toDate();
    // const title = `${evento.grupo.nombre} : ${evento.grupo.cantidadCamas} habitaciones`;
    const title = `${evento.nombre}`;
    // const title = 'hola';

    return {
      title,
      start: fechaInicio,
      end: fechaTermino,
    };
  });

  return (
    <div style={{ height: 500 }}>
      {/*
        {JSON.stringify(ruts)}
        */}
        <style>{`
          .rbc-time-header {
            height: 100%;
          }
          .rbc-time-view {
            .rbc-label {
              display: none;
            }
            .rbc-allday-cell {
              height: 100%
              max-height: unset;
            }
            .rbc-time-content {
              display: none;
            }
          }
        `}</style>
      <Calendar
        localizer={localizer}
        events={mappedEvents}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week']}
        defaultView="month"
        messages={messages}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "#174300",
            color: 'white',
            borderRadius: "0px",
            border: "none",
            fontSize: '12px', // Tamaño de la fuente deseado
            wordBreak: 'break-word',
          };
      
          if (event.isMine) {
            newStyle.backgroundColor = "#174300";
          }
      
          return {
            className: "",
            style: newStyle
          };
        }}
        formats={{
          dateFormat: 'DD',
        }}
      />
    </div>
  );
};

export default CalendarioCreateRuts;
