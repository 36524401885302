import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { fetchHorariosAseo, getAllHorariosAseos, getHorariosAseoStatus } from "../../../redux/features/aseo/aseoSlice";

const Horario = () => {

  const dispatch = useDispatch();
  const horarios = useSelector(getAllHorariosAseos);
  const status = useSelector(getHorariosAseoStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchHorariosAseo());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de horarios de aseo"
          subtitle="En este módulo, podrás crear uno o más rangos de horario donde se desarrollarán las tareas de aseo en la plataforma.">
          {<WatchLaterIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2}/>
          { status == 'loading' ? <SkeletonTable/> : <Table registros={horarios} setOpen3={setOpen3} /> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Horario