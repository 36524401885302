import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Popper, Select, TextField, Typography, FormControl, InputLabel } from '@mui/material';
import Slide from '@mui/material/Slide';
import TableHabitaciones from './TableHabitaciones'
import CalendarioCreateRuts from './CalendarioCreateRuts';
import CalendarioCreate from './CalendarCreate';
import { addNewPlanificacionAseo, getGrupo, planificacionPersona } from '../../../../redux/features/aseo/aseoSlice';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { liveSearchByRut } from '../../../../redux/features/users/userSlice';
import CircleIcon from '@mui/icons-material/Circle';

const defaulData = {
  horarioAseoId: '',
  desde: '',
  hasta: '',
  gruposHabitaciones: [],
  trabajadoresRut: [],
  tareasId: [],
  tipo: '',
  ruts:[]
}

const Create = ({ open2, setOpen2, setOpen3, grupos, franjas, tareas, personal }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(defaulData);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedHabitaciones, setSelectedHabitaciones] = useState([])
  const [ruts, setRuts] = useState([])
  const [selectedRuts, setSelectedRuts] = useState([])
  const [selectedNameByRuts, setSelectedNameByRuts] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [grupo, setGrupo] = useState({});
  const [eventos, setEventos] = useState([])
  const [tipo, setTipo] = useState('');

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
    setFormData({...formData, tipo: event.target.value});
  };
  const handleChangeHorario = (event, newValue) => {
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, horario: newValue.id});
    } else{
      setFormData({...formData, horario: 0})
    }
  };

  const handleChangeGroup = async (event, newValue) => {
    setSelectedGroup({id: newValue.id, nombre: newValue.nombre});
    setGrupo(newValue);
    console.log('grupo seleccionado:', newValue)
    if(newValue != null){
      setFormData({...formData, grupo: newValue});
    } else{
      setFormData({...formData, grupo: 0})
    }
  };
  
  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value))
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }
  const onChangeRutSelect = async (e, newValue) => {    
    if (newValue != null) {
      console.log(newValue);
      setFormData({...formData, trabajadoresRut: newValue.map(item => item.rut)});
      
      try {
        setOpen2(true);
        setCargando(true);
        for (const rut of newValue.map(item => item.rut)) {
          const resp = await dispatch(planificacionPersona(rut));
          setEventos(prevEventos => [...prevEventos, ...resp.payload.filter(event => !prevEventos.some(prevEvent => prevEvent.id === event.nombre))]);
        }
      } catch (error) {
        console.log('error', error);
      } finally{
        setCargando(false);
        setOpen2(false);
      }
      setSelectedRuts(newValue);
      setSelectedNameByRuts(newValue);
    } else {
      setFormData({...formData, trabajadoresRut: []});
    }
  }

  useEffect(() => {
    const allRuts = formData.ruts.flatMap(item => item);
    const uniqueRuts = [...new Set(allRuts)];
    setRuts(uniqueRuts);
  }, [formData?.ruts])

  useEffect(() => {
    // setFormData
    console.log('entra')
    setFormData(defaulData);
    setTipo('');
          setCheckedItems([])
          setSelectedGroup()
          setSelectedCampamento()
          setSelectedHabitaciones([])
          setSelectedRuts([])
          setSelectedNameByRuts([])
          setRuts([])
          setListItems([])
          setEventos([])
          
          // setOpen(false);
  }, [open])
  

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const canSaveAgregar = [formData?.grupo, formData?.horario, formData?.trabajadoresRut.length > 0, formData?.desde, formData?.hasta, formData?.tipo ].every(Boolean) && addRequestStatus === 'idle';

  const canSave = [listItems.length > 0].every(Boolean) && addRequestStatus === 'idle';
  
  const handleCheckboxChange = (tarea) => {
    setCheckedItems(prevCheckedItems => {
      let newCheckedItems;
      if (prevCheckedItems.includes(tarea)) {
        newCheckedItems = prevCheckedItems.filter(item => item !== tarea);
      } else {
        newCheckedItems = [...prevCheckedItems, tarea];
      }

      setFormData({ ...formData, tareasId: newCheckedItems.map(item => item.id) });
      return newCheckedItems;
    });
  };

  const handleAgregar = () => {
    const newDesde = moment(formData.desde);
    const newHasta = moment(formData.hasta);
  
    const isOverlapping = listItems.some(item => {
      const itemDesde = moment(item.desde);
      const itemHasta = moment(item.hasta);
      return (
        (newDesde.isBetween(itemDesde, itemHasta) || newDesde.isSame(itemDesde) || newDesde.isSame(itemHasta)) ||
        (newHasta.isBetween(itemDesde, itemHasta) || newHasta.isSame(itemDesde) || newHasta.isSame(itemHasta))
      );
    });
  
    if (isOverlapping) {
      toast.error("La planificación calza con otra ya ingresada en el calendario de asignaciones.");
      return; 
    }
    const newItem = { ...formData, id: uuidv4() };    
    setListItems([...listItems, newItem]);
    setFormData({
      grupo: '',
      horario: '',
      trabajadoresRut: [],
      desde: '',
      hasta:'',
      tipo:'',
      ruts:[],
      tareasId: []
    })
    setSelectedCampamento(null);
    setSelectedGroup(null);
    setSelectedRuts([]);
    setCheckedItems([]);
    setTipo('');
  }
  
  const handleDelete = (id) => {
    setListItems(listItems.filter((item) => item.id !== id));
  };

  const handleSubmit = async () => {
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewPlanificacionAseo(listItems));
        if(resp.payload.status === 'success'){
          setFormData(defaulData);
          setCheckedItems([])
          setSelectedGroup()
          setSelectedCampamento()
          setSelectedHabitaciones([])
          setSelectedRuts([])
          setSelectedNameByRuts([])
          setRuts([])
          setListItems([])
          setEventos([])
          setOpen(false);
        }
      } catch (error) {
        console.error('Error al crear la planificación', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }

  return (
    <>
    <Dialog
      open={!!openTooltip}
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
      onClose={() => setOpenTooltip(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography style={{ fontSize:'20px', fontWeight:'600' }}>{grupo?.nombre}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography style={{ fontSize:'16px', fontWeight:'400' }}>{grupo?.descripcion}</Typography>
          </Grid>
          { grupo.campamentos && grupo.campamentos.length > 0 && 
            <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection:'column' }}>
              <Typography style={{ fontSize:'16px', fontWeight:'600' }}>Campamentos</Typography>
              {grupo.campamentos.map(item => (
                <Typography style={{ fontSize:'16px', fontWeight:'400' }}>{item.nombre}</Typography>
              ))}
            </Grid>
           }
          { grupo.pabellones && grupo.pabellones.length > 0 && 
            <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection:'column' }}>
              <Typography style={{ fontSize:'16px', fontWeight:'600' }}>pabellones</Typography>
              {grupo.pabellones.map(item => (
                <Typography style={{ fontSize:'16px', fontWeight:'400' }}>{item.identificador}</Typography>
              ))}
            </Grid>
           }
          { grupo.wings && grupo.wings.length > 0 && 
            <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection:'column' }}>
              <Typography style={{ fontSize:'16px', fontWeight:'600' }}>wings</Typography>
              {grupo.wings.map(item => (
                <Typography style={{ fontSize:'16px', fontWeight:'400' }}>{item.nombre}</Typography>
              ))}
            </Grid>
           }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenTooltip(false)} style={{ color:'#333' }} autoFocus>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>


      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder asignar personal a los horarios creados en la vista "horarios de aseo".</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/> Asignar</Button>
      </div>
      <Dialog
        open={open}
         maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <MenuBookIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1'  }}>Asignar personal</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para asignar personal.</span>
          </div>
        </DialogTitle>
        <DialogContent>
        {/*
        {JSON.stringify(listItems)}
        {JSON.stringify(formData)}
        */}
         <Grid container={true} spacing={1} mb={2}>
          </Grid>
          <label className={styles.label} style={{ fontSize:'16px', fontWeight:'600' }}>Datos planificación</label>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={4} style={{ overflowY:'auto'}}>{/* maxHeight:'400px', */}
              <div>
                <label className={styles.label}>Jornada</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                  }}
                  disablePortal
                  id="campamentos-autocomplete"
                  name="franja"
                  disableClearable
                  options={Array.isArray(franjas) && franjas.length > 0 ? franjas : []}
                  getOptionLabel={(option) => option.nombre}
                  value={selectedCampamento || null}
                  onChange={(e, value)=> handleChangeHorario(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona un rango de horario" />}
                />
              </div>
              <div>
                <label className={styles.label}>Grupos</label>
                <Autocomplete
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  disablePortal
                  id="campamentos-autocomplete"
                  name="grupo"
                  disableClearable
                  value={selectedGroup || null}
                  options={Array.isArray(grupos) && grupos.length > 0 ? grupos : []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={handleChangeGroup}
                  renderInput={(params) => <TextField {...params} label="Selecciona un grupo" />}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <span>{option.nombre}</span>
                        <Button variant="outlined" color="primary" onClick={(event) => {
                          event.stopPropagation();
                          setOpenTooltip(true);
                          setGrupo(option);
                        }}>
                          Info
                        </Button>
                      </div>
                    </li>
                  )}
                />
              </div>
              <div>
                <label className={styles.label}>Busque trabajadores</label>
                <Autocomplete
                  className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="rut"
                  value={selectedRuts || null}
                  options={personal ? personal : [] }
                  multiple 
                  getOptionLabel={(option) => option.rut + ' ' + option.nombre + ' ' + option?.apellido}
                  onChange={(e, value) => onChangeRutSelect(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona un rut" onChange={(e) => onChangeRut(e)}/>}
                  openOnFocus
                />
              </div>
              <div>
                <label className={styles.label}>Desde - hasta</label>
                <div style={{ display:'flex', gap:'5px', alignItems:'center' }}>
                  <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                          color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='desde'
                      type="date"
                      value={formData?.desde}
                      onChange={(e) => onChange(e)}
                  />
                  <TextField
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                    variant="outlined"
                    fullWidth
                    name='hasta'
                    type="date"
                    value={formData?.hasta}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <div>
              <label className={styles.label}>Tipo de aseo</label>
              <FormControl fullWidth>
                <Select
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tipo}
                  name='tipo'
                  defaultValue=''
                  label="Tipo"
                  onChange={handleChangeTipo}
                >
                  <MenuItem value='' disabled>Selecciona un tipo de aseo</MenuItem>
                  <MenuItem value='Aseo normal'>Aseo normal</MenuItem>
                  <MenuItem value='Limpieza terminal'>Limpieza terminal</MenuItem>
                  <MenuItem value='Desempolvado'>Desempolvado</MenuItem>
                </Select>
              </FormControl>
            </div>
              <div>
                <label className={styles.label}>Lista de tareas</label>
                <div style={{ maxHeight: '200px', overflowY: 'auto', margin:'0 10px' }}>
                  {tareas && tareas.map((tarea) => (
                    <FormControlLabel
                      key={tarea.id}
                      control={<Checkbox checked={checkedItems.includes(tarea)} onChange={() => handleCheckboxChange(tarea)} />}
                      label={tarea.descripcion}
                      style={{ display:'block' }}
                      onChange={() => handleCheckboxChange(tarea)}
                    />
                  ))}
                </div>
              </div>
              <Button 
                onClick={handleAgregar}
                className={styles.bgColorOrange}
                variant="contained" 
                disabled={!canSaveAgregar}
                style={{ marginTop:'10px' }}
                >
                Agregar
              </Button>
            </Grid>
            <Grid item  xs={12} sm={4} style={{ marginTop:'7px' }}>
              <label className={styles.label}><strong>Calendario de asignaciones.</strong></label>
              <CalendarioCreate events={listItems} ruts={formData?.trabajadoresRut}/>
            </Grid>
            <Grid item  xs={12} sm={4} style={{ marginTop:'7px' }}>
              <label className={styles.label}><strong>Calendario de asignaciones de los trabajadores seleccionados.</strong></label>
                {cargando ? <CircularProgress color="inherit" size={24} /> : <CalendarioCreateRuts events={eventos} ruts={formData?.trabajadoresRut} setCargando={setCargando}/>}
            </Grid>
            <Grid item  xs={12} sm={12} style={{ marginTop:'7px' }}>
              <TableHabitaciones habitaciones={listItems} setOpen3={setOpen3} setSelectedHabitaciones={setSelectedHabitaciones} selectedNameByRuts={selectedNameByRuts} handleDelete={handleDelete}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding:'0 16px' }}>
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}>
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Create