import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";

const EMPRESAS_URL = '/api/v1/Register/Empresas';
const EMPRESAS_URL_LIVE = '/api/v1/Register/empresas/';
const ADD_EMPRESA_URL = '/api/v1/Auth/register';

const axiosInstance = createAxiosInstance();
// ACTIONS
export const fetchEmpresas = createAsyncThunk("empresas/fetchEmpresas", async ()=> {
    try {
        const response = await axiosInstance.get(EMPRESAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const liveSearchByRutEmpresas = createAsyncThunk("empresas/liveSearchByRutEmpresas", async (value) => {
    try {
        const response = await axiosInstance.get(EMPRESAS_URL_LIVE+value);
        return response;
    } catch (error) {
        
    }
});

// idle : no hay operaciones en curso
const initialState = {
    empresas: [],
    liveEmpresas:[],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const empresaSlice = createSlice({
    name:"empresa",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchEmpresas.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchEmpresas.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.empresas) !== JSON.stringify(action.payload)) { //fetch
                state.empresas = action.payload;
            }
        })
        .addCase(fetchEmpresas.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(liveSearchByRutEmpresas.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(liveSearchByRutEmpresas.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.liveEmpresas) !== JSON.stringify(action.payload)) { //fetch
                state.liveEmpresas = action.payload;
            }
        })
        .addCase(liveSearchByRutEmpresas.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
    }
})

export const getAllEmpresas = (state) => state.empresas.empresas;
export const getEmpresasStatus = (state) => state.empresas.status;
export const getEmpresasError = (state) => state.empresas.error;

export const {} = empresaSlice.actions;
export default empresaSlice.reducer;