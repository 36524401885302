import styles from './components/components.module.css'
import { Backdrop, Box, CircularProgress, Container } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import Header from '../../../components/header/Header';
import TableCard from '../../../components/tableCard/TableCard';
import Table from './components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCargos, fetchUsers, getAllCargos, getAllUsers, getUsersError, getUsersStatus, getUsersStatusAll } from '../../../redux/features/users/userSlice';
import { useEffect, useState } from 'react';
import Create from './components/Create';
import { fetchRoles, getAllRoles, getRolesStatus } from '../../../redux/features/roles/rolSlice';
import { fetchEmpresas, getAllEmpresas, getEmpresasStatus } from '../../../redux/features/empresas/empresaSlice'
import SkeletonTable from '../../../components/Skeleton';
import { fetchBodegas, getAllBodegas, getBodegasStatus } from '../../../redux/features/bodegas/bodegaSlice';


const Usuarios = () => {

  const dispatch = useDispatch();
  
  const users = useSelector(getAllUsers);
  const status = useSelector(getUsersStatusAll);
  const error = useSelector(getUsersError);
  const roles = useSelector(getAllRoles);
  const rolesStatus = useSelector(getRolesStatus);
  const empresas = useSelector(getAllEmpresas);
  const empresasStatus = useSelector(getEmpresasStatus);
  const bodegas = useSelector(getAllBodegas);
  const bodegasStatus = useSelector(getBodegasStatus);
  const cargos = useSelector(getAllCargos)
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  
  useEffect(() => { 
    if(status == 'idle'){
      dispatch(fetchUsers());
      dispatch(fetchCargos());
    }
    if(rolesStatus == 'idle'){
      dispatch(fetchRoles());
    }
    if(empresasStatus == 'idle'){
      dispatch(fetchEmpresas());
    }
    if(bodegasStatus == 'idle'){
      dispatch(fetchBodegas());
    }
    
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de administradores"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los administradores en la plataforma.">
          {<GroupIcon style={{ fontSize:'1.5rem' }} className={styles.iconBg}/>}
        </Header>
        
        <TableCard>
          <Create roles={roles} empresas={empresas} open2={open2} setOpen2={setOpen2} bodegas={bodegas} cargos={cargos}/>
          { status == 'loading' ? <SkeletonTable/> : <Table users={users} setOpen3={setOpen3}/> }
        </TableCard>
        
      </Container>
    </Box>
  )
}

export default Usuarios