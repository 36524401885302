import axios from 'axios';
let token = localStorage.getItem('token');
export default axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 120000,
    headers: {
        'Authorization': `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});
export const axiosFormData = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 120000,
    headers: {
        'Authorization': `Bearer ${token}`,
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
    },
});

// Función para obtener el token del localStorage
const getToken = () => localStorage.getItem('token');

// Función para crear una nueva instancia de Axios
const createAxiosInstance = () => {
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        timeout: 120000,
        headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    });

    // Interceptor para actualizar el token en cada solicitud
    instance.interceptors.request.use(
        config => {
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    return instance;
};
const createAxiosInstanceHabitaciones = () => {
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        timeout: 60000,
        headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    });

    // Interceptor para actualizar el token en cada solicitud
    instance.interceptors.request.use(
        config => {
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    return instance;
};

// Función para crear una nueva instancia de Axios con FormData
const createAxiosFormDataInstance = () => {
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        timeout: 120000,
        headers: {
            "Content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
        },
    });

    // Interceptor para actualizar el token en cada solicitud
    instance.interceptors.request.use(
        config => {
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    return instance;
};

export { createAxiosInstance, createAxiosFormDataInstance, createAxiosInstanceHabitaciones };
