import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import MapIcon from '@mui/icons-material/Map';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSectores, getAllSectores, getSectoresStatus } from "../../../redux/features/sectores/sectorSlice";
import SkeletonTable from "../../../components/Skeleton";
import { fetchCampamento, fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';

const Aseo = () => {

  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const registros = [];
  const status = useSelector(getSectoresStatus);
  const campamentosStatus = useSelector(getCampamentosStatus);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    dispatch(fetchCampamentos());
  }, []);
  
  // useEffect(() => {
  //   if (campamentosStatus === 'succeeded' && campamentos.length === 0 && count === 1) {
  //     toast.warning('No hay campamentos registrados, un campamento es necesario para crear un sector.');
  //   }
  // }, [campamentosStatus, campamentos, count]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de aseo"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con las solicitudes de aseo en la plataforma.">
          {<MapIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create campamentos={campamentos} setOpen2={setOpen2} open2={open2}/>
          { status == 'loading' ? <SkeletonTable/> : <Table registros={registros} setOpen3={setOpen3} /> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Aseo