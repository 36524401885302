import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect } from 'react';

const Show = ({ open3, setOpen3, handleClose, seleItem }) => {

  useEffect(() => {
    if (seleItem) {
      console.log('seleItem:', seleItem);
    }
  }, [seleItem]);

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: '#2d3037',
          color: '#FFF',
          borderRadius: '0'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Reclamos y sugerencias:"} ID {seleItem?.id}
      </DialogTitle>

      <DialogContent>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor: '#212227', padding: '10px', borderRadius: '5px', border: '1px solid #0086ae', overflowX: 'auto' }}>
          <Grid item xs={12} md={6}>
            <Typography align="left">Tipo:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.tipo || 'N/A'}</strong></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography align="left">Estado:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.estado || 'N/A'}</strong></Typography>
          </Grid>

          {seleItem?.huesped && (
            <>
              <Grid item xs={12} md={6}>
                <Typography align="left">Huesped:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <strong style={{ fontWeight: '500' }}>{`${seleItem.huesped.nombre} ${seleItem.huesped.apellidoPaterno} ${seleItem.huesped.apellidoMaterno}`}</strong>
                </Typography>
              </Grid>
            </>
          )}

          {seleItem?.encargadoASeo && (
            <>
              <Grid item xs={12} md={6}>
                <Typography align="left">Encargado:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <strong style={{ fontWeight: '500' }}>{`${seleItem.encargadoASeo.nombre} ${seleItem.encargadoASeo.apellido} - ${seleItem.encargadoASeo.rut}`}</strong>
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6}>
            <Typography align="left">Solicitud:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.criticidadRequerimiento?.solicitud || 'N/A'}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography align="left">Especialidad:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.criticidadRequerimiento?.especialidad || 'N/A'}</strong></Typography>
          </Grid>

          {seleItem?.descripcion && (
            <>
              <Grid item xs={12} md={6}>
                <Typography align="left">Descripción:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><strong style={{ fontWeight: '500' }}>{seleItem.descripcion}</strong></Typography>
              </Grid>
            </>
          )}

          {seleItem?.habitacion && (
            <>
              <Grid item xs={12} md={6}>
                <Typography align="left">Habitación:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><strong style={{ fontWeight: '500' }}>{seleItem.habitacion.numero || 'N/A'}</strong></Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="left">Campamento:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="left">
                  {seleItem.habitacion.pabellon?.campamento.nombre ||
                    seleItem.habitacion.wing?.campamento.nombre ||
                    seleItem.habitacion.edificio?.campamento.nombre || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="left">
                  {seleItem.habitacion.pabellon ? 'Pabellón' :
                    seleItem.habitacion.wing ? 'Wing' :
                      seleItem.habitacion.edificio ? 'Edificio' : 'N/A'}:
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="left">
                  {seleItem.habitacion.pabellon?.identificador ||
                    seleItem.habitacion.wing?.nombre ||
                    seleItem.habitacion.edificio?.nombre || 'N/A'}
                </Typography>
              </Grid>
            </>
          )}

          {seleItem?.imagen && (
            <>
              <Grid item xs={12} md={12}>
                <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem.imagen}`} alt="sin imagen" />
              </Grid>
            </>
          )}

        </Grid>
      </DialogContent>
      <DialogActions >
        <Button onClick={handleClose} style={{ color: '#FFF' }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
