import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect } from 'react';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
useEffect(() => { console.log('seleItem:', seleItem) }, [seleItem]);

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
         backgroundColor:'#2d3037',
         color:'#FFF',
         borderRadius:'0'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" >
        {"Resumen Solicitud"} ID: {seleItem?.id}
      </DialogTitle>
      
      <DialogContent>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#212227', padding:'10px', borderRadius:'5px', border:'1px solid #0086ae', overflowX:'auto' }}>
          <Grid item xs={12} md={4}>
            <Typography align="left">Estado:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.estado}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Encargado:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.rutEncargadoAseo ?? '-'}</strong></Typography>
          </Grid>

          { seleItem?.habitacion &&
          <>
          <Grid item xs={12} md={4}>
            <Typography align="left">Habitación:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.habitacion?.numero ?? '-'}</strong></Typography>
          </Grid>
          </>
        }
        { seleItem?.espacioBodega &&
        <>
          <Grid item xs={12} md={4}>
            <Typography align="left">ID Espacio bodega:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.espacioBodega?.identificador ?? '-'}</strong></Typography>
          </Grid>
        </>
        }

          <Grid item xs={12} md={4}>
            <Typography align="left">Tipo:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.tipo}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Solicitud:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.criticidadRequerimiento?.solicitud}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Especialidad:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.criticidadRequerimiento?.especialidad}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Descripción:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.descripcion}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.foto}`} alt="img_plano" />
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions >
        <Button onClick={handleClose} style={{ color:'#FFF' }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
