import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import MapIcon from '@mui/icons-material/Map';
import ExtensionIcon from '@mui/icons-material/Extension';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { useAuth } from '../context/AppContext';

export const navDataAdministradorContrato = [
    {
        id: 0,
        icon: <EventIcon/>,
        text: "Inicio",
        link: "/inicio"
    },
]

export const navDataAdministradorAplicacion = [
    {
                text: "Inicio",
                icon: <DashboardIcon/>,
                link: "/inicio"
    },
    {
        text: "Espacios Físicos",
        icon: <DashboardIcon/>,
        submenu: [
            {
                text: "Campamentos",
                link: "/campamentos",
                icon: <HolidayVillageIcon/>,
            },
            {
                text: "Sectores",
                link: "/sectores",
                icon: <MapIcon/>,
            },
            {
                text: "Edificios",
                icon: <ApartmentIcon/>,
                submenu: [
                    {
                      text: "Wings",
                      link: "/wings",
                      icon: <LabelImportantIcon/>,
                    },
                    {
                      text: "Pabellones",
                      link: "/pabellones",
                      icon: <ApartmentIcon/>,
                    },
                ]
            },
            {
                text: "Áreas",
                icon: <MeetingRoomIcon/>,
                submenu: [
                  {
                    text: "Habitaciones",
                    link: "/habitaciones",
                    icon: <MeetingRoomIcon/>,
                  },
                  {
                    text: "Estandares",
                    link: "/estandar",
                    icon: <SettingsInputSvideoIcon/>,
                  },
                  {
                    text: "Espacios Comunes",
                    link: "/espacios",
                    icon: <LabelImportantIcon/>,
                },
                ],
            },
        ]
    },
    {
        text: "Criticidad de requerimientos",
        icon: <TroubleshootIcon/>,
        link: "/criticidad_requerimientos"
    },
    {
        text: "Usuarios",
        icon: <GroupIcon/>,
        submenu: [
            {
                text: "Administradores",
                link: "/usuarios",
                icon: <GroupIcon/>,
            },
            {
                text: "Responsables",
                link: "/responsables",
                icon: <PersonIcon/>,
            },
            {
                text: "Huéspedes",
                link: "/huespedes",
                icon: <DashboardIcon/>
            }
        ]
    }
];
export const navDataAdministradorInventario = [
    {
                text: "Inicio",
                icon: <DashboardIcon/>,
                link: "/inicio"
    },
    {
                text: "Inventario",
                icon: <DashboardIcon/>,
                link: "/inventario"
    },
]
export const navDataAdministradorAplicacionDashboard = [
    {
                text: "Inicio",
                icon: <DashboardIcon/>,
                link: "/inicio"
    },
    {
        text: "Espacios Físicos",
        icon: <DashboardIcon/>,
        submenu: [
            {
                text: "Campamentos",
                link: "/campamentos",
                icon: <HolidayVillageIcon/>,
            },
            {
                text: "Sectores",
                link: "/sectores",
                icon: <MapIcon/>,
            },
            {
                text: "Edificios",
                link: "/edificios",
                icon: <ApartmentIcon/>,
            },
            {
                text: "Áreas",
                icon: <MeetingRoomIcon/>,
                submenu: [
                  {
                    text: "Habitaciones",
                    link: "/habitaciones",
                    icon: <MeetingRoomIcon/>,
                  },
                  {
                    text: "Estandares",
                    link: "/estandar",
                    icon: <SettingsInputSvideoIcon/>,
                  },
                  {
                    text: "Espacios Comunes",
                    link: "/espacios",
                    icon: <LabelImportantIcon/>,
                },
                ],
            },
        ]
    },
    // {
    //             text: "Tipo de requerimientos",
    //             link: "/requerimientos",
    //             icon: <AssignmentIcon/>,
    // },
    {
        text: "Criticidad de requerimientos",
        icon: <TroubleshootIcon/>,
        link: "/criticidad_requerimientos"
    },
    {
        text: "Usuarios",
        icon: <GroupIcon/>,
        submenu: [
            {
                text: "Administradores",
                link: "/usuarios",
                icon: <GroupIcon/>,
            },
            {
                text: "Responsables",
                link: "/responsables",
                icon: <PersonIcon/>,
            },
            {
                text: "Huéspedes",
                link: "/huespedes",
                icon: <DashboardIcon/>
            }
        ]
    }
];