import styles from './components.module.css'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { liveSearchByRut, liveSearchEncargadoBodegaByBodegaId } from '../../../redux/features/users/userSlice';
import { addNewTraspaso } from '../../../redux/features/bodegas/bodegaSlice';
import { createAxiosInstance } from '../../../api/axios';
import CircleIcon from '@mui/icons-material/Circle';
import { itemsTrasladoColumns } from '../../../lib/columns';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';

const data = {
  fecha: new Date().toISOString().split('T')[0],
  bodegaOrigenId:'',
  bodegaDestinoId:'',
  articulos: [],
  encargadoDeBodega:'',
}

const Create = ({ open2, setOpen2, bodegas }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [itemsNew, setItemsNew] = useState([]);
  const [ruts, setRuts] = useState([])
  const [totalPrice, setTotalPrice] = useState(0);
  const [enterPrice, setEnterPrice] = useState(0);
  const [searching, setSearching] = useState(false);
  const [listItems, setListItems] = useState([])
  const [listItems2, setListItems2] = useState([])
  const [bodegasItems, setBodegasItems] = useState([])
  const [bodegaOrigen, setBodegaOrigen] = useState({nombre: ''})
  const [bodegaDestino, setBodegaDestino] = useState({nombre: ''})
  const [encargado, setEncargado] = useState({})
  const [selectedItems, setSelectedItems] = useState(null)
  const [open3, setOpen3] = useState(false);
  
  const [autocompleteText, setAutocompleteText] = useState('Ingrese ID de bodega');



  const [pageSize, setPageSize] = useState(10);
  const finalColumns = itemsTrasladoColumns();

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  // const onChangeRut = async (e) => {
  //   try {
  //     setOpen2(true);
  //     const resp = await dispatch(liveSearchEncargadoBodegaByBodegaId(e.target.value))
  //     console.log('encargados', resp.payload.data);
  //     setRuts(resp.payload.data);
  //   } catch (error) {
  //     console.log(error)
  //   } finally{
  //     setOpen2(false)
  //   }
  // }
  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const value = e.target.value;
      if (value) {
        const resp = await dispatch(liveSearchEncargadoBodegaByBodegaId(value));
        console.log('encargados', resp.payload.data);
        setRuts(resp.payload.data);
        if(resp.payload.data.length > 0){
          setAutocompleteText('Seleccione un encargado')
        } else{
          setAutocompleteText('La bodega no presenta encargados')
        }
      }
    } catch (error) {
      console.log(error);
    } finally{
      setOpen2(false)
    }
  };
  const onChangeRutSelect = async (e,newValue) => {
    console.log(newValue.rut)
    if(newValue != null){
      setFormData({...formData, encargadoDeBodega: newValue.rut})
      setEncargado(newValue)
    } else{
      setFormData({...formData, encargadoDeBodega: null})
    }
  }

  const handleChangeBodega = async (event, newValue) => {
    setSelectedBodega(newValue);
    setBodegaOrigen(newValue)
    try {
      const response = await getBodegas(newValue.id);
      setBodegasItems(response);
    } catch (error) {
      console.log('error al traer los items de la bodega', error);
    }
    console.log(newValue)
    if(newValue != null){
      setFormData({...formData, bodegaOrigenId: newValue.id});
    } else{
      setFormData({...formData, bodegaOrigenId: 0})
    }  
  };

  const handleChangeBodegaDestino = (event, newValue) => {
    setSelectedBodega(newValue);
    setBodegaDestino(newValue);
    console.log(newValue)
    if(newValue != null){
      setFormData({...formData, bodegaDestinoId: newValue.id});
    } else{
      setFormData({...formData, bodegaDestinoId: 0})
    }  
  };

  const onChange2 = (e, index) => {
    const { name, value } = e.target;
    
    setItemsNew(prevItems => {
      const updatedItems = [...prevItems];
      updatedItems[index][name] = value;
      
      updatedItems.forEach(item => {
        if (item.hasOwnProperty('cantidad') && item.hasOwnProperty('precio')) {
          const cantidad = parseFloat(item.cantidad || 0);
          const precio = parseFloat(item.precio || 0);
          item.total = cantidad * precio;
        }
      });
      
      setItemsNew(updatedItems);
      return updatedItems;
    });
  };
  

  const handleTotalPrice = () => {
    const totalPrice = itemsNew.reduce((total, item) => {
      // Parsear el precio y la cantidad a números
      const precioNumerico = parseFloat(item.precio);
      // const cantidadNumerica = parseInt(item.cantidad);
      const cantidadNumerica = parseInt(item.cantidadTraspaso);
  
      // Verificar si tanto el precio como la cantidad son números válidos
      if (!isNaN(precioNumerico) && !isNaN(cantidadNumerica)) {
        return total + (precioNumerico * cantidadNumerica);
      } else {
        return total; // Si falta el precio o la cantidad, no sumar nada
      }
    }, 0);
    
    setTotalPrice(totalPrice);
    setFormData({...formData, montoTotal: totalPrice})
  }

  useEffect(()=>{
    if(itemsNew.length != 0 ){
      handleTotalPrice();
    }
  },[itemsNew])
  
  const axiosInstance = createAxiosInstance();

  const getProducts = async () => {
    try {
      const response = await axiosInstance.get('/api/v1/producto');
      console.log(response);
      setListItems2(response.data);
    } catch (error) {
      console.log('Error al traer los items: ',error)
    }
  }
  const getBodegas = async (bodega) => {
    try {
      const response = await axiosInstance.get('/api/v1/Inventario/productos-bodega/'+bodega);
      console.log('bodegas productos: ',response.data);
      // setBodegasItems(response.data);
      setListItems(response.data);
      return response.data
    } catch (error) {
      console.log('Error al traer los items: ',error)
    }
  }

  useEffect(() => {
    getProducts();
  }, [])

  const search = async (e, newValue) => {
    try {
      setSearching(true);
      const filterItem = listItems.find(item => item.codigo === newValue.codigo || item.nombre.toUpperCase() === newValue.nombre.toUpperCase());
      console.log('FilterItem', filterItem) 
      
      if (filterItem) {
        const alreadyIn = itemsNew.find(item => item.codigo === filterItem.codigo);
        if(alreadyIn){
          toast.error('El artículo ya esta agregado.');  
        }else{
            setItems([...items, newValue]);
            const item = {
              // ...filterItem, 
              codigo: filterItem.codigo, descripcion: filterItem.descripcion,
              productoId: filterItem.id, cantidadRecibida: filterItem.stock, cantidadDelDocumento: 0, precio:filterItem.precio, cantidadTraspaso: ''
            }
            setItemsNew([...itemsNew, item]);
            setNewItem('');

              setFormData(prev => (
                {
                  ...prev,
                  articulos: [...prev.articulos, item]
                }
              ));
        }
      }else{
        toast.error('No se ha encontrado el artículo.');
      }
    } catch (error) {
        
    } finally{
      setSearching(false);
    }
  };

  const canSave = [formData.encargadoDeBodega, formData.bodegaOrigenId, formData.bodegaDestinoId, formData?.articulos.length > 0, formData.montoTotal > 0].every(Boolean) && addRequestStatus === 'idle';

  useEffect(() => {
    const total = itemsNew.reduce((accumulator, item) => {
      // return accumulator + (item.cantidadRecibida * item.precio);
      return accumulator + (item.cantidadTraspaso * item.precio);
    }, 0);
    setTotalPrice(total);
  }, [itemsNew])
  


  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewTraspaso(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
          setSelectedBodega()
          setSearching()
          setSelectedItems([])
          setBodegaOrigen()
          setEncargado()
          setItems()
          setItemsNew()
          setListItems()
          setListItems2()
          setTotalPrice()
        }
      } catch (error) {
        console.error('Error al crear el traspaso', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }

  const handleRemove = (index) => {
    const newItems = [...itemsNew];
    newItems.splice(index, 1);
    setItemsNew(newItems);
    
    const newFormData = { ...formData };
    newFormData.articulos = newItems;
    setFormData(newFormData);
  };
  
  const handleClose = () => setOpen3(false);

  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para registrar un traspaso de artículos entre bodegas.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>Ingresar registro</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >

      <Dialog
        open={open3}      
        onClose={handleClose}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
              <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "#2d3037", color:'#FFF' }}>
                {"Resumen solicitud"}
              </DialogTitle>
              <DialogContent style={{ backgroundColor: "#2d3037", color:'#FFF' }}>
                <Grid container={true} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography>Bodega Origen: <strong>{bodegaOrigen?.nombre}</strong></Typography>
                    <Typography>Bodega Destino: <strong>{bodegaDestino?.nombre}</strong></Typography>
                    <Typography>Encargado de bodega: <strong>{encargado?.nombre}</strong></Typography>
                    <Typography>Monto Total: <strong>${formData?.montoTotal}</strong></Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                      <Typography variant='h7' style={{ fontWeight:'600', marginBottom:'5px' }}>Artículos</Typography>
                      <DataGrid
                        getRowId={row=>row.id ? row.id : Math.random() }
                        lots={{ toolbar: GridToolbar }}
                        rows={itemsNew}
                        columns={finalColumns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "No hay datos disponibles",
                        pagination: {
                            labelRowsPerPage: "Filas por página:",
                        }
                        }}
                        classes={{ menuIcon: styles.menuIcon }}
                        style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                        sx={{ 
                              '& .MuiDataGrid-cell': {
                                color: '#FFF',
                                borderBottom: '1px solid #c6c6c6',
                              },
                              '& .MuiDataGrid-columnHeader': {
                                color: '#FFF',
                                borderBottom: '1px solid #c6c6c6',
                              },
                              '& .MuiDataGrid-columnHeaders': {
                                borderBottom: '1px solid #c6c6c6',
                              },
                              '& .MuiDataGrid-footerContainer': {
                                color: '#FFF',
                                borderTop: '1px solid #c6c6c6',
                              },
                              '& .MuiTablePagination-root': {
                                color: '#FFF',
                                borderTop: '1px solid #c6c6c6',
                              },
                              '& .MuiDataGrid-toolbarContainer': {
                                color: '#FFF',
                              },
                              '& .MuiDataGrid-menu': {
                                color: '#FFF',
                              },
                              '& .MuiTablePagination-actions': {
                                color: '#FFF', // Color for pagination actions
                              },
                              '& .MuiTablePagination-select': {
                                color: '#FFF', // Color for pagination select
                              },
                              '& .MuiTablePagination-selectLabel': {
                                color: '#FFF', // Color for pagination select label
                              },
                              '& .MuiTablePagination-displayedRows': {
                                color: '#FFF', // Color for displayed rows text
                              },
                        }}
                        slotProps={{
                              toolbar:{
                                sx:{
                                  color:'#FFF'
                                }
                              },
                              pagination: {
                                sx: {
                                  color: '#FFF',
                                },
                              },
                              menu: {
                                sx: {
                                  color: '#FFF',
                                },
                              },
                        }}
                      />
                    </Grid>
                  </Grid>
              </DialogContent>
              <DialogActions style={{ backgroundColor: "#2d3037", color:'#FFF' }}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorOrange}
                  style={{ opacity: !canSave ? '.7':'' }}
                  variant="contained" 
                  disabled={!canSave}
                  >
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Ingresar registro'}
                </Button>
                <Button onClick={handleClose}>Cerrar</Button>
              </DialogActions>
            </Dialog>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <ExitToAppIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Registro de traspaso  entre bodegas</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para ingresar el registro.</span>
          </div>
      </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>Desde la bodega:</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={bodegas}
                name="bodega"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeBodega(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona una bodega" />}
              />      
            </Grid>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>A la bodega:</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={bodegas}
                name="bodega"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeBodegaDestino(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona una bodega" />}
              />      
            </Grid>
            <Grid item xs={12} md={4} >
              <label className={styles.label}>Encargado de bodega de destino</label>
              <Autocomplete
                key={ruts.length}
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                disablePortal
                disableClearable
                id="sectores-autocomplete"
                name="encargadoDeBodega"
                options={ruts}
                getOptionLabel={(option) => option ? `${option.nombre} ${option.apellido}` : ''}
                onChange={onChangeRutSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={autocompleteText}
                    onChange={onChangeRut}
                  />
                )}
            />                  
            </Grid>
            <Grid item  xs={12} sm={3}>
              <label className={styles.label}>Fecha</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='fecha'
                type="date"
                value={formData.fecha}
                onChange={(e) => onChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
                <label className={styles.label}>
                  {selectedBodega ? 'Lista de artículos (Busca artículos por nombre o por código)' : 'Primero selecciona una bodega para poder buscar árticulos.'}
                </label>
                <Autocomplete
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#FFF',
                      },
                    }}
                    disablePortal
                    disableClearable
                    id="campamentos-autocomplete"
                    options={listItems}
                    name="bodega"
                    disabled={!selectedBodega}
                    value={selectedItems}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(event, value) => search(event, value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
            </Grid>
            </Grid>
            
            <Grid container={true} spacing={1} style={{ maxHeight:'500px', overflowY:'auto', border:'1px solid #777', borderRadius:'5px', padding:'5px', margin:'0', marginTop:'20px', }}> 
              {itemsNew.length != 0 ? itemsNew?.map((item, index) => (
                <>
                  <Grid item xs={12} md={1} key={index}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <Button 
                      variant='contained' 
                      className={styles.button} 
                      onClick={() => handleRemove(index)}
                      style={{ 
                        width: '90px',
                        backgroundColor: 'red'
                      }}
                    >
                      Remover
                    </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Código Artículo {index + 1}</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='codigo'
                      value={item.codigo}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={5} key={index}>
                    <label className={styles.label}>Descripción</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='descripcion'
                      value={item.descripcion}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Precio (CLP)</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='precio'
                      value={item.precio}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Cantidad a traspasar</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='cantidadTraspaso'
                      value={item.cantidadTraspaso}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  {/*
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Cantidad del documento</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='cantidadDelDocumento'
                      value={item.cantidadDelDocumento || 0}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Cantidad recibida</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='cantidadRecibida'
                      value={item.cantidadRecibida || 0}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  */}
                </>
              )) : <span>Buscar artículos para poder ver su información.</span>
              }
            </Grid>

            <Grid container={true}>
              <Grid item  xs={12} sm={3}>
                <label className={styles.label}>Monto total (CLP)</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                  }}
                variant="outlined"
                fullWidth
                name='monto'
                // disabled
                value={totalPrice}
                type="number"
                // onChange={(e) => onChange(e)}
                />
              </Grid>
            </Grid>
            
            </DialogContent>
            <DialogActions>
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={()=>setOpen3(true)}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}>
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Registrar'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
            </DialogActions>
      </Dialog>
    </div>
  )
}

export default Create