import styles from './components.module.css';
import { useEffect, useState } from "react";
import { trasladoColumns } from "../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Delete from './Delete'
import Edit from './Edit'
import Show from './Show'

const Table = ({ registros, setOpen3 }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = trasladoColumns(setOpen, setSeleItem, setOpenEdit, setOpenShow);
  const handleClose = () => { setOpenShow(false) };

  return (
    <div style={{ marginTop:'16px' }}>
        <Delete open={open} setOpen={setOpen} seleItem={seleItem} setOpen3={setOpen3}/>
        <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3}/>
        <Show seleItem={seleItem} open3={openShow} setOpen3={setOpenShow} handleClose={handleClose}/>
        <DataGrid
            getRowId={row=>row.id ? row.id : Math.random() }
            lots={{ toolbar: GridToolbar }}
            rows={registros}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#FFF',
              },
              '& .MuiDataGrid-menu': {
                color: '#FFF',
              },
              '& .MuiTablePagination-actions': {
                color: '#FFF', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#FFF', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#FFF', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#FFF', // Color for displayed rows text
              },
             }}
            slotProps={{
              toolbar:{
                sx:{
                  color:'#FFF'
                }
              },
              pagination: {
                sx: {
                  color: '#FFF',
                },
              },
              menu: {
                sx: {
                  color: '#FFF',
                },
              },
            }}
        />
    </div>
  )
}

export default Table