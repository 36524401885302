import styles from './components.module.css';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllHabitaciones, getHabitacionesStatus } from "../../../../redux/features/habitaciones/habitacionSlice";
import SkeletonTable from '../../../../components/Skeleton';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { habitacionReservasColumns } from "../../../../lib/columns";
import { fetchEstandar } from '../../../../redux/features/estandares/estandarSlice';

const Habitaciones = ({ seleccionados, checkedHabitaciones, setCheckedHabitaciones, asignar, setAsignacionHabitacionesTrabajadores, setCheck, check, seleItemSolicitud, envioS, setEenvioS, asignacionHabitacionesTrabajadores }) => {

    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [numeroHabitacion, setNumeroHabitacion] = useState(0);
    const [selectModel, setSelectModel] = useState({});
    const habitaciones = useSelector(getAllHabitaciones);
    const [selectedTrabajador, setSelectedTrabajador] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = habitacionReservasColumns(setOpen, setSeleItem);
    const status = useSelector(getHabitacionesStatus);
    const [rolesList, setRolesList] = useState([])
    const dispatch = useDispatch();
    const [count, setCount] = useState(0)
    const [rows, setRows] = useState([])

    // const handleRowSelectionModelChange = (selectionModel) => {
    //   console.log(selectModel)
    //   const selectedRows = selectionModel.map((id) => {
    //     return habitaciones.find((row) => row.id === id);
    //   });
    //   setCheckedHabitaciones(selectedRows.map((row) => ({ ...row })));
    //   setSelectModel(selectionModel)
    // };
    
    const handleChangeTrabajador = (event) => {
      const { value } = event.target;
      setRolesList(value);
      setSelectedTrabajador(value);
    };
    const handleRowSelectionChange = () => {
      console.log('seleItem', seleItem);
      console.log('seleTrabajador', selectedTrabajador);
      setCheck(false);
      setAsignacionHabitacionesTrabajadores([]);
      // console.log('seleccion', selectModel);
      const habitacionesAsignadas = selectedTrabajador.map((trabajador) => {
        return {
          habitacion: { id: seleItem.id, numeroHabitacion: seleItem.numero },
          trabajador: trabajador
        };
      });
      const solicitudEnvio = selectedTrabajador.map((trabajador) => {
        return {  rut: trabajador.rut, idHabitacion: seleItem.numero, solicitudId: seleItemSolicitud.id };
      });
    
      setCheckedHabitaciones(prevCheckedHabitaciones => prevCheckedHabitaciones.concat(habitacionesAsignadas));
      setEenvioS((prevSolicitudes) => {
        if (!Array.isArray(prevSolicitudes)) {
          return solicitudEnvio;
        }
        return prevSolicitudes.concat(solicitudEnvio);
      });

      setOpen(false);
      setCheck(true);
      asignar();
      setRolesList([]);
    };

    

  return (
    <>
        {/* FILTROS */}
        {/* LISTADO DE TRABAJADORES SELECCIONADOS (DARLE UN SCROLL) 
          {JSON.stringify(seleccionados)}
          <br />
          {JSON.stringify(checkedHabitaciones)}
          <br />
        */}

      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
      >
        <DialogTitle>Asignar habitación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecciona un trabajador para asignarle la habitación seleccionada.
          </DialogContentText>
          <FormControl sx={{ width: '100%' }}>
         <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={rolesList}
            onChange={handleChangeTrabajador}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.map(item => item.nombre).join(', ')}
          >
            {asignacionHabitacionesTrabajadores.map((seleccionado) => (
              <MenuItem key={seleccionado} value={seleccionado}>
                <Checkbox checked={rolesList.indexOf(seleccionado) > -1} />
                <ListItemText primary={seleccionado.rut + ' - ' + seleccionado.nombre} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
      
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpen(false)}>Cancelar</Button>
          <Button onClick={()=>handleRowSelectionChange(selectModel)}>Asignar Habitación</Button>
        </DialogActions>
      </Dialog>
        <Grid container={true} spacing={1}>
            <Grid item sm={12} style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                <Typography variant='span'>{seleccionados.length - checkedHabitaciones.length == 1 ? 'Queda ' : 'Quedan'} {seleccionados.length - checkedHabitaciones.length} {seleccionados.length - checkedHabitaciones.length == 1 ? 'habitación ' : 'habitaciones'} por asignar</Typography>
                {/*<Button
                  onClick={asignar}
                  className={styles.bgColor}
                  variant="contained"
                >
                  Asignar todos los trabajadores
                </Button> */}
            </Grid>
        </Grid>

        {
            status == 'loading' 
            ? <SkeletonTable/>
            :
            <Grid item xs={12} sm={12} mt={2} style={{ height:'500px' }}>
                <DataGrid
                    getRowId={row=>row.id ? row.id : Math.random() }
                    // checkboxSelection
                    // rowSelectionModel ={checkedHabitaciones.map(item => item.id)}
                    // onRowSelectionModelChange={handleRowSelectionChange}
                    // onRowSelectionModelChange={handleRowSelectionModelChange}
                    lots={{ toolbar: GridToolbar }}
                    rows={habitaciones}
                    columns={finalColumns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    localeText={{
                    ...esES.components.MuiDataGrid.defaultProps.localeText,
                    noRowsLabel: "No hay datos disponibles",
                    pagination: {
                        labelRowsPerPage: "Filas por página:",
                    }
                    }}
                    classes={{ menuIcon: styles.menuIcon }}
                />
            </Grid>
        }
    </>
  )
}

export default Habitaciones