import styles from './components.module.css';
import { useEffect, useState } from "react";
import { habitacionGruposColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Delete from './Delete'
import Edit from './Edit'
import { toast } from 'react-toastify';

const Table = ({ habitaciones, setOpen3, setSelectedHabitaciones, selectedPabellon, selectedWing, selectedOtro, setSelectedWing, setSelectedPabellon, setSelectedOtro, formData, setFormData }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = habitacionGruposColumns(setOpen, setSeleItem, setOpenEdit);
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  const [selectedHabitacionIds, setSelectedHabitacionIds] = useState([]);
  
  const handleRowSelectionChange = (selectionModel) => {
    console.log('Selection Model', selectionModel);
    setSelectedHabitaciones(selectionModel);
    setSelectedHabitacionIds(selectionModel);
    setFormData((prevFormData) => ({
      ...prevFormData,
      habitaciones: selectionModel,
    }));
  };

  useEffect(() => {
    let filtered = [];

    if (Array.isArray(habitaciones)) {
      if (selectedPabellon && selectedPabellon.length > 0) {
        const pabellonIds = selectedPabellon.map(pabellon => pabellon.id);
        filtered = filtered.concat(habitaciones.filter(hab => pabellonIds.includes(hab.pabellonId)));
        if(filtered.length == 0){
          toast.info('El pabellón no tiene habitaciones asociadas.')
        }
      }
    
      if (selectedWing && selectedWing.length > 0) {
        const wingIds = selectedWing.map(wing => wing.id);
        filtered = filtered.concat(habitaciones.filter(hab => wingIds.includes(hab.wingId)));
      }
    
      if (selectedOtro && selectedOtro.length > 0) {
        const otrosIds = selectedOtro.map(otro => otro.id);
        filtered = filtered.concat(habitaciones.filter(hab => otrosIds.includes(hab.recintoId)));
      }
    } else {
      console.warn('Habitaciones no está cargado o no es un arreglo');
    }

    setFilterHabitaciones(filtered);
  }, [selectedPabellon, selectedWing, selectedOtro, habitaciones]);


  return (
    <div style={{ marginTop:'16px' }}>
        <Delete open={open} setOpen={setOpen} seleItem={seleItem} setOpen3={setOpen3}/>
        <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3} selectedHabitacionIds={selectedHabitacionIds} setSelectedHabitacionIds={setSelectedHabitacionIds}/>
        {/*
        {JSON.stringify(selectedPabellon)}
        {JSON.stringify(selectedWing)}
        {JSON.stringify(selectedOtro)}
        {JSON.stringify(formData.habitaciones)}
        */}
        <DataGrid
            getRowId={row=>row.id ? row.id : Math.random() }
            checkboxSelection
            onRowSelectionModelChange={handleRowSelectionChange}
            rowSelectionModel={selectedHabitacionIds}
            lots={{ toolbar: GridToolbar }}
            rows={filterHabitaciones}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            // isRowSelectable={(params) => params.row.grupo == true}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#FFF',
              },
              '& .MuiDataGrid-menu': {
                color: '#FFF',
              },
              '& .MuiTablePagination-actions': {
                color: '#FFF', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#FFF', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#FFF', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#FFF', // Color for displayed rows text
              },
             }}
            slotProps={{
              toolbar:{
                sx:{
                  color:'#FFF'
                }
              },
              pagination: {
                sx: {
                  color: '#FFF',
                },
              },
              menu: {
                sx: {
                  color: '#FFF',
                },
              },
            }}
        />
    </div>
  )
}

export default Table