import styles from './components.module.css';
import { useEffect, useState } from "react";
import { feedbackColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Delete from './Delete'
import Edit from './Edit'
import { createAxiosInstance } from '../../../../api/axios';
import { Autocomplete, Grid, TextField, Typography  } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Historico from '../../../AdministradorContrato/ingreso/components_ingreso/Historico';

const Table = ({ solicitudes, setOpen3, data, tramos, setTramos, ger, siArea, empresas, contratos, setOpen2, open2, turnos }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [openHistorico, setOpenHistorico] = useState(false);
  const finalColumns = feedbackColumns(setOpen, setSeleItem, setOpenEdit, setOpenHistorico);
  const [vicep, setVicep] = useState([]);
  
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedVicepresidencias, setSelectedVicepresidencias] = useState('');
  const [selectedGerencia, setSelectedGerencia] = useState('');
  const [selectedContrato, setSelectedContrato] = useState('');
  const [filterRegistros, setFilterRegistros] = useState([]);
  const [filterContratos, setFilterContratos] = useState([]);
  const axiosInstance = createAxiosInstance();
  // useEffect(() => {
  //   setFilterRegistros(solicitudes);
  // }, [solicitudes]);

  useEffect(() => {
    console.log('registros', solicitudes);
    const sortedRegistros = [...solicitudes].sort((a, b) => {
      if (a.estado === 'Rechazado' && b.estado !== 'Rechazado') {
        return -1;
      }
      if (a.estado !== 'Rechazado' && b.estado === 'Rechazado') {
        return 1;
      }
      return 0;
    });
    setFilterRegistros(sortedRegistros);
  }, [solicitudes]);

  useEffect(() => {
    if (Array.isArray(ger)) {
      const vp = ger.filter(item => typeof item === 'string' && item.startsWith('VP'));
      setVicep(vp);
    }
  }, [ger]);
  
  
  const handleChange = async (name, newValue) => {
    if (newValue != null) {
      if (name === 'empresa') {
        setSelectedEmpresa(newValue);
        try {
          setOpen3(true);
          const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+newValue.idEmpresa);
          setFilterContratos(resp.data);
          setSelectedContrato('')
        } catch (error) {
          console.log(error);
        } finally {
          setOpen3(false);
        }
      } else if (name === 'contrato') {
        setSelectedContrato(newValue);
      } else if (name === 'vicepresidencia') {
        setSelectedVicepresidencias(newValue);
      } else if (name === 'gerencia') {
        setSelectedGerencia(newValue);
      } else if (name === 'siArea') {
        setSelectedArea(newValue);
      }
    } else {
      if (name === 'empresa') {
        setSelectedEmpresa('');
        setFilterContratos([]);
        // setSelectedContrato(''); 
      } else if (name === 'contrato') {
        setSelectedContrato('');
      } else if (name === 'vicepresidencia') {
        setSelectedVicepresidencias('');
      } else if (name === 'gerencia') {
        setSelectedGerencia('');
      } else if (name === 'siArea') {
        setSelectedArea('');
      }
    }
    filterData(
      name === 'empresa' ? newValue?.razonSocial?.toLowerCase() : selectedEmpresa?.razonSocial?.toLowerCase(),
      name === 'contrato' ? newValue : selectedContrato,
      name === 'vicepresidencia' ? newValue : selectedVicepresidencias,
      name === 'gerencia' ? newValue : selectedGerencia,
      name === 'siArea' ? newValue : selectedArea
    );
  }

  const filterData = (empresa, contrato, vicepresidencia, gerencia, siArea) => {
    const filteredRegistros = solicitudes.filter(reg => {
      const empresaMatch = empresa ? reg.empresa.razonSocial.toLowerCase().includes(empresa) : true;
      const contratoMatch = contrato ? reg.tramos.some(item => item.contrato.toLowerCase().includes(contrato.toLowerCase())) : true;
      const vicepresidenciaMatch = vicepresidencia ? reg.tramos.some(item => item.vicepresidencia.toLowerCase().includes(vicepresidencia.toLowerCase())) : true;
      const gerenciaMatch = gerencia ? reg.tramos.some(item => item.gerencia.toLowerCase().includes(gerencia.toLowerCase())) : true;
      const siAreaMatch = siArea ? reg.tramos.some(item => item.siArea.toLowerCase().includes(siArea.toLowerCase())) : true;

      return empresaMatch && contratoMatch && vicepresidenciaMatch && gerenciaMatch && siAreaMatch;
    });

    setFilterRegistros(filteredRegistros);
  }
  return (
    <div style={{ marginTop:'16px' }}>
        <Delete open={open} setOpen={setOpen} seleItem={seleItem} setOpen3={setOpen3}/>
        <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3} setOpen2={setOpen2} siArea={siArea} empresas={empresas} ger={ger} open2={open2} setOpen={setOpen} open={open} data={data} tramos={tramos} setTramos={setTramos} contratos={contratos} turnos={turnos}/>
        
        <Historico setOpen={setOpenHistorico} open={openHistorico} seleItem={seleItem} setOpen3={setOpen3}/>

        <Accordion sx={{ backgroundColor:'transparent', width:'100%', color:'#FFF', border:'1px solid var(--border-blue)', borderRadius:'2px'}}>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon sx={{ color:'#FFF' }}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor:'var(--textfield-bg)', width:'100%'}}
        >
          <Typography variant='span' style={{ fontSize:'16px', fontWeight:'500' }}>Haz click aquí y utiliza los filtros para desplegar información especifica en la tabla</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor:'var(--textfield-bg)' }}>
          <Grid container spacing={1} style={{ marginBottom: '5px' }}>
            <Grid item xs={12} md={3}>
              <label className={styles.label}>Empresas</label>
              <Autocomplete
              className={styles.textField2}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                disablePortal
                id="empresa-autocomplete"
                options={empresas}
                name="empresa"
                value={selectedEmpresa || null}
                getOptionLabel={(option) => option.razonSocial}
                onChange={(e, value) => handleChange('empresa', value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.label}>Contratos</label>
              <Autocomplete
              className={styles.textField2}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                disablePortal
                id="contrato-autocomplete"
                options={filterContratos}
                name="contrato"
                value={selectedContrato || null}
                getOptionLabel={(option) => option}
                onChange={(e, value) => handleChange('contrato', value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.label}>Vicepresidencias</label>
              <Autocomplete
              className={styles.textField2}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                disablePortal
                id="vicepresidencia-autocomplete"
                options={vicep}
                name="vicepresidencia"
                value={selectedVicepresidencias || null}
                getOptionLabel={(option) => option}
                onChange={(e, value) => handleChange('vicepresidencia', value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.label}>Gerencias</label>
              <Autocomplete
              className={styles.textField2}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                disablePortal
                id="gerencia-autocomplete"
                options={ger}
                name="gerencia"
                value={selectedGerencia || null}
                getOptionLabel={(option) => option}
                onChange={(e, value) => handleChange('gerencia', value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.label}>siAreas</label>
              <Autocomplete
              className={styles.textField2}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                disablePortal
                id="siArea-autocomplete"
                options={siArea}
                name="siArea"
                value={selectedArea || null}
                getOptionLabel={(option) => option}
                onChange={(e, value) => handleChange('siArea', value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        </Accordion>

        <DataGrid
            getRowId={row=>row.id ? row.id : Math.random() }
            lots={{ toolbar: GridToolbar }}
            rows={filterRegistros}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#FFF',
              },
              '& .MuiDataGrid-menu': {
                color: '#FFF',
              },
              '& .MuiTablePagination-actions': {
                color: '#FFF', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#FFF', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#FFF', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#FFF', // Color for displayed rows text
              },
             }}
            slotProps={{
              toolbar:{
                sx:{
                  color:'#FFF'
                }
              },
              pagination: {
                sx: {
                  color: '#FFF',
                },
              },
              menu: {
                sx: {
                  color: '#FFF',
                },
              },
            }}
        />
    </div>
  )
}

export default Table