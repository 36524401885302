import styles from './components.module.css';
import { useEffect, useState } from "react";
import { gestionForecastColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Edit from './Edit';
import { createAxiosInstance } from '../../../../api/axios';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useAuth } from '../../../../context/AppContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Aprobar from './Aprobar';

const Table = ({ registros, setTramos, tramos, openModal, setOpenModal, onChange2, selectedTramosForecast, handleChange, handleEliminarTramo, handleAgregarTramo, setSelectedTramosForecast, ger, siArea, empresas, open3, setOpen3, handleEdit, value, setValue, turnos }) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const { info } = useAuth();
  const rol = info?.roles;
  console.log('rol', rol);
  const [openModal2, setOpenModal2] = useState(false);
  const finalColumns = gestionForecastColumns(setOpen, setSeleItem, setOpenModal, rol, setOpenModal2);
  const [checkedHabitaciones, setCheckedHabitaciones] = useState(registros);
  const [count, setCount] = useState(0);

  const [vicep, setVicep] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedVicepresidencias, setSelectedVicepresidencias] = useState('');
  const [selectedGerencia, setSelectedGerencia] = useState('');
  const [selectedContrato, setSelectedContrato] = useState('');
  const axiosInstance = createAxiosInstance();
  const [filterRegistros, setFilterRegistros] = useState([]);
  const [filterContratos, setFilterContratos] = useState([]);


  const handleRowSelectionModelChange = (selectionModel) => {
      console.log(selectionModel);
      
      const selectedRows = selectionModel.map((id) => {
        return registros.find((row) => row.id === id);
      });
      
      setCheckedHabitaciones(selectedRows.map((row) => ({ ...row })));
      // setSelectModel(selectionModel)
    };
    useEffect(() => {
      if(typeof setTramos == 'function'){
        setTramos(checkedHabitaciones);
      }
    }, [checkedHabitaciones])
    
    useEffect(() => {
      if (tramos.length != 0 && count === 0) {
          setCheckedHabitaciones(tramos);
          setCount(prevCount => prevCount + 1);
      }
  }, [tramos, count]);
  
  useEffect(() => {
    console.log('registros', registros);
    const sortedRegistros = [...registros].sort((a, b) => {
      if (a.estado === 'Rechazado' && b.estado !== 'Rechazado') {
        return -1;
      }
      if (a.estado !== 'Rechazado' && b.estado === 'Rechazado') {
        return 1;
      }
      return 0;
    });
    setFilterRegistros(sortedRegistros);
  }, [registros]);
  
  useEffect(() => {
    if (Array.isArray(ger)) {
      const vp = ger.filter(item => typeof item === 'string' && item.startsWith('VP'));
      setVicep(vp);
    }
  }, [ger]);
  
  
  const handleChangeFilter = async (name, newValue) => {
    if (newValue != null) {
      if (name === 'empresa') {
        setSelectedEmpresa(newValue);
        try {
          setOpen3(true);
          const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+newValue.idEmpresa);
          setFilterContratos(resp.data);
          setSelectedContrato('')
        } catch (error) {
          console.log(error);
        } finally {
          setOpen3(false);
        }
      } else if (name === 'contrato') {
        setSelectedContrato(newValue);
      } else if (name === 'vicepresidencia') {
        setSelectedVicepresidencias(newValue);
      } else if (name === 'gerencia') {
        setSelectedGerencia(newValue);
      } else if (name === 'siArea') {
        setSelectedArea(newValue);
      }
    } else {
      if (name === 'empresa') {
        setSelectedEmpresa('');
        setFilterContratos([]);
        // setSelectedContrato(''); 
      } else if (name === 'contrato') {
        setSelectedContrato('');
      } else if (name === 'vicepresidencia') {
        setSelectedVicepresidencias('');
      } else if (name === 'gerencia') {
        setSelectedGerencia('');
      } else if (name === 'siArea') {
        setSelectedArea('');
      }
    }
    filterData(
      name === 'empresa' ? newValue?.razonSocial?.toLowerCase() : selectedEmpresa?.razonSocial?.toLowerCase(),
      name === 'contrato' ? newValue : selectedContrato,
      name === 'vicepresidencia' ? newValue : selectedVicepresidencias,
      name === 'gerencia' ? newValue : selectedGerencia,
      name === 'siArea' ? newValue : selectedArea
    );
  }

  const filterData = (empresa, contrato, vicepresidencia, gerencia, siArea) => {
    const filteredRegistros = registros.filter(reg => {
      const empresaMatch = empresa ? reg.empresa.razonSocial.toLowerCase().includes(empresa) : true;
      const contratoMatch = contrato ? reg.tramos.some(item => item.contrato.toLowerCase().includes(contrato.toLowerCase())) : true;
      const vicepresidenciaMatch = vicepresidencia ? reg.tramos.some(item => item.vicepresidencia.toLowerCase().includes(vicepresidencia.toLowerCase())) : true;
      const gerenciaMatch = gerencia ? reg.tramos.some(item => item.gerencia.toLowerCase().includes(gerencia.toLowerCase())) : true;
      const siAreaMatch = siArea ? reg.tramos.some(item => item.siArea.toLowerCase().includes(siArea.toLowerCase())) : true;

      return empresaMatch && contratoMatch && vicepresidenciaMatch && gerenciaMatch && siAreaMatch;
    });

    setFilterRegistros(filteredRegistros);
  }
  
  return (
    <div style={{  marginTop:'16px' }}>
      <Aprobar seleItem={seleItem} openModal={openModal2} setOpenModal={setOpenModal2}/>

      <Edit seleItem={seleItem} setSeleItem={setSeleItem} openModal={openModal} setOpenModal={setOpenModal} onChange2={onChange2} registros={registros} selectedTramosForecast={selectedTramosForecast} 
      // handleChangeTab={handleChange}
      turnos={turnos}
       handleEliminarTramo={handleEliminarTramo} handleAgregarTramo={handleAgregarTramo} setSelectedTramosForecast={setSelectedTramosForecast} open3={open3} value={value} setValue={setValue} ger={ger} siArea={siArea} empresas={empresas}/>
      
      <Accordion sx={{ backgroundColor:'transparent', width:'100%', color:'#FFF', borderRadius:'2px'}}>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon sx={{ color:'#FFF' }}/>}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ backgroundColor:'var(--textfield-bg)', width:'100%'}}
      >
        <Typography variant='span' style={{ fontSize:'16px', fontWeight:'500' }}>Haz click aquí y utiliza los filtros para desplegar información especifica en la tabla</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ backgroundColor:'var(--textfield-bg)' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <label className={styles.label}>Empresas</label>
            <Autocomplete
              className={styles.textField2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#FFF',
                },
                '& .MuiInputLabel-root': {
                  color: '#FFF',
                },
                '& .MuiAutocomplete-clearIndicator': {
                  color: '#FFF',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  color: '#FFF',
                },
              }}
              disablePortal
              id="empresa-autocomplete"
              options={empresas}
              name="empresa"
              value={selectedEmpresa || null}
              getOptionLabel={(option) => option.razonSocial}
              onChange={(e, value) => handleChangeFilter('empresa', value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <label className={styles.label}>Contratos</label>
            <Autocomplete
              className={styles.textField2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#FFF',
                },
                '& .MuiInputLabel-root': {
                  color: '#FFF',
                },
                '& .MuiAutocomplete-clearIndicator': {
                  color: '#FFF',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  color: '#FFF',
                },
              }}
              disablePortal
              id="contrato-autocomplete"
              options={filterContratos}
              name="contrato"
              value={selectedContrato || null}
              getOptionLabel={(option) => option}
              onChange={(e, value) => handleChangeFilter('contrato', value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <label className={styles.label}>Vicepresidencias</label>
            <Autocomplete
              className={styles.textField2}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color: '#FFF',
                            },
                          }}
              disablePortal
              id="vicepresidencia-autocomplete"
              options={vicep}
              name="vicepresidencia"
              value={selectedVicepresidencias || null}
              getOptionLabel={(option) => option}
              onChange={(e, value) => handleChangeFilter('vicepresidencia', value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <label className={styles.label}>Gerencias</label>
            <Autocomplete
                className={styles.textField2}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color: '#FFF',
                            },
                          }}
              disablePortal
              id="gerencia-autocomplete"
              options={ger}
              name="gerencia"
              value={selectedGerencia || null}
              getOptionLabel={(option) => option}
              onChange={(e, value) => handleChangeFilter('gerencia', value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <label className={styles.label}>siAreas</label>
            <Autocomplete
              className={styles.textField2}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color: '#FFF',
                            },
                          }}
              disablePortal
              id="siArea-autocomplete"
              options={siArea}
              name="siArea"
              value={selectedArea || null}
              getOptionLabel={(option) => option}
              onChange={(e, value) => handleChangeFilter('siArea', value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
        </AccordionDetails>
        </Accordion>

        <DataGrid
            getRowId={row=>row.id }
            lots={{ toolbar: GridToolbar }}
            checkboxSelection
            rowSelectionModel={checkedHabitaciones.map(item => item.id)}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rows={filterRegistros}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            disableSelectionOnClick={true}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#2d3037',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#2d3037',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#2d3037',
              },
              '& .MuiDataGrid-menu': {
                color: '#2d3037',
              },
              '& .MuiSvgIcon-root':{
                color: '#9ca91c',
              },
              '& .MuiTablePagination-actions': {
                color: '#2d3037', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#2d3037', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#2d3037', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#2d3037', // Color for displayed rows text
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                color: 'red',
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                color: 'red',
              },
            }}
            slotProps={{
                  toolbar:{
                    sx:{
                      color:'#FFF'
                    }
                  },
                  pagination: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
                  menu: {
                    sx: {
                      color: '#f19d38',
                    },
                  },
            }}
        />
    </div>
  )
}

export default Table