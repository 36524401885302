import { Fragment, useState } from 'react';
import styles from './components.module.css';
import Button from '@mui/material/Button';
import { Autocomplete, Backdrop, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, Popper, TextField, Typography } from '@mui/material';
import { addNewHuesped, getAllCargos, getInfoByRut, liveSearchByRut } from '../../../../redux/features/users/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import GroupIcon from '@mui/icons-material/Group';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  nombre: '',
  apellido: '',
  email: '',
  rut: '',
  rol: [],
  cargoId: '',
  empresa: null,
  genero: '',
  telefono: '',
  fechaNacimiento: '',
}
const Create = ({ roles, empresas, open2, setOpen2 }) => {

  const dispatch = useDispatch();
  const cargos = useSelector(getAllCargos)
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [ruts, setRuts] = useState([])
  const [rolesList, setRolesList] = useState([])

  const canSave = [formData.nombre, formData.apellido, formData.rut, formData.email, formData.fechaNacimiento].every(Boolean) && addRequestStatus === 'idle';
  
  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  
  const handleSelectAll = () => {
    setFormData({ ...formData, empresa: empresas });
  };

  const handleChangeEmpresa = (event, newValue) => {
    if (newValue) {
      setFormData({...formData, empresa: newValue});
      const rutEmpresas = newValue.map(item => item.idEmpresa);
    } else {
      setFormData({...formData, empresa: null});
    }
  };

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value))
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }
  const handleDeleteEmpresa = (empresaToDelete) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      empresa: prevFormData.empresa.filter((empresa) => empresa.idEmpresa !== empresaToDelete.idEmpresa),
    }));
  };

  
  const onChangeRutSelect = async (e,newValue) => {
    if(newValue != null){
      setFormData({...formData, rut: newValue.rut})
      try {
        setOpen2(true);
        const resp = await dispatch(getInfoByRut(newValue.rut));
        const info = resp.payload.data;
        setFormData({...formData, nombre: info.nombre, email: info.email == null ? '': info.email, apellido: info.apellido, telefono: info.telefono == null ? '' : info.telefono, rut: newValue.rut, cargoId: info.idCargo == null ? '': info.idCargo});
        
      } catch (error) {
        
      } finally{
        setOpen2(false);
      }
    } else{
      setFormData({...formData, rut: null})
    }
  }
  const onChangeCargo = async (e,newValue) => {
    if(newValue != null){
      console.log(newValue)
      setFormData({...formData, cargoId: newValue.idCargo})
    } else{
      setFormData({...formData, cargoId: null})
    }
  }

  const handleChangeRole = (event) => {
    const { value } = event.target;
    setRolesList(value);
    setFormData((prevFormData) => ({...prevFormData, rol: value}));
  };
  

  const handleSubmit = async () => {
    console.log(formData)
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        const resp = await dispatch(addNewHuesped(formData));
        console.log('resp',resp)
        if(resp.payload.status == 'success'){
          setFormData(data);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error('Error al crear el huesped', error);
      } finally{
        setAddRequestStatus('idle');
        setOpen2(false);
      }
    }
  }

  return (
    <div>
        <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder registrar un huésped.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>Crear huésped</Button>
        </div>
        <Dialog
          open={open}
          maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
          onBackdropClick={() => {
            setOpen(false);
          }}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <GroupIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Crear usario</Typography>
            <span className={styles.subTitle}>Ingresa un rut para buscar trabajador.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Rut</label>
                <Autocomplete
                    disablePortal
                    disableClearable
                    className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    id="sectores-autocomplete"
                    name="rut"
                    value={formData}
                    options={ruts ? ruts : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: '200px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.rut + ' ' + option.nombre}
                    onChange={(e, value) => onChangeRutSelect(e, value)}
                    renderInput={(params) => <TextField {...params} label="Selecciona un rut" onChange={(e) => onChangeRut(e)}/>}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Nombre</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  name='nombre'
                  value={formData.nombre}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Apellidos</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='apellido'
                  value={formData.apellido}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Correo</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='email'
                  type="text"
                  value={formData.email}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Fecha de nacimiento</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='fechaNacimiento'
                  type="date"
                  value={formData.fechaNacimiento}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Cargo</label>
                <Autocomplete
                    disablePortal
                    disableClearable
                    className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    id="sectores-autocomplete"
                    // disabled
                    name="cargo"
                    value={cargos?.find(option => option.idCargo === formData?.cargoId) || null}
                    options={cargos ? cargos : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: '200px',
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nCargo}
                    // onChange={(e, value) => onChangeCargo(e, value)}
                    renderInput={(params) => <TextField {...params} label="Cargo no modificable"/>}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Teléfono</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='telefono'
                  value={formData.telefono}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              {/*<Grid item  xs={12} sm={6}>
                <label className={styles.label}>Género</label>
                <Select
                  style={{ width:'100%' }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Seleccione un género</em>;
                    }
                    return selected;
                  }}
                  value={formData.genero}
                  name='genero'
                  onChange={(e) => onChange(e)}
                >
                {generos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              </Grid> */}
              {/*
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Rol</label>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label">Selecciona 1 o más roles</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    disabled
                    value={rolesList}
                    onChange={handleChangeRole}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox checked={rolesList.indexOf(role) > -1} />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              */}
              
              { (formData.rol.includes('Administrador de Contrato CMDIC (ACC)') || formData.rol.includes('Administrador de Contrato ESED (ACE)')) && 
                <>
                  <Grid item  xs={12} sm={12}>
                    <FormControl sx={{ width: '100%' }}> 
                        <Autocomplete
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiSvgIcon-root ': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiChip-label': {
                            color: '#FFF',
                          },
                         
                        }}
                          disablePortal
                          disableClearable
                          id="sectores-autocomplete-empresa"
                          name="empresa"
                          multiple
                          value={formData?.empresa ? formData.empresa : []}
                          options={empresas ? empresas : [] }
                          PopperComponent={(props) => (
                            <Popper
                              {...props}
                              sx={{
                                maxHeight: '200px', 
                                overflowY: 'auto',
                              }}
                            />
                          )}
                          getOptionLabel={(option) => option.razonSocial}
                          onChange={(e, value) => handleChangeEmpresa(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona una empresa"
                              // onChange={(e) => onChangeEmpresa(e)}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Fragment>
                                    {(formData?.empresa || []).map((option) => (
                                      <Chip
                                        key={option.idEmpresa}
                                        label={option.razonSocial}
                                        onDelete={() => handleDeleteEmpresa(option)}
                                        color="primary"
                                        variant="outlined"
                                      />
                                    ))}
                                  </Fragment>
                                ),
                                endAdornment: (
                                  <Fragment>
                                    <Button onClick={handleSelectAll} variant="outlined">
                                      Seleccionar todas
                                    </Button>
                                  </Fragment>
                                ),
                              }}
                            />
                          )}
                        />

                    
                  
                  
                    </FormControl>
                </Grid>

                <Grid item  xs={12} sm={12}>
                  <span className={styles.span}>El usuario será registrado como administrador de contratos.</span>
                </Grid> 
              
                </>
              }
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}
                  >
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
           
           
            </Grid>
          </DialogContent>
        
      </Dialog>
      
    </div>
  )
}

export default Create