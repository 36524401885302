import styles from './components.module.css';
import { useState } from "react";
import { reclamosSugerenciasHuespedColumns } from "../../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card, Typography } from '@mui/material';
import SkeletonTable from '../../../components/Skeleton'

const HistorialReclamos = ({reclamosSugerencias}) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reclamosSugerenciasHuespedColumns(setOpen, setSeleItem);
  const addRequestStatus = 'idle'

  return (
    <div style={{ width:'100%', marginTop:'10px' }}>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        : <Card style={{ padding:'16px', backgroundColor:'var(--inst-card-bg)', color:'#FFF' }}>
              <Typography style={{ color: '#FFF', border:'none', margin:'10px 0' }}><strong>Tus últimos reclamos y sugerencias registradas.</strong></Typography>
              { reclamosSugerencias.length == 0 ? <span style={{ fontSize:'14px', color:"#FFF" }}>No cuentas con reclamos o sugerencias ingresadas.</span> :
              <DataGrid
                getRowId={row=>row.id ? row.id : Math.random() }
                lots={{ toolbar: GridToolbar }}
                rowHeight={100}
                rows={reclamosSugerencias}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay datos disponibles",
                pagination: {
                    labelRowsPerPage: "Filas por página:",
                }
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#FFF',
              },
              '& .MuiDataGrid-menu': {
                color: '#FFF',
              },
              '& .MuiTablePagination-actions': {
                color: '#FFF', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#FFF', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#FFF', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#FFF', // Color for displayed rows text
              },
             }}
            slotProps={{
              toolbar:{
                sx:{
                  color:'#FFF'
                }
              },
              pagination: {
                sx: {
                  color: '#FFF',
                },
              },
              menu: {
                sx: {
                  color: '#FFF',
                },
              },
            }}
            />}
        </Card>
     }
    </div>
  )
}

export default HistorialReclamos