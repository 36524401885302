import { Backdrop, Box, Card, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { fetchOficinas, getAllOficinas, getOficinasStatus } from "../../../redux/features/oficinas/oficinaSlice";

const GestionOficina = () => {

  const dispatch = useDispatch();
  const oficinas = useSelector(getAllOficinas);
  const status = useSelector(getOficinasStatus);
  const [count, setCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [check, setCheck] = useState(0);

  useEffect(() => {
    dispatch(fetchOficinas());
  }, []);


  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestión de oficinas"
          subtitle="En este módulo, aquí podrás visualizar, asignar, liberar e inhabilitar oficinas registradas en la plataforma.">
          {/* {<BookmarkAddIcon style={{ fontSize:'1.5rem' }}/>} */}
          <img src={process.env.REACT_APP_LOGO} alt="loginImg" style={{ width:'100px', marginRight: '10px' }} />
        </Header>

        <TableCard>
          {/*<Create setOpen2={setOpen2} open2={open2} campamentos={campamentos} wings={wings} pabellones={pabellones} otros={otros}/> */}
          {/*campamentos={campamentos} wings={wings} pabellones={pabellones} otros={otros}  */}

          { status == 'loading' ? <SkeletonTable/> : <Table setOpen2={setOpen2} open2={open2} 
          registros={oficinas} 
          setOpen3={setOpen3} 
          setCheck={setCheck} check={check}/> }
        </TableCard>
        <Card mt={2} style={{ width:'100%', padding:'16px', backgroundColor: '#1d1d1b', color: '#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <span style={{ fontSize: '12px' }}>Powered by</span>
                  <img src={process.env.REACT_APP_LOGO_POWERED} alt="loginImg" style={{ width:'45px', marginLeft: '4px', filter: 'grayscale(100%)' }} />
                </div>
        </Card>
      </Container>
    </Box>
  )
}

export default GestionOficina