import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Popper, Select, TextField, Typography, DialogActions } from '@mui/material';

import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../../redux/features/campamentos/campamentoSlice';
import { useEffect } from 'react';
import { updateSector } from '../../../../redux/features/sectores/sectorSlice';
import { getAllWings } from '../../../../redux/features/wings/wingSlice';
import { getAllEdificios } from '../../../../redux/features/edificios/edificioSlice';
import { liveSearchByRut } from '../../../../redux/features/users/userSlice';
import { getAllPersonal, updatelanificacionEspecificaAseo } from '../../../../redux/features/aseo/aseoSlice';
import { addMonths, format, isBefore, parseISO } from 'date-fns';

const data = {
  nombre: '',
  descripcion: '',
  wing: [],
  pabellon: [],
  edificio: [],
  hora: '',
  piso: '',
  trabajadoresId: [],
}

export default function Edit({ open2, setOpen2, openEdit, setOpenEdit, seleItem, setOpen3, personal, otros }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(data);
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos);
    const wings = useSelector(getAllWings);
    const pabellones = useSelector(getAllEdificios);

    const [selectedCampamento, setSelectedCampamento] = useState(null);
    const [selectedWing, setSelectedWing] = useState(null);
    const [selectedPabellon, setSelectedPabellon] = useState(null);
    const [selectedOtro, setSelectedOtro] = useState([]);
    const [pabellonWing, setPabellonWing] = useState(0);
    const [ruts, setRuts] = useState([])
    const [selectedRuts, setSelectedRuts] = useState([])
    const [selectedDays, setSelectedDays] = useState([]);

    const [alarmaFecha, setAlarmaFecha] = useState('');
    const [alarmaHora, setAlarmaHora] = useState('');
    const [error, setError] = useState('');
    
    const handleAlarmaFechaChange = (event) => {
      setAlarmaFecha(event.target.value);
      setFormData({...formData, fechaAlarma: event.target.value });
    };

    const handleAlarmaHoraChange = (event) => {
      setAlarmaHora(event.target.value);
      setFormData({...formData, horaAlarma: event.target.value });
    };

    const handleAlarm = (event) => {
      event.preventDefault();
  
      const formFechaHora = parseISO(`${formData.fecha}T${formData.hora}`);
      const alarmaFechaHora = parseISO(`${alarmaFecha}T${alarmaHora}`);
      const fechaLimite = addMonths(formFechaHora, -1);
  
      if (isBefore(alarmaFechaHora, formFechaHora) && !isBefore(alarmaFechaHora, fechaLimite)) {
        setError('');
        // Aquí puedes manejar el caso cuando la alarma es válida
        toast.success('Alarma válida:', alarmaFechaHora);
      } else {
        toast.error('La alarma debe ser hasta 1 mes antes de la fecha y hora proporcionadas.');
      }
    };
  

    useEffect(() => {
      if (seleItem) {
          setFormData(data);
          setSelectedCampamento([])
          setSelectedPabellon([])
          setSelectedWing([])
          setSelectedRuts([])
          setSelectedOtro([])
          setPabellonWing(0)
          setSelectedDays([])

        setFormData(prev => ({...prev, id: seleItem.id , nombre: seleItem.nombre, piso: seleItem.piso, descripcion: seleItem.descripcion, fecha: format(new Date(seleItem.fecha), 'yyyy-MM-dd'), hora: format(new Date(seleItem.fecha), 'HH:mm'), trabajadoresId: seleItem.trabajadores.map(item => item.rut), repetirPlanificacion: seleItem.repetirPlanificacion}));
        
        setSelectedRuts(seleItem.trabajadores);
        
        // setSelectedDays(seleItem?.dias);

          if (seleItem?.pabellon.length > 0) {
              setPabellonWing(1);
              setSelectedPabellon(seleItem?.pabellon);
              setFormData(prev => ({...prev, campamento: seleItem?.pabellon[0].campamento, pabellon: seleItem.pabellon.map(item => item.id)}));
              setSelectedCampamento(seleItem?.pabellon[0].campamento);
          } else if(seleItem?.wing.length > 0) {
              setPabellonWing(2);
              setSelectedWing(seleItem?.wing);
              setSelectedCampamento(seleItem?.wing[0].campamento);
              setFormData(prev => ({...prev, campamento: seleItem?.wing[0].campamento, wing: seleItem.wing.map(item => item.id)}));
          } else if(seleItem?.edificio.length > 0){
            setPabellonWing(3);
            setSelectedOtro(seleItem?.edificio);
            setSelectedCampamento(seleItem?.edificio[0].campamento);
            setFormData(prev => ({...prev, campamento: seleItem?.wing[0].campamento, otro: seleItem.edificio.map(item => item.id)}));
          }
          console.log('seleItem:', seleItem);
      }
  }, [seleItem]);

  useEffect(() => {
    console.log('formData:', formData);
  }, [formData])
  
  

    const handleChangePW = (event) => {
        setPabellonWing(event.target.value);
        setFormData({...formData, pabellon: [], wing: [], otro: [] })
        setSelectedWing(null);
        setSelectedPabellon(null);
      };
    
      const handleChangeCampamento = (event, newValue) => {
        setSelectedCampamento(newValue);
        if(newValue != null){
          setFormData({...formData, campamento: newValue.id, });
          setSelectedWing(null);
          setSelectedPabellon(null);
        } else{
          setFormData({...formData, campamento: 0})
        }
      };
      const handleChangePabellon = (event, newValue) => {
        if(newValue != null){
          setSelectedWing([]);
          setFormData({...formData, pabellon: newValue.map(item => item.id), wing: [], otro: [] })
          setSelectedPabellon(newValue);
        } else{
          setFormData({...formData, pabellon: 0})
        }
      };
      const handleChangeWing = (event, newValue) => {
        if(newValue != null){
          setSelectedPabellon([]);
          setFormData({...formData, wing:  newValue.map(wing => wing.id), pabellon: [], otro: [] })
          setSelectedWing(newValue);
        } else{
          setFormData({...formData, wing: 0})
        }
      };
    
      const handleChangeOtro = (event, newValue) => {
        if(newValue != null){
          setSelectedPabellon([]);
          setSelectedWing([]);
          setFormData({...formData, wing: [], pabellon: [], otro: newValue.map(wing => wing.id) })
          setSelectedOtro(newValue);
        } else{
          setFormData({...formData, wing: 0})
        }
      };
    
      const onChangeRut = async (e) => {
        try {
          setOpen2(true);
          const resp = await dispatch(liveSearchByRut(e.target.value))
          setRuts(resp.payload.data);
        } catch (error) {
          console.log(error)
        } finally{
          setOpen2(false)
        }
      }
      const onChangeRutSelect = async (e,newValue) => {
        
        if(newValue != null){
          console.log('selected rut: ', newValue);
          setFormData({...formData, trabajadoresId: newValue.map(item => item.rut)})
          // setSelectedRuts({...selectedRuts, newValue});
          setSelectedRuts(newValue);
    
        } else{
          setFormData({...formData, trabajadoresId: null})
        }
      }
      
      const onChange = (e) => {
          setSelectedDays([]);
          setFormData({...formData, [e.target.name]: e.target.value });
      }
    
        const handleDayChange = (event) => {
          const { value, checked } = event.target;
          if (checked) {
            setSelectedDays((prevSelected) => [...prevSelected, value]);
          } else {
            setSelectedDays((prevSelected) => prevSelected.filter((day) => day !== value));
          }
        };

        useEffect(() => {
          setFormData(prev => ({...prev, days: selectedDays}))
        }, [selectedDays])

      const canSave = [
        formData.nombre,
        (formData.wing.length > 0 || formData.pabellon.length > 0 || formData.edificio.length > 0),
        formData.fecha,
        formData.trabajadoresId.length > 0
      ].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updatelanificacionEspecificaAseo(formData))
            if(resp.payload.status === 'success'){
                setOpen3(false);
            }
        } catch (error) {
            console.error('Error al actualizar la planificación especifca.', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpenEdit(false);
            setOpen3(false);
        }
    }

  return (
    <div>
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={
          {
            sx: {
              width: { xs: '100%', sm: '75%', md: '50%' },
              backgroundColor:'#2d3037',
              color:'#FFF'
            }
          }
        }
      >
        {seleItem && (
            <>
                <DialogTitle>
                  <Grid container={true} spacing={1} p={2}>
                      <Grid item  xs={12} sm={12} mb={2} style={{ display:'flex', gap:'5px'}}>
                          <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                          <div style={{ display:'flex', flexDirection:'column' }}>
                              <Typography variant="h6" className={styles.textIconEdit}>Actualizar planificación {seleItem?.nombre}</Typography>
                              <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar la planificación especifica.</Typography>
                          </div>
                      </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container={true} spacing={1} p={2}>
                      <Grid item  xs={12} sm={4}>
                        <label className={styles.label}>Nombre</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          variant="outlined"
                          fullWidth
                          name='nombre'
                          value={formData?.nombre || null}
                          type="text"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={4}>
                        <label className={styles.label}>Fecha</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          variant="outlined"
                          fullWidth
                          name='fecha'
                          value={formData?.fecha || null}
                          type="date"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={4}>
                        <label className={styles.label}>Hora</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          variant="outlined"
                          fullWidth
                          name='hora'
                          value={formData?.hora || null}
                          type="time"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>

                      <Grid item  xs={12} sm={4}>
                        <label className={styles.label}>Campamento</label>
                        <Autocomplete
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          disablePortal
                          id="campamentos-autocomplete"
                          name="campamento"
                          value={formData?.campamento || null}
                          options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                          getOptionLabel={(option) => option.nombre}
                          onChange={(e, value)=> handleChangeCampamento(e, value)}
                          renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                        /> 
                      </Grid>
                      {/* { selectedCampamento &&  */}
                      
                        {/*<Grid item  xs={12} sm={12}>
                          <Typography><small><strong>Busca y selecciona un pabellón o un wing.</strong></small></Typography>
                        </Grid> */}

                        <Grid item  xs={12} sm={4}>
                          <label className={styles.label}>Pabellón, Wing o Recinto</label>
                          <Select
                            className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: '#FFF',
                              },
                              '& .MuiInputLabel-root': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color: '#FFF',
                              },
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pabellonWing}
                            label="Age"
                            onChange={handleChangePW}
                            style={{ width:'100%' }}
                          >
                            <MenuItem value={1}>Pabellón</MenuItem>
                            <MenuItem value={2}>Wing</MenuItem>
                            <MenuItem value={3}>Recinto</MenuItem>
                          </Select>
                        </Grid>
                      {/* {selectedSector && ( */}
                      {pabellonWing == 1 && ( 
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>Pabellón</label>
                            <Autocomplete
                              className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '.MuiChip-label':{
                                  color: 'white',
                                },
                                '.MuiSvgIcon-root':{
                                  color: '#FFF !important',
                                },
                                '& .MuiInputBase-input': {
                                  color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color: '#FFF',
                                },
                              }}
                              disablePortal
                              disableClearable
                              multiple
                              id="sectores-autocomplete"
                              name="wing"
                              value={selectedPabellon}
                              options={Array.isArray(pabellones) ? pabellones : [] }
                              getOptionLabel={(option) => option.identificador}
                              onChange={(e, value) => handleChangePabellon(e, value)}    
                              renderInput={(params) => <TextField
                              className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color: '#FFF',
                                },
                              }} 
                              {...params} label="Selecciona un pabellón" />}
                            />

                        </Grid>
                      )} 
                      {/* {selectedCampamento && ( */}
                      {pabellonWing == 2 && ( 
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>Wing</label>
                            <Autocomplete
                              className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '.MuiChip-label':{
                                  color: 'white',
                                },
                                '.MuiSvgIcon-root':{
                                  color: '#FFF !important',
                                },
                                '& .MuiInputBase-input': {
                                  color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color: '#FFF',
                                },
                              }}
                              disablePortal
                              disableClearable
                              multiple
                              id="sectores-autocomplete"
                              name="wing"
                              value={selectedWing}
                              options={wings ?  wings : [] }
                              getOptionLabel={(option) => option.nombre}
                              onChange={(e, value) => handleChangeWing(e, value)}    
                              renderInput={(params) => <TextField
                              className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color: '#FFF',
                                },
                              }} {...params} label="Selecciona un wing" />}
                          />
                        </Grid>
                      )} 
                      {pabellonWing == 3 && ( 
                        <Grid item  xs={12} sm={10}>
                            <label className={styles.label}>Recinto</label>
                            <Autocomplete
                              className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '.MuiChip-label':{
                                  color: 'white',
                                },
                                '.MuiSvgIcon-root':{
                                  color: '#FFF !important',
                                },
                                '& .MuiInputBase-input': {
                                  color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color: '#FFF',
                                },
                              }}
                              disablePortal
                              multiple
                              disableClearable
                              id="sectores-autocomplete"
                              name="wing"
                              value={selectedOtro}
                              options={otros ?  otros : [] }
                              getOptionLabel={(option) => option.nombre}
                              onChange={handleChangeOtro}    
                              renderInput={(params) => <TextField
                               className={styles.textField}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                  color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                  color: '#FFF',
                                },
                              }} {...params} label="Selecciona un recinto" />}
                          />
                        </Grid>
                      )} 
                      <Grid item  xs={12} sm={4}>
                        <label className={styles.label}>Piso</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name='piso'
                        value={formData?.piso || null}
                        type="number"
                        onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={8}>
                        <label className={styles.label}>Asignar trabajadores (opcional)</label>
                          <Autocomplete
                            className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '.MuiChip-label':{
                                color: 'white',
                              },
                              '.MuiSvgIcon-root':{
                                color: '#FFF !important',
                              },
                              '& .MuiInputBase-input': {
                                color: '#FFF',
                              },
                              '& .MuiInputLabel-root': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color: '#FFF',
                              },
                            }}
                            disablePortal
                            disableClearable
                            id="sectores-autocomplete"
                            name="rut"
                            value={selectedRuts || []}
                            options={personal ?? [] }
                            multiple
                            getOptionLabel={(option) => option.rut + ' ' + option.nombre + ' ' + option.apellido}
                            onChange={(e, value) => onChangeRutSelect(e, value)}
                            renderInput={(params) => <TextField
                              className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: '#FFF',
                              },
                              '& .MuiInputLabel-root': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color: '#FFF',
                              },
                            }} {...params} label="Selecciona un rut"/>}
                          />
                      </Grid>
                      <Grid item  xs={12} sm={12}>
                        <label className={styles.label}>Descripción (opcional)</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          variant="outlined"
                          fullWidth
                          name='descripcion'
                          type="text"
                          value={formData?.descripcion || null}
                          multiline
                          rows={2}
                          maxRows={4}
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item  xs={12} sm={12}>
                        <label className={styles.label}>Repetir planificación</label>
                        <Select
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData?.repetirPlanificacion || ''}
                          label="Age"
                          name='repetirPlanificacion'
                          style={{ width:'100%' }}
                          onChange={(e) => onChange(e)}
                          
                        >
                          <MenuItem value="diariamente">Diariamente</MenuItem>
                          <MenuItem value="Ciertos dias de la semana">Ciertos días de la semana</MenuItem>
                          <MenuItem value="semanalmente">Semanalmente</MenuItem>
                          <MenuItem value="mensualmente">Mensualmente</MenuItem>
                          <MenuItem value="semestralmente">Semestralmente</MenuItem>
                          <MenuItem value="anualmente">Anualmente</MenuItem>
                        </Select>
                      </Grid>
                      {formData?.frecuencia === 'Ciertos dias de la semana' && (
                        <Grid item xs={12} sm={12}>
                          {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((day) => (
                            <FormControlLabel
                              key={day}
                              control={
                                <Checkbox
                                  checked={selectedDays.includes(day)}
                                  onChange={handleDayChange}
                                  value={day}
                                />
                              }
                              label={day}
                            />
                          ))}
                          </Grid>
                        )}
                        {/*<Grid item  xs={12} sm={12}>
                          <label className={styles.label}>Alarma (Opcional)</label>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <TextField
                            className={styles.textField}
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                    '& .MuiAutocomplete-clearIndicator': {
                                      color: '#FFF',
                                    },
                                    '& .MuiAutocomplete-popupIndicator': {
                                      color: '#FFF',
                                    },
                                  }}
                            label="Fecha de Alarma"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={alarmaFecha}
                            disabled={!formData?.fecha || !formData?.hora}
                            onChange={handleAlarmaFechaChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <TextField
                            className={styles.textField}
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                    '& .MuiAutocomplete-clearIndicator': {
                                      color: '#FFF',
                                    },
                                    '& .MuiAutocomplete-popupIndicator': {
                                      color: '#FFF',
                                    },
                                  }}
                            label="Hora de Alarma"
                            type="time"
                            InputLabelProps={{ shrink: true }}
                            value={alarmaHora}
                            disabled={!formData?.fecha || !formData?.hora}
                            onChange={handleAlarmaHoraChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Button variant="contained" className={ styles.bgColorBlue } onClick={handleAlarm}>
                            Establecer Alarma
                          </Button>
                        </Grid> */}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid container={true}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColorEdit}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                      </Grid>
                  </DialogActions>
            </>
        )}
      </Drawer>
    </div>
  );
}