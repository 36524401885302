import styles from './components.module.css'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { addNewBodega } from '../../../redux/features/bodegas/bodegaSlice';
import Map from './Map';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  nombre: '',
  campamentoId: '',
  latitud: '',
  longitud: '',
  descripcion: '',
  imagen: '',
}
let center = {
  lat: 0,
  lng: 0,
}

const Create = ({ open2, setOpen2, campamentos }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [position, setPosition] = useState(center)

  const onChange = (e) => {
    if (e.target.type === 'file') {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0],
        });
    } else {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }
};


 useEffect(() => {
      if(position){
        setFormData({...formData, latitud: position?.lat, longitud: position?.lng})
      }
  }, [position])

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue)
    setFormData({...formData, campamentoId: newValue.id});
  };

  const canSave = [ formData.nombre, formData.campamentoId, formData.longitud, formData.latitud ].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewBodega(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
          setPosition(center);
          setSelectedCampamento(null);
        }
      } catch (error) {
        console.error('Error al crear la bodega', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para registrar la ubcación.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>  Ingresar registro</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <WarehouseIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px',lineHeight:'1'}}>Ingreso de bodega</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para registrar la ubicación de la bodega.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos || []}
                value={selectedCampamento}
                name="bodega"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />                    
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Nombre</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='nombre'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Descripción</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='descripcion'
                type="text"
                multiline
                rows={2}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Map position={position} setPosition={setPosition} setFormData={setFormData} formData={formData}/>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Longitud</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                value={position?.lat}
                fullWidth
                name='longitud'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Latitud</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                value={position?.lng}
                fullWidth
                name='latitud'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Imagen (Opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='imagen'
                type="file"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Ingresar'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create