import { Backdrop, Box, Container, Grid } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Calendario from "./components/CalendarPlanificacionDiaria";
import { fetchGruposAseo, fetchHorariosAseo, fetchPersonalAseo, fetchPlanificacionDiaria, fetchTareasAseo, getAllGruposAseos, getAllHorariosAseos, getAllPersonal, getAllPlanificacionesDiarias, getAllTareas, getGruposAseoStatus, getHorariosAseoStatus, getPersonalStatus, getPlanificacionesDiariasStatus, getTareasStatus } from "../../../redux/features/aseo/aseoSlice";
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from "../../../redux/features/habitaciones/habitacionSlice";

const Planificacion = () => {

  const dispatch = useDispatch();
  const planificaciones = useSelector(getAllPlanificacionesDiarias);
  const status = useSelector(getPlanificacionesDiariasStatus);
  const grupos = useSelector(getAllGruposAseos);
  const gruposStatus = useSelector(getGruposAseoStatus);
  const franjas = useSelector(getAllHorariosAseos);
  const franjasStatus = useSelector(getHorariosAseoStatus);
  const personal = useSelector(getAllPersonal);
  const personalStatus = useSelector(getPersonalStatus);
  const habitaciones = useSelector(getAllHabitaciones);
  const habStatus = useSelector(getHabitacionesStatus);
  const tareas = useSelector(getAllTareas);
  const tarStatus = useSelector(getTareasStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if(status == 'idle'){
    }
    dispatch(fetchPlanificacionDiaria());
    if(gruposStatus == 'idle'){
      dispatch(fetchGruposAseo());
    }
    if(franjasStatus == 'idle'){
      dispatch(fetchHorariosAseo());
    }
    if(personalStatus == 'idle'){
      dispatch(fetchPersonalAseo());
    }
    if(tarStatus == 'idle'){
      dispatch(fetchTareasAseo());
    }
    if(habStatus == 'idle'){
      dispatch(fetchHabitaciones());
    }
      
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Planificación diaria"
          subtitle="En este módulo, podrás asignar personal a los horarios creados en la vista de 'Horarios de aseo'.">
          {<MenuBookIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} grupos={grupos} setOpen3={setOpen3} franjas={franjas} tareas={tareas} personal={personal}/>
          
        { status == 'loading' ? <SkeletonTable/> : <Table registros={planificaciones} setOpen3={setOpen3} open3={open3} grupos={grupos} franjas={franjas} habitaciones={habitaciones} selectedItems={selectedItems} setSelectedItems={setSelectedItems} tareas={tareas} personal={personal}/> }
        
        
              <Calendario events={selectedItems}/>
          
        </TableCard>
      </Container>
    </Box>
  )
}

export default Planificacion