import styles from '../components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, Card, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Select, TextField, Typography, capitalize } from '@mui/material';
import { useState } from 'react';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { useDispatch, useSelector } from 'react-redux';
import { reservasTrabajadadoresRecepcionistaColumns } from "../../../../../lib/columns";

import { useEffect } from 'react';
import {  esES, DataGrid } from '@mui/x-data-grid';
import { cancelarSolicitud, rechazarSolicitud } from '../../../../../redux/features/reservas/reservaSlice';
import { formatDate } from 'date-fns';
import { createAxiosInstance } from '../../../../../api/axios';
import CustomToolbar from '../../../../../components/SlotsToolBar';
import SkeletonTable from '../../../../../components/Skeleton';

export default function Show({ open, setOpen, seleItem }) {

    const dispatch = useDispatch();
    const [addRequestStatus, setAddRequestStatus] = useState('idle');    
    const [open2, setOpen2] = useState(false);
    const [formData, setFormData] = useState({})
    const [pageSize, setPageSize] = useState(10);
    const [statusLoading, setStatusLoading] = useState(false);
    const finalColumns = reservasTrabajadadoresRecepcionistaColumns();
    
    const canSave = (
         formData.razonRechazo &&
        formData.id &&
        addRequestStatus === 'idle'
      );
      
    const axiosInstance = createAxiosInstance();
    const [trabajadores, setTrabajadores] = useState([]);

    const callTrabajadores = async () => {
        try {
            setStatusLoading(true);
            const resp = await axiosInstance.get(`/api/v1/Solicitud/TrabajadoresSolicitud/${seleItem?.id}`);
            console.log('trabajadores',resp.data);
            if(resp.data && resp.data.length > 0){
              setTrabajadores(resp.data);
            }
        } catch (error) {
            console.log(error);
        }finally{
            setStatusLoading(false);
        }
    }

    useEffect(() => {
        if (seleItem) {
          setFormData({ ...formData, id: seleItem?.id });
          console.log(seleItem);
          callTrabajadores();
        }
      }, [seleItem]);
      
    
    useEffect(() => {
        if(formData?.estado == 'Aprobada'){
            setFormData({ ...formData, razonRechazo: '' })
            console.log(seleItem);
        }
    }, [formData?.estado])
    


    const rechazar = async () => {
        setAddRequestStatus('loading')
        try {
            const resp = await dispatch(rechazarSolicitud(formData));
            if(resp.payload.status === 'success'){
                setFormData({})
                return true;
            }
        } catch (error) {
            
        }finally{
            setOpen(false);
            setOpen2(false);
            setAddRequestStatus('idle')
        }
    }

    const cancelar = async () => {
        setAddRequestStatus('loading')
        try {
            const resp = await dispatch(cancelarSolicitud(formData));
            if(resp.payload.status === 'success'){
                setFormData({})
                return true;
            }
        } catch (error) {
            
        }finally{
            setOpen(false);
            setOpen2(false);
            setAddRequestStatus('idle')
        }
    }

  return (
      <Dialog
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        PaperProps={{
            sx: {
             backgroundColor:'#2d3037',
             color:'#FFF',
             borderRadius:'0'
            }
          }}
      >
        {seleItem && (
            
            <>
                <DialogTitle style={{ display:'flex', gap:'5px'}}>
                    <FeaturedPlayListIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
                    <div style={{ display:'flex', flexDirection:'column' }}>
                        <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Listado de trabajadores en reserva N°{seleItem?.id}</Typography>
                        <span className={styles.subTitle}>Información general de la reserva y listado de trabajadores asociados.</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                  <Grid container={true} spacing={2} mb={2}>
                    <Grid item xs={12} md={4}>
                    {statusLoading ? '' : <Card style={{ padding:'16px', backgroundColor:'var(--inst-card-bg)', border:'1px solid var(--border-orange)', color:'#FFF'}}>
                            <Typography className={styles.label}>
                                Empresa: <strong style={{ fontWeight:'500' }}>
                                {seleItem?.empresa?.razonSocial || 'N/A'}
                                </strong>
                            </Typography>
                            <Typography className={styles.label}>
                                Contacto: <strong style={{ fontWeight:'500' }}>
                                {seleItem?.empresa?.contacto || 'N/A'}
                                </strong>
                            </Typography>
                            <Typography className={styles.label}>
                                Correo: <strong style={{ fontWeight:'500' }}>
                                {seleItem?.empresa?.email || 'N/A'}
                                </strong>
                            </Typography>
                            <Typography className={styles.label}>
                                Fono: <strong style={{ fontWeight:'500' }}>
                                {seleItem?.empresa?.fono || 'N/A'}
                                </strong>
                            </Typography>
                  
                            <Typography className={styles.label}>Fecha Solicitud: <strong style={{ fontWeight:'500' }}>{formatDate(seleItem?.fechaSolicitud, 'dd-MM-yyyy')}</strong></Typography> 
                            <Typography className={styles.label}>Fecha Ingreso: <strong style={{ fontWeight:'500' }}>{formatDate(seleItem?.fechaIngreso, 'dd-MM-yyyy')}</strong></Typography> 
                            <Typography className={styles.label}>Fecha Salida: <strong style={{ fontWeight:'500' }}>{formatDate(seleItem?.fechaSalida, 'dd-MM-yyyy')}</strong></Typography>
                            
                            <Typography className={styles.label}>Estado: <strong style={{ fontWeight:'500' }}>{seleItem?.estado}</strong></Typography>

                            {seleItem?.aprobaoPor ? <Typography className={styles.label}>
                                Aprobado por:{' '}<strong style={{ fontWeight:'500' }}> {seleItem?.aprobaoPor.nombre } {' '} {seleItem?.aprobaoPor.apellido }</strong>
                            </Typography> 
                            : '' 
                            }
                            {seleItem?.rechazadoPor ? <Typography className={styles.label}>
                                Rechazado por:{' '}<strong style={{ fontWeight:'500' }}> {seleItem?.rechazadoPor.nombre } {' '} {seleItem?.rechazadoPor.apellido }</strong>
                            </Typography> 
                            : '' 
                            }

                            <Typography className={styles.label}>Vicepresidencia: <strong style={{ fontWeight:'500' }}>{seleItem?.vicepresidencia}</strong></Typography> 
                            <Typography className={styles.label}>Gerencia: <strong style={{ fontWeight:'500' }}>{seleItem?.gerencia}</strong></Typography> 
                            <Typography className={styles.label}>Si Area: <strong style={{ fontWeight:'500' }}>{seleItem?.sI_Area}</strong></Typography> 
                            <Typography className={styles.label}>Solicitante: <strong style={{ fontWeight:'500' }}>{seleItem?.nombreSolicitante} {seleItem?.apellidoSolicitante}</strong></Typography> 
                            <Typography className={styles.label}>Correo: <strong style={{ fontWeight:'500' }}>{seleItem?.emailSolicitante}</strong></Typography> 
                            <Typography className={styles.label}>Observaciones: <strong style={{ fontWeight:'500' }}>{seleItem?.observaciones}</strong></Typography> 
                            {seleItem?.razonRechazo && <Typography className={styles.label}>Razón rechazo: <strong style={{ fontWeight:'500' }}>{seleItem?.razonRechazo}</strong></Typography> }
                            <Typography className={styles.label}>Total Trabajadores: <strong style={{ fontWeight:'500' }}>{seleItem?.trabajadoresTotales}</strong></Typography> 
                            <Typography className={styles.label}>Total Trabajadores Dia: <strong style={{ fontWeight:'500' }}>{seleItem?.trabajadoresDia}</strong></Typography> 
                            <Typography className={styles.label}>Total Trabajadores Noche: <strong style={{ fontWeight:'500' }}>{seleItem?.trabajadoresNoche}</strong></Typography> 
                        </Card>}
                    </Grid>
                    <Grid item xs={12} md={statusLoading ? 12 : 8}>
                    {statusLoading 
                    ? <SkeletonTable/> 
                    :<DataGrid
                        getRowId={row=>row.id ? row.id : Math.random() }
                        rows={trabajadores}
                        columns={finalColumns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps.localeText,
                            noRowsLabel: "No hay datos disponibles",
                            pagination: {
                            labelRowsPerPage: "Filas por página:",
                            }
                        }}
                        classes={{ menuIcon: styles.menuIcon }}
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                        sx={{ 
                          '& .MuiDataGrid-cell': {
                            color: '#FFF',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color: '#FFF',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-footerContainer': {
                            color: '#FFF',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiTablePagination-root': {
                            color: '#FFF',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-toolbarContainer': {
                            color: '#FFF',
                          },
                          '& .MuiDataGrid-menu': {
                            color: '#FFF',
                          },
                          '& .MuiTablePagination-actions': {
                            color: '#FFF', // Color for pagination actions
                          },
                          '& .MuiTablePagination-select': {
                            color: '#FFF', // Color for pagination select
                          },
                          '& .MuiTablePagination-selectLabel': {
                            color: '#FFF', // Color for pagination select label
                          },
                          '& .MuiTablePagination-displayedRows': {
                            color: '#FFF', // Color for displayed rows text
                          },
                         }}
                        slotProps={{
                          toolbar:{
                            sx:{
                              color:'#FFF'
                            }
                          },
                          pagination: {
                            sx: {
                              color: '#FFF',
                            },
                          },
                          menu: {
                            sx: {
                              color: '#FFF',
                            },
                          },
                        }}
                    />}
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                    {seleItem && seleItem?.estado && 
                        (seleItem?.estado.trim().toLowerCase() === 'aprobada' || seleItem?.estado.trim().toLowerCase() === 'pendiente') && (
                           <Button 
                               onClick={() => setOpen2(true)}
                               className={styles.bgColorDelete}
                               variant="contained" 
                           >
                               Rechazar solicitud
                           </Button>
                       )}                       
                        <Button 
                        onClick={()=>setOpen(false)}
                        className={styles.bgColorOutlined}
                        variant="outlined" 
                        >
                            Cerrar
                        </Button>
                    </Grid>
                </DialogActions>

                <Dialog
                    open={open2}
                    onClose={()=>setOpen2(false)}
                    PaperProps={{
                        sx: {
                         backgroundColor:'#2d3037',
                         color:'#FFF',
                         borderRadius:'0'
                        }
                      }}
                >
                    <DialogTitle>Estás a punto de rechazar el estado de la solicitud {seleItem?.id}</DialogTitle>
                    <DialogContent>
                    <DialogContentText style={{ color:'#FFF' }}>
                        Escriba el motivo por el cual rechaza la solicitud.
                    </DialogContentText>
                    <Grid container={true} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                className={styles.textField}
                                style={{ padding: '10px' }}
                                sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    border: 'none',
                                    },
                                    '&:hover fieldset': {
                                    border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                    border: 'none',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#FFF',
                                },
                                padding:'10px',
                                }}
                                margin="dense"
                                id="razon"
                                name="razonRechazo"
                                onChange={(e)=>setFormData({...formData, [e.target.name]: e.target.value})}
                                label="Razón"
                                value={formData.razonRechazo}
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={rechazar}
                            className={styles.bgColorDelete}
                            variant="contained" 
                            disabled={!canSave}
                        >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Rechazar'}
                        </Button>
                        <Button 
                            onClick={()=>setOpen(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined" 
                            >
                                Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )}
        
      </Dialog>
  );
}