import { useState, useRef, useEffect, memo } from 'react';
import { MapContainer, Marker, Popup, TileLayer, Icon, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Button, Grid, TextField } from "@mui/material";
import styles from './components.module.css';
import * as L from "leaflet";
import GeoCoderMarker from './GeoCoderMarker';

const Map = ({ position, setPosition, formData, setFormData }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const inputRef = useRef(null);
  const mapRef = useRef(null);

  const lat = formData ? parseFloat(formData.latitud) || 0 : 0;
const lng = formData ? parseFloat(formData.longitud) || 0 : 0;
// Asegurarse de que el mapa tenga un centro y un zoom inicial
const initialCenter = position || { lat: -22.4359832, lng: -68.9249763 };
const initialZoom = 13;

  useEffect(() => {
      inputRef.current.focus(); // Mantener el enfoque en el input
  }, [searchResults]);

  const searchAddress = async () => {
      if (!searchQuery) return;

      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchQuery)}`);
      const data = await response.json();
      
      setSearchResults(data);
  };

  const handleSearch = () => {
      searchAddress();
  };

  const handleResultClick = (result) => {
      const lat = parseFloat(result.lat);
      const lon = parseFloat(result.lon);
      setPosition([lat, lon]);
      setFormData({...formData, latitud: lat, longitud: lon });
      setSearchResults([]);
      if (mapRef.current) {
          mapRef.current.setView([lat, lon]);
      }
  };

    return (
      <>
        <Grid item xs={12} sm={12} mt={2} md={5}>
            <div style={{ position: 'relative', width:'100%' }}>
                <div style={{ display:'flex', gap:'5px', width:'100%' }}>
                  <TextField
                    ref={inputRef}
                    type="text"
                    className={styles.textField}
                    value={searchQuery}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Buscar dirección..."
                    style={{ width: '100%', marginBottom: '8px' }}
                />
                <Button style={{ backgroundColor:'#0086ae', color:'#fff', marginBottom: '8px', padding:'0 20px' }} onClick={handleSearch}>Buscar</Button>
                </div>
                <ul style={{ listStyleType: 'none', padding: 0, maxHeight:'400px', overflowY:'auto' }}>
                    {searchResults.map(result => (
                        <li key={result.place_id} style={{ cursor:'pointer', borderBottom:'1px solid black' }} onClick={() => handleResultClick(result)}>
                        * {result.display_name}</li>
                    ))}
                </ul>
            </div>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
        <label className={styles.label} style={{ display:'flex', alignItems:'center', gap:'4px', marginTop:'10px' }}>Seleccione el marcador y arrástrelo</label>

        <MapContainer center={initialCenter} zoom={initialZoom} scrollWheelZoom={true} style={{ height: "480px", width: "100%" }} ref={mapRef}>
          <TileLayer
              attribution=''
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        
                {/*
                <Marker
                position={position}
                    draggable={true}
                    
                    eventHandlers={{
                        dragend: (event) => {
                            setPosition(event.target.getLatLng());
                        }
                    }}
                    icon={icon}
                >
                    <Popup minWidth={90}>
                      <span>Arrastre el marcador</span>
                    </Popup>
                </Marker>                
                */}
                <GeoCoderMarker lat={lat} lng={lng} setPosition={setPosition} />
              </MapContainer>
            </Grid>
            </>
    );
};

export default Map;
