import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Autocomplete, Button, CircularProgress, Container, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography, Dialog } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllCategorias, updateInventario } from '../../../redux/features/inventario/inventarioSlice';
import { articulosTomaColumns } from '../../../lib/columns';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { format } from 'date-fns';

const estados = [
    { nombre: 'Activado', value: true },
    { nombre: 'Desactivado', value: false },
]

export default function EditArticulo({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const dispatch = useDispatch();
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [open, setOpen] = useState(false);
    const finalColumns = articulosTomaColumns(
      // setOpen, setSeleItem
    );

    useEffect(() => {
      if(seleItem){
        setFormData(seleItem)
        console.log('seleItem: ', seleItem);
      }
    }, [seleItem])
    
 

  return (
    <div>
      <Dialog
        open={openEdit}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onClose={() => setOpenEdit(false)}
      >
        {seleItem && (
        <>
          <DialogTitle>
                <Grid container={true} spacing={1} p={2}>
                    <Grid item  xs={12} sm={12} mb={2} style={{ display:'flex', gap:'5px'}}>
                        <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                        <div style={{ display:'flex', flexDirection:'column' }}>
                            <Typography variant="h6" className={styles.textIconEdit}>Toma de Inventario en {seleItem?.bodega?.nombre}</Typography>
                            <Typography variant="span" className={styles.textIconSpan}>Información general de la toma de inventario en la bodega {seleItem?.bodega?.nombre}.</Typography>
                        </div>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container={true} spacing={1} p={2}>
                  <Grid item xs={12} md={12} style={{ border:'1px solid var(--inst-li-blue)', borderRadius:'5px', padding:'5px' }}>
                  <Typography>Bodega: <strong>{seleItem?.bodega.nombre}</strong></Typography>
                  <Typography>Fecha: <strong>{seleItem?.fecha && format(seleItem?.fecha, 'dd-MM-yyyy')}</strong></Typography>
                  <Typography>Monto Total: <strong>${seleItem?.montoTotal}</strong></Typography>
                  <Typography>Monto Total Inventariado: <strong>${seleItem?.montoTotalTomaInventario}</strong></Typography>
                  <Typography>Usuario: <strong>{seleItem?.usuario?.nombre} {seleItem?.usuario?.apellido}</strong></Typography>
                  <Typography>Correo: <strong>{seleItem?.usuario?.email}</strong></Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <DataGrid
                      getRowId={row=>row.id ? row.id : Math.random() }
                      lots={{ toolbar: GridToolbar }}
                      rows={seleItem?.tomaInventarioArticulos || []}
                      columns={finalColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      localeText={{
                      ...esES.components.MuiDataGrid.defaultProps.localeText,
                      noRowsLabel: "No hay datos disponibles",
                      pagination: {
                          labelRowsPerPage: "Filas por página:",
                      }
                      }}
                      classes={{ menuIcon: styles.menuIcon }}
                      style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
                        sx={{ 
                          '& .MuiDataGrid-cell': {
                            color: '#FFF',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeader': {
                            color: '#FFF',
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            borderBottom: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-footerContainer': {
                            color: '#FFF',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiTablePagination-root': {
                            color: '#FFF',
                            borderTop: '1px solid #c6c6c6',
                          },
                          '& .MuiDataGrid-toolbarContainer': {
                            color: '#FFF',
                          },
                          '& .MuiDataGrid-menu': {
                            color: '#FFF',
                          },
                          '& .MuiTablePagination-actions': {
                            color: '#FFF', // Color for pagination actions
                          },
                          '& .MuiTablePagination-select': {
                            color: '#FFF', // Color for pagination select
                          },
                          '& .MuiTablePagination-selectLabel': {
                            color: '#FFF', // Color for pagination select label
                          },
                          '& .MuiTablePagination-displayedRows': {
                            color: '#FFF', // Color for displayed rows text
                          },
                        }}
                        slotProps={{
                          toolbar:{
                            sx:{
                              color:'#FFF'
                            }
                          },
                          pagination: {
                            sx: {
                              color: '#FFF',
                            },
                          },
                          menu: {
                            sx: {
                              color: '#FFF',
                            },
                          },
                        }}
                        />
                    </Grid>
                </Grid>  
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                        <Button 
                            onClick={()=>setOpenEdit(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined" 
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
        )}
      </Dialog>
    </div>
  );
}