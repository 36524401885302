import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components_ingreso/Create";
import Table from './components_ingreso/Table'
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from '@mui/material';
import StormIcon from '@mui/icons-material/Storm';
import { fetchForecastAreas, fetchForecastGerencias, fetchForecasts, getAllAreas, getAllForecast, getAllGerencia, getforecastStatus } from "../../../redux/features/forecast/forecastSlice";
import { useAuth } from "../../../context/AppContext";
import { fetchTurnos, getAllTurnos, getTurnosStatus } from "../../../redux/features/users/userSlice";

const Ingreso = () => {
  
  const { info } = useAuth();
  const empresas = info?.empresas;
  const dispatch = useDispatch();
  const forecast = useSelector(getAllForecast);
  const status = useSelector(getforecastStatus);
  const gerencia = useSelector(getAllGerencia);
  const siArea = useSelector(getAllAreas);
  const turnos = useSelector(getAllTurnos);
  const turnosStatus = useSelector(getTurnosStatus);
  const contratos = [];
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [tramos, setTramos] = useState([]);
  const [tramosEdit, setTramosEdit] = useState([]);

  useEffect(()=>{
    if(status === 'idle'){
      dispatch(fetchForecasts());
      dispatch(fetchForecastGerencias());
      dispatch(fetchForecastAreas());
    }
    if(turnosStatus === 'idle'){
      dispatch(fetchTurnos());
    }
  },[]);

const data = {
  empresa: '',
  tramos: []
}

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Forecast"
          subtitle="Aquí podrás ingresar un forecast con el rango de fechas, cantidad de personas que van a subir a faena y sus respectivas jornadas.">
          {<StormIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>
        
        <TableCard>
          <Create setOpen2={setOpen2} siArea={siArea} empresas={empresas} ger={gerencia.length > 0 ? gerencia : []} open2={open2} data={data} tramos={tramos} setTramos={setTramos} contratos={contratos} turnos={turnos}/>
          <Table registros={forecast ? forecast : []} status={status} setOpen3={setOpen3} siArea={siArea} empresas={empresas} ger={gerencia.length > 0 ? gerencia : []} data={data} tramos={tramosEdit} setTramos={setTramosEdit} contratos={contratos} open2={open2} setOpen2={setOpen2} turnos={turnos}/>
        </TableCard>
      </Container>
    </Box>
  )
}
export default Ingreso