import styles from './components/components.module.css'
import { Grid, CircularProgress, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, Autocomplete, Popper, TextField, Box, Tabs, Tab, Backdrop } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { liveSearchByRut } from '../../redux/features/users/userSlice';
import { asignarHabitacionGrupo } from '../../redux/features/aseo/aseoSlice';
import { format, setMinutes } from 'date-fns';
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';

const data = {
  nombre:'',
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Asignar = ({ openEdit, setOpenEdit, seleItem, setOpen3, grupos, personal, selectedItems, open3, planificaciones }) => {
    
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(data);
    const [selectedGrupo, setSelectedGrupo] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [ruts, setRuts] = useState([]);
    const [msg, setMsg] = useState('');
    const dispatch = useDispatch();


    useEffect(() => {
            setFormData(prev => ({...prev, habitaciones:selectedItems}))
    }, [selectedItems])

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangeGrupo = (event, newValue) => {
      setSelectedGrupo(newValue);
      console.log('NEWVALUE', newValue)
      setFormData(prev => ({...prev, id: newValue.gruposHabitaciones[0].id}))
      setMsg(`Las habitaciones seleccionadas se asignarán al grupo ${newValue.gruposHabitaciones[0].nombre}`)
    };

    const onChangeRut = async (e) => {
      try {
        setOpen3(true);
        const resp = await dispatch(liveSearchByRut(e.target.value))
        setRuts(resp.payload.data);
      } catch (error) {
        console.log(error)
      } finally{
        setOpen3(false)
      }
    }

    const onChangeRutSelect = async (e,newValue) => {
      if(newValue != null){
        setFormData({...formData, rut: newValue})
      } else{
        setFormData({...formData, rut: null})
      }
    }

    // const onChange = (e) => setFormData({...formData, [e.target.name] : e.target.value});
    const onChange = (e) => setSelectedName(e.target.value);

    useEffect(() => {
      if (selectedName !== null) {
        setSelectedGrupo(null);
      }
    }, [selectedName]);
  
    useEffect(() => {
      if (selectedGrupo !== null) {
        setSelectedName(null);
      }
    }, [selectedGrupo]);
    
    
    const handleAsignarOne = async () => {
      setOpen3(true);
        try {
            setAddRequestStatus('loading');
            let resp = await dispatch(asignarHabitacionGrupo(formData))
            if(resp.payload.status === 'success'){
              setOpenEdit(false);
              setOpen3(false);
              setSelectedGrupo('')
            }
        } catch (error) {
            console.error('Error al actualizar la solicitud', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpen3(false)
        }
    }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder ingresar un reclamo y una sugerencia al sistema.</Typography>
        
        <Button
          size='large' 
          disabled={selectedItems.length == 0} 
          className={ styles.bgColor }
          onClick={()=> setOpenEdit(true)}
          >
          <CircleIcon className={styles.iconButton} /> Asignar habitaciones</Button>
      </div>
      <Backdrop
          sx={{ color: '#fff', zIndex: 1000000 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
            <Dialog
                open={openEdit}
                maxWidth="lg"
                PaperProps={{
                  sx: {
                  backgroundColor:'#2d3037',
                  color:'#FFF',
                  borderRadius:'0'
                  }
                }}
                onBackdropClick={() => {
                    setOpenEdit(false);
                }}
            >
              <DialogTitle style={{ color:'#FFF' }}>
                <Typography variant='p'>Asignar habitaciones</Typography>
                <span className={styles.subTitle} style={{ color:'#dedede' }}>
                A continuación podrás asignar habitaciones a una planificación registrada.</span>
              </DialogTitle>
              
              <DialogContent >
                <Grid container={true}>
                  <Grid item xs={12} md={12}>
                              <label className={styles.label}>Selecciona una planificación</label>
                              <Autocomplete
                                className={styles.textField}
                                style={{ marginBottom:'50px' }}
                                sx={{
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: 'none',
                                          },
                                          '&:hover fieldset': {
                                            border: 'none',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: 'none',
                                          },
                                        },
                                        '& .MuiInputBase-input': {
                                          color: '#FFF',
                                        },
                                        '& .MuiAutocomplete-root': {
                                          backgroundColor: '#FFF !important',
                                        },
                                        '& .MuiInputLabel-root': {
                                          color: '#FFF',
                                        },
                                        '& .MuiAutocomplete-clearIndicator': {
                                          color: '#FFF',
                                        },
                                        '& .MuiAutocomplete-popupIndicator': {
                                          color: '#FFF',
                                        },
                                      }}
                                disablePortal
                                id="campamentos-autocomplete"
                                options={planificaciones || []}
                                value={selectedGrupo || null}
                                name="bodega"
                                disableClearable
                                getOptionLabel={(option) => `${format(option.desde, 'dd-MM-yyyy')} / ${format(option.hasta, 'dd-MM-yyyy')} Planificación: ${option.horarioAseo.nombre}`}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    {`${format(option.desde, 'dd-MM-yyyy')} / ${format(option.hasta, 'dd-MM-yyyy')} `}
                                    <CircleIcon style={{ fontSize:'6px', margin:'0 5px' }} />
                                    {' Planificación: '}
                                    {` ${option.horarioAseo.nombre}`}
                                  </li>
                                )}
                                onChange={(e, value)=> handleChangeGrupo(e, value)}
                                renderInput={(params) => <TextField {...params}/>}
                              />                    
                            </Grid>
                            <Typography>{msg}</Typography>
                          </Grid>
                      {/*<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Seleccionar planificaciòn" {...a11yProps(0)} />
                        </Tabs>
                        <CustomTabPanel value={value} index={0}>
                          <Grid container={true}>
                            <Grid item xs={12} md={12}>
                              <label className={styles.label}>Selecciona una planificación</label>
                              <Autocomplete
                                disablePortal
                                id="campamentos-autocomplete"
                                options={planificaciones || []}
                                value={selectedGrupo || null}
                                name="bodega"
                                disableClearable
                                getOptionLabel={(option) => option.nombre}
                                onChange={(e, value)=> handleChangeGrupo(e, value)}
                                renderInput={(params) => <TextField {...params}/>}
                              />                    
                            </Grid>
                          </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <Grid container={true}>
                            <Grid item xs={12} md={12}>
                              <label className={styles.label}>Nombre</label>
                              <TextField
                                variant="outlined"
                                fullWidth
                                name='nombre'
                                value={selectedName}
                                type="text"
                                onChange={(e) => onChange(e)}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <label className={styles.label}>Asigna un miembro del personal</label>
                              <Autocomplete
                              disablePortal
                              disableClearable
                              id="sectores-autocomplete"
                              name="rut"
                              value={formData?.rut || null}
                              options={personal ? personal : [] }
                              getOptionLabel={(option) => option.rut + ' ' + option.nombre}
                              onChange={(e, value) => onChangeRutSelect(e, value)}
                              renderInput={(params) => <TextField {...params} label="Busca un miembro del personal" />}
                          />
                            </Grid>
                          </Grid>
                        </CustomTabPanel>
                      </Box> */}

                    </DialogContent>
                    <DialogActions>
                      <Grid container={true} style={{ padding:'0 16px' }}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                          <Button 
                            onClick={value === 0 ? handleAsignarOne : null}
                            className={styles.bgColorModal}
                            variant="contained"
                            disabled={value === 0 ? (!selectedGrupo|| addRequestStatus == 'loading' ) : ''}
                            >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Asignar'}
                          </Button>
                          <Button 
                            onClick={()=>setOpenEdit(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined"
                            style={{ border:'none' }}
                            >
                            Cancelar
                          </Button>
                        </Grid>          
                      </Grid>
                    </DialogActions>
                  </Dialog>
          </div>
  )
}

export default Asignar