import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MapIcon from '@mui/icons-material/Map';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, ListItem, List, TextField, Typography } from '@mui/material';
import { addNewEspacio } from '../../../../redux/features/guardarropia/guardarropiaSlice';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  fila: 0,
  nivel: 0,
  numero: '',
  campamentoId: 0,
}

const Create = ({ campamentos, open2, setOpen2, espacios }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedFila, setSelectedFila] = useState(null);
  const [selectedNivel, setSelectedNivel] = useState(null);
  const [selectedNumero, setSelectedNumero] = useState(null);

  const [ocupado, setOcupado] = useState(false);

  const [filasNivelesNumeros, setFilasNivelesNumeros] = useState([]);

  const [search, setSearch] = useState('');
  const [filteredNumbers, setFilteredNumbers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");

  const [isCampamentoEnabled, setIsCampamentoEnabled] = useState(false);
  const [isFilaEnabled, setIsFilaEnabled] = useState(false);
  const [isNivelEnabled, setIsNivelEnabled] = useState(false);
  const [isNumerosEnabled, setIsNumerosEnabled] = useState(false);

  const [errorFila, setErrorNumero] = useState(null);

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, campamentoId: newValue.id});
      const espaciosFiltrados = espacios.filter(item => item.campamento.id === newValue.id);
      const filasNivelesNumeros = espaciosFiltrados.map(item => ({
        campamento: item.campamento.id,
        fila: item.fila,
        nivel: item.nivel,
        numero: item.numero
      }));
      setFilasNivelesNumeros(filasNivelesNumeros);
      setIsCampamentoEnabled(true);
    } else{
      setFormData({...formData, campamentoId: 0})
      setFilasNivelesNumeros([])
      setIsCampamentoEnabled(false);
      setSelectedFila(null);
    }    
  };
  
  const handleChangeCheckbox = () => {
    setOcupado(!ocupado);
    setFormData({...formData, ocupado: !ocupado });
  };
  
  const canSave = [formData.fila, formData.nivel, formData.numero, formData.campamentoId != 0].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewEspacio(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
        }
      } catch (error) {
        console.error('Error al crear el espacio de almacenamiento', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }

  const handleOpenFila = () => {
    const filas = filasNivelesNumeros.filter(item => item.campamento === selectedCampamento.id);
    if (filas.length > 0) {
      setDialogTitle("Filas creadas");
      setDialogContent(
        <List>
          {filas.map((fila, index) => (
            <ListItem key={index}>{fila.fila}</ListItem>
          ))}
        </List>
      );
    }else{
      setDialogContent("No existen filas en el campamento");
    }
    setOpenDialog(true);
  };
  
  const handleCloseFila = () => {
    setOpenDialog(false);
    setDialogTitle();
    setDialogContent();
  };
  
  const handleOpenNivel = () => {
    const niveles = filasNivelesNumeros.filter(item => item.fila === selectedFila);
    if (niveles.length > 0) {
      setDialogTitle("Niveles creados");
      setDialogContent(
        <List>
          {niveles.map((nivel, index) => (
            <ListItem key={index}>{nivel.nivel}</ListItem>
          ))}
        </List>
      );
    }else{
      setDialogContent("No existen niveles creados en la fila seleccionada");
    }
    setOpenDialog(true);
  };
  
  const handleCloseNivel = () => {
    setOpenDialog(false);
    setDialogTitle();
    setDialogContent();
  };
  
  const handleOpenNumero = () => {
    const numeros = filasNivelesNumeros.filter(item => item.fila === selectedFila && item.nivel === selectedNivel && item.numero);
    if (numeros.length > 0) {
      setDialogTitle("Números creados");
      setDialogContent(
        <List>
          {numeros.map((numero, index) => (
            <ListItem key={index}>{numero.numero}</ListItem>
          ))}
        </List>
      );
    }else{
      setDialogContent("No existen numeros creados en el nivel seleccionado");
    }
    setOpenDialog(true);
  };
  
  const handleCloseNumero = () => {
    setOpenDialog(false);
    setDialogTitle();
    setDialogContent();
  };
  
  const handleFilaChange = (event) => {
    const nuevaFila = event.target.value;
    if(nuevaFila){
      setSelectedFila(nuevaFila);
      if (nuevaFila) {
        let filaInt = parseInt(nuevaFila);
        setFormData({...formData, [event.target.name]: nuevaFila});
        setIsFilaEnabled(true);
      } else {
        setIsFilaEnabled(false);
        setSelectedNivel(null);
      }
    }else{
      setIsFilaEnabled(false);
      setFormData({...formData, [event.target.name]: 0});
    }
  };
  
  const handleNivelChange = (event) => {
    setIsCampamentoEnabled(false);
    const nuevoNivel = event.target.value;
    if(nuevoNivel){
      setSelectedNivel(nuevoNivel);
      if (nuevoNivel) {
        // let nivelInt = parseInt(nuevoNivel);
        setFormData({...formData, [event.target.name]: nuevoNivel});
        setIsNivelEnabled(true);
      } else {
        setIsNivelEnabled(false);
        setSelectedNumero(null);
      }
    }else{
      setFormData({...formData, [event.target.name]: 0});
      setIsCampamentoEnabled(true);
      setIsNivelEnabled(false);
    }    
  };
  
  const handleNumberChange = (event) => {
    setIsFilaEnabled(false);
    const nuevoNumero = event.target.value;
    if(nuevoNumero){
      setSelectedNumero(nuevoNumero);
      const numeroExists = filasNivelesNumeros.some(item => item.fila === selectedFila && item.nivel === selectedNivel && item.numero === nuevoNumero);
      if (!numeroExists && nuevoNumero) {
        setFormData({...formData, [event.target.name]: nuevoNumero});
        setIsNumerosEnabled(true);
        setErrorNumero(null);
      } else {
        setErrorNumero('Existente');
        setIsNumerosEnabled(false);
      }
    }else{
      setFormData({...formData, [event.target.name]: ''});
      setErrorNumero(null);
      setIsFilaEnabled(true);
    }
  };

  const inputDefault = () => {
      setSelectedCampamento(null);
      setSelectedFila(null);
      setSelectedNivel(null);
      setSelectedNumero(null);
      setIsCampamentoEnabled(false);
      setIsFilaEnabled(false);
      setIsNivelEnabled(false);
      setIsNumerosEnabled(false);
      setFormData(data);
      setErrorNumero(null);
  }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder registrar un espacio de almacenamiento.</Typography>
        <Button variant='contanied' size='large' disabled={campamentos.length == 0} className={styles.bgColor} onClick={()=> {setOpen(true); inputDefault();}}>Crear espacio</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <MapIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px' }}>Crear unidad</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear la unidad.</span>
          </div>
        </DialogTitle>
        
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={12} >
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#FFF',
                      },
                    }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                name="idCampamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />
            </Grid>

            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>Fila</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidths
                name='fila'
                type="text"
                disabled={!isCampamentoEnabled}
                onChange={handleFilaChange}
              />
              {isCampamentoEnabled && (
                <Button
                  onClick={handleOpenFila}
                  className={styles.bgColorOrange}
                  style={{ marginTop:'10px' }}
                  >
                  Filas creadas
                </Button>
              )}
            </Grid>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>Nivel</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='nivel'
                type="text"
                disabled={!isFilaEnabled}
                onChange={handleNivelChange}
              />
              {isFilaEnabled && (
                <Button
                  onClick={handleOpenNivel}
                  className={styles.bgColorOrange}
                  style={{ marginTop:'10px' }}
                  >
                  Niveles creados
                </Button>
              )}
            </Grid>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>ID</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='numero'
                type="text"
                disabled={!isNivelEnabled}
                onChange={handleNumberChange}
                error={!!errorFila}
                helperText={errorFila}
              />
              {isNivelEnabled && (
                <Button
                  onClick={handleOpenNumero}
                  className={styles.bgColorOrange}
                  style={{ marginTop:'10px' }}
                  >
                    ID's creados
                </Button>
              )}
            </Grid>
            <Dialog onClose={handleCloseNumero} open={openDialog}>
              <DialogTitle>{dialogTitle}</DialogTitle>
              <DialogContent>
                {dialogContent}
              </DialogContent>
            </Dialog>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create