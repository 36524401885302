import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllEdificios } from '../../../../redux/features/edificios/edificioSlice';
import { updateEspacio } from '../../../../redux/features/espacios/espacioSlice';
import { getAllWings } from '../../../../redux/features/wings/wingSlice';

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const pabellones = useSelector(getAllEdificios);
    const wings = useSelector(getAllWings);

    useEffect(() => {
        setFormData(seleItem)
    }, [seleItem])

    const handleChangeEdificio = (e, value) => setFormData({ ...formData, pabellonId: value ? value.id : null, wingId: null });
    const handleChangeWing = (e, value) => setFormData({ ...formData, wingId: value ? value.id : null, pabellonId: null });
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
    
    const canSave = [formData?.nombre, (formData?.pabellonId || formData?.wingId), formData?.descripcion, formData?.piso].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateEspacio(formData));
            if(resp.payload.success){
                setOpen3(false);
            }else{
                setOpen3(false);
            }
        } catch (error) {
            toast.error('Error al actualizar el espacio común.');
            console.error('Error al actualizar el espacio común', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpenEdit(false);
        }
    }

  return (
    <div>
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={
            {
              sx: {
                width: { xs: '100%', sm: '75%', md: '50%' },
                backgroundColor:'#2d3037',
                color:'#FFF'
              }
            }
        }
      >
        {seleItem && (
                <>
                <DialogTitle>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2} style={{ display:'flex', gap:'5px'}}>
                            <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                            <div style={{ display:'flex', flexDirection:'column' }}>
                                <Typography variant="h6" className={styles.textIconEdit}>Actualizar espacio común {seleItem?.nombre}</Typography>
                                <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el espacio común.</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={6}>
                        { formData?.pabellonId ? <label className={styles.label}>Pabellón</label>
                            : <label className={styles.label}>Wing</label>
                        }
                            { formData?.pabellonId 
                                ? 
                                <Autocomplete
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                    disablePortal
                                    disableClearable
                                    id="sectores-autocomplete"
                                    options={pabellones}
                                    name="edificio"
                                    getOptionLabel={(option) => option.identificador}
                                    value={pabellones.find(option => option.id === formData?.pabellonId) || null}
                                    onChange={(e, value) => handleChangeEdificio(e, value)}
                                    renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                                />
                                :
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                    id="sectores-autocomplete"
                                    options={wings}
                                    name="edificio"
                                    getOptionLabel={(option) => option.nombre}
                                    value={wings.find(option => option.id === formData?.wingId) || null}
                                    onChange={(e, value) => handleChangeWing(e, value)}
                                    renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                                />
                            }
                        </Grid>
                        <Grid item  xs={12} sm={6}></Grid>
                        <Grid item  xs={12} sm={8}>
                            <label className={styles.label}>Nombre</label>
                            <TextField
                            className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                            variant="outlined"
                            fullWidth
                            name='nombre'
                            type="text"
                            value={formData?.nombre}
                            onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>Piso</label>
                            <TextField
                            className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                variant="outlined"
                                fullWidth
                                name='piso'
                                type="number"
                                value={formData?.piso}
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={12}>
                            <label className={styles.label}>Descripción</label>
                            <TextField
                            className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                variant="outlined"
                                fullWidth
                                name='descripcion'
                                type="text"
                                multiline
                                rows={2}
                                maxRows={4}
                                value={formData?.descripcion}
                                onChange={(e) => onChange(e)}
                            />
                            </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container={true} style={{ padding:'0 16px' }}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColorEdit}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </>
        )}
      </Drawer>
    </div>
  );
}