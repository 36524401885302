import styles from './admAplicacion.module.css'
import { Box, Card, Container, Grid,  Typography } from "@mui/material"
import CardSlider from './components/Slider';
import { useAuth } from '../../context/AppContext'
import Hero from './components/Hero'

const Dashboard = () => {

  return (
    <Box width={1} mt={0} mb={2}>
        <Container className={styles.container}>
            <Hero/>
            <CardSlider/> 
        </Container>
    </Box>
  )
}

export default Dashboard