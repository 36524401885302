import React, { useCallback, useEffect, useState } from 'react';
import styles from './components.module.css'
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Backdrop, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Slide, TextField, Toolbar, Typography } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { reservasTrabajadadoresRecepcionistaColumns } from '../../../../lib/columns';
import Edit from './Edit'
import Edit2 from './Edit2'
import { createAxiosInstance } from '../../../../api/axios';
import SkeletonTable from '../../../../components/Skeleton';
import { rechazarSolicitud } from '../../../../redux/features/reservas/reservaSlice';
import { useDispatch } from 'react-redux';
import CustomToolbar from '../../../../components/SlotsToolBar';
import { fetchHabitacionesDisponibles } from '../../../../redux/features/habitaciones/habitacionSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateReserva = ({ open,  open2, setOpen, setOpen2, seleItem, campamentos, wings, habitaciones, setSeleItem, pabellones, setLoaderList, loaderList }) => {

  const [trabajadores, setTrabajadores] = useState([])
  const [check, setCheck] = useState(false)
  const [formData, setFormData] = useState({})
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reservasTrabajadadoresRecepcionistaColumns();
  const [openEdit, setOpenEdit] = useState(false);
  const [openModalRechazar, setOpenModalRechazar] = useState(false);
  const [open3, setOpen3] = useState(false)
  const axiosInstance = createAxiosInstance();
  const [expanded, setExpanded] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const dispatch = useDispatch();
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };
  
  const canSave = ( (formData.razonRechazo ) && formData.id && addRequestStatus === 'idle' );

  const getTrabajadores = async () => {
    try {
      setLoaderList(true);
  
      // Verificar que seleItem esté definido y tenga una propiedad id válida
      if (!seleItem || !seleItem.id) {
        console.error('Error: seleItem no está definido o no tiene un id válido.');
        return;
      }
  
      // Obtener los trabajadores de la solicitud
      const resp = await axiosInstance.get(`/api/v1/Solicitud/TrabajadoresSolicitud/${seleItem.id}`);
      if (resp.data.length > 0) {
        console.log('getTrabajadores: ', resp.data);
  
        // Verificar si ya se han actualizado los trabajadores en seleItem
        if (!seleItem.trabajadores || seleItem.trabajadores.length === 0) {
          // Mapear y obtener cargos y empresa para cada trabajador
          console.log('seleItem.trabajadores: ', resp.data)
          const updatedTrabajadores = await Promise.all(resp.data.map(async (item) => {
            try {
              // Obtener información del cargo del trabajador
              const respCargo = await axiosInstance.get(`/api/v1/Solicitud/Trabajadores?Rut=${item.trabajador.rut}`);
              console.log('respCargo', respCargo)
              if(respCargo.data[0]){
                const empresa = await axiosInstance.get(`/api/v1/Solicitud/Contratos/Info/${respCargo.data[0].contrato}`);
                console.log('empresaData:',empresa.data);
                console.log('item', item);
                return {
                  ...item,
                  cargo: respCargo.data[0]?.nCargo,
                  acreditado: item.trabajador?.acreditado,
                  cargoId: respCargo.data[0]?.cargoId,
                  contrato: respCargo.data[0]?.contrato,
                  empresaId: empresa.data?.data?.empresaId
                };
              }else{
                return {
                  ...item,
                  cargo: '',
                  acreditado: item.trabajador?.acreditado,
                  cargoId: '',
                  contrato: '',
                  empresaId: ''
                };
              }
              
            } catch (error) {
              console.error(`Error al obtener cargo para el trabajador ${item.trabajador.rut}: `, error);
              return {
                ...item,
                acreditado: item.trabajador?.acreditado,
              }; // Mantener el item original en caso de error
            }
          }));
  
          // Actualizar estado con los trabajadores actualizados
          setTrabajadores(updatedTrabajadores);
          console.log('updatedTrabajadores',updatedTrabajadores);
          // Actualizar seleItem con los trabajadores actualizados
          setSeleItem(prev => ({ ...prev, trabajadores: updatedTrabajadores }));
        }
      } else {
        setTrabajadores([]);
      }
    } catch (error) {
      console.error('Error al obtener trabajadores de la solicitud:', error);
    } finally {
      setExpanded('panel1');
      setLoaderList(false);
    }
  };
  
  const habitacionesDisponibles = async (fechaIngreso, fechaSalida) => {
    try {
      const resp = await dispatch(fetchHabitacionesDisponibles({FechaIngreso: fechaIngreso, FechaSalida:fechaSalida}));
      console.log('habitacionesDisponibles', resp.payload);
      setFilterHabitaciones(resp.payload);
    } catch (error) {
      
    }
  }
  useEffect(() => {
    // Llamar a la función para obtener trabajadores cuando cambie seleItem
    setExpanded(false);
    if (seleItem && seleItem.id) {
      console.log('seleItem',seleItem);
      console.log('seleItem fechaIngreso',seleItem.fechaIngreso);
      console.log('seleItem fechaSalida',seleItem.fechaSalida);
      setFormData({ ...formData, id: seleItem?.id })
      getTrabajadores();
      habitacionesDisponibles(seleItem.fechaIngreso, seleItem.fechaSalida);
    }

  }, [seleItem]);
  

  const rechazar = async () => {
    setAddRequestStatus('loading')
    setLoaderList(true);
    try {
        const resp = await dispatch(rechazarSolicitud(formData));
        if(resp.payload.status === 'success'){
            setFormData({})
            return true;
        }
    } catch (error) {
        
    }finally{
        setOpen(false);
        setOpenModalRechazar(false);
        setAddRequestStatus('idle')
        setLoaderList(false);
    }
}

  useEffect(() => {
    if (trabajadores && Array.isArray(trabajadores)) {
      console.log('Trabajadores pasados por parámetro: --> ', trabajadores);
      const mapped = trabajadores.map(item => ({
        ...item,
        trabajador: {
          ...item.trabajador,
          jornada: item.jornada
        }
      }));

      // Comparar si los datos han cambiado antes de actualizar el estado
      const isEqual = JSON.stringify(trabajadores) === JSON.stringify(mapped);
      if (!isEqual) {
        setTrabajadores(mapped);
      }
    }
  }, [trabajadores]);;

  return (
    <React.Fragment>
    {/*<Button variant="outlined" className={styles.bgColor} onClick={()=> setOpen(true)}>
      Asignar Alojamiento
    </Button> */}
    <Dialog
      maxWidth="lg"
      PaperProps={{
        sx: {
         backgroundColor:'#2d3037',
         color:'#FFF',
         borderRadius:'0'
        }
      }}
      open={open}
      onClose={()=>setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar style={{ backgroundColor:'var(--border-blue)' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={()=>setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            RESERVA
          </Typography>
          <Button autoFocus color="inherit" onClick={()=>setOpen(false)}>
            Cerrar
          </Button>
        </Toolbar>
      </AppBar>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderList}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      <DialogContent>
        <Grid container={true} spacing={1} style={{ marginBottom:'10px' }}>
          <Grid item xs={12} md={4} style={{ textAlign:'start' }}>
            <Card style={{ padding:'16px', backgroundColor:'var(--inst-card-bg)', color:'#FFF', border:'1px solid var(--border-orange)' }}>
              <Typography style={{ fontSize:'16px', fontWeight:'600' }}>Información de la solicitud</Typography>
              <Typography>Empresa: <strong style={{ fontWeight:'500' }}>{seleItem?.empresa?.razonSocial}</strong></Typography>
              <Typography>Contrato: <strong style={{ fontWeight:'500' }}>{seleItem?.contrato}</strong></Typography>
              <Typography>Vicepresidencia: <strong style={{ fontWeight:'500' }}>{seleItem?.vicepresidencia}</strong></Typography>
              <Typography>Gerencia: <strong style={{ fontWeight:'500' }}>{seleItem?.gerencia}</strong></Typography>
              <Typography>Si-Area: <strong style={{ fontWeight:'500' }}>{seleItem?.sI_Area}</strong></Typography>
              <Typography>Solicitante: <strong style={{ fontWeight:'500' }}>{seleItem?.nombreSolicitante} {seleItem?.apellidoSolicitante}</strong></Typography>
              <Typography>email: <strong style={{ fontWeight:'500' }}>{seleItem?.emailSolicitante}</strong></Typography>
              <Typography>Fecha ingreso: <strong style={{ fontWeight:'500' }}>{formatDate(seleItem?.fechaIngreso)}</strong></Typography>
              <Typography>Fecha salida: <strong style={{ fontWeight:'500' }}>{formatDate(seleItem?.fechaSalida)}</strong></Typography>
              <Typography>N° trabajadores: <strong style={{ fontWeight:'500' }}>{seleItem?.trabajadoresTotales}</strong></Typography>
              <Typography>observaciones: <strong style={{ fontWeight:'500' }}>{seleItem?.observaciones}</strong></Typography>
              <Button 
                onClick={()=>setCheck(true)}
                className={styles.bgColor}
                variant="contained" 
                // disabled={!canSave}
                style={{ width:'100%', marginTop:'10px' }}
                >
                Generar Propuesta
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <DataGrid
              components={{
                Toolbar: CustomToolbar
              }}
              getRowId={row=>row.id ? row.id : Math.random() }
              lots={{ toolbar: GridToolbar }}
              rows={trabajadores || []}
              columns={finalColumns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15, 20]}
              localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                  labelRowsPerPage: "Filas por página:",
              }
              }}
              classes={{ menuIcon: styles.menuIcon }}
              style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
              sx={{ 
                '& .MuiDataGrid-cell': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeader': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-footerContainer': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiTablePagination-root': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  color: '#FFF',
                },
                '& .MuiDataGrid-menu': {
                  color: '#FFF',
                },
                '& .MuiTablePagination-actions': {
                  color: '#FFF', // Color for pagination actions
                },
                '& .MuiTablePagination-select': {
                  color: '#FFF', // Color for pagination select
                },
                '& .MuiTablePagination-selectLabel': {
                  color: '#FFF', // Color for pagination select label
                },
                '& .MuiTablePagination-displayedRows': {
                  color: '#FFF', // Color for displayed rows text
                },
               }}
              slotProps={{
                toolbar:{
                  sx:{
                    color:'#FFF'
                  }
                },
                pagination: {
                  sx: {
                    color: '#FFF',
                  },
                },
                menu: {
                  sx: {
                    color: '#FFF',
                  },
                },
              }}
          />
          </Grid> 
        </Grid>
        {
          check && 
          
          <Grid container={true} spacing={1}>
            <Grid item xs={12}>
              {/*<Edit openEdit={check} setOpenEdit={setCheck} seleItem={seleItem} setOpen3={setOpen3} habitaciones={habitaciones} campamentos={campamentos} wings={wings} setOpenModal={setOpen}/> */}

              <Edit2 openEdit={check} setOpenEdit={setCheck} seleItem={seleItem} setOpen3={setOpen3} habitaciones={filterHabitaciones} campamentos={campamentos} wings={wings} setOpenModal={setOpen} pabellones={pabellones} trabajadores={trabajadores}/>
            </Grid>
          </Grid>
        }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setOpenModalRechazar(true)}
            className={styles.bgColorDelete}
            variant="contained" 
          >
            Rechazar solicitud
          </Button>
        </DialogActions>
    </Dialog>
    <Dialog
                    open={openModalRechazar}
                    onClose={()=>setOpenModalRechazar(false)}
                    PaperProps={{
                      sx: {
                       backgroundColor:'#2d3037',
                       color:'#FFF',
                       borderRadius:'0'
                      }
                    }}
                >
                    <DialogTitle>Estás a punto de rechazar el estado de la solicitud {seleItem?.id}</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Escriba el motivo por el cual rechaza la solicitud.
                    </DialogContentText>
                    <Grid container={true} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                            className={styles.textField}
                            sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                border: 'none',
                                },
                                '&:hover fieldset': {
                                border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                border: 'none',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#FFF',
                            },
                            'padding':'10px'
                            }}
                                margin="dense"
                                id="razon"
                                name="razonRechazo"
                                onChange={(e)=>setFormData({...formData, [e.target.name]: e.target.value})}
                                label="Razón"
                                value={formData.razonRechazo}
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={rechazar}
                            className={styles.bgColorDelete}
                            variant="contained" 
                            disabled={!canSave}
                        >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Rechazar'}
                        </Button>
                        <Button 
                            onClick={()=>setOpenModalRechazar(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined" 
                            >
                                Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
  </React.Fragment>
  )
}

export default CreateReserva