import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Popper, Select, TextField, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { MuiFileInput } from 'mui-file-input';
import { getAllCargos, getInfoById, getInfoByRut, updateUser } from '../../../../redux/features/users/userSlice';
import { getAllRoles } from '../../../../redux/features/roles/rolSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      with:'100%'
    },
  },
};
export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3, empresas }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const [value, setValue] = useState(null);
    const dispatch = useDispatch();
    const roles = useSelector(getAllRoles);

    const cargos = useSelector(getAllCargos);
    const [rolesList, setRolesList] = useState([])

    useEffect(() => {
        setRolesList([])
        if (seleItem) {
            setOpen3(true);
            const getIn = async () => {
                try {
                    const resp = await dispatch(getInfoByRut(seleItem.rut));
                    const resps = await dispatch(getInfoById(seleItem.id));
                    console.log('roles by id',resps);
                    const selectedCompanies = seleItem?.empresas.map(selected => empresas.find(company => company.idEmpresa === selected.empresaId));
                    
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        ...seleItem,
                        empresas: selectedCompanies,
                        roles: resps.payload.data.roles
                    }));
                } catch (error) {
                    // Manejar errores aquí si es necesario
                } finally{
                    setOpen3(false);
                }
            };
            getIn();
        }
        console.log('item', seleItem);
    }, [seleItem]);
    

    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const handleSelectAll = () => {
        setFormData({ ...formData, empresas: empresas });
      };
    const handleChangeEmpresa = (event, newValue) => {
        if (newValue) {
          setFormData({...formData, empresas: newValue});
        } else {
          setFormData({...formData, empresas: null}); // O cualquier valor predeterminado según tu lógica
        }
      };
      const handleDeleteEmpresa = (empresaToDelete) => {
        console.log(formData)
        setFormData((prevFormData) => ({
          ...prevFormData,
          empresas: prevFormData.empresas.filter((empresa) => empresa.idEmpresa !== empresaToDelete.idEmpresa),
        }));
      };    

    const onChangeCargo = async (e,newValue) => {
        if(newValue != null){
          console.log(newValue)
          setFormData({...formData, cargoId: newValue.idCargo})
        } else{
          setFormData({...formData, cargoId: null})
        }
      }
      const handleChangeRole = (event) => {
        const { value } = event.target;
        setRolesList(value);
        setFormData((prevFormData) => ({...prevFormData, roles: value}));
      };
      

    const canSave = [formData?.nombre, formData?.rut, formData?.apellido, formData?.email, formData?.phoneNumber, formData?.cargoId].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateUser(formData));
            console.log(resp)
            if(resp.payload.status == 'success'){
                setOpenEdit(false);
                setOpen3(false);
                setTimeout(()=>{
                    window.location.reload();
                  },[1000])
                return false;
            }

        } catch (error) {
            toast.error('Error al actualizar el usuario.');
            console.error('Error al actualizar el usuario', error.message);
        } finally{
            setAddRequestStatus('idle');
            setOpen3(false);
        }
    }

  return (
    <div>
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} mb={2}>
                <Container >
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2}>
                            <Typography variant="h6" className={styles.textIcon}>
                                <ErrorIcon/>Estás a punto de actualizar el usuario {seleItem?.nombre}
                            </Typography>
                        </Grid>
                        <Grid item  xs={12} sm={6} >
                            <label className={styles.label}>Rut</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='nombre'
                                type="text"
                                disabled
                                value={formData?.rut}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Nombre</label>
                            <TextField
                            variant="outlined"
                            fullWidth
                            name='nombre'
                            type="text"
                            value={formData?.nombre}
                            onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6} >
                            <label className={styles.label}>Apellidos</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name='apellido'
                                    value={formData?.apellido}
                                    type="text"
                                    onChange={(e) => onChange(e)}
                                />
                        </Grid>
                        <Grid item  xs={12} sm={6} >
                            <label className={styles.label}>Correo</label>
                            <TextField
                            variant="outlined"
                            fullWidth
                            name='email'
                            type="text"
                            value={formData?.email}
                            onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6} >
                            <label className={styles.label}>Cargo</label>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                id="sectores-autocomplete"
                                name="cargo"
                                value={cargos?.find(option => option.idCargo === formData?.cargoId) || null}
                                options={cargos ? cargos : [] }
                                PopperComponent={(props) => (
                                <Popper
                                    {...props}
                                    sx={{
                                    height: '200px',
                                    overflowY: 'auto',
                                    }}
                                />
                                )}
                                getOptionLabel={(option) => option.nCargo}
                                onChange={(e, value) => onChangeCargo(e, value)}
                                renderInput={(params) => <TextField {...params} label="Selecciona un cargo"/>}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6} >
                            <label className={styles.label}>Teléfono</label>
                            <TextField
                            variant="outlined"
                            fullWidth
                            name='phoneNumber'
                            value={formData?.phoneNumber}
                            type="text"
                            onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Rol</label>
                                <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-multiple-checkbox-label">Selecciona 1 o más roles</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={formData?.roles ? formData?.roles : rolesList}
                                    onChange={handleChangeRole}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {roles.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        <Checkbox checked={rolesList.indexOf(role) > -1} />
                                        <ListItemText primary={role} />
                                    </MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                        </Grid>

                        { (formData?.roles.includes('Administrador de Contrato CMDIC (ACC)') || formData?.roles.includes('Administrador de Contrato ESED (ACE)')) && 
                        <> 
                        <Grid item  xs={12} sm={12}>
                            <FormControl sx={{ width: '100%' }}> 
                            <Autocomplete
                            disablePortal
                            disableClearable
                            id="sectores-autocomplete-empresa"
                            name="empresa"
                            multiple
                            value={formData?.empresas ? formData?.empresas : []}
                            options={empresas ? empresas : [] }
                            PopperComponent={(props) => (
                                <Popper
                                    {...props}
                                    sx={{
                                        maxHeight: '200px', 
                                        overflowY: 'auto',
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option ? option.razonSocial : 'Empresa no definida'}
                            onChange={(e, value) => handleChangeEmpresa(e, value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Selecciona una empresa"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <Fragment>
                                                {(formData?.empresas || []).filter(option => option).map(option => (
                                                    <Chip
                                                        key={option?.empresaId}
                                                        label={option?.razonSocial}
                                                        onDelete={() => handleDeleteEmpresa(option)}
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                ))}
                                            </Fragment>
                                        ),
                                        endAdornment: (
                                            <Fragment>
                                                <Button onClick={handleSelectAll} variant="outlined">
                                                    Seleccionar todas
                                                </Button>
                                            </Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                                                
                            </FormControl>
                        </Grid>
                    
                        </>
                        } 
                        <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                            <Button 
                                onClick={handleEdit}
                                className={styles.bgColor}
                                variant="contained" 
                                disabled={!canSave}
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpenEdit(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )}
      </Drawer>
    </div>
  );
}