import { Fragment, useState } from 'react';
import styles from './components.module.css';
import Button from '@mui/material/Button';
import { Autocomplete, Backdrop, Checkbox, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, ListItemText, MenuItem, TextField, Typography, } from '@mui/material';
import { addNewUser, getInfoByRut, getInfoByRutAdmUsuarios, liveSearchByRut, liveSearchByRutAdmUsuarios } from '../../../../redux/features/users/userSlice';
import { useDispatch } from 'react-redux';
import GroupIcon from '@mui/icons-material/Group';
import CircleIcon from '@mui/icons-material/Circle';

const generos = [
  {value:'masculino', label:'Masculino'},
  {value:'femenino', label:'Femenino'},
]

const data = {
  nombre: '',
  apellido: '',
  email: '',
  rut: '',
  rol: [],
  cargoId: '',
  empresa: [],
  genero: '',
  telefono: '',
  bodegas: [],
}

const steps = ['Información personal', 'Información laboral'];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      with:'100%'
    },
  },
};

const Create = ({ roles, empresas, open2, setOpen2, bodegas, cargos }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [ruts, setRuts] = useState([])
  const [rolesList, setRolesList] = useState([])
  const [bodegasList, setBodegasList] = useState([])
  const [openBodega, setOpenBodega] = useState(false)
  
  // (formData.rol.includes('Administrador de Contrato ESED (ACE)') && formData?.empresa.length === 0)
  const canSave = (
    formData.rol.includes('Administrador de Contrato ESED (ACE)') 
    || formData.rol.includes('Responsable ingreso de reservas ESED (RIR)')) 
    ? [formData.rol.length != 0,formData.nombre, formData.apellido, formData.rut, formData.email, formData?.empresa.length > 0].every(Boolean) 
    && addRequestStatus === 'idle' 
    : [formData.rol.length != 0, formData.nombre, formData.apellido, formData.rut, formData.email, formData.cargoId].every(Boolean) && addRequestStatus === 'idle';
  
  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  
  const handleSelectAll = () => {
    setFormData({ ...formData, empresa: empresas });
  };

  const handleChangeEmpresa = (event, newValue) => {
    if (newValue) {
      setFormData({...formData, empresa: newValue});
      const rutEmpresas = newValue.map(item => item.idEmpresa);
    } else {
      setFormData({...formData, empresa: null}); 
    }
  };

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRutAdmUsuarios(e.target.value))
      setRuts(resp.payload);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }
  const handleDeleteEmpresa = (empresaToDelete) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      empresa: prevFormData.empresa.filter((empresa) => empresa.idEmpresa !== empresaToDelete.idEmpresa),
    }));
  };

  
  const onChangeRutSelect = async (e,newValue) => {
    console.log(newValue.rut)
    if(newValue != null){
      setFormData({...formData, rut: newValue.rut})
      try {
        setOpen2(true);
        const resp = await dispatch(getInfoByRutAdmUsuarios(newValue.rut));
        const info = resp.payload;
        console.log('info usuario', info)
        setFormData({...formData, nombre: info.nombre, email: info.email == null ? '': info.email, apellido: info.apellido, telefono: info.telefono == null ? '' : info.telefono, rut: newValue.rut ,cargoId: info.idCargo == null ? '': info.idCargo, persona: info});
        
      } catch (error) {
        
      } finally{
        setOpen2(false);
      }
    } else{
      setFormData({...formData, rut: null})
    }
  }
  const onChangeCargo = async (e,newValue) => {
    if(newValue != null){
      console.log(newValue)
      setFormData({...formData, cargoId: newValue.idCargo})
    } else{
      setFormData({...formData, cargoId: null})
    }
  }

  const handleChangeRole = (event) => {
    const { value } = event.target;
    setRolesList(value);
    setFormData((prevFormData) => ({...prevFormData, rol: value}));

    const isSupervisorBodegaSelected = value.includes("Supervisor de Bodega");
    if (isSupervisorBodegaSelected) {
      setOpenBodega(true);
    } else {
      setOpenBodega(false);
      setBodegasList([]);
      setFormData((prevFormData) => ({...prevFormData, bodegas: null}));
    }
  };

  const handleChangeBodega = (e, newValue) => {
    if(newValue != null){
      console.log('newValue',newValue);
      setBodegasList(newValue);
      setFormData({...formData, bodegas: newValue.map(item => item.id) })
    }else{
      setFormData({...formData, bodegas: []})
    }
  };

  const handleSelectAllBodegas = () => {
    setBodegasList(bodegas);
    console.log(bodegasList)
    setFormData({ ...formData, bodegas: bodegas.map(item => item.id) });
  };


  const handleSubmit = async () => {
    console.log(formData)
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        const resp = await dispatch(addNewUser(formData));
        console.log('resp', resp);
        if(resp.payload.includes('Usuario registrado')){
          setFormData(data);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error('Error al crear el usuario', error);
      } finally{
        setAddRequestStatus('idle');
        setOpen2(false);
      }
    }
  }

  return (
    <div>
        <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
            <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder registrar un usuario administrador.</Typography>
            <Button variant='contained' size='large' className={styles.bgColor} onClick={() => { setOpen(true); setOpenBodega(false); setRolesList([]); setBodegasList([]); setFormData(data); }}><CircleIcon className={styles.iconButton}/> Crear usuario</Button>
          </div>
        <Dialog
          open={open}
          maxWidth="sm"
          PaperProps={{
            sx: {
             backgroundColor:'#2d3037',
             color:'#FFF',
             borderRadius:'0'
            }
          }}
          onBackdropClick={() => {
            setOpen(false);
          }}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle style={{ display:'flex', gap:'5px'}}>
            <GroupIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
            <div style={{ display:'flex', flexDirection:'column' }}>
              <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Crear administrador</Typography>
              <span className={styles.subTitle}>Rellena los siguientes campos para crear el usuario.</span>
            </div>
          </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Rut</label>
                <Autocomplete
                    disablePortal
                    disableClearable
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    id="sectores-autocomplete"
                    name="rut"
                    value={formData.persona || null}
                    options={ruts ? ruts : [] }
                    getOptionLabel={(option) => option ? option?.rut + ' ' + option?.nombre : ''}
                    onChange={(e, value) => onChangeRutSelect(e, value)}
                    renderInput={(params) => <TextField {...params} label="Selecciona un rut" onChange={(e) => onChangeRut(e)}/>}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Nombre</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='nombre'
                  value={formData.nombre}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Apellidos</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='apellido'
                  value={formData.apellido}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Correo</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='email'
                  type="text"
                  value={formData.email}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Cargo</label>
                <Autocomplete
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="cargo"
                    value={cargos?.find(option => option.idCargo === formData?.cargoId) || null}
                    options={cargos ? cargos : [] }
                    getOptionLabel={(option) => option.nCargo}
                    onChange={(e, value) => onChangeCargo(e, value)}
                    renderInput={(params) => <TextField {...params} label="Selecciona un cargo"/>}
                />
              </Grid>
              <Grid item  xs={12} sm={6} >
                <label className={styles.label}>Teléfono</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='telefono'
                  value={formData.telefono}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Rol</label>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                        id="demo-multiple-checkbox"
                        select
                        label="Selecciona 1 o más roles"
                        value={rolesList}
                        onChange={handleChangeRole}
                        variant="outlined"
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected) => selected.join(', '),
                          MenuProps: MenuProps,
                        }}
                      >
                        {roles.map((role) => (
                          <MenuItem key={role} value={role}>
                            <Checkbox checked={rolesList.indexOf(role) > -1} />
                            <ListItemText primary={role} />
                          </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
              </Grid>

              {openBodega && (
                <>
                <Grid item xs={12} sm={12}>
                  <label className={styles.label}>Bodegas</label>
                  <Autocomplete
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiSvgIcon-root ': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiChip-label': {
                      color: '#FFF',
                    },
                    '& .MuiButtonBase-root': {
                      backgroundColor: '#0086ae',
                    },
                  }}
                    disablePortal
                    disableClearable
                    multiple
                    id="bodegas-autocomplete"
                    name="bodegas"
                    value={bodegasList}
                    options={Array.isArray(bodegas) && bodegas.length > 0 ? bodegas : []}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value)=> handleChangeBodega(e, value)}
                    renderInput={(params) => <TextField {...params} label="Selecciona bodegas" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" className={styles.bgColorOrange} onClick={handleSelectAllBodegas}>
                    Seleccionar Todas
                  </Button>
                </Grid>
                </>
              )}

              { (formData.rol.includes('Administrador de Contrato CMDIC (ACC)') || 
                formData.rol.includes('Administrador de Contrato ESED (ACE)') ||
                formData.rol.includes('Responsable ingreso de reservas ESED (RIR)') 
              ) && 
                <>
                  <Grid item  xs={12} sm={12}>
                    <FormControl sx={{ width: '100%' }}> 
                        <Autocomplete
                          className={styles.textField}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#FFF',
                            },
                            '& .MuiSvgIcon-root ': {
                              color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#FFF',
                            },
                            '& .MuiChip-label': {
                              color: '#FFF',
                            },
                            maxHeight:'200px',
                            overflowY:'auto',
                            margin:'10px 0'
                          }}
                          disablePortal
                          disableClearable
                          id="sectores-autocomplete-empresa"
                          name="empresa"
                          multiple
                          value={formData?.empresa ? formData?.empresa : []}
                          options={empresas ? empresas : [] }
                          getOptionLabel={(option) => option.razonSocial}
                          onChange={(e, value) => handleChangeEmpresa(e, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona una empresa"
                              style={{ margin:'10px 0' }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Fragment>
                                    {(formData?.empresa && formData?.empresa || []).map((option) => (
                                      <Chip
                                        key={option.idEmpresa}
                                        label={option.razonSocial}
                                        onDelete={() => handleDeleteEmpresa(option)}
                                        color="primary"
                                        variant="outlined"
                                      />
                                    ))}
                                  </Fragment>
                                ),
                                endAdornment: (
                                  <Fragment>
                                    <Button onClick={handleSelectAll} variant="outlined">
                                      Seleccionar todas
                                    </Button>
                                  </Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                    </FormControl>
                </Grid>

                <Grid item  xs={12} sm={12}>
                  <span className={styles.span} style={{ color:'#FFF' }}>El usuario será registrado como administrador de contratos.</span>
                </Grid> 
              
                </>
              }
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}
                >
                  {addRequestStatus === 'loading' ? <CircularProgress color="inherit" size={24} /> : 'Crear'}
                </Button>
            
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
           
           
            </Grid>
          </DialogContent>
        
      </Dialog>
      
    </div>
  )
}

export default Create