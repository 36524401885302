import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosFormDataInstance, createAxiosInstance } from "../../../api/axios";

const BUDGET_URL = '/api/v1/Budget';
const ADD_BUDGET = '/api/v1/Budget';

const axiosInstance = createAxiosInstance();
const axiosFormDataInstance = createAxiosFormDataInstance()

// ACTIONS
export const fetchBudgets = createAsyncThunk("budgets/fetchBudgets", async ()=> {
    try {
        const response = await axiosInstance.get(BUDGET_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const addNewBudget = createAsyncThunk("budgets/createBudget", async (data, { dispatch }) => {
    try {
        console.log("llega", data);
        const formData = new FormData();
        
        formData.append('budget', data.budget);
        formData.append('confirmacion', true);

        const response = await axiosFormDataInstance.post(ADD_BUDGET, formData)
        
        if (response.status == 200) {
            toast.success('Budget creado correctamente.');
            dispatch(fetchBudgets());
            return {data: response.data, status: 'success'};
        }

        return {status: 'error'};
      } catch (e) {
        toast.error('Error al enviar el budget: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})


// idle : no hay operaciones en curso
const initialState = {
    budgets: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const budgetSlice = createSlice({
    name:"budget",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchBudgets.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchBudgets.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.budgets) !== JSON.stringify(action.payload)) { //fetch
                state.budgets = action.payload;
            }
        })
        .addCase(fetchBudgets.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewBudget.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){
                state.status = 'succeeded'
                state.budgets.push(action.payload);
            } else{ 
                state.status = 'failed'    
            }
        })
    }
})

export const getAllBudgets = (state) => state.budget.budgets;
export const getBuddgetsStatus = (state) => state.budget.status;
export const getBudgetsError = (state) => state.budget.error;

export const {} = budgetSlice.actions;
export default budgetSlice.reducer;