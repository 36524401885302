import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización de moment en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment-timezone'; // Importa moment-timezone

const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
};

// Configura el localizador de fechas usando moment.js
const localizer = momentLocalizer(moment);

const Calendario = ({ events }) => {
  // Mapea los eventos en un formato compatible con react-big-calendar
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mappedEvents = events.map(evento => {
    // Ajusta la fecha al objeto Date
    const fecha = moment.tz(evento.fecha, localTimeZone).toDate();
    // Construye el título del evento
    const title = `${evento.nombre} - ${evento.campamento.nombre}`;
    return {
      title,
      start: fecha,
      end: fecha, // Como es un evento de un solo día, la fecha de inicio y fin es la misma
    };
  });

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        fontSize: '12px', // Tamaño de la fuente deseado,
        wordBreak: 'break-word',
      },
    };
  };
  
  return (
    <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={mappedEvents}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week', 'day']}
        defaultView="month"
        messages={messages}
        eventPropGetter={eventStyleGetter} // Aplica los estilos personalizados a los eventos
      />
    </div>
  );
};

export default Calendario;
