import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MuiFileInput } from "mui-file-input";
import {
  getAllCargos,
  getInfoById,
  getInfoByRut,
  updateUser,
} from "../../../../redux/features/users/userSlice";
import { getAllRoles } from "../../../../redux/features/roles/rolSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      with: "100%",
    },
  },
};
export default function Edit({
  openEdit,
  setOpenEdit,
  seleItem,
  setOpen3,
  empresas,
  bodegas,
}) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState(null);
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  const roles = useSelector(getAllRoles);
  const cargos = useSelector(getAllCargos);
  const [rolesList, setRolesList] = useState([]);
  const [bodegasList, setBodegasList] = useState([]);
  const [openBodega, setOpenBodega] = useState(false);
  const [reload, setReload] = useState(false);

  const handleButtonReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    if (seleItem) {
      setOpen3(true);
      const getIn = async () => {
        try {
          const resp = await dispatch(getInfoByRut(seleItem.rut));
          const resps = await dispatch(getInfoById(seleItem.id));
          console.log("roles by id", resps);
          const selectedCompanies = seleItem?.empresas.map((selected) =>
            empresas.find((company) => company.idEmpresa === selected.empresaId)
          );

          setFormData((prevFormData) => ({
            ...prevFormData,
            ...seleItem,
            empresas: selectedCompanies,
            roles: resps.payload.data.roles,
            bodegas: resps.payload.data?.bodegas,
          }));
          setRolesList(resps.payload.data.roles);
          if (resps.payload.data.roles.includes("Supervisor de Bodega")) {
            setBodegasList(resps.payload.data?.bodegas);
            setOpenBodega(true);
          } else {
            setOpenBodega(false);
          }
        } catch (error) {
          // Manejar errores aquí si es necesario
        } finally {
          setOpen3(false);
        }
      };
      getIn();
    }
    console.log("item", seleItem);
  }, [seleItem, reload]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSelectAll = () => {
    setFormData({ ...formData, empresas: empresas });
  };
  const handleChangeEmpresa = (event, newValue) => {
    if (newValue) {
      setFormData({ ...formData, empresas: newValue });
    } else {
      setFormData({ ...formData, empresas: null }); // O cualquier valor predeterminado según tu lógica
    }
  };
  const handleDeleteEmpresa = (empresaToDelete) => {
    console.log(formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      empresas: prevFormData.empresas.filter(
        (empresa) => empresa.idEmpresa !== empresaToDelete.idEmpresa
      ),
    }));
  };

  const onChangeCargo = async (e, newValue) => {
    if (newValue != null) {
      console.log(newValue);
      setFormData({ ...formData, cargoId: newValue.idCargo });
    } else {
      setFormData({ ...formData, cargoId: null });
    }
  };
  const handleChangeRole = (event) => {
    const { value } = event.target;
    setRolesList(value);
    setFormData((prevFormData) => ({ ...prevFormData, roles: value }));

    const isSupervisorBodegaSelected = value.includes("Supervisor de Bodega");
    if (isSupervisorBodegaSelected) {
      setOpenBodega(true);
    } else {
      setOpenBodega(false);
      setBodegasList([]);
      setFormData((prevFormData) => ({ ...prevFormData, bodegas: null }));
    }
  };

  const handleChangeBodega = (e, newValue) => {
    if (newValue != null) {
      console.log("newValue", newValue);
      setBodegasList(newValue);
      setFormData({ ...formData, bodegas: newValue.map((item) => item.id) });
    } else {
      setFormData({ ...formData, bodegas: [] });
    }
  };

  const handleSelectAllBodegas = () => {
    setBodegasList(bodegas);
    console.log(bodegasList);
    setFormData({ ...formData, bodegas: bodegas.map((item) => item.id) });
  };

  const canSave =
    [
      formData?.nombre,
      formData?.rut,
      formData?.apellido,
      formData?.email,
      formData?.phoneNumber,
      formData?.cargoId,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateUser(formData));
      console.log(resp);
      if (resp.payload.status == "success") {
        setOpenEdit(false);
        setOpen3(false);
        setTimeout(() => {
          window.location.reload();
        }, [1000]);
        return false;
      }
    } catch (error) {
      toast.error("Error al actualizar el usuario.");
      console.error("Error al actualizar el usuario", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpen3(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      PaperProps={{
        sx: {
          backgroundColor: "#2d3037",
          color: "#FFF",
          width: { xs: "100%", sm: "75%", md: "50%" },
        },
      }}
    >
      {seleItem && (
        <>
          <DialogTitle>
            <Grid container={true} spacing={1} p={2}>
              <Grid
                item
                xs={12}
                sm={12}
                mb={2}
                style={{ display: "flex", gap: "5px" }}
              >
                <ErrorIcon
                  style={{ fontSize: "3rem" }}
                  className={styles.iconBg}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6" className={styles.textIconEdit}>
                    Actualizar al usuario {seleItem?.nombre}
                  </Typography>
                  <Typography variant="span" className={styles.textIconSpan}>
                    Modifica los campos para actualizar al usuario.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container={true} spacing={1} p={2}>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Rut</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      color: "#FFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFF",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "#FFF",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  style={{ color: "#FFF" }}
                  name="nombre"
                  type="text"
                  // disabled
                  value={formData?.rut}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Nombre</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFF",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="nombre"
                  type="text"
                  value={formData?.nombre}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Apellidos</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFF",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="apellido"
                  value={formData?.apellido}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Correo</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFF",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="email"
                  type="text"
                  value={formData?.email}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Cargo</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFF",
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="cargo"
                  value={
                    cargos?.find(
                      (option) => option.idCargo === formData?.cargoId
                    ) || null
                  }
                  options={cargos ? cargos : []}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        height: "200px",
                        overflowY: "auto",
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.nCargo}
                  onChange={(e, value) => onChangeCargo(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un cargo" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Teléfono</label>
                <TextField
                  className={styles.textField}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFF",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  name="phoneNumber"
                  value={formData?.phoneNumber}
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Rol</label>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Selecciona 1 o más roles
                  </InputLabel>
                  <Select
                    className={styles.textField}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#FFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFF",
                      },
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={rolesList}
                    onChange={handleChangeRole}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox checked={rolesList.indexOf(role) > -1} />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {openBodega && (
                <>
                  <Grid item xs={12} sm={12}>
                    <label className={styles.label}>Bodegas</label>
                    <Autocomplete
                      className={styles.textField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: "#FFF",
                        },
                        "& .MuiSvgIcon-root ": {
                          color: "#FFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFF",
                        },
                        "& .MuiChip-label": {
                          color: "#FFF",
                        },
                        "& .MuiButtonBase-root": {
                          backgroundColor: "#0086ae",
                        },
                      }}
                      disablePortal
                      disableClearable
                      multiple
                      id="bodegas-autocomplete"
                      name="bodegas"
                      value={bodegasList || []}
                      options={
                        Array.isArray(bodegas) && bodegas.length > 0
                          ? bodegas
                          : []
                      }
                      getOptionLabel={(option) => option.nombre}
                      onChange={(e, value) => handleChangeBodega(e, value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecciona bodegas" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      className={styles.bgColorOrange}
                      onClick={handleSelectAllBodegas}
                    >
                      Seleccionar Todas
                    </Button>
                  </Grid>
                </>
              )}

              {(formData?.roles.includes(
                "Administrador de Contrato CMDIC (ACC)"
              ) ||
                formData?.roles.includes(
                  "Administrador de Contrato ESED (ACE)"
                ) ||
                formData?.roles.includes(
                  "Responsable ingreso de reservas ESED (RIR)"
                )) && (
                <>
                  <Grid item xs={12} sm={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        className={styles.textField}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "#FFF",
                          },
                          "& .MuiSvgIcon-root ": {
                            color: "#FFF",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFF",
                          },
                          "& .MuiChip-label": {
                            color: "#FFF",
                          },
                        }}
                        disablePortal
                        disableClearable
                        id="sectores-autocomplete-empresa"
                        name="empresa"
                        multiple
                        value={formData?.empresas ? formData?.empresas : []}
                        // style={{ maxHeight:'300px', overflowY:'auto' }}
                        options={empresas ? empresas : []}
                        getOptionLabel={(option) =>
                          option ? option.razonSocial : "Empresa no definida"
                        }
                        onChange={(e, value) => handleChangeEmpresa(e, value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecciona una empresa"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <Fragment>
                                  {(formData?.empresas || [])
                                    .filter((option) => option)
                                    .map((option) => (
                                      <Chip
                                        key={option?.empresaId}
                                        label={option?.razonSocial}
                                        onDelete={() =>
                                          handleDeleteEmpresa(option)
                                        }
                                        color="primary"
                                        variant="outlined"
                                      />
                                    ))}
                                </Fragment>
                              ),
                              endAdornment: (
                                <Fragment>
                                  <Button
                                    onClick={handleSelectAll}
                                    variant="outlined"
                                  >
                                    Seleccionar todas
                                  </Button>
                                </Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container={true}>
              <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                <Button
                  onClick={handleEdit}
                  className={styles.bgColorEdit}
                  variant="contained"
                  disabled={!canSave}
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Actualizar"
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setOpenEdit(false);
                    handleButtonReload();
                  }}
                  className={styles.bgColorOutlined}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Drawer>
  );
}
