import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, Backdrop, Button, Checkbox, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Popper, TextField, Tooltip, Typography } from '@mui/material';
import { liveSearchByRut, liveSearchByRutAndName } from '../../../../redux/features/users/userSlice';
import { addNewIngreso, fetchEspacios, getAllEspacios } from '../../../../redux/features/guardarropia/guardarropiaSlice';
import MapIcon from '@mui/icons-material/Map';
import { toast } from 'react-toastify';

const data = {
  espacioId: '',
  rut: 0,
  items: [],
}

const elementos = [
  "Lentes",
  "Cascos",
  "Zapatos de seguridad",
  "Máscaras de aire",
  "Máscaras de soldador (Sin vidrios)",
  "Bloqueador solar",
  "Guantes de todo tipo",
  "Protector de oídos",
  "Trajes de agua",
  "Buzo Tíbet (blanco de papel)",
  "Rodilleras de soldador",
  "Traje de soldador"
];


const Create = ({ campamentos, open2, setOpen2, registros, espacios, ingresos }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedEspacio, setSelectedEspacio] = useState(null);
  const [ocupado, setOcupado] = useState(false);
  const [ultimoNumero, setUltimoNumero] = useState(0);
  const [primerCampamento, setPrimerCampamento] = useState(0);
  const [ruts, setRuts] = useState([])
  const [selectedRuts, setSelectedRuts] = useState([])
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [elemtosList, setElemtosList] = useState(elementos);
  const [checkedItems, setCheckedItems] = useState([...elemtosList]);
  const [filterEspacios, setFilterEspacios] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  }, [errorMessage]);

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
        setItems([...items, newItem]);
        setNewItem('');
    }
  }; 

  const handleDeleteItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const handleChange = (itemName) => {
    setCheckedItems(prevState => {
      const isChecked = prevState.includes(itemName);
      if (isChecked) {
        return prevState.filter(item => item !== itemName);
      } else {
        return [...prevState, itemName];
      }
    });
  };
  
  const onChangeOtro = () => {
    if (formData?.otro) {
      const isUnique = !elemtosList.includes(formData?.otro);
      if (!isUnique) {
        setErrorMessage('El items ingresado ya existe.');
      } else {
        setElemtosList(prev => [...prev, formData?.otro]);
        setCheckedItems(prev => [...prev, formData?.otro]); // Only add the new item to checkedItems
      }
    }
  };

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    const filter = espacios.filter(item => item.campamento.id == newValue.id);
    setFilterEspacios(filter);
    if(newValue != null){
      setFormData({...formData, campamento: newValue.id});
    } else{
      setFormData({...formData, campamento: 0})
    }
    
  };
  const handleChangeEspacio = (event, newValue) => {
    setSelectedEspacio(newValue);
    if(newValue != null){
      setFormData({...formData, espacioId: newValue.id});
    } else{
      setFormData({...formData, espacioId: 0})
    }
    
  };
  
  useEffect(() => {
    if(espacios){
      const primerEspacioLibre = espacios.find(espacio => !espacio.ocupado);
      if (primerEspacioLibre) {
        setUltimoNumero(primerEspacioLibre.numero);
        setPrimerCampamento(primerEspacioLibre.campamento);
      }
    }
  }, [espacios])

  useEffect(() => {
    setFormData({...formData, items: checkedItems})
  }, [checkedItems])

  useEffect(() => {
    const newItem = elemtosList[elemtosList.length - 1];
    if (newItem && !checkedItems.includes(newItem)) {
      setCheckedItems(prev => [...prev, newItem]);
    }
  }, [elemtosList]);

  

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      const resp = await dispatch(liveSearchByRut(e.target.value))
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }
  const onChangeRutSelect = async (e,newValue) => {
    
    if(newValue != null){

      const isIn = ingresos.find(item => item.trabajador.rut === newValue.rut)
      if (isIn !== undefined) {
        toast.error('El trabajador ya tiene asignado un espacio.');
        setFormData(data);
        return;
      }
      setFormData({...formData, rut: newValue})
      setSelectedRuts({...selectedRuts, newValue});
    } else{
      setFormData({...formData, rut: null})
    }
  }
  

  const canSave = [formData.rut, formData.items].every(Boolean) && addRequestStatus === 'idle';
  

  const handleSubmit = async () => {
    
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        console.log('envio: ',formData)
        // return;
        let resp = await dispatch(addNewIngreso(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
          setItems([])
          setCheckedItems([])
          dispatch(fetchEspacios());
        }
      } catch (error) {
        console.error('Error al registrar la solicitud de ingreso al almacenamiento', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }

  const validateInput = () => {
    if (formData?.otro) {
      const words = formData?.otro.split(' ');
      const isValid = words.every(word => word.length <= 24);
      if (!isValid) {
        setErrorMessage('Ninguna palabra debe tener más de 24 caracteres.');
      }
      return isValid;
    }
  };

  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }} className={styles.textMobile}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder registrar asignar un espacio a un trabajador.</Typography>
        <Button variant='contanied' size='large' disabled={campamentos.length == 0} className={styles.bgColor} onClick={()=> {setOpen(true); setElemtosList(elementos); setCheckedItems(elementos); setFormData({...formData, otro: ''});}}>Asignar espacio</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <MapIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Asignar espacio de almacenamiento</Typography>
            <span className={styles.subTitle}>Selecciona un trabajador y los items que guardará, el sistema lo asignará a un espacio disponible.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
           {/* <Grid item  xs={12} sm={6} >
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                // defaultValue={primerCampamento}
                name="idCampamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Busca y selecciona un campamento" />}
              /> 
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>N° Espacio de almacenamiento</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#FFF',
                      },
                    }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  options={filterEspacios}
                  name="idEspacio"
                  getOptionLabel={(option) => option.numero}
                  onChange={(e, value)=> handleChangeEspacio(e, value)}
                  renderInput={(params) => <TextField {...params} label="Busca y selecciona un espacio" />}
                /> 
            </Grid>
             */}
            <Grid item  xs={12} sm={12} >
              <label className={styles.label}>Trabajador</label>
              <Autocomplete
                  className={styles.textField}
                  sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#FFF',
                      },
                    }}
                disablePortal
                disableClearable
                id="sectores-autocomplete"
                name="rut"
                value={formData?.rut || null}
                options={ruts ? ruts : [] }
                getOptionLabel={(option) => option.rut + ' ' + option.nombre}
                onChange={(e, value) => onChangeRutSelect(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un rut" onChange={(e) => onChangeRut(e)}/>}
              />
            </Grid>
            <Grid container style={{ margin: '10px' }}>
              <Grid xs={12} md={12}>
                <label className={styles.label} style={{ display:'block' }}>Ingreso de items</label>
              </Grid>
              <Grid item xs={12} md={6}>
                {elemtosList.slice(0, elemtosList.length / 2).map((item, index) => (
                  <Grid item xs={12}>
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={checkedItems.includes(item)}
                          onChange={() => handleChange(item)}
                          color="primary"
                        />
                      }
                      label={item}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                {elemtosList.slice(elemtosList.length / 2).map((item, index) => (
                  <Grid item xs={12}>
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={checkedItems.includes(item)}
                          onChange={() => handleChange(item)}
                          color="primary"
                        />
                      }
                      label={item}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <label className={styles.label} style={{ display:'block' }}>Otro</label>
            </Grid>
            <Grid item xs={12} md={8}>
              <Tooltip title={errorMessage || ''} open={Boolean(errorMessage)} placement="top">
                <TextField
                  variant="outlined"
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                  fullWidth
                  name='otro'
                  type="text"
                  placeholder='Ingreso otro item'
                  onChange={(e) => onChange(e)}
                  onKeyPress={(e) => {
                    const inputValue = e.target.value + e.key;
                    const words = inputValue.split(' ');
                    const isValid = words.every(word => word.length <= 24);
                    if (!isValid) {
                      e.preventDefault();
                      setErrorMessage('Ninguna palabra debe tener más de 24 caracteres.');
                    }
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button 
                onClick={() => {
                  if (validateInput()) {
                    onChangeOtro();
                  }
                }}
                className={[styles.bgColorOrange, styles.fullwidth]}
                variant="contained" 
                style={{ marginTop:'7px' }}
              >
                Agregar a la lista
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColor}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create