import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogContentText,
  Stack,
  Alert,
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import TableAsignaciones from "./TableAsignaciones";
import { toast } from "react-toastify";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import TableNoAsignaciones from "./TableNoAsignaciones";
import TableHabitaciones from "./TableHabitaciones";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import {
  asignarHabitacionesRecepcionista,
  generarPropuesta,
  getPropuesta,
} from "../../redux/features/reservas/reservaSlice";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const data = [
  {
    id: 1,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
        campamentoId: 26,
      },
      standard: [
        {
          id: 57,
          nombre: "INDIVIDUAL",
          descripcion: "",
          campamento: null,
          campamentoId: 26,
        },
      ],
    },
    habitacion:{
        id: 23066,
        nroCamarotes: 0,
        nroCamas: 2,
        numero: 26,
        piso:1,
        numeroTotalCamas: 2,
        wing: {
          id: 14,
          nombre: "A",
          descripcion: "",
          numeroPisos: 3,
          numeroHabitaciones: 0,
          generoHuesped: "Unisex",
          campamento: {
            id: 26,
            nombre: "Pioneros",
            direccion: "santiago",
            descripcion: null,
            plano: null,
            latitud: "-33.3660954",
            longitud: "-70.7514249"
          },
          campamentoId: 26,
          standard: [
            {
              id: 57,
              nombre: "INDIVIDUAL",
              descripcion: "",
              campamento: null,
              campamentoId: 26
            }
          ]
        },
        campamento: {
          id: 26,
          nombre: "Pioneros",
          direccion: "santiago"
        },
        campamentoId: 26,
        generoHuesped: "Solo hombres",
        standard: {
          id: 56,
          nombre: "COMPARTIDO",
          campamentoId: 26,
          descripcion: ""
        },
        tieneOxigeno: false,
        tipoCama: "Una plaza",
        tipoHuesped: "Solo hombres",
        trabajadores: [],
        pabellon: null
      },
    trabajador: {
      apellido: "PEREZ FIGUEROA",
      cargo: "OPERADOR SULFUROS D",
      cargoId: "10001204",
      email: "gowoso3991@kinsef.com",
      id: "de095fe0-7ce1-48f0-b01b-538ec81615cb",
      nombre: "ESTEBAN ALEXANDER",
      phoneNumber: "34243242",
      roles: ["Administrador de Contrato ESED (ACE)"],
      rut: "87036199-2",
      jornada: "Diurna",
      turno: "4x3",
      suspendido: false,
    },
    permanente: true,
  },
  {
    id: 2,
    wing: {
      id: 14,
      nombre: "A",
      descripcion: "",
      numeroPisos: 3,
      numeroHabitaciones: 0,
      generoHuesped: "Unisex",
      campamento: {
        id: 26,
        nombre: "Pioneros",
        direccion: "santiago",
        descripcion: null,
        plano: null,
        latitud: "-33.3660954",
        longitud: "-70.7514249",
        campamentoId: 26,
      },
      standard: [
        {
          id: 57,
          nombre: "INDIVIDUAL",
          descripcion: "",
          campamento: null,
          campamentoId: 26,
        },
      ],
    },
    habitacion:{
        id: 23066,
        nroCamarotes: 0,
        nroCamas: 2,
        numero: 26,
        piso:1,
        numeroTotalCamas: 2,
        wing: {
          id: 14,
          nombre: "A",
          descripcion: "",
          numeroPisos: 3,
          numeroHabitaciones: 0,
          generoHuesped: "Unisex",
          campamento: {
            id: 26,
            nombre: "Pioneros",
            direccion: "santiago",
            descripcion: null,
            plano: null,
            latitud: "-33.3660954",
            longitud: "-70.7514249"
          },
          campamentoId: 26,
          standard: [
            {
              id: 57,
              nombre: "INDIVIDUAL",
              descripcion: "",
              campamento: null,
              campamentoId: 26
            }
          ]
        },
        campamento: {
          id: 26,
          nombre: "Pioneros",
          direccion: "santiago"
        },
        campamentoId: 26,
        generoHuesped: "Solo hombres",
        standard: {
          id: 56,
          nombre: "COMPARTIDO",
          campamentoId: 26,
          descripcion: ""
        },
        tieneOxigeno: false,
        tipoCama: "Una plaza",
        tipoHuesped: "Solo hombres",
        trabajadores: [],
        pabellon: null
      },
    trabajador: {
      apellido: "ANTONIO SANCHEZ",
      cargo: "OPERADOR SULFUROS D",
      cargoId: "10001204",
      email: "sadas932@efkins.com",
      id: "gr0dse0-7ce1-48f0-b01b-538ec81615cc",
      nombre: "CARLOS",
      phoneNumber: "3124565",
      roles: ["Administrador de Contrato ESED (ACE)"],
      rut: "20061993-5",
      jornada: "Nocturno",
      turno: "4x3",
      suspendido: false,
    },
    permanente: true,
  },
];

export default function Edit2({
  openEdit,
  setOpenEdit,
  seleItem,
  campamentos,
  wings,
  pabellones,
  habitaciones,
  trabajadores,
  setOpenModal,
}) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState({
    asignaciones: []
  });

  const dispatch = useDispatch();
  const [asignaciones, setAsignaciones] = useState(data); // REMOVER ESTA "DATA" POR UNA VERDADERA
  const [selectedRut, setSelectedRut] = useState("");
  const [selectedCampamento, setSelectedCampamento] = useState("");
  const [selectedHabitacion, setSelectedHabitacion] = useState(null);
  const [selectedPabellon, setSelectedPabellon] = useState();
  const [selectedPiso, setSelectedPiso] = useState(0);
  const [selectedWing, setSelectedWing] = useState();
  const [pabellonWing, setPabellonWing] = useState(0);
  const [habitacionesList, setHabitacionesList] = useState([]);
  const [noAsignados, setNoAsignados] = useState([]);

  const [errores, setErrores] = useState([]);

  const [value, setValue] = useState("1");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loaderList, setLoaderList] = useState(false);
  const [filterHabitaciones, setfilterHabitaciones] = useState([]);
  const [habitacionSelectedId, setHabitacionSelectedId] = useState({});
  const [infoHabitaciones, setInfoHabitaciones] = useState({});
  const [permanente, setPermanente] = useState(false);

  useEffect(() => {
    if (seleItem) {
      setFormData(seleItem);
      console.log("seleItem", seleItem);
    }
  }, [seleItem]);

  useEffect(() => {
    if (Array.isArray(habitaciones) && habitaciones.length > 0 && Array.isArray(asignaciones) && asignaciones.length > 0) {
      setFormData((prev) => ({ ...prev, asignaciones: asignaciones }));
      console.log("asignaciones cambio", asignaciones);
  
      // Inicializa un mapa para recoger todas las asignaciones por habitación
      const asignacionesMap = asignaciones.reduce((acc, asignacion) => {
        const { habitacion, trabajador } = asignacion;
        const habitacionId = habitacion.id;
        if (!acc[habitacionId]) {
          acc[habitacionId] = [];
        }
        acc[habitacionId].push(trabajador.rut);
        return acc;
      }, {});
  
      // Mapea las habitaciones y asigna todas las RUTs encontrados en el mapa
      const mapped = habitaciones.map(item => {
        const habitacionId = item.id;
        const asignado = asignacionesMap[habitacionId] || [];
        return {
          ...item,
          asignado: asignado
        };
      });
  
      setInfoHabitaciones(mapped);
      setfilterHabitaciones(mapped);
    }
  }, [asignaciones, habitaciones]);
  
  
  useEffect(() => {
    console.log("formData cambio", formData);
  }, [formData]);
  useEffect(() => {
    console.log("selectedRut cambio", selectedRut);
  }, [selectedRut]);
  useEffect(() => {
    console.log("filterHabitaciones: ", filterHabitaciones);
  }, [filterHabitaciones]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (e) => {
    setSelectedRut((prev) => ({ ...prev, piso: e.target.value }));
    setSelectedPiso(e.target.value);
  };

  const handleChangeWing = (event, newValue) => {
    if (newValue != null) {
      setSelectedPabellon(null);
      setSelectedWing(newValue);
      console.log('newValue wing', newValue);
      setSelectedRut({
        ...selectedRut,
        wing: { id: newValue.id, nombre: newValue.nombre },
        pabellon: null,
      });

      const filterHabitacion = filterHabitaciones.filter(
        (item) => item?.wing && item.wing.id == newValue.id
      );
      console.log('habitación:', habitaciones);
      console.log('filterHabitaciones:', filterHabitacion);

      setHabitacionesList(filterHabitacion);
      setSelectedHabitacion(null);
      setSelectedPiso(null);
      setInfoHabitaciones(filterHabitacion);
    } else {
      //   setFormData({...formData, wing: 0})
    }
  };

  const handleCheckboxChange = (event) => {
    setSelectedRut({
        ...selectedRut,
        permanente: event.target.checked,
      });
  };

  const handleChangeHabitacion = (e) => {
    const { value } = e.target;
    console.log("habitacion seleccionadaaa------>", value);
    if (value != null) {
      const selectedHab = habitacionesList.find((hab) => hab.numero === value);
      setSelectedHabitacion(selectedHab);
      setSelectedRut((prevRut) => ({
        ...prevRut,
        habitacion: {
          id: selectedHab?.id || null,
          numero: selectedHab?.numero || null,
          piso: selectedHab?.piso || null,
          pabellon: selectedPabellon,
          wing: selectedWing,
        },
      }));
      setSelectedPiso(selectedHab.piso || 0);
    } else {
      setSelectedHabitacion(null);
      setSelectedRut((prevRut) => ({
        ...prevRut,
        habitacion: {
          id: null,
          numero: null,
          piso: null,
          pabellon: null,
          wing: null,
        },
      }));
    }
  };

  const canSave =
    [
        // selectedRut?.wing, selectedRut?.habitacion, 
        // selectedRut?.rut
    ].every(
      Boolean
    ) && addRequestStatus === "idle";

  const actualizarRegistro = () => {
    
    if (!selectedRut.trabajador.rut) {
      toast.error(
        'Selecciona un trabajador de la lista de "asignaciones" o de la lista "no asignados"'
      );
      return;
    }

    console.log('trabajador seleccionado: ', selectedRut.trabajador);
    console.log('trabajadores: ', trabajadores);
    console.log('asignaciones: ', asignaciones);

    const inAsignacion = asignaciones.findIndex(item => item.trabajador.rut === selectedRut.trabajador.rut);
    const trab = trabajadores.find(item => item.rut === selectedRut.trabajador.rut);

    console.log('trabajador encontrado: ', trab)
    if (!trab) {
      console.error("No se encontró el trabajador con el RUT seleccionado.");
      return;
    }

    // Verificar si la habitación ya tiene un trabajador asignado
    const habitacionSeleccionada = filterHabitaciones.find(item => item.id === selectedRut.habitacion.id);
    const habitacionSeleccionadaIndex = filterHabitaciones.findIndex(item => item.id === selectedRut.habitacion.id);
    console.log('filtHabitacion:',habitacionSeleccionada);

    // const verificarMismatches = (mismaHabitacion, trab) => {
    //   const generoMismatch =
    //     (mismaHabitacion.tipoHuesped === "Solo hombres" &&
    //       (trab.genero === "Femenino" ||
    //         trab.genero === "Mujer")) ||
    //     (mismaHabitacion.tipoHuesped === "Solo mujeres" &&
    //       (trab.genero === "Hombre" ||
    //         trab.genero === "Masculino")) ||
    //     (mismaHabitacion.tipoHuesped === "Unisex" &&
    //       (trab.genero === "Femenino" ||
    //         trab.genero === "Mujer"));

    //   const jornadaMismatch = !trab.jornada

    //   // Verifica si hay una discrepancia en los cargos entre trabajadorAsignacion y trab
    //   const cargoMismatch = (trab.tipoCargo?.toLowerCase() ?? "");

    //   // Verifica si el cargo está ausente en cualquiera de los trabajadores
    //   const cargoAusente =
    //     !trab.tipoCargo?.toLowerCase() ||
    //     !trab.tipoCargo?.toLowerCase();

    //   const empresaMismatch =
    //     trabajadorAsignacion &&
    //     (trab.empresaId ?? "") !== (trab.empresaId ?? "");
    //   const empresaAusente = !trab.empresaId || !trab.empresaId;

    //   if (generoMismatch) {
    //     toast.error(
    //       "El género del trabajador seleccionado no coincide con el género del trabajador ya asignado a la misma habitación."
    //     );
    //     return true;
    //   }
    //   if (jornadaMismatch) {
    //     toast.warning(
    //       "Las jornadas coinciden para los trabajadores de la habitación N° " +
    //         selectedRut.habitacion.numero
    //     );
    //   }
    //   if (cargoAusente) {
    //     toast.warning("Uno o ambos trabajadores no tienen un cargo asociado.");
    //   } else if (cargoMismatch) {
    //     toast.warning(
    //       "El cargo del trabajador seleccionado no coincide con el cargo del trabajador ya asignado a la misma habitación."
    //     );
    //   }
    //   if (empresaAusente) {
    //     toast.warning(
    //       "Uno o ambos trabajadores no tienen una empresa asociada."
    //     );
    //   } else if (empresaMismatch) {
    //     toast.warning(
    //       "El trabajador seleccionado no pertenece a la misma empresa del trabajador ya asignado a la misma habitación."
    //     );
    //     return true;
    //   }
    //   return false;
    // };


    if (
      trab.genero.toLowerCase() === "mujer" &&
      habitacionSeleccionada.tipoHuesped != "Solo mujeres"
    ) {
      toast.error("Error géneros no coinciden.");
      return;
    } else if (
      trab.genero.toLowerCase() === "hombre" &&
      habitacionSeleccionada.tipoHuesped === "Solo mujeres"
    ) {
      toast.error("Error géneros no coinciden.");
      return;
    }

    if (inAsignacion !== -1) { // Si ya está en la tabla de asignados
      console.log("Actualizar el registro existente");
    
      const mismaHabitacion = asignaciones.find(item => item.habitacion.id === habitacionSeleccionada.id);
      const asignacionIndex = asignaciones.findIndex(item => item.trabajador.rut === trab.rut);
      
      if (mismaHabitacion) {
        // Verificar si el trabajador ya está asignado a la misma habitación
        if (habitacionSeleccionada.asignado.includes(trab.rut)) {
          toast.error('Trabajador ya asignado a la habitación.');
          return;
        }

        // Crear una copia de asignaciones para mantener la inmutabilidad
        const newAsignaciones = [...asignaciones];
        newAsignaciones[asignacionIndex] = {
          ...newAsignaciones[asignacionIndex],
          habitacion: {
            ...habitacionSeleccionada,
            asignado: [...(habitacionSeleccionada.asignado || []), trab.rut]
          }
        };
    
        // Encontrar el índice de la habitación en filterHabitaciones
        const filtHabitacionIndex = filterHabitaciones.findIndex(item => item.id === mismaHabitacion.habitacion.id);
    
        // Crear una copia de filterHabitaciones para mantener la inmutabilidad
        const newFilterHabitaciones = [...filterHabitaciones];
    
        // Encontrar la habitación actual del trabajador y remover el RUT
        const trabajadorEnHabitacionActualIndex = newFilterHabitaciones.findIndex(item => item.asignado && item.asignado.includes(trab.rut));
        if (trabajadorEnHabitacionActualIndex !== -1) {
          newFilterHabitaciones[trabajadorEnHabitacionActualIndex] = {
            ...newFilterHabitaciones[trabajadorEnHabitacionActualIndex],
            asignado: newFilterHabitaciones[trabajadorEnHabitacionActualIndex].asignado.filter(item => item !== trab.rut)
          };
        }
    
        // Actualizar la propiedad asignado de la nueva habitación
        newFilterHabitaciones[filtHabitacionIndex] = {
          ...newFilterHabitaciones[filtHabitacionIndex],
          asignado: [...(newFilterHabitaciones[filtHabitacionIndex].asignado || []), trab.rut]
        };
        
        // Actualizar el estado
        setAsignaciones(newAsignaciones);
        setfilterHabitaciones(newFilterHabitaciones);
        setInfoHabitaciones(newFilterHabitaciones);
    
      } else { // En caso de que la habitación seleccionada no esté en asignaciones
        const asignacionIndex = asignaciones.findIndex(item => item.trabajador.rut === trab.rut);
    
        // Crear una copia de asignaciones para mantener la inmutabilidad
        const newAsignaciones = [...asignaciones];
        newAsignaciones[asignacionIndex] = {
          ...newAsignaciones[asignacionIndex],
          habitacion: {
            ...habitacionSeleccionada,
            asignado: [...(habitacionSeleccionada.asignado || []), trab.rut]
          }
        };
    
        // Crear una copia de filterHabitaciones para mantener la inmutabilidad
        const newFilterHabitaciones = [...filterHabitaciones];
    
        // Encontrar la habitación actual del trabajador y remover el RUT
        const trabajadorEnHabitacionActualIndex = newFilterHabitaciones.findIndex(item => item.asignado && item.asignado.includes(trab.rut));
        if (trabajadorEnHabitacionActualIndex !== -1) {
          newFilterHabitaciones[trabajadorEnHabitacionActualIndex] = {
            ...newFilterHabitaciones[trabajadorEnHabitacionActualIndex],
            asignado: newFilterHabitaciones[trabajadorEnHabitacionActualIndex].asignado.filter(item => item !== trab.rut)
          };
        }
    
        // Actualizar la propiedad asignado de la nueva habitación
        const habitacionSeleccionadaIndex = newFilterHabitaciones.findIndex(item => item.id === habitacionSeleccionada.id);
        newFilterHabitaciones[habitacionSeleccionadaIndex] = {
          ...newFilterHabitaciones[habitacionSeleccionadaIndex],
          asignado: [...(newFilterHabitaciones[habitacionSeleccionadaIndex].asignado || []), trab.rut]
        };
    
        // Actualizar los estados
        setAsignaciones(newAsignaciones);
        setfilterHabitaciones(newFilterHabitaciones);
        setInfoHabitaciones(newFilterHabitaciones);
      }
      return;
    }
    
    
  };

  const autoasignar = () => {
    if (!selectedRut) {
      toast.error(
        "Selecciona pabellón o wing del formularío al costado de la tabla de asignaciones."
      );
      return;
    }
    if (noAsignados.length === 0) {
      toast.error("No hay trabajadores por asignar.");
      return;
    }

    console.log("habitaciones", habitaciones);
    console.log("FILTERhabitaciones", filterHabitaciones);

    // Determinar el criterio de filtrado
    let habitacionesFiltradas = [];
    if (selectedRut.pabellon) {
      // Filtrar habitaciones por pabellonId
      habitacionesFiltradas =
        filterHabitaciones && Array.isArray(filterHabitaciones)
          ? filterHabitaciones.filter(
              (item) =>
                item.pabellon?.id === selectedRut.pabellon.id && !item.asignado
            )
          : [];
    } else if (selectedRut.wing) {
      // Filtrar habitaciones por wingId
      habitacionesFiltradas =
        filterHabitaciones && Array.isArray(filterHabitaciones)
          ? filterHabitaciones.filter(
              (item) => item.wing?.id === selectedRut.wing.id && !item.asignado
            )
          : [];
    }
    console.log("habitacionesFiltradas", habitacionesFiltradas);

    // Separar trabajadores por género
    const trabajadoresHombres = noAsignados.filter(
      (trabajador) => trabajador.trabajador.genero === "Hombre"
    );
    const trabajadoresMujeres = noAsignados.filter(
      (trabajador) => trabajador.trabajador.genero === "Mujer"
    );
    const trabajadoresIndefinidos = noAsignados.filter(
      (trabajador) => !trabajador.trabajador.genero
    );

    // Combinar trabajadores indefinidos con los hombres
    const trabajadoresHombresCompletos = [
      ...trabajadoresHombres,
      ...trabajadoresIndefinidos,
    ];

    const asignacionesAutomaticas = [];
    let trabajadoresNoAsignados = [...trabajadoresHombresCompletos];
    let indexHabitacion = 0;

    // Asignar trabajadores masculinos a habitaciones disponibles de tipo "Solo hombres" o "Unisex"
    while (
      trabajadoresNoAsignados.length > 0 &&
      indexHabitacion < habitacionesFiltradas.length
    ) {
      const habitacion = habitacionesFiltradas[indexHabitacion];
      if (
        habitacion.tipoHuesped === "Solo hombres" ||
        habitacion.tipoHuesped === "Unisex"
      ) {
        const trabajador = trabajadoresNoAsignados.shift(); // Remover y obtener el primer trabajador
        asignacionesAutomaticas.push({
          rut: trabajador.trabajador.rut ?? "",
          nombre: trabajador.trabajador.nombre
            ? trabajador.trabajador.nombre
            : trabajador.trabajador.nombres ?? "",
          campamento:
            habitacion.pabellon?.campamento ??
            habitacion.wing?.campamento ??
            "",
          pabellon: habitacion.pabellon ?? "",
          wing: habitacion.wing ?? "",
          habitacion: habitacion ?? "",
          piso: habitacion.piso ?? "",
          trabajador: trabajador.trabajador ?? null,
          jornada: trabajador.trabajador?.jornada ?? "",
          turno: trabajador.turno ?? null,
        });
      }
      indexHabitacion++;
    }

    // Filtrar habitaciones para mujeres de tipo "Solo mujeres"
    const habitacionesSoloMujeres = habitacionesFiltradas.filter(
      (habitacion) => habitacion.tipoHuesped === "Solo mujeres"
    );

    // Asignar las trabajadoras mujeres a habitaciones de tipo "Solo mujeres"
    trabajadoresNoAsignados = trabajadoresMujeres;
    indexHabitacion = 0; // Reiniciar el índice de habitación

    while (
      trabajadoresNoAsignados.length > 0 &&
      indexHabitacion < habitacionesSoloMujeres.length
    ) {
      const habitacion = habitacionesSoloMujeres[indexHabitacion];
      const trabajador = trabajadoresNoAsignados.shift(); // Remover y obtener el primer trabajador
      asignacionesAutomaticas.push({
        rut: trabajador.trabajador.rut ?? "",
        nombre: trabajador.trabajador.nombre
          ? trabajador.trabajador.nombre
          : trabajador.trabajador.nombres ?? "",
        campamento:
          habitacion.pabellon?.campamento ?? habitacion.wing?.campamento ?? "",
        pabellon: habitacion.pabellon ?? "",
        wing: habitacion.wing ?? "",
        habitacion: habitacion ?? "",
        piso: habitacion.piso ?? "",
        trabajador: trabajador.trabajador ?? null,
        jornada: trabajador.trabajador?.jornada ?? "",
        turno: trabajador.turno ?? null,
      });
      indexHabitacion++;
    }

    // Actualiza las habitaciones asignadas
    const updatedHabitaciones =
      filterHabitaciones && Array.isArray(filterHabitaciones)
        ? filterHabitaciones.map((habitacion) => {
            const asignacionesParaHabitacion = asignacionesAutomaticas.filter(
              (asignacion) => asignacion.habitacion.id === habitacion.id
            );
            return asignacionesParaHabitacion.length > 0
              ? {
                  ...habitacion,
                  asignado: asignacionesParaHabitacion
                    .map((asignacion) => asignacion.rut)
                    .join(", "),
                }
              : habitacion;
          })
        : [];

    // Actualiza el estado solo una vez al final
    setNoAsignados(trabajadoresNoAsignados);
    setAsignaciones((prevAsignaciones) => [
      ...prevAsignaciones,
      ...asignacionesAutomaticas,
    ]);
    setfilterHabitaciones(updatedHabitaciones);

    const filterHabitacio = updatedHabitaciones.filter((item) => {
      // Si existe el pabellonId, comparamos con el pabellonId del selectedRut
      if (item.pabellon && selectedRut.pabellon) {
        return item.pabellon.id === selectedRut.pabellon.id;
      }
      // Si existe el wingId, comparamos con el wingId del selectedRut
      if (item.wing && selectedRut.wing) {
        return item.wing.id === selectedRut.wing.id;
      }
      return false;
    });

    setInfoHabitaciones(filterHabitacio);
    console.log("Asignaciones finalizadas");
  };

  const actualizarRegistroAutomatico = (idHabitacion) => {

    const mismaHabitacion = asignaciones.find(item => item.habitacion.id === idHabitacion); //si está en la tabla de asignaciones
    const habi = filterHabitaciones.find(item => item.id === idHabitacion); 

    console.log("misma habitación", mismaHabitacion);
    console.log("habitación", habi);

    const firstTrabajador = trabajadores.find(item => item.rut === habi.asignado);
    console.log("firstTrabajador", firstTrabajador);

    const index = asignaciones.findIndex(
      (item) => item.trabajador.rut === firstTrabajador.rut
    );                      //para ver si exite un registro mio participe en walmart
    const trab = trabajadores.find(
      (item) => item.rut === firstTrabajador.rut
    );

    console.log("trabajador seleceted rut", trab);
    // Verificar si la habitación ya tiene un trabajador asignado

    const verificarMismatches = (trabajadorAsignacion, trab) => {
      const generoMismatch =
        (trabajadorAsignacion.genero === "Hombre" &&
          (trab.genero === "Femenino" ||
            trab.genero === "Mujer")) ||
        (trabajadorAsignacion.genero === "Masculino" &&
          (trab.genero === "Femenino" ||
            trab.genero === "Mujer")) ||
        (trabajadorAsignacion.genero === "Femenino" &&
          (trab.genero === "Hombre" ||
            trab.genero === "Masculino")) ||
        (trabajadorAsignacion.genero === "Mujer" &&
          (trab.genero === "Hombre" ||
            trab.genero === "Masculino"));

      const jornadaMismatch =
        trabajadorAsignacion &&
        trabajadorAsignacion.turno.tipoJornada === trab.turno.tipoJornada;

      const cargoMismatch =
        trabajadorAsignacion &&
        (trabajadorAsignacion.cargo ?? "") !==
          (trab.cargo ?? "");
      const cargoAusente =
        !trabajadorAsignacion.cargo || !trab.cargo;

      const empresaMismatch =
        trabajadorAsignacion &&
        (trabajadorAsignacion.empresaId ?? "") !== (trab.empresaId ?? "");
      const empresaAusente = !trabajadorAsignacion.empresaId || !trab.empresaId;

      if (generoMismatch) {
        toast.error(
          "El género del trabajador seleccionado no coincide con el género del trabajador ya asignado a la misma habitación."
        );
        return true;
      }
      if (jornadaMismatch) {
        toast.warning(
          "Las jornadas coinciden para los trabajadores de la habitación N° " +
            selectedRut.habitacion.numero
        );
      }
      if (cargoAusente) {
        toast.warning("Uno o ambos trabajadores no tienen un cargo asociado.");
      } else if (cargoMismatch) {
        toast.warning(
          "El cargo del trabajador seleccionado no coincide con el cargo del trabajador ya asignado a la misma habitación."
        );
      }
      if (empresaAusente) {
        toast.warning(
          "Uno o ambos trabajadores no tienen una empresa asociada."
        );
      } else if (empresaMismatch) {
        toast.warning(
          "El trabajador seleccionado no pertenece a la misma empresa del trabajador ya asignado a la misma habitación."
        );
        return true;
      }
      return false;
    };

    if (index !== -1) {
      // Actualizar el registro existente
      if (mismaHabitacion) {
        console.log("eliminar el registro 1");
        const trabajadorAsignacion = trabajadores.find(
          (item) => item.rut === habi.asignado
        );
        if (
          (trabajadorAsignacion.genero === "Hombre" &&
            habi.tipoHuesped !== "Solo hombres" &&
            habi.tipoHuesped !== "Unisex") ||
          (trabajadorAsignacion.trabajador.genero === "Mujer" &&
            habi.tipoHuesped !== "Solo mujeres")
        ) {
          toast.error("Género no coincide con el género permitido.");
          return;
        }

        console.log("trabajador ya en la habitacion", trabajadorAsignacion);
        if (verificarMismatches(trabajadorAsignacion, trab)) {
          return;
        }
      }
      const asignacionesActualizadas = [...asignaciones];
      asignacionesActualizadas[index] = selectedRut;
      setAsignaciones(asignacionesActualizadas);
    } else {
      // Agregar nuevo registro
      if (mismaHabitacion) {
        console.log("eliminar el registro 2");
        const trabajadorAsignacion = trabajadores.find(
          (item) => item.rut === habi.asignado
        );

        if (
          (trabajadorAsignacion.genero === "Hombre" &&
            habi.tipoHuesped !== "Solo hombres" &&
            habi.tipoHuesped !== "Unisex") ||
          (trabajadorAsignacion.genero === "Mujer" &&
            habi.tipoHuesped !== "Solo mujeres")
        ) {
          toast.error("Género no coincide con el género permitido.");
          return;
        }

        console.log("trabajador ya en la habitacion", trabajadorAsignacion);
        if (verificarMismatches(trabajadorAsignacion, trab)) {
          return;
        }
      } else {
        console.log("eliminar el registro 3");
        const habitacion = filterHabitaciones.find(
          (item) => item.id === idHabitacion
        );
        console.log("habitacion::", habitacion);

        if (
          (trab.genero === "Hombre" &&
            habitacion.tipoHuesped !== "Solo hombres" &&
            habitacion.tipoHuesped !== "Unisex") ||
          (trab.genero === "Mujer" &&
            habitacion.tipoHuesped !== "Solo mujeres")
        ) {
          toast.error("Género no coincide con el género permitido.");
          return;
        }

        const mapped = {
          rut: trab.rut ?? "",
          nombre: trab.nombre
            ? trab.nombre
            : trab.nombres ?? "",
          campamento:
            habitacion?.pabellon?.campamento ??
            habitacion?.wing?.campamento ??
            "",
          pabellon: habitacion?.pabellon ?? "",
          wing: habitacion?.wing ?? "",
          habitacion: habitacion ?? "",
          piso: habitacion?.piso ?? "",
          trabajador: trab ?? null,
          jornada: trab?.jornada ?? "",
          turno: trab.turno ?? null,
        };
        setAsignaciones((prevAsignaciones) => [...prevAsignaciones, mapped]);
        console.log("mapped: ", mapped);

        const mappedHabitaciones =
          filterHabitaciones && Array.isArray(filterHabitaciones)
            ? filterHabitaciones.map((item) => {
                if (item.id === idHabitacion && !item.asignado) {
                  return { ...item, asignado: trab.rut };
                }
                return item;
              })
            : [];
        setfilterHabitaciones(mappedHabitaciones);

        if (habitacion) {
          const filterHabitacio = mappedHabitaciones.filter((item) => {
            // Si existe el pabellonId, comparamos con el pabellonId del selectedRut
            if (item.pabellon && habitacion.pabellon) {
              return item.pabellon.id === habitacion.pabellon.id;
            }
            // Si existe el wingId, comparamos con el wingId del habitacion
            if (item.wing && habitacion.wing) {
              return item.wing.id === habitacion.wing.id;
            }
            // Si no cumple ninguna de las anteriores, no incluimos el item en el resultado
            return false;
          });
          setInfoHabitaciones(filterHabitacio);
        } else {
          setInfoHabitaciones(mappedHabitaciones);
        }
      }

      // Eliminar al trabajador de los no-asignados
    //   const filt = noAsignados.filter(
    //     (item) => item.trabajador.rut !== trab.trabajador.rut
    //   );
    //   setNoAsignados(filt);
    }
  };

  const canSaveAprobar =
    [asignaciones.length > 0].every(Boolean) && addRequestStatus === "idle";

  const handleAprobar = async () => {
    // if(canSave) {
    try {
      setLoaderList(true);
      console.log("mando", formData);
      setAddRequestStatus("loading");
      let resp = await dispatch(asignarHabitacionesRecepcionista(formData));
      if (resp.payload.status === "success") {
        setFormData(null);
        setOpenModal(false);
        setOpenEdit(false);
      }
    } catch (error) {
      console.error("Error al asignar habitaciones", error);
    } finally {
      setLoaderList(false);
      setAddRequestStatus("idle");
    }
    // }
  };

  const verificarAsignaciones = (asignaciones) => {
    if (!Array.isArray(asignaciones)) {
      console.error("Las asignaciones no son un arreglo válido.");
      return;
    }

    // Mapa para almacenar habitaciones, jornadas y tipos de cargo
    const habitacionesMap = new Map();

    // Arreglo para almacenar los mensajes de conflicto
    const mensajesDeConflicto = [];

    // Iterar sobre las asignaciones
    asignaciones.forEach((asignacion) => {
      const { habitacion, trabajador } = asignacion;
      const { jornada, tipoCargo } = trabajador;
      const habitacionId = habitacion?.id;

      if (!habitacionId || !jornada || !tipoCargo) {
        console.warn("Asignación inválida:", asignacion);
        return;
      }

      if (!habitacionesMap.has(habitacionId)) {
        habitacionesMap.set(habitacionId, {
          jornadas: new Set(),
          tiposCargo: new Set(),
        });
      }

      const habitacionData = habitacionesMap.get(habitacionId);

      if (habitacionData.jornadas.has(jornada)) {
        // Conflicto de jornada encontrado
        const mensaje = `Conflicto en habitación ${habitacion.numero}, ${
          habitacion.wing
            ? "Wing"
            : habitacion.pabellon
            ? "Pabellón"
            : "Edificio"
        } ${
          habitacion.wing
            ? habitacion.wing.nombre
            : habitacion.pabellon.identificador
            ? habitacion.pabellon.identificador
            : habitacion.edificio.nombre
        } con jornada ${jornada}`;
        mensajesDeConflicto.push(mensaje);
      } else {
        habitacionData.jornadas.add(jornada);
      }

      if (
        habitacionData.tiposCargo.size > 0 &&
        !habitacionData.tiposCargo.has(tipoCargo)
      ) {
        // Conflicto de tipo de cargo encontrado
        const mensaje = `Conflicto en habitación ${habitacion.numero}, ${
          habitacion.wing
            ? "Wing"
            : habitacion.pabellon
            ? "Pabellón"
            : "Edificio"
        } ${
          habitacion.wing
            ? habitacion.wing.nombre
            : habitacion.pabellon.identificador
            ? habitacion.pabellon.identificador
            : habitacion.edificio.nombre
        } con tipo de cargo ${tipoCargo}`;
        mensajesDeConflicto.push(mensaje);
      } else {
        habitacionData.tiposCargo.add(tipoCargo);
      }
    });

    if (mensajesDeConflicto.length > 0) {
      console.log("Conflictos encontrados:");
      mensajesDeConflicto.forEach((mensaje) => {
        toast.warning(`${mensaje}`);
      });
      setErrores(mensajesDeConflicto);
    } else {
      console.log("No se encontraron conflictos.");
    }
  };

//   useEffect(() => {
//     if (Array.isArray(formData?.asignaciones) && formData.asignaciones) {
//       verificarAsignaciones(formData?.asignaciones);
//       console.log("formData.asignaciones", formData?.asignaciones);
//     }
//   }, [formData?.asignaciones]);

  return (
    <div>
      <Dialog
        open={openConfirm}
        width="md"
        PaperProps={{
          sx: {
            backgroundColor: "#2d3037",
            color: "#FFF",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setOpenConfirm(false);
        }}
      >
        <DialogTitle style={{ display: "flex", gap: "5px" }}>
          <BlurOnIcon
            className={styles.iconBgCreate}
            style={{ fontSize: "3rem", marginTop: "0px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="span"
              style={{ fontSize: "24px", lineHeight: "1" }}
            >
              Confirmar aprobación de reserva
            </Typography>
            <span className={styles.subTitle}>
              Verifíca la información antes de aprobar.
            </span>
          </div>
        </DialogTitle>

        <DialogContent>
          <Grid container={true}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">
                {errores.length > 0
                  ? "Conflictos encontrados"
                  : "Sin conflictos encontrados."}
              </Typography>
            </Grid>
          </Grid>
          <Grid container={true}>
            {errores.map((item) => (
              <Grid item xs={12} sm={12}>
                <Typography>{item}</Typography>
              </Grid>
            ))}
            {noAsignados.length != 0 && (
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" style={{ color: "red" }}>
                  Faltan trabajadores por asignar.
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={6} className={styles.buttonContainerEdit}>
            <Tooltip
              title="Aprobarás la asignación de los trabajadores de la lista de asignaciones y el proceso finalizará."
              placement="top-start"
            >
              <Button
                onClick={handleAprobar}
                className={styles.bgColorModal}
                variant="contained"
                disabled={!canSaveAprobar}
              >
                {addRequestStatus == "loading" ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Confirmar"
                )}
              </Button>
            </Tooltip>
            <Button
              onClick={() => setOpenConfirm(false)}
              className={styles.bgColorOutlined}
              variant="outlined"
            >
              Cancelar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEdit}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "#2d3037",
            color: "#FFF",
            borderRadius: "0",
          },
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setOpenEdit(false);
        }}
      >
        {seleItem && (
          <>
            <DialogTitle style={{ display: "flex", gap: "5px" }}>
              <BlurOnIcon
                className={styles.iconBgCreate}
                style={{ fontSize: "3rem", marginTop: "0px" }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="span"
                  style={{ fontSize: "24px", lineHeight: "1" }}
                >
                  Revisión reserva {formData?.id}
                </Typography>
                <span className={styles.subTitle}>
                  A continuación podrá modificar la asignación automatica de
                  habitaciones a trabajadores generada por el sistema
                  seleccionando los registros de las tablas.
                </span>
              </div>
            </DialogTitle>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loaderList}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <DialogContent>
              <Grid container={true} spacing={1} p={2}>
                <Grid item xs={12} sm={2} mb={2} style={{ padding: "0 10px" }}>
                  <div style={{ paddingBottom: "10px" }}>
                  {selectedRut && selectedRut.trabajador && selectedRut.trabajador.rut ? (
                    <div>
                      <label className={styles.label}>Trabajador</label>
                      <TextField
                        className={styles.textField}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "#FFF",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFF",
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name="rut"
                        type="text"
                        value={selectedRut.trabajador.rut}
                      />
                    </div>
                  ) : null}
                    <div>
                      <label className={styles.label}>Wing</label>
                      <Autocomplete
                        className={styles.textField}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "#FFF",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFF",
                          },
                        }}
                        disablePortal
                        disableClearable
                        id="sectores-autocomplete"
                        name="wing"
                        value={selectedWing || null}
                        options={wings || []}
                        getOptionLabel={(option) => option.nombre}
                        onChange={handleChangeWing}
                        renderInput={(params) => (
                          <TextField {...params} label="Selecciona un wing" />
                        )}
                      />
                    </div>

                    {selectedRut && (
                      <div>
                        <label className={styles.label}>Piso</label>
                        <TextField
                          className={styles.textField}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#FFF",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#FFF",
                            },
                          }}
                          variant="outlined"
                          fullWidth
                          disabled={!selectedRut}
                          name="piso"
                          type="number"
                          value={selectedPiso || 0}
                          onChange={onChange}
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                      </div>
                    )}
                    {selectedRut && (
                      // selectedRut.rut &&
                      <div>
                        <label className={styles.label}>Habitación</label>
                        <Select
                            className={styles.textField}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                                },
                                "& .MuiInputBase-input": {
                                color: "#FFF",
                                },
                                "& .MuiInputLabel-root": {
                                color: "#FFF",
                                },
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedHabitacion ? parseInt(selectedHabitacion.numero) : ""}
                            disabled={!selectedRut}
                            onChange={(e) => handleChangeHabitacion(e)}
                            style={{ width: "100%" }}
                            >
                            {habitacionesList && habitacionesList.length > 0 && habitacionesList.map((hab) => (
                                <MenuItem key={hab.id} value={parseInt(hab.numero)}>
                                {hab.numero}
                                </MenuItem>
                            ))}
                        </Select>
                      </div>
                    )}
                  </div>
                  {
                    selectedRut && selectedRut.trabajador && selectedRut.trabajador.rut ? 
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!selectedRut?.permanente}
                                    onChange={handleCheckboxChange}
                                    sx={{
                                    color: '#FFF',
                                    '&.Mui-checked': {
                                        color: '#FFF',
                                    },
                                    }}
                                />
                                }
                                label="Permanente"
                                sx={{
                                color: '#FFF',
                            }}
                        />
                    </div> : null
                    }

                  {selectedRut && selectedRut.trabajador && selectedRut.trabajador.rut ? (
                    <Button
                      onClick={actualizarRegistro}
                      className={styles.bgColorOrange}
                      variant="contained"
                      disabled={!canSave}
                    >
                      Actualizar
                    </Button>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={10} mb={2}>
                  <TabContext value={value} style={{ padding: "0" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                          "& .MuiTab-root": { color: "gray" },
                          "& .MuiTab-root.Mui-selected": { color: "#FFF" },
                          "& .MuiTabs-indicator": { bgcolor: "#FFF" },
                        }}
                      >
                      <Tab
                        label={`(${formData?.asignaciones?.length ?? 0}) ${
                            formData?.asignaciones?.length === 1 ? "Asignados" : "Asignados"
                        }`}
                        value="1"
                        />
                    
                        {/*<Tab
                          label={`(${noAsignados.length}) ${
                            noAsignados.length == 1
                              ? "no inscritos"
                              : "no inscritos"
                          } `}
                          value="2"
                        />
                        */}
                      </TabList>
                    </Box>
                    <TabPanel value="1" style={{ padding: "0" }}>
                      <TableAsignaciones
                        asignaciones={asignaciones ? asignaciones : []}
                        setSelectedRut={setSelectedRut}
                        setSelectedWing={setSelectedWing}
                        setSelectedPabellon={setSelectedPabellon}
                        setPabellonWing={setPabellonWing}
                        habitaciones={filterHabitaciones}
                        setHabitacionesList={setHabitacionesList}
                        setSelectedHabitacion={setSelectedHabitacion}
                        setSelectedPiso={setSelectedPiso}
                        setSelectedCampamento={setSelectedCampamento}
                        selectedRut={selectedRut}
                        filterHabitaciones={filterHabitaciones}
                        setfilterHabitaciones={setfilterHabitaciones}
                        setInfoHabitaciones={setInfoHabitaciones}
                      />
                    </TabPanel>
                    <TabPanel value="2" style={{ padding: "0" }}>
                      <TableNoAsignaciones
                        asignaciones={noAsignados ? noAsignados : []}
                        setSelectedRut={setSelectedRut}
                        setSelectedWing={setSelectedWing}
                        setSelectedPabellon={setSelectedPabellon}
                        setPabellonWing={setPabellonWing}
                        habitaciones={habitaciones}
                        setHabitacionesList={setHabitacionesList}
                        setSelectedHabitacion={setSelectedHabitacion}
                        setSelectedPiso={setSelectedPiso}
                        selectedRut={selectedRut}
                      />
                    </TabPanel>
                  </TabContext>
                </Grid>
                <Grid item xs={12} sm={12} mb={2}>
                  <TableHabitaciones
                    rows={infoHabitaciones}
                    addRequestStatus={false}
                    setOpen3={""}
                    noAsignados={noAsignados ?? []}
                    setHabitacionSelectedId={setHabitacionSelectedId}
                    actualizarRegistroAutomatico={actualizarRegistroAutomatico}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item xs={12} sm={6} className={styles.buttonContainerEdit}>
                <Button
                  onClick={() => setOpenConfirm(true)}
                  className={styles.bgColorModal}
                  variant="contained"
                  disabled={!canSaveAprobar}
                >
                  Guardar cambios
                </Button>
                <Button
                  onClick={() => setOpenEdit(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
