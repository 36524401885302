import styles from './components.module.css';
import { useEffect, useState } from "react";
import { forecastColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Edit from './Edit';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { createAxiosInstance } from '../../../../api/axios';
import SkeletonTable from '../../../../components/Skeleton';
import Historico from './Historico';
import { useAuth } from '../../../../context/AppContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select from 'react-select';

const Table = ({ setOpen3, registros, data, tramos, setTramos, ger, siArea, empresas, contratos, setOpen2, open2, status, value, setValue, turnos }) => {

  const [seleItem, setSeleItem] = useState();
  const [vicep, setVicep] = useState([]);
  
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedVicepresidencias, setSelectedVicepresidencias] = useState('');
  const [selectedGerencia, setSelectedGerencia] = useState('');
  const [selectedContrato, setSelectedContrato] = useState('');

  const [open, setOpen] = useState(false);
  const [openHistorico, setOpenHistorico] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filterRegistros, setFilterRegistros] = useState([]);
  const [filterContratos, setFilterContratos] = useState([]);
  const { info } = useAuth();
  const rol = info?.roles;
  console.log('rol', rol);
  const finalColumns = forecastColumns(setOpen, setSeleItem, setOpenHistorico, rol);
  const axiosInstance = createAxiosInstance();
  const [search, setSearch] = useState('');

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  useEffect(() => {
    console.log('registros', registros);
    const sortedRegistros = [...registros].sort((a, b) => {
      if (a.estado === 'Rechazado' && b.estado !== 'Rechazado') {
        return -1;
      }
      if (a.estado !== 'Rechazado' && b.estado === 'Rechazado') {
        return 1;
      }
      return 0;
    });
    setFilterRegistros(sortedRegistros);
  }, [registros]);

  useEffect(() => {
    if (Array.isArray(ger)) {
      const vp = ger.filter(item => item.startsWith('VP'));
      setVicep(vp);
    }
  }, [ger]);
  
  const handleChange = async (name, newValue) => {
    if (newValue != null) {
      if (name === 'empresa') {
        setSelectedEmpresa(newValue);
        try {
          setOpen3(true);
          const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+newValue.idEmpresa);
          setFilterContratos(resp.data);
          setSelectedContrato('')
        } catch (error) {
          console.log(error);
        } finally {
          setOpen3(false);
        }
      } else if (name === 'contrato') {
        setSelectedContrato(newValue);
      } else if (name === 'vicepresidencia') {
        setSelectedVicepresidencias(newValue);
      } else if (name === 'gerencia') {
        setSelectedGerencia(newValue);
      } else if (name === 'siArea') {
        setSelectedArea(newValue);
      }
    } else {
      if (name === 'empresa') {
        setSelectedEmpresa('');
        setFilterContratos([]);
        // setSelectedContrato(''); 
      } else if (name === 'contrato') {
        setSelectedContrato('');
      } else if (name === 'vicepresidencia') {
        setSelectedVicepresidencias('');
      } else if (name === 'gerencia') {
        setSelectedGerencia('');
      } else if (name === 'siArea') {
        setSelectedArea('');
      }
    }
    filterData(
      name === 'empresa' ? newValue?.razonSocial?.toLowerCase() : selectedEmpresa?.razonSocial?.toLowerCase(),
      name === 'contrato' ? newValue : selectedContrato,
      name === 'vicepresidencia' ? newValue : selectedVicepresidencias,
      name === 'gerencia' ? newValue : selectedGerencia,
      name === 'siArea' ? newValue : selectedArea
    );
  }

  const filterData = (empresa, contrato, vicepresidencia, gerencia, siArea) => {
    const filteredRegistros = registros.filter(reg => {
      const empresaMatch = empresa ? reg.empresa.razonSocial.toLowerCase().includes(empresa) : true;
      const contratoMatch = contrato ? reg.tramos.some(item => item.contrato.toLowerCase().includes(contrato.toLowerCase())) : true;
      const vicepresidenciaMatch = vicepresidencia ? reg.tramos.some(item => item.vicepresidencia.toLowerCase().includes(vicepresidencia.toLowerCase())) : true;
      const gerenciaMatch = gerencia ? reg.tramos.some(item => item.gerencia.toLowerCase().includes(gerencia.toLowerCase())) : true;
      const siAreaMatch = siArea ? reg.tramos.some(item => item.siArea.toLowerCase().includes(siArea.toLowerCase())) : true;

      return empresaMatch && contratoMatch && vicepresidenciaMatch && gerenciaMatch && siAreaMatch;
    });

    setFilterRegistros(filteredRegistros);
  }

  const options = Array.isArray(vicep) ? vicep.map((item) => ({ value: item, label: item })) : [];
  const gerOptions = Array.isArray(ger) ? ger.map(item => ({ value: item, label: item })) : [];
  const siAreaOptions = Array.isArray(siArea) ? siArea.map(item => ({ value: item, label: item })) : [];

  return (
    <div style={{ marginTop: '16px' }}>
      <Edit setOpen2={setOpen2} siArea={siArea} empresas={empresas} ger={ger} open2={open2} setOpen={setOpen} open={open} data={data} tramos={tramos} value={value} setValue={setValue} setTramos={setTramos} contratos={contratos} seleItem={seleItem} turnos={turnos}/>
      
      <Historico setOpen={setOpenHistorico} open={openHistorico} seleItem={seleItem} setOpen3={setOpen3}/>
        
      
      <Accordion sx={{ width:'100%', color:'#FFF', border:'none', borderRadius:'2px'}}>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon sx={{ color:'#0086ae' }}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          className={ styles.acordionDetails }
          sx={{ width:'100%'}}
        >
          <Typography variant='span' style={{ fontSize:'16px', fontWeight:'500' }}>Haz click aquí y utiliza los filtros para desplegar información especifica en la tabla</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <label className={styles.labelFilter}>Empresas</label>
              <Select
                className={styles.textField}
                options={empresas}
                isClearable
                value={empresas.find(option => option.razonSocial === selectedEmpresa.razonSocial) || null}
                onChange={(selectedOption) => handleChange('empresa', selectedOption)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#0086ae',
                    border: 'none',
                    color: '#FFF',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#FFF',
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: '#FFF',
                    padding:'11px'
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: '#0086ae',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#FFF', // Color del placeholder
                  }),
                }}
                getOptionLabel={(option) => option.razonSocial}
                getOptionValue={(option) => option.id}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.labelFilter}>Contratos</label>
              <Autocomplete
                className={styles.textField2}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#FFF',
                      },
                    }}
                disablePortal
                id="contrato-autocomplete"
                options={filterContratos}
                name="contrato"
                value={selectedContrato || null}
                getOptionLabel={(option) => option}
                onChange={(e, value) => handleChange('contrato', value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.labelFilter}>Vicepresidencias</label>
              <Select
                isClearable 
                className={styles?.textField2}
                options={options}
                value={options.find(option => option.value === selectedVicepresidencias) || null}
                // value={selectedVicepresidencias}
                onChange={(selectedOption) => handleChange('vicepresidencia', selectedOption?.value)}
                onInputChange={handleInputChange}
                styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#0086ae',
                        border: 'none',
                        color: '#FFF',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#FFF',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#FFF',
                        padding:'11px',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#0086ae',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#FFF', // Color del placeholder
                      }),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.labelFilter}>Gerencias</label>
              <Select
                isClearable 
                className={styles.textField}
                options={gerOptions}
                value={gerOptions.find(option => option.value === selectedGerencia) || null}
                onChange={(selectedOption) => handleChange('gerencia', selectedOption?.value)}
                onInputChange={handleInputChange}
                SelectProps={{
                        native: true,
                        placeholder: "",
                        style: {
                          color: '#FFF',
                          padding: '11px',
                          backgroundColor: '#0086ae',
                          border: 'none',
                        },
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#0086ae',
                    border: 'none',
                    color: '#FFF',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#FFF',
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: '#FFF',
                    padding:'11px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: '#0086ae',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#FFF', // Color del placeholder
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label className={styles.labelFilter}>siAreas</label>
              <Select
                isClearable 
                className={styles.textField}
                options={siAreaOptions}
                value={siAreaOptions.find(option => option.value === selectedArea) || null}
                onChange={(selectedOption) => handleChange('siArea', selectedOption?.value)}
                onInputChange={handleInputChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#0086ae',
                    border: 'none',
                    color: '#FFF',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#FFF',
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: '#FFF',
                    padding:'11px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: '#0086ae',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#3182ce' : 'transparent',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#FFF', // Color del placeholder
                  }),
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {status == 'loading' ? <SkeletonTable/> :
        <DataGrid
          getRowId={row => row.id ? row.id : Math.random()}
          lots={{ toolbar: GridToolbar }}
          rows={filterRegistros}
          columns={finalColumns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
              labelRowsPerPage: "Filas por página:",
            }
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight:'500px', color:'#000', border:'none' }}
          sx={{ 
                '& .MuiDataGrid-cell': {
                  color: '#2d3037',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeader': {
                  color: '#2d3037',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-footerContainer': {
                  color: '#2d3037',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiTablePagination-root': {
                  color: '#2d3037',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  color: '#2d3037',
                },
                '& .MuiDataGrid-menu': {
                  color: '#2d3037',
                },
                '& .MuiSvgIcon-root':{
                  color: '#9ca91c',
                },
                '& .MuiTablePagination-actions': {
                  color: '#2d3037', // Color for pagination actions
                },
                '& .MuiTablePagination-select': {
                  color: '#2d3037', // Color for pagination select
                },
                '& .MuiTablePagination-selectLabel': {
                  color: '#2d3037', // Color for pagination select label
                },
                '& .MuiTablePagination-displayedRows': {
                  color: '#2d3037', // Color for displayed rows text
                },
                '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                  color: 'red',
                },
                '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                  color: 'red',
                },
          }}
          slotProps={{
                toolbar:{
                  sx:{
                    color:'#FFF'
                  }
                },
                pagination: {
                  sx: {
                    color: '#f19d38',
                  },
                },
                menu: {
                  sx: {
                    color: '#f19d38',
                  },
                },
          }}
        />
      }
    </div>
  )
}

export default Table;
