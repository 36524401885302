import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography, Checkbox } from '@mui/material';
import { createAxiosInstance } from '../../../api/axios';
import { addNewIngreso } from '../../../redux/features/bodegas/bodegaSlice';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import { fetchTrabajadores, getAllTrabajadores, getTrabajadoresStatus, rechazarSolicitud } from '../../../redux/features/reservas/reservaSlice';
import { liveSearchByRut, liveSearchByRutAndName } from '../../../redux/features/users/userSlice';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  bodegaId: '',
  receptor: '',
  tipoDocumento: '',
  ordenDeCompra: '',
  numeroDocumento: '',
  montoTotal: 0,
  observacion: '',
  fechaIngreso: '',
  imagen1: '',
  imagen2: '',
  imagen3: '',
  ingresoArticulos: [],
}

const Create = ({ open2, setOpen2, campamentos, bodegas, users }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); 
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [itemsNew, setItemsNew] = useState([]);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [filterBodegas, setFilterBodegas] = useState(null);
  const [searching, setSearching] = useState(false);
  const [compraDirecta, setCompraDirecta] = useState(false);
  const [ruts, setRuts] = useState([])
  const [listItems, setListItems] = useState([])
  const [selectedItems, setSelectedItems] = useState(null)
  const axiosInstance = createAxiosInstance();

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    setSelectedBodega(null)
    setFormData({...formData, bodegaId: null});
    const filter = bodegas.filter(bod => bod.campamento.id == newValue.id);
    setFilterBodegas(filter)
  };

  const onChange = (e) => {
    if (e.target.type === 'file') {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  

  const getBodegas = async (bodega) => {
    try {
      const response = await axiosInstance.get('/api/v1/Inventario/productos-bodega/'+bodega);
      console.log('bodegas productos: ',response.data);
      // setBodegasItems(response.data);
      // setListItems(response.data);
      return response.data
    } catch (error) {
      console.log('Error al traer los items: ',error)
    }
  }

  const handleChangeBodega = async (event, newValue) => {
    setSelectedBodega(newValue);
    if(newValue != null){
      try {
        // const response = await getBodegas(newValue.id);
        // setListItems(response)
        setFormData({...formData, bodegaId: newValue.id});
      } catch (error) {
        
      }
    } else{
      setFormData({...formData, bodegaId: 0})
    }  
  };
  
  
  const onChange2 = (e, index) => {
    const { name, value } = e.target;
    setItemsNew(prevItems => {
        const updatedItems = [...prevItems];
        updatedItems[index][name] = value;

        setFormData(prev => ({
            ...prev,
            ingresoArticulos: updatedItems
        }));
        
        return updatedItems;
    });
};

  

  const getProducts = async () => {
    try {
      const response = await axiosInstance.get('/api/v1/producto');
      console.log(response);
      setListItems(response.data);
    } catch (error) {
      console.log('Error al traer los items: ',error)
    }
  }

  useEffect(() => {
    getProducts();
  }, [])
  

  const search = async (event, newValue) => {
    try {
      if(newValue){
        setSearching(true);
        setSelectedItems(newValue);
        console.log(' ca',newValue)
        const filterItem = listItems.find(item => item.codigo === newValue.codigo || item.nombre.toUpperCase() === newValue.nombre.toUpperCase());
        console.log('FilterItem', filterItem)
        if (filterItem) {
          const alreadyIn = itemsNew.find(item => item.codigo === filterItem.codigo);
          if (alreadyIn) {
            toast.error('El artículo ya está agregado.');
          } else {
              setItems([...items, newValue.codigo]);
              const item = {
                codigo: filterItem.codigo,
                nombre: filterItem.nombre,
                descripcion: filterItem.descripcion,
                productoId: filterItem.id,
                cantidadRecibida: 0,
                cantidadDelDocumento: 0,
                precio: filterItem.precio || 0
              };
              
              setItemsNew([...itemsNew, item]);
              setFormData(prev => ({
                ...prev,
                ingresoArticulos: [...prev.ingresoArticulos, item]
              }));
              // setSelectedItems();
          }
        } else {
          toast.error('No se ha encontrado el artículo.');
        }
      }
      return;
    } catch (error) {
      console.error(error);
    } finally {
      setSearching(false);
    }
  };

  const canSave = [formData.bodegaId, compraDirecta || formData.tipoDocumento, compraDirecta || formData.numeroDocumento, formData.receptor, formData.montoTotal, formData.ingresoArticulos.length > 0].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewIngreso(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
          setSelectedBodega()
          setSelectedCampamento()
          setItems([])
          setItemsNew([])
          // setListItems([])
          setNewItem('')
          setFilterBodegas([])
          setSelectedItems()
          setRuts([])
          setCompraDirecta()
          setSearching()
        }
      } catch (error) {
        console.error('Error al registrar el ingreso a bodega', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }
  const handleRemove = (index) => {
    const newItems = [...itemsNew];
    newItems.splice(index, 1);
    setItemsNew(newItems);
    
    const newFormData = { ...formData };
    newFormData.ingresoArticulos = newItems;
    setFormData(newFormData);
  };

  const handleCompraDirectaChange = (event) => {
    setCompraDirecta(event.target.checked);
    if (event.target.checked) {
      setFormData({...formData, tipoDocumento: '', numeroDocumento: ''});
    }
  };

  const onChangeRut = async (e) => {
    try {
      setOpen2(true);
      setRuts([]);
      const resp = await dispatch(liveSearchByRut(e.target.value))
      console.log('trabajadores', resp);
      // const mappedResp = resp.filter(item => item.)
      setRuts(resp.payload.data);
      // setRuts(mappedResp);
    } catch (error) {
      console.log(error)
    } finally{
      setOpen2(false)
    }
  }
  const onChangeRutSelect = (e, newValue) => {
    if (newValue) {
      setFormData({...formData, receptor: newValue.rut});
    } else {
      setRuts({});
    }
  }

  const handleInputChange = (event, newInputValue) => {
    if (!newInputValue) {
      setFormData({...formData, receptor: ''});
    }
  }
  


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para ingresar los artículos.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> {setOpen(true); setItemsNew([]); setFormData(data); setSelectedBodega(null); setFilterBodegas([]); setSelectedCampamento(null); setCompraDirecta(false);}}><CircleIcon className={styles.iconButton}/>Ingresar registro</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <AlignHorizontalCenterIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Registro de Artículos en bodega</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para ingresar el registro.</span>
          </div>
        </DialogTitle>
        
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={3}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={campamentos}
                name="bodega"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />      
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={styles.label}>Bodega</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                disablePortal
                disableClearable
                disabled={!selectedCampamento}
                id="campamentos-autocomplete"
                options={filterBodegas}
                name="bodega"
                value={selectedBodega}
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeBodega(e, value)}
                renderInput={(params) => <TextField {...params} label={selectedCampamento ? "Selecciona una bodega" : <span style={{ color: '#ffb6b6' }}>Por favor, seleccione un campamento</span>} />}
              />      
            </Grid>
            <Grid item xs={12} sm={5}>
              <label className={styles.label}>Receptor</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                name="receptor"
                // options={ruts ? ruts : []}
                options={users ? users : []}
                getOptionLabel={(option) => option && option.rut && option.nombre ? option.rut + ' - ' + option.nombre : ''}
                onChange={onChangeRutSelect}
                onInputChange={handleInputChange}
                renderInput={(params) => <TextField {...params} label="Busca rut o nombre" 
                // onChange={onChangeRut}
                />
              }
              />
            </Grid>
            <Grid item  xs={12} sm={1}></Grid>
            <Grid item  xs={12} sm={2}>
              <Checkbox
                checked={compraDirecta}
                onChange={handleCompraDirectaChange}
                name="compraDirecta"
                color="primary"
              />
              <label>Compra directa</label>
            </Grid>
            <Grid item xs={12} sm={2}>
              <label className={styles.label}>Tipo de documento</label>
              <Select
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData?.tipoDocumento}
                name="tipoDocumento"
                onChange={(e) => onChange(e)}
                style={{ width:'100%' }}
                disabled={compraDirecta}
              >
                <MenuItem value='Factura'>Factura</MenuItem>
                <MenuItem value='Boleta'>Boleta</MenuItem>
                <MenuItem value='Guía de despacho'>Guía de despacho</MenuItem>
              </Select> 
            </Grid>
            <Grid item xs={12} sm={2}>
              <label className={styles.label}>N° de documento</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                value={formData?.numeroDocumento}
                name='numeroDocumento'
                type="number"
                onChange={(e) => onChange(e)}
                disabled={compraDirecta}
              />
            </Grid>
            <Grid item  xs={12} sm={2}>
              <label className={styles.label}>Ord.de compra (opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='ordenDeCompra'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={2}>
              <label className={styles.label}>Monto total (CLP)</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='montoTotal'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={2}>
              <label className={styles.label}>Fecha de ingreso</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='fechaIngreso'
                type="date"
                value={new Date().toISOString().split('T')[0]}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Observaciones (Opcional)</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='observacion'
                type="text"
                multiline
                rows={3}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            
            {/*  ------------- LISTA DE ARTICULOS -------------  */}
            <Grid item xs={12} md={6}>
                <label className={styles.label}>
                  {selectedBodega ? 'Lista de artículos (Busca artículos por nombre o por código)' : 'Primero selecciona una bodega para poder buscar árticulos.'}
                </label>
                <div className={styles.inventario}>
                  <Autocomplete
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#FFF',
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#FFF',
                      },
                    }}
                    disablePortal
                    disableClearable
                    id="campamentos-autocomplete"
                    options={listItems}
                    name="bodega"
                    disabled={!selectedBodega}
                    value={selectedItems}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(event, value) => search(event, value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </Grid>
                <Grid item xs={12} sm={4} style={{ display:'flex', flexWrap:'wrap', alignItems:'center' }}>
                    <label className={styles.label}>Imagen (Opcional)</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='imagen1'
                      type="file"
                      onChange={(e) => onChange(e)}
                    />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ display:'flex', flexWrap:'wrap', alignItems:'center' }}>
                      <label className={styles.label}>Imagen (Opcional)</label>
                      <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                        variant="outlined"
                        fullWidth
                        name='imagen2'
                        type="file"
                        onChange={(e) => onChange(e)}
                      />
                      </Grid>
                    <Grid item xs={12} sm={4} style={{ display:'flex', flexWrap:'wrap', alignItems:'center' }}>
                    <label className={styles.label}>Imagen (Opcional)</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='imagen3'
                      type="file"
                      onChange={(e) => onChange(e)}
                    />
              </Grid>
            </Grid>

            {/*{JSON.stringify(itemsNew)} */}

            <Grid container={true} spacing={1} style={{ maxHeight:'500px', overflowY:'auto', border:'1px solid #777', borderRadius:'5px', padding:'5px', margin:'0', marginTop:'20px', }}> 
              {itemsNew.length != 0 ? itemsNew?.map((item, index) => (
                <>
                <Grid item xs={12} md={1} key={index}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                  <Button 
                    variant='contained' 
                    className={styles.button} 
                    onClick={() => handleRemove(index)}
                    style={{ 
                      width: '90px',
                      backgroundColor: 'red'
                    }}
                  >
                    Remover
                  </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={1} key={index}>
                    <label className={styles.label}>Código {index + 1}</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='codigo'
                      value={item.codigo}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={index}>
                    <label className={styles.label}>Nombre</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='nombre'
                      value={item.nombre}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3.5} key={index}>
                    <label className={styles.label}>Descripción</label>
                    <TextField
                    className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='descripcion'
                      value={item.descripcion}
                      type="text"
                      // onChange={(e) => onChange2(e, index)}
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={1.5} key={index}>
                    <label className={styles.label}>Precio (CLP)</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='precio'
                      value={item.precio}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={1.5} key={index}>
                    <label className={styles.label}>Cant.documento</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='cantidadDelDocumento'
                      value={item.cantidadDelDocumento}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={1.5} key={index}>
                    <label className={styles.label}>Cantidad recibida</label>
                    <TextField
                      className={styles.textField}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#FFF',
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      name='cantidadRecibida'
                      value={item.cantidadRecibida}
                      type="number"
                      onChange={(e) => onChange2(e, index)}
                    />
                  </Grid>
                </>
              )) : <span>Buscar artículos para poder ver su información.</span>}
            </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}>
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
            </DialogActions>
      </Dialog>
    </div>
  )
}

export default Create


  {/*<TextField
                    className={styles.textField}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: '#FFF',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#FFF',
                      },
                    }}
                    name='correo'
                    type="email"
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    fullWidth
                  />
                  <div style={{ display:'flex', alignItems:'center', gap:'5px', marginTop:'10px' }}>
                    <Button variant='contained' disabled={!formData.bodegaId} className={styles.bgColorOrange} onClick={search}>Buscar</Button>
                    </div> */}