import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";

const MERMAS_URL = '/api/v1/Inventario/merma';
const MERMA_URL = '/api/v1/Inventario/merma/';
const DELETE_MERMA_URL = '/api/v1/Inventario/merma/';
const UPDATE_MERMA_URL = '/api/v1/Inventario/merma/'
const ADD_MERMA_URL = '/api/v1/Inventario/merma';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchMermas = createAsyncThunk("mermas/fetchMermas", async ()=> {
    try {
        const response = await axiosInstance.get(MERMAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchMerma = createAsyncThunk("mermas/fetchMerma", async (id)=> {
    try {
        const response = await axiosInstance.get(MERMA_URL+id);        
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteMerma = createAsyncThunk("mermas/deleteMerma", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_MERMA_URL+id);
        
        if (response.status >= 200 && response.status < 300) {
            toast.success('Merma eliminada correctamente.');
            dispatch(fetchMermas());
            return { data: {id} ,status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el registro: ' + e.response.data);
        return e.message;
    }
})
export const updateMerma = createAsyncThunk("mermas/updateMerma", async (upMerma, {dispatch})=> {
    console.log('Merma', upMerma);
    try {
        const response = await axiosInstance.put(UPDATE_MERMA_URL+upMerma.id, {
            productoId: upMerma.productoId,
            espacioBodegaId: upMerma.espacioBodegaId,
            cantidad: upMerma.cantidad,
            motivo: upMerma.motivo,
            observaciones: upMerma.observaciones,
        });
        
        if (response.status === 200) {
            toast.success('Merma actualizada correctamente.');
            dispatch(fetchMermas());
            return { data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar la merma: ' + e.response.data);
        return e.message;
    }
})

export const addNewMerma = createAsyncThunk("mermas/createMerma", async (initialCampamento, { dispatch }) => {
    try {
        const response = await axiosInstance.post(ADD_MERMA_URL, {
            productoId: initialCampamento.producto.id,
            espacioBodegaId: initialCampamento.espacioBodegaId,
            cantidad: initialCampamento.cantidad,
            motivo: initialCampamento.motivo,
            observaciones: initialCampamento.observaciones,
            });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Merma creada correctamente.');
            dispatch(fetchMermas());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear la merma: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})
export const addNewMermaAutomatic = createAsyncThunk("mermas/createMerma", async (initialCampamento, { dispatch }) => {
    console.log('data', initialCampamento)
    try {
        const response = await axiosInstance.post(ADD_MERMA_URL, {
            productoId: initialCampamento.productoId,
            espacioBodegaId: initialCampamento.espacioBodegaId,
            cantidad: initialCampamento.cantidad,
            motivo: initialCampamento.motivo,
            observaciones: initialCampamento.observaciones,
            });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Merma creada correctamente.');
            dispatch(fetchMermas());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear la merma: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})



// idle : no hay operaciones en curso
const initialState = {
    mermas: [],
    merma: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const mermaSlice = createSlice({
    name:"merma",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchMermas.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchMermas.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.mermas) !== JSON.stringify(action.payload)) { //fetch
                state.mermas = action.payload;
            }
        })
        .addCase(fetchMermas.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchMerma.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchMerma.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.merma) !== JSON.stringify(action.payload)) { //fetch
                state.merma = action.payload;
            }
        })
        .addCase(fetchMerma.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewMerma.fulfilled, (state, action) => { //ADD
            state.status = 'succeeded'
            state.mermas.push(action.payload);
        })
        .addCase(deleteMerma.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteMerma.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.mermas = state.mermas.filter(merma => merma.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteMerma.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateMerma.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateMerma.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.mermas = state.mermas.map(merma => {
                    if (merma.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return merma;
                });
            }else{
                state.status = 'failed';    
            }
        })
        .addCase(updateMerma.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllMermas = (state) => state.mermas.mermas;
export const getMerma = (state) => state.mermas.merma;
export const getMermasStatus = (state) => state.mermas.status;
export const getMermasError = (state) => state.mermas.error;

export const {} = mermaSlice.actions;
export default mermaSlice.reducer;