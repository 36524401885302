import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { createAxiosFormDataInstance, createAxiosInstance } from "../../../api/axios";

const INVENTARIO_URL = '/api/v1/Producto';
const TOMAS_URL = '/api/v1/Inventario/toma-inventario';
const CATEGORIAS_URL = '/api/v1/CategoriaProducto';
const ADD_INVENTARIO_URL = '/api/v1/Producto';
const ADD_CATEGORIA_URL = '/api/v1/CategoriaProducto';
const ADD_TOMA_URL = '/api/v1/Inventario/toma-inventario';
const UPDATE_INVENTARIO_URL = '/api/v1/Producto/';
const UPDATE_CATEGORIA_URL = '/api/v1/CategoriaProducto/';
const GET_INVENTARIO_URL = '/api/v1/Producto/';
const DELETE_INVENTARIO_URL = '/api/v1/Producto/';
const UPDATE_STATE_INVENTARIO_URL = '/api/v1/Producto/CambiarEstado/';
const DELETE_CATEGORIA_URL = '/api/v1/CategoriaProducto/';

const axiosInstance = createAxiosInstance();
const axiosFormDataInstance = createAxiosFormDataInstance();

// ACTIONS
export const fetchInventarios = createAsyncThunk("inventarios/fetchInventarios", async ()=> {
    try {
        const response = await axiosInstance.get(INVENTARIO_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchTomas = createAsyncThunk("inventarios/fetchTomas", async ()=> {
    try {
        const response = await axiosInstance.get(TOMAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const fetchCategorias = createAsyncThunk("inventarios/fetchCategorias", async ()=> {
    try {
        const response = await axiosInstance.get(CATEGORIAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const fetchInventario = createAsyncThunk("inventarios/fetchInventario", async (id)=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.get(GET_INVENTARIO_URL+id);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteInventario = createAsyncThunk("inventarios/deleteInventario", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_INVENTARIO_URL+id);
        if (response.status === 200) {
            toast.success('Producto eliminado correctamente.');
            dispatch(fetchInventarios());
            return { id, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el producto: ' + e.response.data);
        return e.message;
    }
})

export const updateStateInventario = createAsyncThunk("inventarios/updateStateInventario", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.put(UPDATE_STATE_INVENTARIO_URL+id);
        if (response.status === 200) {
            toast.success('Estado del producto actualizado correctamente.');
            dispatch(fetchInventarios());
            return { id, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el estado del producto: ' + e.response.data);
        return e.message;
    }
})
export const updateInventario = createAsyncThunk("inventarios/updateInventario", async (upProducto, {dispatch})=> {
    console.log('llega', upProducto);
    try {
        const response = await axiosInstance.put(UPDATE_INVENTARIO_URL+upProducto.id, {
            codigo: upProducto.codigo,
            nombre: upProducto.nombre,
            descripcion: upProducto.descripcion,
            stockMinimo: upProducto.stockMinimo,
            categoriaId: upProducto.categoria.id,
            tiempoDeReposicion: upProducto.tiempoDeReposicion,
            duracionMeses: upProducto.duracionMeses,
        });
        
        if (response.status === 200 ) {
            toast.success('Producto actualizado correctamente.');
            dispatch(fetchInventarios());
            return { data: response.data, status: 'success' };
        } 
        return { status: 'error'};
      } catch (e) {
        console.log(e.response);
        toast.error('Error al actualizar el producto: ' + e.response.data.title);
        return e.message;
    }
})
export const updateCategoria = createAsyncThunk("inventarios/updateCategoria", async (upProducto, {dispatch})=> {
    console.log('id', upProducto.id);
    try {
        const response = await axiosInstance.put(UPDATE_CATEGORIA_URL+upProducto.id, {
            nombre: upProducto.nombre,
            descripcion: upProducto.descripcion,
        });
        
        if (response.status === 200 ) {
            toast.success('Categoría actualizada correctamente.');
            dispatch(fetchCategorias());
            return { data: response.data, status: 'success' };
        } 
        return { status: 'error'};
      } catch (e) {
        console.log(e.response);
        toast.error('Error al actualizar la categoría: ' + e.response.data.title);
        return e.message;
    }
})
export const deleteCategoria = createAsyncThunk("inventarios/deleteCategoria", async (id, {dispatch})=> {
    try {
        const response = await axiosInstance.delete(DELETE_CATEGORIA_URL+id);
        if (response.status === 200 ) {
            toast.success('Categoría eliminada correctamente.');
            dispatch(fetchCategorias());
            return { data: response.data, status: 'success' };
        } 
        return { status: 'error'};
      } catch (e) {
        console.log(e.response);
        toast.error('Error al eliminar la categoría: ' + e.response.data.title);
        return e.message;
    }
})

export const addNewToma = createAsyncThunk("inventarios/addNewToma", async (initialProducto, { dispatch }) => {
    const { bodega, montoTotal, montoTotalTomaInventario, articulos, motivo } = initialProducto;
    try {
        const mappedArticulos = articulos.map(item => ({
            productoId: item.id,
            stock: item.stock,
            precio: item.precio,
            cantidad: parseInt(item.cantidad)
        }));
        console.log('mappedArticulos', mappedArticulos);

        const response = await axiosInstance.post(ADD_TOMA_URL, {
            bodegaId: bodega,
            tomaArticulos: mappedArticulos,
            montoTotalTomaInventario,
            motivo,
        });

        if (response.status === 200) {
            toast.success('Producto creado correctamente.');
            dispatch(fetchTomas());
            return { data: response.data, status: 'success' };
        } else {
            toast.error('Error inesperado en la creación del producto.');
            return { status: 'error' };
        }
    } catch (e) {
        toast.error(`Error al crear el producto: ${e.message}`);
        return { message: e.message, status: 'error' };
    }
});

export const addNewProducto = createAsyncThunk("inventarios/addNewProducto", async (initialProducto, { dispatch }) => {
    try {
        const response = await axiosInstance.post(ADD_INVENTARIO_URL, {
            codigo: initialProducto.codigo,
            nombre: initialProducto.nombre,
            categoriaId: initialProducto.categoria,
            descripcion: initialProducto.descripcion,
            stockMinimo: initialProducto.stockMinimo,
            duracionMeses: initialProducto.duracion,
            tiempoDeReposicion: initialProducto.tiempo_reposicion,
        });
        if (response.status == 200) {
            toast.success('Producto creado correctamente.');
            dispatch(fetchInventarios());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al crear el producto: ');
        return {message: e.message, status: 'error'};
    }
})
export const addNewCategoria = createAsyncThunk("inventarios/addNewCategoria", async (initialProducto, { dispatch }) => {
    try {
        const response = await axiosInstance.post(ADD_CATEGORIA_URL, {
            nombre: initialProducto.nombre,
            descripcion: initialProducto.descripcion,
        });
        if (response.status == 200) {
            toast.success('Categoría creada correctamente.');
            dispatch(fetchCategorias());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al crear la categoría: ');
        return {message: e.message, status: 'error'};
    }
})

// idle : no hay operaciones en curso
const initialState = {
    inventarios: [],
    categorias: [],
    tomas: [],
    inventario: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusToma: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const inventarioSlice = createSlice({
    name:"inventario",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchTomas.pending, (state, action) => {state.statusToma = 'loading'} ) //fetch
        .addCase(fetchTomas.fulfilled, (state, action) => {
            state.statusToma = 'succeeded';
            if (JSON.stringify(state.tomas) !== JSON.stringify(action.payload)) { //fetch
                state.tomas = action.payload;
            }
        })
        .addCase(fetchTomas.rejected, (state, action) => { //fetch
            state.statusToma = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchInventarios.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchInventarios.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.inventarios) !== JSON.stringify(action.payload)) { //fetch
                state.inventarios = action.payload;
            }
        })
        .addCase(fetchInventarios.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchCategorias.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchCategorias.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.categorias) !== JSON.stringify(action.payload)) { //fetch
                state.categorias = action.payload;
            } 
        })
        .addCase(fetchCategorias.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchInventario.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchInventario.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.inventario) !== JSON.stringify(action.payload)) { //fetch
                state.inventario = action.payload;
            }
        })
        .addCase(fetchInventario.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewProducto.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.inventarios.push(action.payload.data);
            } else {
                state.status = 'failed'
            }
        })
        .addCase(addNewCategoria.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.categorias.push(action.payload.data);
            } else {
                state.status = 'failed'
            }
        })
        .addCase(addNewToma.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.statusToma = 'succeeded';
                state.tomas.push(action.payload.data);
            } else {
                state.statusToma = 'failed'
            }
        })
        
        .addCase(deleteInventario.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteInventario.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.inventarios = state.inventarios.filter(inventario => inventario.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteInventario.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(deleteCategoria.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteCategoria.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.categorias = state.categorias.filter(categoria => categoria.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteCategoria.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateInventario.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateInventario.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.inventarios = state.inventarios.map(inventario => {
                    if (inventario.id === action.payload.id) {
                        return action.payload.data;
                    }
                    return inventario;
                });
            }else{
                state.status = 'failed';
            }
        })
        .addCase(updateInventario.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(updateCategoria.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateCategoria.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.categorias = state.categorias.map(categoria => {
                    if (categoria.id === action.payload.id) {
                        return action.payload.data;
                    }
                    return categoria;
                });
            }else{
                state.status = 'failed';
            }
        })
        .addCase(updateCategoria.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        
    }
})

export const getAllInventarios = (state) => state.inventarios.inventarios;
export const getAllCategorias = (state) => state.inventarios.categorias;
export const getAllTomas = (state) => state.inventarios.tomas;
export const getTomasStatus = (state) => state.inventarios.statusToma;
export const fetchInventarioData = (state) => state.inventarios.inventario;
export const getInventariosStatus = (state) => state.inventarios.status;
export const getInventariosError = (state) => state.inventarios.error;

export const {} = inventarioSlice.actions;
export default inventarioSlice.reducer;