import styles from "./components.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";
import { getAllCampamentos } from "../../../redux/features/campamentos/campamentoSlice";
import { updateSector } from "../../../redux/features/sectores/sectorSlice";
import { updateBodega } from "../../../redux/features/bodegas/bodegaSlice";
import { MuiFileInput } from "mui-file-input";
import Map from "./MapEdit";

let center = {
  lat: 0,
  lng: 0,
};

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [formData, setFormData] = useState(null);
  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [value, setValue] = useState(null);
  const [position, setPosition] = useState(center);

  useEffect(() => {
    if (position) {
      setFormData({
        ...formData,
        latitud: position?.lat,
        longitud: position?.lng,
      });
    }
  }, [position]);

  useEffect(() => {
    if (seleItem) {
      setFormData(seleItem);
      setSelectedCampamento(seleItem?.campamento);
      setFormData((prev) => {
        return {
          ...prev,
          campamentoId: seleItem?.campamento?.id,
        };
      });
      if (seleItem?.latitud && seleItem?.longitud) {
        let newPosition = {
          lat: seleItem?.latitud,
          lng: seleItem?.longitud,
        };
        setPosition(newPosition);
      }
    }
  }, [seleItem]);

  const handleChangeCampamento = (e, value) => {
    if (value != null) {
      setSelectedCampamento(value);
      setFormData({ ...formData, campamentoId: value.id });
    } else {
      setFormData({ ...formData, campamentoId: null });
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
    setFormData({ ...formData, imagen: newValue });

    if (newValue) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
      };
      reader.readAsDataURL(newValue);
    } else {
      setNewImage(null);
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const canSave =
    [
      formData?.nombre,
      formData?.campamentoId,
      formData?.longitud,
      formData?.latitud,
      formData?.descripcion,
    ].every(Boolean) && addRequestStatus === "idle";

  const handleEdit = async () => {
    try {
      setOpen3(true);
      setAddRequestStatus("loading");
      let resp = await dispatch(updateBodega(formData));
      if (resp.payload.status === "success") {
        setOpen3(false);
      }
    } catch (error) {
      console.error("Error al actualizar el item", error.message);
    } finally {
      setAddRequestStatus("idle");
      setOpenEdit(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openEdit}
      PaperProps={
        {
          sx: {
            width: { xs: '100%', sm: '75%', md: '50%' },
            backgroundColor:'#2d3037',
            color:'#FFF'
          }
        }
    }
      onClose={() => setOpenEdit(false)}
    >
      {seleItem && (
        <>
        
          {/*
              */}
          <DialogTitle>
            <Grid container={true} spacing={1} p={2}>
              <Grid
                item
                xs={12}
                sm={12}
                mb={2}
                style={{ display: "flex", gap: "5px" }}
              >
                <ErrorIcon
                  style={{ fontSize: "3rem" }}
                  className={styles.iconBg}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6" className={styles.textIconEdit}>
                    Actualizar bodega {seleItem?.nombre}
                  </Typography>
                  <Typography variant="span" className={styles.textIconSpan}>
                    Modifica los campos para actualizar la bodega.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container={true} spacing={1} p={2}>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Campamento</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  options={campamentos || []}
                  value={selectedCampamento}
                  name="bodega"
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value) => handleChangeCampamento(e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona un campamento" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Nombre</label>
                <TextField
                 className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  value={formData?.nombre}
                  name="nombre"
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <label className={styles.label}>Descripción</label>
                <TextField
                 className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name="descripcion"
                  value={formData?.descripcion}
                  type="text"
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Map
                position={position}
                setPosition={setPosition}
                setFormData={setFormData}
                formData={formData}
                seleItem={seleItem}
              />
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Longitud</label>
                <TextField
                 className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  value={position?.lat}
                  fullWidth
                  name="longitud"
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={styles.label}>Latitud</label>
                <TextField
                 className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  value={position?.lng}
                  fullWidth
                  name="latitud"
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label
                  className={styles.label}
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <CloudUploadIcon style={{ height: "20px" }} /> Imagen del
                  plano (opcional)
                </label>
                <MuiFileInput
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiFormHelperText-root': {
                      color: '#FFF',
                    },
                  }}
                  inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
                  value={value}
                  onChange={handleChange}
                  helperText="Extensiones validas: .jpg, .jpeg, .png"
                  style={{ width:'100%', color:"#FFF" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className={styles.label}>Imagen actual</label>
                {newImage ? (
                  <img
                    style={{ height: "100%", width: "100%" }}
                    src={newImage}
                    alt="img_bodega"
                  />
                ) : formData?.imagen ? (
                  <img
                    style={{ height: "100%", width: "100%" }}
                    src={`${process.env.REACT_APP_API_URL}${formData?.imagen}`}
                    alt="img_bodega"
                  />
                ) : (
                  "Sin imagen"
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container={true}>
              <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                <Button
                  onClick={handleEdit}
                  className={styles.bgColorEdit}
                  variant="contained"
                  disabled={!canSave}
                >
                  {addRequestStatus == "loading" ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Actualizar"
                  )}
                </Button>
                <Button
                  onClick={() => setOpenEdit(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Drawer>
  );
}
