import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { createAxiosInstance } from "../../../api/axios";

const OTROS_URL = '/api/v1/Edificio';
const ADD_OTRO_URL = '/api/v1/Edificio';
const UPDATE_OTRO_URL = '/api/v1/Edificio/';
const DELETE_OTRO_URL = '/api/v1/Edificio/';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchOtros = createAsyncThunk("otros/fetchOtros", async ()=> {
    try {
        const response = await axiosInstance.get(OTROS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteOtro = createAsyncThunk("otros/deleteOtro", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_OTRO_URL+id);
        if (response.status === 200) {
            toast.success('Recinto eliminado correctamente.');
            dispatch(fetchOtros());
            return { id, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el recinto: ' + e.response.data);
        return e.message;
    }
})
export const updateOtro = createAsyncThunk("otros/updateOtro", async (upCampamento, {dispatch})=> {
    console.log('id', upCampamento.id);
    try {
        const response = await axiosInstance.put(UPDATE_OTRO_URL+upCampamento.id, {
            nombre: upCampamento.nombre,
            campamentoid: upCampamento.campamentoId,
            numeroPisos: upCampamento.numeroPisos,
        });
        
        if (response.status === 200 ) {
            toast.success('Recinto actualizado correctamente.');
            dispatch(fetchOtros());
            return { data: response.data, status: 'success' };
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el recinto: ' + e.response.data);
        return e.message;
    }
})

export const addNewOtro = createAsyncThunk("otros/createCampamentos", async (initialCampamento, { dispatch }) => {
    console.log(initialCampamento)
    try {
        const response = await axiosInstance.post(ADD_OTRO_URL, {
            nombre: initialCampamento.nombre,
            campamentoid: initialCampamento.campamentoId,
            numeroPisos: initialCampamento.numeroPisos,
        });
        if (response.status == 200) {
            toast.success('Recinto creado correctamente.');
            dispatch(fetchOtros());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al crear el recinto: ' + e.response.data);
        console.log(e.message)
        return {message: e.message, status: 'error'};
    }
})


// idle : no hay operaciones en curso
const initialState = {
    otros: [],
    otro: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const otroSlice = createSlice({
    name:"otro",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchOtros.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchOtros.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.otros) !== JSON.stringify(action.payload)) { //fetch
                state.otros = action.payload;
            }
        })
        .addCase(fetchOtros.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewOtro.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.otros.push(action.payload.data);
            } else {
                state.status = 'failed'
            }
        })
        
        .addCase(deleteOtro.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteOtro.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.otros = state.otros.filter(otro => otro.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteOtro.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateOtro.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateOtro.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.otros = state.otros.map(otro => {
                    if (otro.id === action.payload.id) {
                        return action.payload.data;
                    }
                    return otro;
                });
            }else{
                state.status = 'failed';
            }
        })
        .addCase(updateOtro.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
        
    }
})

export const getAllOtros = (state) => state.otros.otros;
export const fetchOtrosData = (state) => state.otros.otro;
export const getOtrosStatus = (state) => state.otros.status;
export const getOtrosError = (state) => state.otros.error;

export const {} = otroSlice.actions;
export default otroSlice.reducer;