import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useState, useTransition } from "react";
import { useForm } from 'react-hook-form';
import { validateLoginCredentials, validateSetPass } from "../../../utils/validate";
import axios from "../../../api/axios";
import { useAuth } from "../../../context/AppContext";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, setPass } from "../../../redux/features/login/loginSlice";
import { toast } from "react-toastify";

const SetPassForm = ({ id, token }) => {

    const { login } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const updateData = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
    const [errors, setErrors] = useState({ pass: "", setpass: "" });
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({ id, token, pass: "", setpass: "" });
    
    const onSubmit = async () => {
        setError('');
        setLoading(true);
        try {
            errors.pass = '';
            errors.setpass = '';
            const validate = validateSetPass(formData);
            if (validate.error) {
                setErrors({ ...errors, [validate.error.type]: validate.error.message });
                console.log(errors);
                return;
            }
            const resp = await dispatch(setPass(formData));
            if (resp.payload.status == 'success') {
                navigate('/')
            }
            setError(resp.message);
            return;
        } catch (error) {
            console.log('error en el setPass', error);
            setError(error.message);
        } finally{
            setLoading(false);
        }
    }


    return (
        <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
                <span>Contraseña</span>
                <TextField
                    variant="outlined"
                    type="password"
                    name="pass"
                    placeholder="*******"
                    fontFamily='Poppins'
                    error={errors.pass.length > 0 ? true : false}
                    helperText={errors.pass.length > 0 && <span style={{ fontFamily: 'Poppins' }}>{errors.pass}</span>}
                    fullWidth={true}
                    onChange={(e) => updateData(e)}
                    style={{ fontFamily: 'Poppins' }}
                />
            </Grid>
            <Grid item={true} xs={12}>
                <span>Repite la contraseña</span>
                <TextField
                    variant="outlined"
                    type="password"
                    name="setpass"
                    placeholder="*******"
                    fontFamily='Poppins'
                    error={errors.setpass.length > 0 ? true : false}
                    helperText={errors.setpass.length > 0 && <span style={{ fontFamily: 'Poppins' }}>{errors.setpass}</span>}
                    fullWidth={true}
                    onChange={(e) => updateData(e)}
                    style={{ fontFamily: 'Poppins' }}
                />
            </Grid>
            <Grid item={true} xs={12} mb={2} >
                <Button
                    style={{ background: !loading ? '#174300' : 'rgba(23, 67, 0, 0.64)', fontFamily: 'Poppins', marginBottom: error ? '10px' : '0' }}
                    variant="contained"
                    size="large"
                    disabled={loading}
                    fullWidth={true}
                    onClick={onSubmit}
                >
                    {!loading && <span>Enviar</span>}
                    {loading && <CircularProgress size={24} style={{ color:'white' }}/>}
                </Button>
                {error && <span className="error-toast">{error}</span>}
            </Grid>
        </Grid>
    )
}


export default SetPassForm