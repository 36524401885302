import {  esES, DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { utils, writeFile } from 'xlsx';
import { Button } from '@mui/material'

const CustomToolbar = ({ rows, columns }) => {
    const exportToExcel = () => {
        const data = rows.map(row => {
            return columns.reduce((acc, col) => {
                if (col.renderCell) {
                    // Si hay una función renderCell, úsala para obtener el valor
                    const renderedValue = col.renderCell({ row });
                    // Extrae el texto del elemento renderizado, si es necesario
                    if (typeof renderedValue === 'object' && renderedValue?.props?.children) {
                        acc[col.headerName] = renderedValue.props.children;
                    } else {
                        acc[col.headerName] = renderedValue;
                    }
                } else {
                    // De lo contrario, usa el campo directamente
                    acc[col.headerName] = row[col.field];
                }
                return acc;
            }, {});
        });

        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        writeFile(workbook, 'data.xlsx');
    };


    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton style={{ color:'#2d3037' }}/>
            <GridToolbarDensitySelector style={{ color:'#2d3037' }}/>
            <GridToolbarExport 
                csvOptions={{ disableToolbarButton: false }} 
                printOptions={{ disableToolbarButton: false }} 
                style={{ color: '#2d3037' }} 
           />
           <Button onClick={exportToExcel} style={{ color: '#2d3037' }}>Exportar a Excel</Button>
        </GridToolbarContainer>
    );
};
export default CustomToolbar;