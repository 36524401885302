import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FeedIcon from '@mui/icons-material/Feed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const RenderCell = ({ row, setOpen, setSeleItem, setAbrirEdit, setAbrirRepetir }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = (param) => {
    setSeleItem(row);
    setAbrirEdit(true);
    handleClose();
  };
  
  const handleDeleteClick = () => {
    setSeleItem(row);
    setOpen(true);
    handleClose();
  };

  return (
    <div>
      <IconButton style={{ color:'#FFF' }} onClick={(event) => {
        event.stopPropagation(); // Evitar la propagación del evento
        handleClick(event); // Pasar el evento a handleClick
      }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=>{
          setSeleItem(row);
          setAbrirEdit(true);
          setAnchorEl(false);
        }}>
          <EditIcon style={{ marginRight: 8 }} />
          Editar
        </MenuItem>
        <MenuItem onClick={()=>{
          setSeleItem(row);
          setAbrirRepetir(true);
          setAnchorEl(false);
        }}>
          <FeedIcon style={{ marginRight: 8 }} />
          Repetir
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon style={{ marginRight: 8 }} />
          Eliminar
        </MenuItem>
      </Menu>
    </div>
  );
};