import './App.css';

import Login from './pages/login/Login';
import SetPass from './pages/login/SetPass';
import { Routes, Route, Navigate } from 'react-router-dom';
import Unauthorized from './pages/Unauthorized/Unauthorized';
import Layout from './components/layouts/LayoutAdmin';
import { useAuth } from './context/AppContext';
import ProtectedRoute from './components/RequireAuth';
import Usuarios from './pages/Mantenedores/usuarios/Usuarios';
import Campamentos from './pages/Mantenedores/campamentos/Campamentos';
import Wings from './pages/Mantenedores/wing/Wings';
import Sectores from './pages/Mantenedores/sectores/Sectores';
import Espacios from './pages/Mantenedores/espacios/Espacios';
import MotivosSolicitud from './pages/Mantenedores/motivos/MotivosSolicitud';
import Habitaciones from './pages/Mantenedores/habitaciones/Habitacion';
import Responsables from './pages/Mantenedores/responsables/Responsables';
// import Reservas from './pages/AdministradorContrato/reservas/Reservas';
import Reservas from './pages/AdministradorContrato/reservas/ReservasNew';
import LayoutAdminContrato from './components/layouts/LayoutAdminContrato';
import Dashboard from './pages/admAplicacion/Dashboard';
import Inventario from './pages/admInventario/Inventario';
import Articulo from './pages/admInventario/Articulo';
import Solicitudes from './pages/recepcionista/reservas/Solicitudes';
import EstadoReserva from './pages/recepcionista/reservas/EstadoReserva';
import EstadoReservaRecepcionista from './pages/recepcionista/reservas/EstadoReservaRecepcionista';
import Edificios from './pages/Mantenedores/edificios/Edificios';
import Standar from './pages/Mantenedores/standar/Standar';
import DashboardRecepcionista from './pages/recepcionista/dashboard/Dashboard';
import Historial from './pages/recepcionista/reservas/Historial';
import CriticidadRequemientos from './pages/admAplicacion/criticidad/CriticidadRequemientos';
import Otros from './pages/Mantenedores/otros/Otros';
import Aprobacion from './pages/AdministradorContrato/reservas/Aprobacion';
// import Oficina from './pages/AdministradorContrato/oficinas/Oficina';
import DashboardHuesped from './pages/huesped/DashboardHuesped';
import ReclamosSugerencias from './pages/huesped/ReclamosSugerencias';
import DetallesReservas from './pages/huesped/DetallesReservas';
import Huespedes from './pages/Mantenedores/huespedes/Huespedes';
import Ingreso from './pages/AdministradorContrato/ingreso/Ingreso';
import Gestion from './pages/AdministradorContrato/gestion/Gestion';
import Revision from './pages/AdministradorContrato/revision/Revision';
import Aseo from './pages/Mantenedores/aseo/Aseo';
import Horario from './pages/aseo/horario/Horario';
import Tareas from './pages/aseo/tareas/Tareas';
import Personal from './pages/aseo/personal/Personal';
import Planificacion from './pages/aseo/planificacion/Planificacion';
import PlanificacionEspecifica from './pages/aseo/planificacionEspecifica/PlanificacionEspecifica';
import FeedBack from './pages/campamentero/feedback/FeedBack';
import Grupos from './pages/aseo/grupos/Grupos';
import Asignadas from './pages/aseo/asignadas/Asignadas';
import Listado from './pages/aseo/listado/Listado';
import Categorias from './pages/admInventario/Categorias';
import Notificaciones from './pages/admInventario/Notificaciones';
import NotificacionesSupervisor from './pages/admInventario/NotificacionesSupervisor';
import Merma from './pages/admInventario/Merma';
import Ingresos from './pages/admInventario/Ingresos';
import Salidas from './pages/admInventario/Salidas';
import Bodega from './pages/admInventario/Bodega';
import Comparacion from './pages/admInventario/Comparacion';
import EspaciosBodega from './pages/admInventario/EspaciosBodega';
import Almacenamiento from './pages/Adm.Guardarropia/almacenamiento/Almacenamiento';
import AsignacionEspacios from './pages/Adm.Guardarropia/asignacion-espacios/AsignacionEspacios';
import Oficina from './pages/admOficina/oficinas/Oficina';
import GestionOficina from './pages/admOficina/gestion/Gestion';
import Traslado from './pages/admInventario/Traslado';
import ReservasRecepcionista from './pages/recepcionista/reservas/Reservas';
import { useEffect } from 'react';
import CargaInicial from './pages/admAplicacion/cargaInicial/CargaInicial';
import ResetPass from './pages/login/ResetPass';
import ReservaCmdic from './pages/adm.cmdic/ReservaCmdic'

function App() {
  const { info } = useAuth();
  const rol = info?.roles;
  console.log('rol', rol);

  return (
    <Routes>
    {
      (rol?.includes('Administrador de Aplicaciones (AAP)') || rol?.includes('Personal de Aseo') || rol?.includes('Administrador de Contrato ESED (ACE)') || rol?.includes('Administrador de Contrato CMDIC (ACC)') || rol?.includes('Huésped') || rol?.includes('Administrador de Inventario (AIN)') || rol?.includes('Recepcionista') || rol?.includes('Administrador de Guardarropía (AGU)') || rol?.includes('Responsable ingreso de reservas ESED (RIR)')|| rol?.includes('Administrador de Aseo (AAS)') || rol?.includes('Administrador de Oficina') || rol?.includes('Administrador de RRHH (ARH)') || rol?.includes('Encargado de Aprobaciones Bodega') ) &&
      <Route element={<Layout />}>
        <Route element={<ProtectedRoute allowedRoles={["Administrador de Aplicaciones (AAP)", "Administrador de Contrato ESED (ACE)", "Administrador de Contrato CMDIC (ACC)", "Huésped", "Administrador de Inventario (AIN)", "Recepcionista", "Administrador de Guardarropía (AGU)", "Administrador de Aseo (AAS)", "Administrador de Oficina", "Personal de Aseo", "Responsable ingreso de reservas ESED (RIR)", "Administrador de RRHH (ARH)", "Encargado de Aprobaciones Bodega"]} />}>
          {/* Rutas comunes a todos los roles */}
          <Route path='/inicio' element={<Dashboard />} />
    
          {/* Rutas específicas para cada rol */}
          {rol?.includes('Administrador de Aplicaciones (AAP)') && ( 
            <>
              {/* Rutas específicas para el rol de Administrador de Aplicaciones (AAP) */}
              <Route path='/campamentos' element={<Campamentos />} />
              <Route path='/sectores' element={<Sectores />} />
              <Route path='/wings' element={<Wings />} />
              <Route path='/pabellones' element={<Edificios />} />
              <Route path='/otros' element={<Otros />} />
              <Route path='/habitaciones' element={<Habitaciones />} />
              <Route path='/estandar' element={<Standar />} />
              <Route path='/espacios' element={<Espacios />} />
              <Route path='/criticidad_requerimientos' element={<CriticidadRequemientos/>} />
              <Route path='/oficinas' element={<Oficina />} />
              <Route path='/usuarios' element={<Usuarios />} />
              <Route path='/responsables' element={<Responsables />} />
              <Route path='/huespedes' element={<Huespedes />} />
              <Route path='/carga_inicial' element={<CargaInicial />} />

              <Route path='/requerimientos' element={<MotivosSolicitud />} />
              <Route path='/aseo' element={<Aseo />} />
              

              // ----------- VER SI LAS SIGUIENTES RUTAS ESTÁN EN EL SIDEBAR ---------------------
            </>
          )} 
          {rol?.includes('Administrador de Contrato ESED (ACE)') && ( 
            <>
            <Route path='/ingreso' element={<Ingreso />} /> // Forecast
            <Route path='/correcciones' element={<FeedBack />} /> // Forecast
            <Route path='/gestion' element={<Gestion />} /> // Forecast
          </> 
           )} 
           {rol?.includes('Administrador de Aseo (AAS)') && (
            <>
              <Route path='/horarios' element={<Horario/>} />
              <Route path='/planificacion' element={<Planificacion/>} />
              <Route path='/planificacion_especifica' element={<PlanificacionEspecifica/>} />
              <Route path='/grupos' element={<Grupos/>} />
              <Route path='/personal' element={<Personal/>} />
              <Route path='/tareas' element={<Tareas/>} />
              <Route path='/asignadas' element={<Asignadas/>} />
              <Route path='/listado' element={<Listado/>} />
            </>
           )}
           {rol?.includes('Personal de Aseo') && (
            <>
              <Route path='/asignadas' element={<Asignadas/>} />
            </>
           )}
           {rol?.includes('Responsable ingreso de reservas ESED (RIR)') && (
            <>
              <Route path='/reservas' element={<Reservas/>} />
            </>
           )}
           {rol?.includes('Administrador de hotelería') && ( 
           // PREGUNTAR POR QUE VISTA ES ESTA Y SI ES QUE ASIGNA HABITACIONES O LE CAMBIA EL ESTADO A PENDIENTE
            <>
              <Route path='/historial' element={<EstadoReservaRecepcionista/>} />
            </>
           )}
           
           {rol?.includes('Administrador de Guardarropía (AGU)') && ( 
            <>
              <Route path='/almacenamiento' element={<Almacenamiento/>} />
              <Route path='/asignacion-espacios' element={<AsignacionEspacios/>} />
            </>
           )} 

           {rol?.includes('Administrador de Contrato CMDIC (ACC)') && (
            <>
              <Route path='/gestion' element={<Gestion />} />
            </>
            )}

          {rol?.includes('Administrador de RRHH (ARH)') && (
            <>
            {/*
              <Route path='/correcciones' element={<FeedBack />} />
            */}
            <Route path='/gestion' element={<Gestion />} />
            </>
          )}

          {rol?.includes('Huésped') && (
            <>
              <Route path='/info-reservas' element={<DetallesReservas />} />
              <Route path='/reclamos-sugerencias' element={<ReclamosSugerencias />} /> 
            </>
          )}
          {rol?.includes('Encargado de Aprobaciones Bodega') && (
            <>
              <Route path='/notificaciones' element={<NotificacionesSupervisor />} />
            </>
          )}
    
          {rol?.includes('Administrador de Inventario (AIN)') && (
            <>
            <Route path='/categorias' element={<Categorias />} />
              <Route path='/inventario' element={<Inventario />} />
              <Route path='/articulos' element={<Articulo />} /> //-------------- PREGUNTAR POR ESTO --------------
              <Route path='/notificaciones' element={<Notificaciones />} />
              
              <Route path='/comparacion' element={<Comparacion />} /> ------------ NUEVA ------------
              <Route path='/merma' element={<Merma />} /> ------------ NUEVA ------------
              <Route path='/bodega' element={<Bodega />} /> ------------ NUEVA ------------
              <Route path='/espacios_bodega' element={<EspaciosBodega />} /> ------------ NUEVA ------------
              <Route path='/ingresos' element={<Ingresos />} /> ------------ NUEVA ------------
              <Route path='/salidas' element={<Salidas />} /> ------------ NUEVA ------------
              <Route path='/traspaso' element={<Traslado />} /> ------------ NUEVA ------------
            </>
          )}
    
          {rol?.includes('Recepcionista') && ( 
            <>
              <Route path='/solicitudes' element={<Solicitudes/>} />
              <Route path='/reservas' element={<ReservasRecepcionista/>} /> 
              <Route path='/gestion' element={<GestionOficina />} />
              <Route path='/historial' element={<EstadoReservaRecepcionista/>} />
             <Route path='/historial2' element={<Historial/>} />
             <Route path='/reservascmdic' element={<ReservaCmdic />} />
            </>
          )}
        </Route>
      </Route>
    }
    
    
      
    
      <Route exact path='/' element={<Login />} />
      <Route exact path='/getpass' element={<ResetPass />} />
      <Route exact path='/register/setpassword' element={<SetPass />} />
      <Route exact path='/unauthorized' element={<Unauthorized />} />
      // <Route path="*" element={<span></span>} />

    </Routes>
  );
}

export default App;

 
 
          //   <Route path='/reservas' element={<ReservaCmdic />} />
          //   <Route path='/ingreso' element={<Ingreso />} />
          //   <Route path='/aprobacion' element={<Aprobacion/>} />
            
          // <Route path='/revision' element={<Revision />} />
          // <Route path='/correcciones' element={<FeedBack />} />

//--------- REVISIÓN ---------
// adm.app: 
// - criticidad de requirimientos: check, en el edit la prioridad no carga
// - oficinas: check, ver el tema del edit campamento (quizas no es necesario tenerlo)

// Guardarropia
  // adm.guardarropia
    // almacenamiento: check, preguntar si cuando se asigna un espacio en la vista de "asignación" el campo "ocupado" se actualiza o no
    // Asignación de espacios: check, no esta filtrando, preguntar por lo del casillero
    // grafícos
  // Huesped:
  //  dashboard: ruta: Guardarropia -> Items del Huesped falta el campamento en el objeto espacioAlmacenamiento

// Reclamos y sugerencias
  // Recepcionista
    // Dashboard tabla reclamos y sugerencias:  Get de ReclamosSugerencia falta que retorne el id, habitacion, pabellon-wing-edifio, campamento 
    // y huesped que lo trae nulo pero solo cuando lo agrega el recepcionista, cuando lo agrega el huesped si lo trae

    //  -----------------------------------------------------------------------------------------------------------------------
// Forecast (Preguntar que vistas ve cada rol) (MODIFICAR ESTILOS)
  // ESSED: Error al editar en las tres vistas (v1/Forecast/10) y necesito permisos para essed en aprobar y rechazar
    // - ingreso: en el get de forecast en la propiedad tramos, "comentarios" viene null, tabla del create y edit (son la misma) no funciona bien al solapar fechas(modificar estilos EDIT)
    // - Correciones: mismo error de la tabla, al añadir notas despues no las trae en el get
    // Gestión: check, falta fix de rutas aprobar, rechas y put

  // Aseo:
    // admAseo:
      // Dashboard: modificar estilos, ESTADISTICAS, CREAR EL GRUPO Y ASIGNARLA (FALTA MODIFICAR EL MODAL)
      //  Horario: check, falta la ruta de la api
      //  Grupo: check, en el get grupo-habitacion falta incluir edificios, piso y grupo asignado, preguntar por lo del numero de habitacione en la tabla.
      // Planificación diaria: revisar el repetir parece que no trae los ruts asociados
      // Planificación especifica: falta api edit y delete. arreglar el edit
      // Tareas de limpieza: check
      // Personal de aseo: hacer posible la modificación del correo en cada trabajador
    // Personal:
        //habitaciones asignadas:  preguntar por planificaciones especificas ya que ahi no se le asigna habitación, preguntar como veo despues ese registro al actualizar lo asignado
  // Oficinas: 
  //  recepcionista:

  // INVENTARIO:
  // categorias: check
  // inventario: check
  // Bodega: check, indicar que se tiene que mover el icono del mapa, ver el tema del edit
  // toma de inventario: check, revisar a fondo si.
  // espacios en bodega: check
  // ingresos: check
  // salidas: check, falta api para eliminar
  // traspaso: check, faltan los campos documento, tipoducomento y monto total
  // notificaciones: ------------------ FALTA CONECTAR --------------------------

// RESERVA ESSED
  //reserva:  
    // Desplegar toda la info en la tabla. 
    // arreglar lo de TURNOS.MAP IS NOT A FUNCTION CUANDO LA API TIENE 403
    // Estilizar y hacer el edit
    // mostrar la data en la tabla

  // RESERVA RECEPCIONISTA
  // 


  // CAMBIOS GENERALES:
  // VER LO DEL AUTOCOMPLETE QUE QUEDA DENTRO DEL MODAL, POR EJEMPLO EN GESTIÓN DE OFICINAS DEL RECEPCIONISTA
  // Arreglar lo del icono del dash que cierra sesión.
  // falta ver lo del id de la solicitud


  // 141 indicar a berna que se agregaron esos campos