import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, ListItem, List, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampamentos } from '../../../../redux/features/campamentos/campamentoSlice';
import { getAllEspacios } from "../../../../redux/features/guardarropia/guardarropiaSlice";
import { useEffect } from 'react';
import { updateSector } from '../../../../redux/features/sectores/sectorSlice';
import { updateEspacio, updateEspacioAlmacenamiento } from '../../../../redux/features/guardarropia/guardarropiaSlice';

const data = {
    fila: 0,
    nivel: 0,
    numero: '',
    campamento: 0,
  }

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3, registros }) {
    const [formData, setFormData] = useState(data);
    const dispatch = useDispatch();
    const campamentos = useSelector(getAllCampamentos);
    const espacios = useSelector(getAllEspacios);
    const [ultimoNumero, setUltimoNumero] = useState(0);

    const [open, setOpen] = useState(false);
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [selectedCampamento, setSelectedCampamento] = useState(null);
    const [selectedFila, setSelectedFila] = useState(null);
    const [selectedNivel, setSelectedNivel] = useState(null);
    const [selectedNumero, setSelectedNumero] = useState(null);

    const [ocupado, setOcupado] = useState(false);

    const [filasNivelesNumeros, setFilasNivelesNumeros] = useState([]);

    const [search, setSearch] = useState('');
    const [filteredNumbers, setFilteredNumbers] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContent, setDialogContent] = useState("");

    const [isCampamentoEnabled, setIsCampamentoEnabled] = useState(false);
    const [isFilaEnabled, setIsFilaEnabled] = useState(false);
    const [isNivelEnabled, setIsNivelEnabled] = useState(false);
    const [isNumerosEnabled, setIsNumerosEnabled] = useState(false);

    const [errorFila, setErrorNumero] = useState(null);

    useEffect(() => {
        if (openEdit) {
            setIsCampamentoEnabled(false);
            setIsFilaEnabled(false);
            setIsNivelEnabled(false);
            setIsNumerosEnabled(false);
            setErrorNumero(null);
        }
    }, [openEdit]);

    useEffect(() => {
        if (seleItem) {
          console.log('seleItem', seleItem);
          setFormData(seleItem); // Actualiza formData con seleItem
          setSelectedCampamento(seleItem.campamento);
          console.log('formData', formData);
          // Evaluar las condiciones correctamente con comparaciones estrictas (===)
          if (formData.campamento && formData.campamento !== null ) {
            setIsCampamentoEnabled(true);
          }
          if (formData.fila && formData.fila !== null) {
            setIsFilaEnabled(true);
          }
          if (formData.nivel && formData.nivel !== null) {
            setIsNivelEnabled(true);
          }
          if (formData.numero && formData.numero !== null) {
            setIsNumerosEnabled(true);
          }
      
          setOcupado(!formData?.estado);
        }
      }, [seleItem]); // Dependencia del efecto: seleItem
      
        
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const handleChangeCampamento = (event, newValue) => {
        setSelectedCampamento(newValue);
        if(newValue != null){
          setFormData({...formData, campamento: newValue});
          const espaciosFiltrados = espacios.filter(item => item.campamento.id === newValue.id);
          const filasNivelesNumeros = espaciosFiltrados.map(item => ({
            campamento: item.campamento.id,
            fila: item.fila,
            nivel: item.nivel,
            numero: item.numero
          }));
          setFilasNivelesNumeros(filasNivelesNumeros);
          setIsCampamentoEnabled(true);
        } else{
          setFormData({...formData, campamento: null})
          setFilasNivelesNumeros([])
          setIsCampamentoEnabled(false);
          setSelectedFila(null);
        }    
      };
      
    const handleChangeCheckbox = () => {
    setOcupado(!ocupado);
    setFormData({...formData, ocupado: !ocupado });
    };
    
    const canSave = [formData.fila, formData.nivel, formData.numero, formData.campamento != 0].every(Boolean) && addRequestStatus === 'idle';    

    const handleEdit = async () => {
        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateEspacioAlmacenamiento(formData))
            if(resp.payload.status === 'success'){
                setOpenEdit(false);
            }
        } catch (error) {
            console.error('Error al actualizar el espacio', error.message);
        } finally{
            setOpen3(false);
            setAddRequestStatus('idle');
        }
    }

    const handleOpenFila = () => {
        const filas = filasNivelesNumeros.filter(item => item.campamento === selectedCampamento.id);
        if (filas.length > 0) {
          setDialogTitle("Filas creadas");
          setDialogContent(
            <List>
              {filas.map((fila, index) => (
                <ListItem key={index}>{fila.fila}</ListItem>
              ))}
            </List>
          );
        }else{
          setDialogContent("No existen filas en el campamento");
        }
        setOpenDialog(true);
    };
      
    const handleCloseFila = () => {
    setOpenDialog(false);
    setDialogTitle();
    setDialogContent();
    };
    
    const handleOpenNivel = () => {
    const niveles = filasNivelesNumeros.filter(item => item.fila === selectedFila);
    if (niveles.length > 0) {
        setDialogTitle("Niveles creados");
        setDialogContent(
        <List>
            {niveles.map((nivel, index) => (
            <ListItem key={index}>{nivel.nivel}</ListItem>
            ))}
        </List>
        );
    }else{
        setDialogContent("No existen niveles creados en la fila seleccionada");
    }
    setOpenDialog(true);
    };
    
    const handleCloseNivel = () => {
    setOpenDialog(false);
    setDialogTitle();
    setDialogContent();
    };
    
    const handleOpenNumero = () => {
    const numeros = filasNivelesNumeros.filter(item => item.fila === selectedFila && item.nivel === selectedNivel && item.numero);
    if (numeros.length > 0) {
        setDialogTitle("Números creados");
        setDialogContent(
        <List>
            {numeros.map((numero, index) => (
            <ListItem key={index}>{numero.numero}</ListItem>
            ))}
        </List>
        );
    }else{
        setDialogContent("No existen numeros creados en el nivel seleccionado");
    }
    setOpenDialog(true);
    };
    
    const handleCloseNumero = () => {
    setOpenDialog(false);
    setDialogTitle();
    setDialogContent();
    };
    
    const handleFilaChange = (event) => {
    const nuevaFila = event.target.value;
    if(nuevaFila){
        setSelectedFila(nuevaFila);
        if (nuevaFila) {
        let filaInt = parseInt(nuevaFila);
        setFormData({...formData, [event.target.name]: nuevaFila});
        setIsFilaEnabled(true);
        } else {
        setIsFilaEnabled(false);
        setSelectedNivel(null);
        }
    }else{
        setIsFilaEnabled(false);
        setFormData({...formData, [event.target.name]: 0});
    }
    };
    
    const handleNivelChange = (event) => {
    setIsCampamentoEnabled(false);
    const nuevoNivel = event.target.value;
    if(nuevoNivel){
        setSelectedNivel(nuevoNivel);
        if (nuevoNivel) {
        let nivelInt = parseInt(nuevoNivel);
        setFormData({...formData, [event.target.name]: nuevoNivel});
        setIsNivelEnabled(true);
        } else {
        setIsNivelEnabled(false);
        setSelectedNumero(null);
        }
    }else{
        setFormData({...formData, [event.target.name]: 0});
        setIsCampamentoEnabled(true);
        setIsNivelEnabled(false);
    }    
    };
    
    const handleNumberChange = (event) => {
        setIsFilaEnabled(false);
        const nuevoNumero = event.target.value;
        if(nuevoNumero){
            setSelectedNumero(nuevoNumero);
            const numeroExists = filasNivelesNumeros.some(item => item.fila === selectedFila && item.nivel === selectedNivel && item.numero === nuevoNumero);
            if (!numeroExists && nuevoNumero) {
            setFormData({...formData, [event.target.name]: nuevoNumero});
            setIsNumerosEnabled(true);
            setErrorNumero(null);
            } else {
            setErrorNumero('Existente');
            setIsNumerosEnabled(false);
            }
        }else{
            setFormData({...formData, [event.target.name]: ''});
            setErrorNumero(null);
            setIsFilaEnabled(true);
        }
    };

  return (
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={
            {
              sx: {
                width: { xs: '100%', sm: '75%', md: '50%' },
                backgroundColor:'#2d3037',
                color:'#FFF'
              }
            }
        }
      >
        {seleItem && (
            <>
            <DialogTitle>
                <Grid container={true} spacing={1} p={2}>
                    <Grid item  xs={12} sm={12} mb={2} style={{ display:'flex', gap:'5px'}}>
                        <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                        <div style={{ display:'flex', flexDirection:'column' }}>
                            <Typography variant="h6" className={styles.textIconEdit}>Actualizar espacio {seleItem?.nuumero}</Typography>
                            <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el espacio.</Typography>
                        </div>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container={true} spacing={1}>
                    <Grid item  xs={12} sm={8} >
                    <label className={styles.label}>Campamento</label>
                    <Autocomplete
                        className={styles.textField}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                border: 'none',
                                },
                                '&:hover fieldset': {
                                border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                border: 'none',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#FFF',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                                color: '#FFF',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                                color: '#FFF',
                            },
                            }}
                        disablePortal
                        disableClearable
                        id="campamentos-autocomplete"
                        options={campamentos}
                        name="idCampamento"
                        value={formData?.campamento || ''}
                        getOptionLabel={(option) => option.nombre}
                        onChange={(e, value)=> handleChangeCampamento(e, value)}
                        renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                    />
                    </Grid>
                    <Grid item  xs={12} sm={4}></Grid>
                    <Grid item  xs={12} sm={4}>
                    <label className={styles.label}>Fila</label>
                    <TextField
                        className={styles.textField}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            border: 'none',
                            },
                            '&:hover fieldset': {
                            border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                            border: 'none',
                            },
                        },
                        '& .MuiInputBase-input': {
                            color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                            color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                        },
                        }}
                        variant="outlined"
                        fullWidth
                        name='fila'
                        type="text"
                        value={formData?.fila || ''}
                        disabled={!isCampamentoEnabled}
                        onChange={handleFilaChange}
                    />
                    {isCampamentoEnabled && (
                        <Button
                        onClick={handleOpenFila}>
                        Filas creadas
                        </Button>
                    )}
                    </Grid>
                    <Grid item  xs={12} sm={4}>
                    <label className={styles.label}>Nivel</label>
                    <TextField
                        className={styles.textField}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            border: 'none',
                            },
                            '&:hover fieldset': {
                            border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                            border: 'none',
                            },
                        },
                        '& .MuiInputBase-input': {
                            color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                            color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                        },
                        }}
                        variant="outlined"
                        fullWidth
                        name='nivel'
                        type="text"
                        value={formData?.nivel || ''}
                        disabled={!isFilaEnabled}
                        onChange={handleNivelChange}
                    />
                    {isFilaEnabled && (
                        <Button
                        onClick={handleOpenNivel}>
                        Niveles creados
                        </Button>
                    )}
                    </Grid>
                    <Grid item  xs={12} sm={4}>
                    <label className={styles.label}>ID</label>
                    <TextField
                        className={styles.textField}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            border: 'none',
                            },
                            '&:hover fieldset': {
                            border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                            border: 'none',
                            },
                        },
                        '& .MuiInputBase-input': {
                            color: '#FFF',
                        },
                        '& .MuiInputLabel-root': {
                            color: '#FFF',
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                        },
                         }}
                        variant="outlined"
                        fullWidth
                        name='numero'
                        type="text"
                        value={formData?.numero || ''}
                        disabled={!isNivelEnabled}
                        onChange={handleNumberChange}
                        error={!!errorFila}
                        helperText={errorFila}
                    />
                    {isNivelEnabled && (
                        <Button
                        onClick={handleOpenNumero}>
                            Números creados
                        </Button>
                    )}
                    </Grid>
                    <Dialog onClose={handleCloseNumero} open={openDialog}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                    </Dialog>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container={true}>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                        <Button 
                            onClick={handleEdit}
                            className={styles.bgColorEdit}
                            variant="contained" 
                            disabled={!canSave}
                            >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                        </Button>
                        <Button 
                            onClick={()=>setOpenEdit(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined" 
                            >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
                
            </>
        )}
      </Drawer>
  );
}