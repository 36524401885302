import React, { useEffect, useState } from 'react'
import styles from './components.module.css'
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText, Tab, TextField, Typography, Card, Box, Stack, Alert } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useAuth } from '../../../../context/AppContext'
import { createAxiosInstance } from '../../../../api/axios'
import { aprobar, rechazar, updateForecast } from '../../../../redux/features/forecast/forecastSlice'
import { useDispatch } from 'react-redux';

const data = {
    empresa: '',
    tramos: []
}
const Aprobar = ({ openModal, setOpenModal, seleItem }) => {
    
    const dispatch = useDispatch();
    const [open2, setOpen2] = useState(false);
    const [formData, setFormData] = useState({});
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [addRequestStatusAprobar, setAddRequestStatusAprobar] = useState('idle');

    useEffect(() => {
        if(seleItem){
            setFormData({empresa: seleItem?.empresa, tramos: seleItem?.tramos, id: seleItem?.id})
        }
    }, [seleItem])
    

    const handleAprobar = async () => {
        try {
          setOpen2(true);
          setAddRequestStatusAprobar('loading');
          let resp = await dispatch(aprobar(formData)); 
          if(resp.payload.data.success){
            setFormData(data);
            setOpenModal(false);
            return false;
          }
        } catch (error) {
          console.error('Error al crear el registro', error.message);
        } finally{
            setAddRequestStatusAprobar('idle');
            setOpen2(false);
        }
    }
    
  const handleRechazar = async () => {
    try {
      setOpen2(true);
      setAddRequestStatus('loading');
      let resp = await dispatch(rechazar(formData)); 
      if(resp.payload.data.success){
        setFormData(data);
        setOpenModal(false);
        return false;
      }
    } catch (error) {
      console.error('Error al crear el registro', error.message);
    } finally{
      setAddRequestStatus('idle');
      setOpen2(false);
    }
}

    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
    // const canSave = [formData.empresa.idEmpresa].every(Boolean) && addRequestStatus === 'idle';

  return (
    <Dialog
        open={openModal}
        keepMounted
        maxWidth="lg"
        PaperProps={{
        sx: {
        backgroundColor:'#2d3037',
        color:'#FFF',
        borderRadius:'0'
        }
        }}
        onBackdropClick={() => {
            // setOpen(false);
        }}
        //  onClose={()=>setOpenModal(false)}
        aria-describedby="alert-dialog-slide-description"
        >
        
        <DialogTitle>
            <div style={{ display:'flex', gap:'5px'}}>
                <AddTaskIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                <div style={{ display:'flex', flexDirection:'column' }}>
                    <Typography variant="h6" className={styles.textIconEdit}>Aprobar o rechazar forecast de {seleItem?.empresa?.razonSocial}</Typography>
                    <Typography variant="span" className={styles.textIconSpan}>Ingresa observaciónes y luego a prueba o rechaza el forecast.</Typography>
                </div>
            </div>
        </DialogTitle>

        <DialogContent>
            <Grid container={true}>
                <Grid item xs={12} md={12}>
                    <label className={styles.label}>Empresa registrada en el forecast</label>
                    <TextField
                        className={styles.textField}
                        sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    border: 'none',
                                    },
                                    '&:hover fieldset': {
                                    border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                    border: 'none',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                    color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    color: '#FFF',
                                },
                        }}
                        variant="outlined"
                        fullWidth
                        name='empresa'
                        type="text"
                        value={seleItem && seleItem?.empresa?.razonSocial}
                        // disabled
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={styles.label}>Observaciones</label>
                    <TextField
                        className={styles.textField}
                        sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    border: 'none',
                                    },
                                    '&:hover fieldset': {
                                    border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                    border: 'none',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: '#FFF',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#FFF',
                                },
                                '& .MuiAutocomplete-clearIndicator': {
                                    color: '#FFF',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    color: '#FFF',
                                },
                        }}
                        variant="outlined"
                        fullWidth
                        name='observaciones'
                        type="text"
                        multiline
                        rows={2}
                        value={formData && formData?.observaciones}
                        maxRows={4}
                        onChange={(e) => onChange(e)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} mt={2}>
              <div style={{ width:'fit-content' }}>
                <Button variant='contained' style={{ marginRight:'5px' }} className={styles.bgColor} onClick={handleAprobar} disabled={addRequestStatusAprobar == 'loading'}>
                    {addRequestStatusAprobar == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
                </Button>
                <Button 
                  variant='contained' 
                  style={{ opacity: !formData.observaciones ? '0.7' : '1', backgroundColor: '#800e0e', color:'#FFF' }}
                  // className={styles.bgColorDelete}
                  onClick={handleRechazar}
                  disabled={!formData.observaciones}
                  >
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Rechazar'}
                  </Button>
              </div>
            </Grid>
        </DialogContent>
        <DialogActions style={{ display:'flex', justifyContent:'space-between' }}>
            <Grid container={true}>
                <Grid item xs={12} sm={12} className={styles.buttonContainer} style={{ width:'100%', display:'flex', justifyContent:'end' }}>
                    <Button onClick={()=>setOpenModal(false)} className={styles.bgColorOutlined} variant="outlined">Cancelar</Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
  )
}

export default Aprobar