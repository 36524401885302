import styles from './components/components.module.css'
import { Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from "../../../redux/features/habitaciones/habitacionSlice";
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from "../../../redux/features/edificios/edificioSlice";
import { fetchEstandares, getAllEstandares, getEstandarStatus } from "../../../redux/features/estandares/estandarSlice";

import SkeletonTable from "../../../components/Skeleton";
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { Backdrop, CircularProgress } from '@mui/material';
import { fetchWings, getAllWings, getWingsStatus } from "../../../redux/features/wings/wingSlice";
import { fetchInventarios, getAllInventarios, getInventariosStatus } from "../../../redux/features/inventario/inventarioSlice";

const Habitaciones = () => {

  const dispatch = useDispatch();
  const habitaciones = useSelector(getAllHabitaciones);
  const status = useSelector(getHabitacionesStatus);
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const wings = useSelector(getAllWings);
  const WingsStatus = useSelector(getWingsStatus);
  const pabellones = useSelector(getAllEdificios);
  const pabStatus = useSelector(getEdificiosStatus);
  const productos = useSelector(getAllInventarios);
  const prodStatus = useSelector(getInventariosStatus);
  const standares = useSelector(getAllEstandares);
  const estStatus = useSelector(getEstandarStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(()=>{
    if(status == 'idle'){
      dispatch(fetchHabitaciones());
    }
    if(camStatus == 'idle'){
      dispatch(fetchCampamentos());
    }
    if(WingsStatus == 'idle'){
      dispatch(fetchWings());
    }
    if(pabStatus == 'idle'){
      dispatch(fetchEdificios());
    }
    if(prodStatus == 'idle'){
      dispatch(fetchInventarios());
    }
    if(prodStatus == 'idle'){
      dispatch(fetchInventarios());
    }
    if(estStatus == 'idle'){
      dispatch(fetchEstandares());
    }

  },[]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de habitaciones"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con las habitaciones en la plataforma.">
          {<MeetingRoomIcon style={{ fontSize:'1.5rem' }} className={styles.iconBg}/>}
        </Header>

        <TableCard>
          <Create campamentos={campamentos} productos={productos} setOpen2={setOpen2} open2={open2} wings={wings} pabellones={pabellones} standares={standares}/>
          { status == 'loading' ? <SkeletonTable/> : <Table habitaciones={habitaciones} setOpen3={setOpen3} inventario={productos}/> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Habitaciones