import styles from './components.module.css'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewSector } from '../../../../redux/features/sectores/sectorSlice';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
const data = {
  nombre: '',
  descripcion: '',
  campamento: 0,
}
const Create = ({ campamentos, open2, setOpen2 }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedCampamento, setSelectedCampamento] = useState(null);

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, campamento: newValue.id});
    } else{
      setFormData({...formData, campamento: 0})
    }
    
  };

  const canSave = [formData.nombre
    // , formData.campamento
  ].every(Boolean) && addRequestStatus === 'idle';
  

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewSector(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
          setOpen2(false);
        }else{
          setOpen2(false);
        }
      } catch (error) {
        toast.error('Error al crear el sector.');
        console.error('Error al crear el sector', error);
      } finally{
        setAddRequestStatus('idle');
      }
    }
  }


  return (
    <div>
      <Button variant='contanied' size='large' disabled={campamentos.length == 0} className={styles.bgColor} onClick={()=> setOpen(true)}>Crear Solicitud</Button>
      <Dialog
        open={open}
        maxwidth="75%"
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>
          <Typography variant='p'>Crear Solicitud</Typography>
          <span className={styles.subTitle}>Rellena los siguientes campos para crear la solcitud.</span>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Nombre</label>
              <TextField
                variant="outlined"
                fullWidth
                name='nombre'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            {/*
            <Grid item  xs={12} sm={12} >
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                disablePortal
                id="campamentos-autocomplete"
                options={campamentos}
                name="idCampamento"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />
            </Grid>
             */}
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
                variant="outlined"
                fullWidth
                name='descripcion'
                type="text"
                multiline
                rows={2}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColor}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create