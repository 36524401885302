import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { createAxiosInstance } from "../../../api/axios";

const EDIFICIOS_URL = '/api/v1/Pabellon';
const ADD_EDIFICIO_URL = '/api/v1/Pabellon';
const DELETE_EDIFICIO_URL = '/api/v1/Pabellon/';
const UPDATE_EDIFICIO_URL = '/api/v1/Pabellon/';
const GET_EDIFICIO_URL = '/api/v1/Pabellon/';

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchEdificios = createAsyncThunk("edificios/fetchEdificios", async ()=> {
    try {
        const response = await axiosInstance.get(EDIFICIOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchPabellon = createAsyncThunk("edificos/fetchPabellon", async (id)=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.get(GET_EDIFICIO_URL+id);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const addNewEdificio = createAsyncThunk("edificios/createEspacio", async (initialCampamento, {dispatch}) => {
    console.log(initialCampamento)
    try {
        const response = await axiosInstance.post(ADD_EDIFICIO_URL, {
            numero: initialCampamento.numero,
            numeroHabitaciones: initialCampamento.n_habitaciones,
            numeroPisos: initialCampamento.n_pisos,
            generoHuesped: initialCampamento.generoHuesped,
            standardId: initialCampamento.standard,
            campamentoId: initialCampamento.campamento,
            sectorId: initialCampamento.sector,
            // descripcion: initialCampamento.descripcion,
            });
        if (response.status === 200) {
            toast.success('Pabellón creado correctamente.');
            dispatch(fetchEdificios());
            return {data: response.data, status: 'success'};
        }
        return {status: 'error'};
      } catch (e) {
        console.log(e.response)
        toast.error('Error al crear el pabellón:' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})

export const deleteEdificio = createAsyncThunk("edificios/deleteEdificio", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_EDIFICIO_URL+id);
        
        if (response.status === 200) {
            toast.success('Pabellón eliminado correctamente.');
            dispatch(fetchEdificios());
            return { data: { id },status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el pabellón: ' + e.response.data);
        return e.message;
    }
})
export const updateEdificio = createAsyncThunk("edificios/updateEdificio", async (upEdificio, {dispatch})=> {
    console.log('edificio', upEdificio);
    try {
        const response = await axiosInstance.put(UPDATE_EDIFICIO_URL+upEdificio.id, {
            numero: upEdificio.numero,
            numeroHabitaciones: upEdificio.numeroHabitaciones,
            numeroPisos: upEdificio.numeroPisos,
            generoHuesped: upEdificio.generoHuesped,
            standardId: upEdificio.standardId,
            sectorId: upEdificio.sectorId,
            campamentoId: upEdificio.campamentoId,
        });
        
        if (response.status === 200) {
            toast.success('Pabellón actualizado correctamente.');
            dispatch(fetchEdificios());
            return { data: response.data, status: 'success'};
        } 
        return { data: response.data, status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el pabellón: ' + e.response.data);
        return e.message;
    }
})


// idle : no hay operaciones en curso
const initialState = {
    edificios: [],
    edificio: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const edificioSlice = createSlice({
    name:"espacio",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchEdificios.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchEdificios.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.edificios) !== JSON.stringify(action.payload)) { //fetch
                state.edificios = action.payload;
            }
        })
        .addCase(fetchEdificios.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchPabellon.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchPabellon.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.edificio) !== JSON.stringify(action.payload)) { //fetch
                state.edificio = action.payload;
            }
        })
        .addCase(fetchPabellon.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewEdificio.fulfilled, (state, action) => { //ADD
            if(action.payload.status === 'success'){
                state.status = 'succeeded'
                state.edificios.push(action.payload.data);
            }else{
                state.status = 'failed'
            }
        })
        .addCase(deleteEdificio.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteEdificio.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.edificios = state.edificios.filter(edificio => edificio.id !== action.payload.data.id);
            } else{
                state.status = 'failed'
            }
        })
        .addCase(deleteEdificio.rejected, (state, action) => { //fetch
                state.status = 'failed'
                state.error = action.error.message;
        })
        .addCase(updateEdificio.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateEdificio.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.edificios = state.edificios.map(edificio => {
                    if (edificio.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return edificio;
                });
            }else{
                state.status = 'failed';
            }
        })
        .addCase(updateEdificio.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllEdificios = (state) => state.edificios.edificios
export const getEdificiosStatus = (state) => state.edificios.status;
export const getEdificiosError = (state) => state.edificios.error;

export const {} = edificioSlice.actions;
export default edificioSlice.reducer;