import styles from './components.module.css';
import { Popper, Button, DialogActions, DialogContent, Drawer, Grid, TextField, Typography, CircularProgress, DialogTitle, Autocomplete, Select, MenuItem, OutlinedInput, ListItemText, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import { getAllEstandares } from '../../../../redux/features/estandares/estandarSlice';
import { useSelector } from 'react-redux';


const tipo_huesped = [
  { label: "Solo mujeres", value: "Solo mujeres" },
  { label: "Solo hombres", value: "Solo hombres" },
  { label: "Unisex", value: "Unisex" },
];
const tipo_cargos = [ 'Administrador de contrato', 'Administrativo', 'Supervisor', 'Operador', 'Mantenedor' ];

const EditCMDIC = ({ openEdit, setOpenEdit, seleItem, setOpen3, habitaciones, campamentos, wings, selectCheck }) => {
    
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const standares = useSelector(getAllEstandares);
    const [selectedCampamento, setSelectedCampamento] = useState(null);
    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedWing, setSelectedWing] = useState(null);
    const [filterWings, setFilterWings] = useState(wings);
    const [selectedStandar, setSelectedStandar] = useState(null);
    const [selectedPabellon, setSelectedPabellon] = useState(null);
    const [filterHabitaciones, setFilterHabitaciones] = useState(habitaciones);
    const [filterPisos, setFilterPisos] = useState();
    const [pabellonWing, setPabellonWing] = useState(2);
    const [cargo, setCargo] = useState(tipo_cargos);

    const handleChangePW = (event) => {
        setPabellonWing(event.target.value);
      };
    
    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

    const handleChangeCampamento = (event, newValue) => {
        setSelectedCampamento(newValue);
        // setStandars(standares.filter(item =>item.campamentoId == newValue.id));
        if(newValue != null){
          setFormData({...formData, campamento: newValue, campamentoId: newValue.id, wing: null, wingId: '', piso:'',habitacion:null, habitacionId:'', tipo_standar: ''  });
          
          const filter = wings.filter(item => item.campamentoId == newValue.id)
          setFilterWings(filter);

          setSelectedSector(null);
          setSelectedWing(null);
          setSelectedPabellon(null);
        } else{
          setFormData({...formData, campamento: {}, campamentoId: ''})
        }    
    };
      
    const handleChangePabellon = (event, newValue) => {
        console.log('pabellon',newValue);
        if(newValue != null){
          setSelectedWing(null);
          setFormData({...formData, pabellon: newValue.id, wing: null, tipo_standar: newValue.standardId})
          setSelectedPabellon(newValue);
        } else{
          setFormData({...formData, pabellon: 0, tipo_standar: 0})
        }
      };
    
      const handleChangeWing = (event, newValue) => {
        console.log('wing',newValue);
        if(newValue != null){
          setSelectedSector(null);
          setSelectedPabellon(null);
          setFormData({...formData, wing: newValue, wingId: newValue.id, pabellon: null, habitacion: null, habitacionId: '', piso: ''})
          setSelectedWing(newValue);
          const pisos = habitaciones.filter(item => item.wingId == newValue.id)
            .map(item => item.piso);
            setFilterPisos(pisos);

        } else{
          setFormData({...formData, wing: 0, tipo_standar: 0})
        }
      };

      const handleChangePiso = (event, newValue) => {
        console.log('wing',newValue);
        if(newValue != null){
          setSelectedSector(null);
          setSelectedPabellon(null);
          setFormData({...formData, piso: newValue, habitacion: null, habitacionId: ''})
        //   setSelectedWing(newValue);
        const filtro = habitaciones.filter(item => item.piso == formData?.piso)
        setFilterHabitaciones(filtro)
        } else{
        //   setFormData({...formData, habitacion: null, tipo_standar: 0})
        }
      };

      const handleChangeHabitacion = (event, newValue) => {
        console.log('pabellon',newValue);
        if(newValue != null){
          setSelectedWing(null);
          setFormData({...formData, habitacion: newValue, habitacionId: newValue.id})
        } else{
          setFormData({...formData, habitacion: null, habitacionId: ''})
        }
      };

      const handleChangeTipo = (event, newValue, tipo) => {
        if(newValue != null){
            console.log(newValue.value)
            setFormData({...formData, tipo_huesped: newValue.value});
        }
      };

      const handleChangeStandar = (event, newValue) => {
        if(newValue != null){
          setFormData({...formData, tipo_standar: newValue.id})
          setSelectedStandar(newValue);
        } else{
          setFormData({...formData, tipo_standar: 0})
        }
      };

      const handleChange = (event) => {
        const cargoValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
        setCargo(cargoValue);
        setFormData({...formData, tipo_cargo: cargoValue});
      };

    useEffect(() => {
        setFormData(seleItem);
        if(seleItem){
            const pisos = habitaciones.filter(item => item.wingId == seleItem.wingId)
            .map(item => item.piso);
            setFilterPisos(pisos);
        }
    }, [seleItem])

    return (
        <div>
            <Drawer
                anchor='right'
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                sx={{
                    width: '50%', // Ancho por defecto
                    '@media (max-width: 600px)': {
                        width: '100%', // Ancho del 100% en dispositivos móviles
                    },
                    flexShrink: 0, // Asegura que el Drawer no se reduzca en tamaño
                    '& .MuiDrawer-paper': {
                        width: '50%', // Ancho por defecto
                        '@media (max-width: 600px)': {
                            width: '100%', // Ancho del 100% en dispositivos móviles
                        },
                        boxSizing: 'border-box', // Incluye el borde y el relleno en el cálculo del tamaño
                    },
                }}
            > 
            <DialogTitle>
                <Grid item  xs={12} sm={12} mb={2}>
                    <Typography variant="h6" className={styles.textIcon}><ErrorIcon/>Estás a punto de actualizar la reserva del trabajador {seleItem?.trabajador?.nombre}</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {seleItem && (
                    <Grid container={true} spacing={1}>
                        <Grid item  xs={12} sm={4}>
                            <label className={styles.label}>RUT</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='rut'
                                type="text"
                                value={formData?.trabajador?.rut}
                                disabled
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={8}>
                            <label className={styles.label}>Nombre</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='nombre'
                                type="text"
                                value={formData?.trabajador?.nombre}
                                disabled
                                // onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Jornada</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='jornada'
                                type="text"
                                value={formData?.jornada}
                                disabled
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Turno</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='turno'
                                type="text"
                                value={formData?.turno?.descripcion}
                                disabled
                                onChange={(e) => onChange(e)}
                            />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Campamento</label>
                            <Autocomplete
                                disablePortal
                                id="campamentos-autocomplete"
                                name="campamento"
                                options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                                value={formData?.campamento || null}
                                getOptionLabel={(option) => option.nombre}
                                disableClearable
                                PopperComponent={(props) => (
                                <Popper
                                    {...props}
                                    sx={{
                                    maxHeight: '200px', 
                                    overflowY: 'auto',
                                    }}
                                />
                                )}
                                onChange={(e, value)=> handleChangeCampamento(e, value)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                        {/*<Grid item  xs={12} sm={6}>
                        <label className={styles.label}>Pabellón o Wing</label>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pabellonWing}
                            label="Age"
                            onChange={handleChangePW}
                            style={{ width:'100%' }}
                        >
                            <MenuItem value={1}>Pabellón</MenuItem>
                            <MenuItem value={2}>Wing</MenuItem>
                        </Select>
                        </Grid>*/}
                            {/*{pabellonWing == 1 && ( 
                                <Grid item  xs={12} sm={6}>
                                        <label className={styles.label}>Pabellón</label>
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id="sectores-autocomplete"
                                          name="wing"
                                          value={selectedPabellon}
                                          options={pabellones ? pabellones : [] }
                                          PopperComponent={(props) => (
                                            <Popper
                                              {...props}
                                              sx={{
                                                height: '200px', 
                                                overflowY: 'auto',
                                              }}
                                            />
                                          )}
                                          getOptionLabel={(option) => option.identificador}
                                          onChange={(e, value) => handleChangePabellon(e, value)}    
                                          renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                                      />
                                    </Grid>
                                   )} */}
                              {/* {selectedCampamento && ( */}
                              {pabellonWing == 2 && ( 
                                <Grid item  xs={12} sm={6}>
                                    <label className={styles.label}>Wing</label>
                                    <Autocomplete
                                      disablePortal
                                      disableClearable
                                      id="sectores-autocomplete"
                                      name="wing"
                                      value={formData?.wing || null}
                                      options={filterWings ?  filterWings : [] }
                                      PopperComponent={(props) => (
                                        <Popper
                                          {...props}
                                          sx={{
                                            height: '100px', 
                                            overflowY: 'auto',
                                          }}
                                        />
                                      )}
                                      getOptionLabel={(option) => option.nombre}
                                      onChange={(e, value) => handleChangeWing(e, value)}    
                                      renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                                  />
                                </Grid>
                              )} 
                              <Grid item  xs={12} sm={6}>
                                <label className={styles.label}>Tipo de huesped</label>
                                <Autocomplete
                                  disablePortal
                                  id="campamentos-autocomplete"
                                  options={tipo_huesped}
                                  name="tipo_huesped"
                                  getOptionLabel={(option) => option.label}
                                  PopperComponent={(props) => (
                                    <Popper
                                      {...props}
                                      sx={{
                                        height: '120px', 
                                        overflowY: 'auto',
                                      }}
                                    />
                                  )}
                                  onChange={(e, value)=> handleChangeTipo(e, value, 'huesped')}
                                  renderInput={(params) => <TextField {...params}  />}
                                  defaultValue={tipo_huesped[1]} 
                                  disableClearable
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <label className={styles.label}>Tipo de standard</label>
                                <Autocomplete
                                  disablePortal
                                  id="campamentos-autocomplete"
                                  options={standares}
                                  name="tipo_standar"
                                  value={formData?.tipo_standar || null}
                                  PopperComponent={(props) => (
                                    <Popper
                                      {...props}
                                      sx={{
                                        overflowY: 'auto',
                                      }}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.nombre}
                                  onChange={(e, value)=> handleChangeStandar(e, value)}
                                  disableClearable
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Grid>
                            <Grid item  xs={12} sm={12}>
                              <label className={styles.label}>Tipo de cargos (Puede elegir más de 1)</label>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={cargo}
                                onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                className={styles.select}
                                style={{ width:'100%' }}
                              >
                                {tipo_cargos.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    <Checkbox checked={cargo.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Piso</label>
                            <Autocomplete
                                      disablePortal
                                      disableClearable
                                      id="sectores-autocomplete"
                                      name="piso"
                                      value={formData?.piso || null}
                                      options={filterPisos ?  filterPisos : [] }
                                      PopperComponent={(props) => (
                                        <Popper
                                          {...props}
                                          sx={{
                                            height: '100px', 
                                            overflowY: 'auto',
                                          }}
                                        />
                                      )}
                                      getOptionLabel={(option) => option}
                                      onChange={(e, value) => handleChangePiso(e, value)}    
                                      renderInput={(params) => <TextField {...params} />}
                                  />
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <label className={styles.label}>Habitación</label>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                id="sectores-autocomplete"
                                name="habitacion"
                                value={formData?.habitacion || null}
                                options={filterHabitaciones ?  filterHabitaciones : [] }
                                PopperComponent={(props) => (
                                <Popper
                                    {...props}
                                    sx={{
                                    height: '100px', 
                                    overflowY: 'auto',
                                    }}
                                />
                                )}
                                getOptionLabel={(option) => option.numero}
                                onChange={(e, value) => handleChangeHabitacion(e, value)}    
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                    </Grid>
                )}
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                        <Button
                            // onClick={handleEdit}
                            className={styles.bgColor}
                            variant="contained" 
                            // disabled={!canSave}
                            >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
                        </Button>
                        <Button 
                            // onClick={()=>setOpenEdit(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined" 
                            >
                            Cancelar
                        </Button>
                    </Grid>                
                </DialogActions>
            </Drawer>
        </div>
  )
}

export default EditCMDIC

