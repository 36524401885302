import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";

const MOTIVOS_URL = '/api/v1/CriticidadRequerimiento';
const ASEO_URL = '/api/v1/CriticidadRequerimiento';
const ADD_MOTIVO_URL = '/api/v1/CriticidadRequerimiento';
const UPDATE_MOTIVO_URL = '/api/v1/CriticidadRequerimiento/';
const ADD_SOLICITUD_ASEO_URL = '/api/v1/CriticidadRequerimiento';
const DELETE_MOTIVOS_URL = '/api/v1/CriticidadRequerimiento/';
const axiosInstance = createAxiosInstance();
// ACTIONSs
export const fetchMotivos = createAsyncThunk("motivos/fetchMotivos", async ()=> {
    try {
        const response = await axiosInstance.get(MOTIVOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const addNewMotivo = createAsyncThunk("motivos/createMotivos", async (initialCampamento, {dispatch}) => {
    try {
        console.log(initialCampamento)
        const response = await axiosInstance.post(ADD_MOTIVO_URL, {
            especialidad: initialCampamento.especialidad,
            solicitud: initialCampamento.solicitud,
            prioridad: initialCampamento.prioridad,
            descripcion: initialCampamento.descripcion,
            });
        if (response.status === 200 ) {
            dispatch(fetchMotivos());
            toast.success('Registro creado correctamente.');
            return {data: response.data, status: 'success'};
        }
        return {status:'error'}
      } catch (e) {
        console.log(e.response)
        if(e.response.data.title){
            toast.error('Error al crear el registro: ' + e.response.data.title);
        }else{
            toast.error('Error al crear el registro: ' + e.response.data);
        }
        return {message: e.message, status: 'error'};
    }
})
export const updateMotivo = createAsyncThunk("motivos/updateMotivo", async (upMotivo, {dispatch})=> {
    console.log('motivo', upMotivo);
    try {
        const response = await axiosInstance.put(UPDATE_MOTIVO_URL+upMotivo.id, {
            especialidad: upMotivo.especialidad,
            solicitud: upMotivo.solicitud,
            prioridad: upMotivo.prioridad,
            descripcion: upMotivo.descripcion,
        });
        
        if (response.status === 200) {
            toast.success('Motivo actualizado correctamente.');
            dispatch(fetchMotivos());
            return { data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el motivo: ' + e.response.data);
        return e.message;
    }
})
// ACTIONSs
export const fetchSolicitudesAseo = createAsyncThunk("motivos/fetchAseo", async ()=> {
    try {
        const response = await axiosInstance.get(ASEO_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})

export const addNewSolicitudAseo = createAsyncThunk("motivos/createAseo", async (initialCampamento, {dispatch}) => {
    try {
        console.log(initialCampamento)
        const response = await axiosInstance.post(ADD_SOLICITUD_ASEO_URL, {
            especialidad: initialCampamento.especialidad,
            solicitud: initialCampamento.solicitud,
            prioridad: initialCampamento.prioridad,
            descripcion: initialCampamento.descripcion,
            });
        if (response.status === 200 ) {
            dispatch(fetchMotivos());
            toast.success('Registro creado correctamente.');
            return {data: response.data, status: 'success'};
        }
        return {status:'error'}
      } catch (e) {
        console.log(e.response)
        if(e.response.data.title){
            toast.error('Error al crear el registro: ' + e.response.data.title);
        }else{
            toast.error('Error al crear el registro: ' + e.response.data);
        }
        return {message: e.message, status: 'error'};
    }
})
export const deleteMotivo = createAsyncThunk("motivos/deleteMotivo", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_MOTIVOS_URL+id);
        if (response.status === 200) {
            toast.success('Registro eliminado correctamente.');
            dispatch(fetchMotivos());
            return { id, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el registro: ' + e.response.data);
        return e.message;
    }
})
// idle : no hay operaciones en curso
const initialState = {
    motivos: [],
    solicitudesAseo: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    statusAseo: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const motivoSlice = createSlice({
    name:"motivo",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchMotivos.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchMotivos.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.motivos) !== JSON.stringify(action.payload)) { //fetch
                state.motivos = action.payload;
            }
        })
        .addCase(fetchMotivos.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchSolicitudesAseo.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.solicitudesAseo) !== JSON.stringify(action.payload)) { //fetch
                state.solicitudesAseo = action.payload;
            }
        })
        .addCase(addNewMotivo.fulfilled, (state, action) => { //ADD
            if(action.status === 'success'){
                state.status = 'succeeded'
                state.motivos.push(action.payload);
            }else{
                state.status = 'failed'
            }
        })
        .addCase(addNewSolicitudAseo.fulfilled, (state, action) => { //ADD
            if(action.statusAseo === 'success'){
                state.statusAseo = 'succeeded'
                state.solicitudesAseo.push(action.payload);
            }else{     
                state.statusAseo = 'failed'
            }
        })
        .addCase(updateMotivo.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateMotivo.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.motivos = state.motivos.map(motivo => {
                    if (motivo.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return motivo;
                });
            }else{
                state.status = 'failed';    
            }
        })
        .addCase(updateMotivo.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
})

export const getAllMotivos = (state) => state.motivos.motivos;
export const getAllSolicitudesAseo = (state) => state.motivos.solicitudesAseo;
export const getMotivosStatus = (state) => state.motivos.statusAseo;
export const getAseoStatus = (state) => state.motivos.status;
export const getMotivosError = (state) => state.motivos.error;

export const {} = motivoSlice.actions;
export default motivoSlice.reducer;