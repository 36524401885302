import styles from './components.module.css';
import { useEffect, useState } from "react";
import { reservasCMDICGeneralColumns } from "../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card } from '@mui/material';
import SkeletonTable from '../../components/Skeleton'

const TableReclamos = ({reservas, addRequestStatus, setOpen3, open, setOpen, setSeleItem}) => {
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = reservasCMDICGeneralColumns(setOpen, setSeleItem);
    
  return (
    <div style={{ width:'100%', marginTop:'10px' }}>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        :<DataGrid
          getRowId={row=>row.id ? row.id : Math.random() }
          lots={{ toolbar: GridToolbar }}
          rows={reservas || []}
          columns={finalColumns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay datos disponibles",
          pagination: {
              labelRowsPerPage: "Filas por página:",
          }
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
          sx={{ 
            '& .MuiDataGrid-cell': {
              color: '#2d3037',
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-columnHeader': {
              borderBottom: '1px solid #c6c6c6',
              backgroundColor: '#1b401d',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-footerContainer': {
              color: '#2d3037',
              borderTop: '1px solid #c6c6c6',
              backgroundColor: '#fff'
            },
            '& .MuiTablePagination-root': {
              color: '#2d3037',
              borderTop: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-toolbarContainer': {
              color: '#2d3037',
            },
            '& .MuiDataGrid-menu': {
              color: '#2d3037',
            },
            '& .MuiSvgIcon-root':{
              color: '#9ca91c',
            },
            '& .MuiTablePagination-actions': {
              color: '#2d3037', // Color for pagination actions
            },
            '& .MuiTablePagination-select': {
              color: '#2d3037', // Color for pagination select
            },
            '& .MuiTablePagination-selectLabel': {
              color: '#2d3037', // Color for pagination select label
            },
            '& .MuiTablePagination-displayedRows': {
              color: '#2d3037', // Color for displayed rows text
            },
            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
              color: 'red',
            },
            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
              color: 'red',
            },
          }}
          slotProps={{
                toolbar:{
                  sx:{
                    backgroundColor: '#fff'
                  }
                },
                pagination: {
                  sx: {
                    backgroundColor: '#fff'
                  },
                },
                menu: {
                  sx: {
                    backgroundColor: '#fff'
                  },
                },
          }}
        />
     }
    </div>
  )
}

export default TableReclamos