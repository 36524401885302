import styles from './components.module.css'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, TextField } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { updateCategoria } from '../../../redux/features/inventario/inventarioSlice';
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { useAuth } from '../../../context/AppContext';
import { aprobarRechazarInventarioSupervisor } from '../../../redux/features/bodegas/bodegaSlice';

const CambiarEstadoSupervisor = ({ openEdit, setOpenEdit, seleItem, setOpen3 }) => {

  const dispatch = useDispatch();
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [check, setCheck] = useState(true);
  const [formData, setFormData] = useState({ estado: '', razonRechazo:'' });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      estado: checked ? name : '',
    }));
  };

  useEffect(() => {
    if (seleItem) {
      setFormData(prev => ({...prev, id: seleItem.id }));
      console.log('seleItem', seleItem);
    }
  }, [seleItem]);

  const canSave = [formData.id, formData.estado, (formData.estado === 'Rechazado' ? formData.razonRechazo : true)].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {
    try {
        setOpen3(true);
        setAddRequestStatus('loading');
          let resp = await dispatch(aprobarRechazarInventarioSupervisor(formData))
          if(resp.payload.status === 'success'){
              setOpen3(false);
          }
    } catch (error) {
        console.error('Error', error.message);
    } finally{
        setAddRequestStatus('idle');
        setOpenEdit(false);
    }
  }

  return (
    <Dialog
        open={openEdit}
        maxWidth='md'
        onBackdropClick={() => {
          setOpenEdit(false);
        }}
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
      >
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
        <NotificationsIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
        <div style={{ display:'flex', flexDirection:'column' }}>
          <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Actualizar el estado de la solicitud de traspaso entre bodegas</Typography>
          <span className={styles.subTitle} style={{ marginTop:'5px' }}>A continuación podras aprobar o rechazar una solicitud de traspaso entre bodegas. En caso de rechazar una solicitud tendrás que ingresar una razón o motivo del rechazo.</span>
        </div>
      </DialogTitle>
        <DialogContent>
          <Grid container={true} style={{ flexDirection:'column' }}>
            <Grid item xs={12} md={12} style={{ justifyContent:'center', alignItems:'center', display:'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.estado === 'Aprobado'}
                    onChange={handleCheckboxChange}
                    name="Aprobado"
                    color="primary"
                  />
                }
                label="Aprobar"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.estado === 'Rechazado'}
                    onChange={handleCheckboxChange}
                    name="Rechazado"
                    color="secondary"
                  />
                }
                label="Rechazar"
              />
            </Grid>
              <Grid item xs={12} md={12}>
                <label className={styles.label}>Observaciones</label>
                <TextField
                  variant="outlined"
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                  }}
                  fullWidth
                  name='razonRechazo'
                  type="text"
                  onChange={(e) => setFormData(prev => ({...prev, razonRechazo: e.target.value}))}
                />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={check ? styles.bgColor : styles.bgColorDelete}
                variant="contained" 
                disabled={!canSave}>
                 { addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> : 'Actualizar' }
              </Button>
            <Button 
              onClick={()=>setOpenEdit(false)}
              className={styles.bgColorOutlined}
              variant="outlined" 
              style={{ border:'none' }}
              >
              Cancelar
            </Button>
          </Grid>
        </DialogActions>

      </Dialog>
  )
}

export default  CambiarEstadoSupervisor