import styles from './components.module.css';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { trasladoShowColumns } from '../../../lib/columns';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
  
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = trasladoShowColumns();
  useEffect(() => { seleItem && console.log('seleItem:', seleItem) }, [seleItem]);

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
         backgroundColor:'#2d3037',
         color:'#FFF',
         borderRadius:'0'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Resumen traspaso"}
      </DialogTitle>
      
      <DialogContent>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#212227', padding:'10px', borderRadius:'5px', border:'1px solid #0086ae', overflowX:'auto' }}>
          <Grid item xs={12} md={4}>
              <Typography align="left">Fecha:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fecha && format(seleItem?.fecha, 'dd-MM-yyyy')}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Encargado de Bodega:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.encardoDeBodega?.nombre} {seleItem?.encardoDeBodega?.apellidos}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Bodega de origen:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.bodegaOrigen?.nombre}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Bodega de destino:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.bodegaDestino?.nombre}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Estado:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.estado}</strong></Typography>
            </Grid>

            {seleItem?.razonRechazo && (
              <>
                <Grid item xs={12} md={4}>
                  <Typography align="left">Razón rechazo:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.razonRechazo}</strong></Typography>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={4}>
              <Typography align="left">Monto total:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.montoTotal}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Usuario:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.usuario?.nombre} {seleItem?.usuario?.apellido}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Correo:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.usuario?.email}</strong></Typography>
            </Grid>
        </Grid>
        <Grid container={true} mt={2}>
          <Grid item xs={12} md={12}>
            <DataGrid
              getRowId={row=>row.id ? row.id : Math.random() }
              lots={{ toolbar: GridToolbar }}
              rows={seleItem?.articulos}
              columns={finalColumns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15, 20]}
              localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                  labelRowsPerPage: "Filas por página:",
              }
              }}
              classes={{ menuIcon: styles.menuIcon }}
              style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
              sx={{ 
                '& .MuiDataGrid-cell': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeader': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-footerContainer': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiTablePagination-root': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  color: '#FFF',
                },
                '& .MuiDataGrid-menu': {
                  color: '#FFF',
                },
                '& .MuiTablePagination-actions': {
                  color: '#FFF', // Color for pagination actions
                },
                '& .MuiTablePagination-select': {
                  color: '#FFF', // Color for pagination select
                },
                '& .MuiTablePagination-selectLabel': {
                  color: '#FFF', // Color for pagination select label
                },
                '& .MuiTablePagination-displayedRows': {
                  color: '#FFF', // Color for displayed rows text
                },
              }}
              slotProps={{
                toolbar:{
                  sx:{
                    color:'#FFF'
                  }
                },
                pagination: {
                  sx: {
                    color: '#FFF',
                  },
                },
                menu: {
                  sx: {
                    color: '#FFF',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color:'#FFF' }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
