import styles from './components.module.css';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { fetchTrabajadores } from '../../../../redux/features/reservas/reservaSlice';
import { trabajadoresResumenReservaColumns } from '../../../../lib/columns';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = trabajadoresResumenReservaColumns()
  const dispatch = useDispatch();
  const [trabajadores, setTrabajadores] = useState([])
  
  const trabajadoresSolicitud = async (id) => {
    try {
      const resp = await dispatch(fetchTrabajadores(id));
      setTrabajadores(resp.payload);
      console.log('trabajadores: ', resp.payload);
    } catch (error) {
      console.log('error al traer trabajadores', error );
    }
  }

  useEffect(() => { 
    seleItem && 
    trabajadoresSolicitud(seleItem.id) 
    console.log('seleItem: ', seleItem)

  }, [seleItem]);

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ backgroundColor: "#2d3037", color: "#FFF" }}
      >
        {"Resumen solicitud de reserva"}
      </DialogTitle>
      
      <DialogContent style={{ backgroundColor: "#2d3037", color: "#FFF" }}>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#212227', borderRadius:'5px', border:'1px solid #0086ae', overflowX:'auto' }}>
            <Grid item xs={12} md={4}>
              <Typography align="left">Estado:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.estado}</strong></Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography align="left">Solicitante:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.nombreSolicitante} {seleItem?.apellidoSolicitante}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Solicitud:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fechaSolicitud && format(seleItem?.fechaSolicitud, 'dd-MM-yyyy') } { seleItem?.fecha && format(seleItem?.fechaSolicitud, 'HH:mm')}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Empresa:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.empresa?.razonSocial}</strong></Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Ingreso:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fechaIngreso && format(seleItem?.fechaIngreso, 'dd-MM-yyyy') }</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Salida:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fechaSalida && format(seleItem?.fechaSalida, 'dd-MM-yyyy') }</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Vicepresidencia:</Typography>
            </Grid>
            <Grid item xs={12} md={8} >
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.vicepresidencia}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Gerencia:</Typography>
            </Grid>
            <Grid item xs={12} md={8} >
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.gerencia}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">siArea:</Typography>
            </Grid>
            <Grid item xs={12} md={8} >
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.sI_Area}</strong></Typography>
            </Grid>


            <Grid item xs={12} md={4}>
              <Typography align="left">Total Trabajadores:</Typography>
            </Grid>
            <Grid item xs={12} md={8} >
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.trabajadoresTotales}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Observaciones:</Typography>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginBottom:'15px' }}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.observaciones ?? 'Sin observaciones'}</strong></Typography>
            </Grid>

        </Grid>

        <Grid container={true} mt={2}>
          <Grid item xs={12} md={12}>
            <DataGrid
              getRowId={row=>row.trabajador?.rut ? row.trabajador?.rut : Math.random() }
              lots={{ toolbar: GridToolbar }}
              rows={trabajadores || []}
              columns={finalColumns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15, 20]}
              localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                  labelRowsPerPage: "Filas por página:",
              }
              }}
              classes={{ menuIcon: styles.menuIcon }}
              style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
              sx={{ 
                '& .MuiDataGrid-cell': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeader': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-footerContainer': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiTablePagination-root': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  color: '#FFF',
                },
                '& .MuiDataGrid-menu': {
                  color: '#FFF',
                },
                '& .MuiTablePagination-actions': {
                  color: '#FFF', // Color for pagination actions
                },
                '& .MuiTablePagination-select': {
                  color: '#FFF', // Color for pagination select
                },
                '& .MuiTablePagination-selectLabel': {
                  color: '#FFF', // Color for pagination select label
                },
                '& .MuiTablePagination-displayedRows': {
                  color: '#FFF', // Color for displayed rows text
                },
              }}
              slotProps={{
                toolbar:{
                  sx:{
                    color:'#FFF'
                  }
                },
                pagination: {
                  sx: {
                    color: '#FFF',
                  },
                },
                menu: {
                  sx: {
                    color: '#FFF',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#2d3037", color: "#FFF" }}>
        <Button onClick={handleClose} style={{ color: "#FFF" }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
