import { Outlet } from "react-router-dom";
import Sidebar from "../adminAplicacion/Sidebar";
import PersistentDrawerLeft from "../adminAplicacion/Sidebar_";
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { useAuth } from '../../context/AppContext';

const actions = [
  { icon: <SaveIcon />, name: 'Subir' },
  { icon: <ShareIcon />, name: 'Descargar' },
];
const Layout = () => {
  
  const { info } = useAuth();
  const rol = info?.roles;

  return (
    <div className="App" style={{ position: 'relative', minHeight: '100vh' }}>
    <PersistentDrawerLeft/>
    

    </div>
  )
}

export default Layout

// {
//   (
//     rol?.includes('Administrador de Contrato ESED (ACE)') ||
//     rol?.includes('Administrador de Contrato CMDIC (ACC)') || 
//     rol?.includes('Administrador de RRHH (ARH)')
//   ) && 
// <Box sx={{
//   height: 320,
//   transform: 'translateZ(0px)',
//   flexGrow: 1,
//   position: 'fixed',
//   bottom: 0,
//   right: 0,
//   left: 0,
// }}>
//   <SpeedDial
//     ariaLabel="SpeedDial basic example"
//     sx={{ position: 'absolute', bottom: 16, right: 16 }}
//     icon={<SpeedDialIcon />}
//   >
//     {actions.map((action) => (
//       <SpeedDialAction
//         key={action.name}
//         icon={action.icon}
//         tooltipTitle={action.name}
//       />
//     ))}
//   </SpeedDial>
// </Box>}