import styles from './components.module.css'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Popper, TextField, Typography } from '@mui/material';
import { addNewOtro } from '../../../../redux/features/otros/otroSlice';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  campamentoId: 0,
  nombre: '',
  numeroPisos: '',
}
const Create = ({ campamentos, open2, setOpen2 }) => {
    
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, campamentoId: newValue.id});
    } else{
      setFormData({...formData, campamentoId: 0})
    }
    
  };

  const canSave = [formData.nombre, formData.numeroPisos, formData.campamentoId].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewOtro(formData));
        if(resp.payload.data.success){
          setFormData(data);
          setOpen(false);
          return;
        }
      } catch (error) {
        console.error('Error al crear el edificio', error);
      } finally{
        setAddRequestStatus('idle');
        setOpen2(false);
      }
    }
  }


  return (
    <div>
        <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
          <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder registrar un recinto.</Typography>
          <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>    Crear Recinto</Button>
        </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open2}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <OtherHousesIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1'  }}>Crear recinto</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear el recinto.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                disablePortal
                id="campamentos-autocomplete"
                options={campamentos}
                name="campamentoId"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />
            </Grid>
            <Grid item  xs={12} sm={8}>
              <label className={styles.label}>Nombre</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='nombre'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={4}>
              <label className={styles.label}>N°Pisos</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='numeroPisos'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={styles.buttonContainer} mt={2}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create