import styles from './components/components.module.css';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import Box from '@mui/material/Box';
import Table, { TabsContainer } from './components/Table';
import { useEffect, useState } from 'react';
import { Backdrop, Container, CircularProgress, Card } from '@mui/material';
import Header from '../../../components/header/Header';
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../../redux/features/habitaciones/habitacionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchReservas, fetchTrabajadores, getAllTrabajadores, getAllreservas } from '../../../redux/features/reservas/reservaSlice';
import { fetchTurnos } from '../../../redux/features/users/userSlice';

const data = {nombre:'', list:[]}

export default function Solicitudes() {
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState(data)
  const [open3, setOpen3] = useState(false);
  
  const [seleItem, setSeleItem] = useState(); //reservas
  const [openDetalle, setOpenDetalle] = useState(false); //reservas

  const [checkedState, setCheckedState] = useState([]); //detalle
  const [envioS, setEenvioS] = useState({}); //detalle
  
  const dispatch = useDispatch();//habitaciones
  const [checkedHabitaciones, setCheckedHabitaciones] = useState([]); //habitaciones
  const status = useSelector(getHabitacionesStatus); //habitaciones
  const [asignacionHabitacionesTrabajadores, setAsignacionHabitacionesTrabajadores] = useState([]);
  const habitaciones = useSelector(getAllHabitaciones);
  const solicitudes = useSelector(getAllreservas);
  const trabajadores = useSelector(getAllTrabajadores);
  const [check, setCheck] = useState(false);
  const [count, setCount] = useState(0)
  const [rows, setRows] = useState([])

  function formatFecha(fecha) {
    const fechaObj = new Date(fecha);
    const dia = fechaObj.getDate().toString().padStart(2, '0');
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const anio = fechaObj.getFullYear().toString();
    return `${dia}-${mes}-${anio}`;
  }

  useEffect(() => {
    if(status == 'idle'){
        dispatch(fetchHabitaciones());
        dispatch(fetchReservas());
    }
}, [])

  useEffect(()=>{
    setCheckedState([]);
    setCheckedHabitaciones([]);
    // const trabajadores = useSelector(getAllTrabajadoresReserva);
    if(seleItem && habitaciones.length != 0){
      dispatch(fetchTrabajadores(seleItem.id))
      toast.success('Solicitud seleccionada, puedes ingresar a "DETALLE RESERVA"')
    } else if(seleItem && habitaciones.length == 0){
      toast.error('No hay habitaciones registradas/disponibles.');
    }
    console.log('solicitud seleccionada',seleItem)
  },[seleItem])

  

  const asignar = () => {
    setCheck(true);
    const asignaciones = [];
    const asignacionesHabitaciones = [];
    console.log('habitaciones cambio',checkedHabitaciones)
    // if( checkedHabitaciones.length != 0){ 
    //   if(checkedHabitaciones.length > checkedState.length ){ //si hay más habitaciones seleccionadas que trabajadores seleccionados.
    //     toast.warning('Se han seleccionado más habitaciones que trabajadores, solo se asignará una cantidad de habitaciones equivalente a los trabajadores seleccionados.')
    //   } else if(checkedHabitaciones.length < checkedState.length){
    //     toast.warning('Se han seleccionado menos habitaciones que trabajadores, solo se asignará una cantidad de habitaciones equivalente a los trabajadores seleccionados.')
    //   } else{
    //     toast.success('Habitaciones asignadas.')
    //     // setAsignacionHabitacionesTrabajadores(checkedHabitaciones);
    //   }
    //   // for (let i = 0; i < checkedState.length && i < checkedHabitaciones.length; i++) {
    //   //   const asignacion = {
    //   //     trabajador: checkedState[i],
    //   //     habitacion: checkedHabitaciones[i]
    //   //   };
    //   //   asignaciones.push(asignacion);
    //   // }
    //   setAsignacionHabitacionesTrabajadores(checkedHabitaciones);

    // }else if(habitaciones.length == 0){
    //   toast.error('No hay habitaciones disponibles');
    //   return;
    // }else if (checkedHabitaciones.length === 0) {
    //   // Si la cantidad de habitaciones seleccionadas es 0
    //   toast.warning('No se han seleccionado habitaciones.');
    //   // toast.warning('No se han seleccionado habitaciones, estas serán asignadas automáticamente según la cantidad de trabajadores seleccionados y habitaciones disponibles.')
    //   // const habitacionesDisponibles = [...checkedHabitaciones]; // Clonamos las habitaciones disponibles
    //   // for (let i = 0; i < checkedState.length && i < habitacionesDisponibles.length; i++) {
    //   //   const asignacion = {
    //   //     trabajador: checkedState[i],
    //   //     habitacion: habitacionesDisponibles[i]
    //   //   };
    //   //   asignaciones.push(asignacion);
    //   // }
    //   // setCheckedHabitaciones(habitacionesDisponibles);
    //   return false;
    // }
    // setAsignacionHabitacionesTrabajadores(asignaciones);
    setAsignacionHabitacionesTrabajadores(checkedHabitaciones);
  
  };
  useEffect(() => {
    console.log('habitaciones cambio',checkedHabitaciones)
  }, [checkedHabitaciones])
  
  useEffect(() => {
    const fetchData = async () => {
      if (typeof trabajadores.map === 'function') {
        const updatedRows = await Promise.all(
          trabajadores.map(async (info) => {
            const turnos = await dispatch(fetchTurnos());
            console.log('ID TURNOs', info.turnoId)
            console.log('turnos',turnos.payload)
            const turnoInfo = turnos.payload.find(item => item.idTurno == info.turnoId)
            console.log(turnoInfo)
            return { ...info, turno: turnoInfo?.descripcion };
          })
          );
          setRows(updatedRows);
        } else{
          setRows([]);
        }
    };
    if(count === 0){
      setCount(1);
    }
    fetchData();
    console.log('trbajadores',trabajadores)
  }, [trabajadores])
  
  useEffect(() => {
    console.log('envio',envioS)
    const trabajadoresSinEnviar = checkedState.filter(trabajador => {
      return !envioS.some(envio => envio.rut === trabajador.rut);
    });
    setAsignacionHabitacionesTrabajadores(trabajadoresSinEnviar)
  }, [envioS])
  
  useEffect(() => {
    console.log('asignacion ss',asignacionHabitacionesTrabajadores);
  }, [asignacionHabitacionesTrabajadores])
  
  useEffect(() => {
    console.log('trabajadores',checkedState);
  }, [checkedState])

  
  


  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Solicitudes de reservas"
          subtitle="Este módulo ofrece la capacidad de asignar habitaciones a los empleados inscritos en las reservas, brindando una gestión eficiente y organizada de los recursos de alojamiento dentro del sistema.">
          {<HolidayVillageIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>


        <Card style={{ marginTop:'10px' }}>
          <TabsContainer 
            setValue={setValue} 
            value={value} 
            seleItem={seleItem} 
            checkedState={checkedState} 
            checkedHabitaciones={checkedHabitaciones}
            check={check}
            />

          <Table 
            formatFecha={formatFecha}
            value={value}
            setValue={setValue}
            setFormData={setFormData}
            formData={formData}
            solicitudes={solicitudes}
            seleItem={seleItem}
            setSeleItem={setSeleItem}
            setOpenDetalle={setOpenDetalle}
            trabajadores={rows}
            checkedState={checkedState} 
            setCheckedState={setCheckedState}
            checkedHabitaciones={checkedHabitaciones}
            setCheckedHabitaciones={setCheckedHabitaciones}
            asignar={asignar}
            setCheck={setCheck}
            check={check}
            setEenvioS={setEenvioS}
            envioS={envioS}
            asignacionHabitacionesTrabajadores={asignacionHabitacionesTrabajadores}
            setAsignacionHabitacionesTrabajadores={setAsignacionHabitacionesTrabajadores}
            />
        </Card>
        
      </Container>
    </Box>
  );
}