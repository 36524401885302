import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography, DialogActions } from '@mui/material';
import { addNewEspacioBodega } from '../../../redux/features/bodegas/bodegaSlice';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  pasilloVertical: '',
  pasilloHorizontal: '',
  ubicacion: '',
  altura: 0,
  bodegaId: '',
  identificador: '',
}
const Create = ({ open2, setOpen2, bodegas }) => {
  const GRID_SIZE = 12;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [grid, setGrid] = useState(Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(false)));
  const [identificador, setIdentificador] = useState(null);

  const onChange = (e) => {
    let { name, value } = e.target;
    if (name === 'altura') {
      if (!isNaN(value)) {
        value = parseInt(value, 10);
      } else {
        value = '';
      }
    }
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeBodega = (event, newValue) => {
    setSelectedBodega(newValue);
    if(newValue != null){
      setFormData({...formData, bodegaId: newValue.id});
    } else{
      setFormData({...formData, bodegaId: 0})
    }
    
  };
// formData?.pasilloHorizontal, formData?.pasilloVertical, formData?.altura, 
  const canSave = [ formData?.bodegaId, formData?.identificador ].every(value => value === 0 || Boolean(value)) &&  addRequestStatus === 'idle';

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewEspacioBodega(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
        }
      } catch (error) {
        console.error('Error al crear el espacio de bodega', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }

  const handleGridClick = (i, j) => {
    const newIdentificador = i * GRID_SIZE + j;
    setIdentificador(newIdentificador);
    const newGrid = grid.map((row, rowIndex) => row.map((cell, cellIndex) => rowIndex * GRID_SIZE + cellIndex === newIdentificador));
    setGrid(newGrid);
    setFormData(prevState => ({
      ...prevState,
      identificador: newIdentificador
    }));
  };
  
  useEffect(() => {
    if (identificador !== null) {
      const i = Math.floor(identificador / GRID_SIZE);
      const j = identificador % GRID_SIZE;
      const newGrid = grid.map((row, rowIndex) => row.map((cell, cellIndex) => rowIndex === i && cellIndex === j));
      setGrid(newGrid);
    }
  }, [identificador]);
  
  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para registar el espacio.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> {setOpen(true); setFormData(data); setIdentificador(null); setGrid(Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(false)));}}><CircleIcon className={styles.iconButton}/>Ingresar registro</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <Grid4x4Icon className={styles.iconBgCreate}  style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Ingresar registro de espacio en bodega</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear el registro.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Bodega</label>
              <Autocomplete
                className={styles.textField}
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                disablePortal
                disableClearable
                id="campamentos-autocomplete"
                options={bodegas}
                name="bodega"
                getOptionLabel={(option) => option.nombre}
                onChange={(e, value)=> handleChangeBodega(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona una bodega"/>}
              />      
            </Grid>
            {/*<Grid item  xs={12} sm={6}>
              <label className={styles.label}>Pasillo vertical (Ejemplo: V1)</label>
              <TextField
                  className={styles.textField}
                  sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='pasilloVertical'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Pasillo horizontal (Ejemplo: H1)</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='pasilloHorizontal'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Ubicación (Ejemplo: Contenedor 1A)</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='ubicacion'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Altura (Ejemplo: 1)</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='altura'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>*/}
            <Grid item xs={12} sm={6}>
              <label>Identificador</label>
              <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='identificador'
                type="text"
                value={identificador}
                // InputProps={{
                //   readOnly: true,
                // }}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {(formData?.bodegaId && selectedBodega 
                // && selectedBodega?.imagen
              ) ? 
                <div>
                  {/*<label>Realice una selección con un <strong style={{ color: 'red' }}>click</strong> para el Identificador</label> */}
                  <img style={{ height: '95%', width: '95%' }} src={`${process.env.REACT_APP_API_URL}${selectedBodega?.imagen}`} alt="sin imagen" />
                  {/*
                    <div style={{ position: 'relative', width: '95%', height: '95%', marginTop:'5px' }}>
                    <div 
                      style={{ 
                        top: 0, 
                        left: 0, 
                        width: '95%', 
                        height: '98.5%', 
                        display: 'grid', 
                        gridTemplateColumns: `repeat(${GRID_SIZE}, 1fr)`, 
                        gridTemplateRows: `repeat(${GRID_SIZE}, 1fr)`,
                        backgroundColor:'#FFF !important'
                      }}
                    >
                      {grid.map((row, i) => row.map((cell, j) => (
                        <div
                          key={`${i}-${j}`}
                          style={{ 
                            border: '1px solid #FFF',
                            padding:'10px',
                            color:'#FFF', 
                          }}
                          onClick={() => handleGridClick(i, j)}></div>
                        )))}
                    </div>
                  </div>
                  */}
                </div>
                :
                <span style={{ fontSize:'14px', fontWeight:'500' }}>La bodega seleccionada no tiene una imagen asociada.</span>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Create