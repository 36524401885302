import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../../redux/features/campamentos/campamentoSlice";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import { fetchWings, getAllWings, getWingsStatus } from "../../../redux/features/wings/wingSlice";
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from "../../../redux/features/edificios/edificioSlice";

import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { fetchOtros, getAllOtros, getOtrosStatus } from "../../../redux/features/otros/otroSlice";
import { fetchOficinas, getAllOficinas, getOficinasStatus } from "../../../redux/features/oficinas/oficinaSlice";
const Oficina = () => {

  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const wings = useSelector(getAllWings);
  const WingsStatus = useSelector(getWingsStatus);
  const pabellones = useSelector(getAllEdificios);
  const pabStatus = useSelector(getEdificiosStatus);
  const otros = useSelector(getAllOtros);
  const otrosStatus = useSelector(getOtrosStatus);

  
  const oficinas = useSelector(getAllOficinas);
  const status = useSelector(getOficinasStatus);

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchOficinas());
    }
    if(camStatus == 'idle'){
      dispatch(fetchCampamentos());
    }
    if(WingsStatus == 'idle'){
      dispatch(fetchWings());
    }
    if(pabStatus == 'idle'){
      dispatch(fetchEdificios());
    }
    if(otrosStatus == 'idle'){
      dispatch(fetchOtros());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestión de oficinas"
          subtitle="En este módulo, podrás crear, editar y remover oficinas de la plataforma.">
          {<BookmarkAddIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} campamentos={campamentos} wings={wings} pabellones={pabellones} otros={otros}/>
          { status == 'loading' ? <SkeletonTable/> : <Table setOpen2={setOpen2} open2={open2} registros={oficinas} setOpen3={setOpen3} campamentos={campamentos} wings={wings} pabellones={pabellones} otros={otros} /> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Oficina