import styles from './components.module.css'
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useState, useEffect } from 'react';
import * as L from "leaflet";  

const defaultPosition = { lat: 0, lng: 0 };

const More = ({openMore, setOpenMore, seleItem}) => {
    const [position, setPosition] = useState(defaultPosition);
    const [mapKey, setMapKey] = useState(0);
    const [isValid, setIsValid] = useState(false);

    const LeafIcon = L.Icon.extend({
        options: {}
    });
    
    const blueIcon = new LeafIcon({
        iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
        shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png'
      })
    
    const [icon, setIcon] = useState(blueIcon);

    useEffect(() => {
        if(seleItem){
            const regex = /^-?\d+(\.\d+)?,\s-?\d+(\.\d+)?$/;
            const isValid = regex.test(seleItem?.direccion);
            setIsValid(isValid);
        
            if (seleItem?.latitud && seleItem?.longitud) {
                setPosition({
                    lat: parseFloat(seleItem.latitud),
                    lng: parseFloat(seleItem.longitud)
                });
            } else if (!seleItem?.latitud && !seleItem?.longitud && isValid) {
                const [lat, lng] = seleItem.direccion.split(',').map(coord => parseFloat(coord.trim()));
                setPosition({
                    lat: lat,
                    lng: lng
                });
            } else {
                setPosition(defaultPosition);
            }
        }
    }, [seleItem]);
    
    
    

    // Actualizar la key del MapContainer para forzar su actualización y centrado
    useEffect(() => {
        // Incrementar la key en 1 para forzar la actualización del MapContainer
        setMapKey(prevKey => prevKey + 1);
    }, [seleItem]);

    return (
        <div>
            <Dialog
                open={openMore}
                maxWidth="md"
                onBackdropClick={() => {
                    setOpenMore(false);
                }}
                PaperProps={
                    {
                      sx: {
                        width: { xs: '100%', sm: '75%', md: '50%' },
                        backgroundColor:'#2d3037',
                        color:'#FFF'
                      }
                    }
                }
            >
                <DialogTitle>
                    <Typography variant='p'>Detalles del campamento {seleItem?.nombre}</Typography>
                    <Grid children sx={12} md={12} style={{ display:'flex', flexDirection:'column' }} mb={1}>
                        <span className={styles.label}>Nombre: {seleItem?.nombre}</span>
                        <span className={styles.label}>Dirección: {seleItem?.direccion}</span>
                        {seleItem?.descripcion && <span className={styles.label}>Descripción: {seleItem?.descripcion}</span>}
                      </Grid>
                </DialogTitle>
                {
                    // seleItem?.latitud && 
                    <DialogContent style={{ maxHeight:'500px' }}>
                        <Grid container={true} mt={1}>
                            <Grid item xs={12} sm={12} mb={2} style={{ marginBottom:'5px' }}>
                                <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.plano}`} alt="img_plano" />
                            </Grid>

                            <Grid children sx={12} md={12} style={{ marginTop:'5px' }}>
                                <MapContainer key={mapKey} center={position} zoom={13} scrollWheelZoom={true} className={styles.map}>
                                    <TileLayer
                                        attribution=''
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={position} icon={icon}></Marker>
                                </MapContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }
            </Dialog>
        </div>
    )
}

export default More
