import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, MenuItem, Paper, TextField, Toolbar, Typography, Chip, Autocomplete, Popper, Backdrop, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CircleIcon from '@mui/icons-material/Circle';
import { toast } from 'react-toastify';
import Select from 'react-select';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { createAxiosInstance } from '../../../../api/axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { getInfoByRutNameContractEssed, liveSearchByRut } from '../../../../redux/features/users/userSlice';
import { format } from 'date-fns';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VerInfoTrabajador = ({ open2, setOpen2, traStatus, trabajadores, pabellones, wings, criticidadRequerimientos, habitaciones }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [search, setSearch] = useState('');
  const [selectedRut, setSelectedRut] = useState(null);
  const axiosInstance = createAxiosInstance();
  const [value, setValue] = useState(0);
  const [info, setInfo] = useState({});
  const [ruts, setRuts] = useState([]);
  const [reservas, setReservas] = useState([]);
  const [ultimaHabitacion, setUltimaHabitacion] = useState([]);
  const [reclamos, setReclamos] = useState([]);

  const [pab, setPab] = useState({});
  const [win, setWin] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  const onChangeRut = async (e) => {
    try {
      setOpen2(true)
      const resp = await dispatch(liveSearchByRut(e.target.value));
      console.log('resp: ', resp.payload.data);
      setRuts(resp.payload.data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen2(false)
    }
  };

  const onChangeRutSelect = async (e, newValue) => {
    setInfo({})
    setReservas(null)
    setUltimaHabitacion([])
    setReclamos([])
    setPab({})
    setWin({})

    if (newValue) {
      setSelectedRut(newValue);
      setLoading(true);

      try {
        const resp = await axiosInstance.get(`/api/v1/Solicitud/InformacionHuesped/${newValue.rut}`);
        
        setInfo(resp.data.data.trabajadorESED);
        setUltimaHabitacion(resp.data.data.ultimaHabitacion);

        if(Array.isArray(resp.data.data.ultimosReclamos) && resp.data.data.ultimosReclamos.length > 0){
          const map = resp.data.data.ultimosReclamos.map(item => {
            const reclamo = criticidadRequerimientos.find(it => it.id === item.criticidadRequerimientoId)
            return {
              ...item,
              criticidadRequerimiento: reclamo
            };
          })
          setReclamos(map);
        }
        if(Array.isArray(resp.data.data.reservasEnCursoOProximas) && resp.data.data.reservasEnCursoOProximas.length > 0){
          const map = resp.data.data.reservasEnCursoOProximas.map(item => {
            const habitacion = habitaciones.find(it => it.id === item.habitacion.id)
            return {
              ...item,
              habitacion: habitacion
            };
          })
          setReservas(map);
        }

        console.log('info: ', resp.data.data);
        if(resp.data.data.ultimaHabitacion.habitacion.pabellonId){
          const findPab = pabellones.find(item => item.id === resp.data.data.ultimaHabitacion.habitacion.pabellonId)
          console.log('findPab',findPab)
          setPab(findPab)
        }else if(resp.data.data.ultimaHabitacion.habitacion.wingId){
          const findWing = wings.find(item => item.id === resp.data.data.ultimaHabitacion.habitacion.wingId)
          console.log('findWing',findWing)
          setWin(findWing)
        }
        
      } catch (error) {
        console.log('Error al obtener la info del trabajador:', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setSelectedRut(null);
    }
  };

  useEffect(() => {
    console.log('trabajadores: ', trabajadores);
  }, [trabajadores])
  useEffect(() => {
    console.log('pabellones: ', pabellones);
  }, [pabellones])
  useEffect(() => {
    console.log('wings: ', wings);
  }, [wings])
  useEffect(() => {
    console.log('criticidadRequerimientos: ', criticidadRequerimientos);
  }, [criticidadRequerimientos])
  useEffect(() => {
    console.log('reclamos: ', reclamos);
  }, [reclamos])
  useEffect(() => {
    console.log('reservas: ', reservas);
  }, [reservas])
  

  return (
    <div>
      
        <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', gap:'10px', flexWrap:'wrap' }}>
            <Typography>El siguiente <strong>botón</strong> te permitirá buscar información de un trabajador.</Typography>
            <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/>Buscar Trabajador</Button>
        </div>

      <Dialog
        open={open}
        width="md"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <PersonSearchIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Buscar información</Typography>
            <span className={styles.subTitle}>Selecciona un trabajador para ver su información.</span>
          </div>
        </DialogTitle>
        {/*{JSON.stringify(formData)} */}
        <DialogContent style={{ height:'80vh' }}>
          <Grid container={true} spacing={1}>
            <Grid item xs={12} sm={12} mb={2}>
            <Autocomplete
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      color: '#FFF',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                      color: '#FFF',
                    },
                  }}
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="rut"
                  value={selectedRut}
                  options={ruts || []}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        overflowY: 'auto',
                      }}
                    />
                  )}
                  getOptionLabel={(option) => `${option.rut} ${option.nombre}`}
                  onChange={onChangeRutSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Busca y selecciona un rut"
                      onChange={onChangeRut}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.rut}>
                      {option.rut} {option.nombre}
                    </li>
                  )}
                />
              </Grid>
            </Grid>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} 
             style={{ borderRight:'none' }}
              sx={{ borderRight: 1, borderColor: 'divider',
                "& .MuiTabs-indicator": {
                    backgroundColor: "#FFF",
                    height: 3,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: '#FFF'
                  },
                  "& .MuiTabs-root":{
                    borderRight:'none'
                  }
              }}>
              <Tab label="Información" {...a11yProps(0)} />
              <Tab label="Reservas" {...a11yProps(1)} />
              <Tab label="Reclamos" {...a11yProps(2)} />
              <Tab label="Reasignar" {...a11yProps(3)} />
            </Tabs>
          </Box>
          { loading 
            ?
            <div style={{ width:'100%', height:'100%', display:'flex', justifyContent:'center', marginTop:'20px'}}>
              <CircularProgress color="inherit" size={24} />
            </div>
            :
             info?.rut && <>
              <CustomTabPanel value={value} index={0}>
                <Grid container={true} spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Typography>Rut: </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{info?.rut}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Nombre: </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{info?.nombre}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Apellidos: </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{info?.apellidoPaterno} {info?.apellidoMaterno}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Cargo:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{info?.cargo ?? '-'}</Typography>
                  </Grid>
                  {info?.email && 
                    <>
                    <Grid item xs={12} sm={6}>
                      <Typography>Email:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>{info?.email}</Typography>
                    </Grid>
                    
                  </>}
                  <Grid item xs={12} sm={6}>
                    <Typography>Acreditación:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{info?.acreditado ? 'Vigente': 'No Vigente'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Ultima entrada a garita:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{info?.ultimaEntrada ? format(info?.ultimaEntrada, 'dd-MM-yyyy') : '-'}</Typography>
                  </Grid>
                  {ultimaHabitacion && 
                  <>
                  <Grid item xs={12} sm={6}>
                    <Typography>Ultima habitaciòn utilizada</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>N°{ultimaHabitacion?.habitacion?.numero}, {pab.identificador} {win.nombre}, {pab.campamento?.nombre}</Typography>
                    <Typography>Desde {ultimaHabitacion?.fechaIngreso && format(ultimaHabitacion?.fechaIngreso, 'dd-MM-yyyy')}</Typography>
                    <Typography>Hasta {ultimaHabitacion?.fechaSalida && format(ultimaHabitacion?.fechaSalida,'dd-MM-yyyy')}</Typography>
                  </Grid>
                  </>
                }
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container={true} spacing={1}>
                  {
                    Array.isArray(reservas) && reservas.map(reserva => (
                      <>
                        <Grid item xs={12} sm={6}>
                            <Typography>ID Solicitud: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>{reserva?.solicitudReservaId}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Fecha Ingreso: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>{reserva?.fechaIngreso && format(reserva?.fechaIngreso,'dd-MM-yyyy')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Fecha Salida: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>{reserva?.fechaSalida && format(reserva?.fechaSalida,'dd-MM-yyyy')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Habitación: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>
                            {reserva?.habitacion?.numero}, {reserva?.habitacion?.pabellon?.identificador} {reserva?.habitacion?.wing?.nombre}, {reserva?.habitacion?.pabellon?.campamento?.nombre} {reserva?.habitacion?.wing?.campamento?.nombre}</Typography>
                        </Grid>
                        <div style={{ height: '1px', backgroundColor: '#212227', width:'100%', margin:'5px 0' }}></div>
                      </>
                    ))
                  }
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid container={true} spacing={1}>
                    {
                      Array.isArray(reclamos) && reclamos.map(reclamo => (
                        <>
                          <Grid item xs={12} sm={6}>
                              <Typography>ID: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>{reclamo.id}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Fecha: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>{reclamo.fecha && format(reclamo.fecha, 'dd-MM-yyyy')}</Typography>
                          </Grid>
                          {/*<Grid item xs={12} sm={6}>
                              <Typography>Campamento: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Pioneros</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Pabellón: </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>23 NORTE</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Habitación:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>32</Typography>
                          </Grid>*/}
                          <Grid item xs={12} sm={6}>
                              <Typography>Especialidad:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>{reclamo.criticidadRequerimiento.especialidad}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Solicitud:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>{reclamo.criticidadRequerimiento.solicitud}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Estado:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>{reclamo.estado}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography>Descripción:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>{reclamo.descripcion}</Typography>
                          </Grid>
                          <div style={{ height: '1px', backgroundColor: '#212227', width:'100%', margin:'5px 0' }}></div>
                        </>
                      ))
                    }
                  </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Grid container={true} spacing={1}>
                  {ultimaHabitacion && 
                    <Grid item xs={12}>
                      <Typography variant="h6">Habitación actual</Typography>
                    </Grid>
                  }
                  {ultimaHabitacion && 
                    <Grid item xs={12} style={{ display:'flex', flexDirection:'column' }}>
                      <Typography variant="span">N°: {ultimaHabitacion.habitacion.numero}</Typography>
                      <Typography variant="span">{pab ? 'Pabellón: ' + pab?.identificador : 'Wing: ' + win?.nombre}</Typography>
                      <Typography variant="span">Tipo cama: {ultimaHabitacion.habitacion.tipoCama}</Typography>
                      <Typography variant="span">N° de camas: {ultimaHabitacion.habitacion.numeroTotalCamas}</Typography>
                      <Typography variant="span">
                        Cargos reservados: {ultimaHabitacion.habitacion.cargosReservados.map(item => item).join(', ')}
                      </Typography>
                    </Grid>
                  }
                  <Grid item xs={12} style={{ marginTop:'5px' }}>
                      <Typography variant="h6">Habitaciónes disponibles</Typography>
                      
                  </Grid>
                </Grid>
                
              </CustomTabPanel>
            </>
          }

        </DialogContent>
        <DialogActions>
            <Grid container={true}>
                <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                    <Button 
                        onClick={()=>setOpen(false)}
                        className={styles.bgColorOutlined}
                        variant="outlined" 
                        >
                        Cancelar
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default VerInfoTrabajador