import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { addNewOficina } from '../../../../redux/features/oficinas/oficinaSlice';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  pabellonId: '',
  wingId: '',
  edificioId: '',
  piso: '',
  numero: '',
  descripcion: '',
  chapa: '',
}
const Create = ({ open2, setOpen2, campamentos, wings, pabellones, otros }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedCampamento, setSelectedCampamento] = useState('');
  const [selectedWing, setSelectedWing] = useState();
  const [selectedOtro, setSelectedOtro] = useState();
  const [selectedPabellon, setSelectedPabellon] = useState();
  const [pabellonWing, setPabellonWing] = useState(0);
  const [tieneChapa, setTieneChapa] = useState(false);
  const [check, setCheck] = useState(false);

  const handleChangePW = (event) => setPabellonWing(event.target.value)

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, campamento: newValue.id, });
      setSelectedWing(null);
      setSelectedPabellon(null);
    } else{
      setFormData({...formData, campamento: 0})
    }
  };
  const handleChangePabellon = (event, newValue) => {
    if(newValue != null){
      setSelectedWing(null);
      setSelectedOtro(null);
      setFormData({...formData, pabellonId: newValue.id, wingId: null, edificioId: null })
      setSelectedPabellon(newValue);
    } else{
      setFormData({...formData, pabellonId: 0})
    }
  };
  const handleChangeWing = (event, newValue) => {
    if(newValue != null){
      setSelectedPabellon(null);
      setSelectedOtro(null);
      setFormData({...formData, wingId:  newValue.id, pabellonId: null, edificioId: null })
      setSelectedWing(newValue);
    } else{
      setFormData({...formData, wingId: 0})
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if(newValue != null){
      setSelectedPabellon(null);
      setSelectedWing(null);
      setFormData({...formData, wingId: null, pabellonId: null, edificioId: newValue.id })
      setSelectedOtro(newValue);
    } else{
      setFormData({...formData, edificioId: 0})
    }
  };
  const onChange = (e) => {
      setFormData({...formData, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if(!check){
      setFormData({...formData, chapa: null});
    }
  }, [check])
  

  const canSave = [(formData.pabellonId || formData.wingId || formData.edificioId), formData.piso, formData.numero].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {

    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewOficina(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
        }
      } catch (error) {
        console.error('Error al crear la oficina', error);
      } finally{
        setAddRequestStatus('idle');
        setOpen2(false);

      }
    }
  }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder crear una oficina.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/> Crear oficina</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}} >
          <BookmarkAddIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Crear oficina</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear la oficina.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>

          <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Número</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='numero'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Piso</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='piso'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                disablePortal
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                disableClearable
                id="campamentos-autocomplete"
                name="campamento"
                options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                getOptionLabel={(option) => option.nombre}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      overflowY: 'auto',
                    }}
                  />
                )}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Pabellón, Wing, recinto</label>
              <Select
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pabellonWing}
                label="Age"
                onChange={handleChangePW}
                style={{ width:'100%' }}
              >
                <MenuItem value={1}>Pabellón</MenuItem>
                <MenuItem value={2}>Wing</MenuItem>
                <MenuItem value={3}>Recinto</MenuItem>
              </Select>
            </Grid>
            {pabellonWing == 0 && ( 
              <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>---</label>
                  <Autocomplete
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="pabellon"
                    multiple
                    disabled
                    value={selectedPabellon}
                    options={pabellones ? pabellones : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: '200px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.identificador}
                    onChange={handleChangePabellon}    
                    renderInput={(params) => <TextField {...params} label="" />}
                />
              </Grid>
             )}
            {pabellonWing == 1 && ( 
              <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>Pabellón</label>
                  <Autocomplete
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="pabellon"
                    value={selectedPabellon}
                    options={pabellones ? pabellones : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          maxHeight: '200px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.identificador}
                    onChange={handleChangePabellon}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                />
              </Grid>
             )} 
            {/* {selectedCampamento && ( */}
            {pabellonWing == 2 && ( 
              <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>Wing</label>
                  <Autocomplete
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedWing}
                    options={wings || []}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          maxHeight: '100px',
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    onChange={handleChangeWing}
                    renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                  />
              </Grid>
            )} 
            {pabellonWing == 3 && ( 
              <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>Recinto</label>
                  <Autocomplete
                    disablePortal
                    className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedOtro}
                    options={otros ?  otros : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          maxHeight: '100px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    onChange={handleChangeOtro}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un recinto" />}
                />
              </Grid>
            )} 
            <Grid item xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                name='descripcion'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                  className={styles.textField}
                  style={{ marginLeft:'10px' }}
                  sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    checked={check}
                    onChange={(e)=> {
                      setTieneChapa(prev=> (!prev)); 
                      setCheck(e.target.checked)
                    }}
                    name="tieneChapa"
                    color="primary"
                  />
                }
                label="¿Tiene chapa? (opcional)"
              />
            </Grid>
            {tieneChapa && <Grid item xs={12} sm={12}>
              <label className={styles.label}>Chapa electrónica </label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='chapa'
                type="text"
                onChange={(e) => onChange(e)}
              />
            </Grid>}
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
              <Button 
                onClick={handleSubmit}
                className={styles.bgColorModal}
                variant="contained" 
                disabled={!canSave}>
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
              </Button>
              <Button 
                onClick={()=>setOpen(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Create