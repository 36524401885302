import styles from './components.module.css'
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewCampamento } from '../../../../redux/features/campamentos/campamentoSlice';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText, TextField, Typography, Card, Box, Tab } from '@mui/material';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import { addDays, eachDayOfInterval, format, getDay, isValid, parseISO  } from 'date-fns'
import MyCalendar from '../../../AdministradorContrato/ingreso/components_ingreso/Calendar';
import moment from 'moment';
import 'moment/locale/es';
import { addForecast, aprobar, rechazar, updateForecast, updateForecastNotas } from '../../../../redux/features/forecast/forecastSlice';
import { DataGrid } from '@mui/x-data-grid';
import { createAxiosInstance } from '../../../../api/axios';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { useAuth } from '../../../../context/AppContext';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Edit = ({ openEdit, setOpenEdit,open2, setOpen2, data, tramos, setTramos, ger, siArea, empresas, contratos, seleItem, setOpen, open, turnos }) => {

  const dispatch = useDispatch();
  const [selectedVice, setSelectedVice] = useState(null);
  const [selectedGer, setSelectedGer] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedTurno, setSelectedTurno] = useState(null);
  const [vicep, setVicep] = useState([]);

  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
    
  const [value, setValue] = useState(dayjs('2022-04-17'));
  const [value2, setValue2] = useState('1');
  const [filteredTramos, setFilteredTramos] = useState([]);
  const [numeroCalendario, setNumeroCalendario] = useState([1]);
  const [check, setCheck] = useState(false);
  const [filterContratos, setFilterContratos] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  
  const { info } = useAuth();
  const rol = info?.roles;
  console.log('rol', rol);
  
  const handleChangeTab = (event, newValue) => {
    setValue2(newValue);
  };

  const changeCalendarNumber = (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count)) {
      setNumeroCalendario(prev => {
        if (prev.length < count) {
          return [...Array(count)].map((_, index) => index + 1);
        } else if (prev.length > count) {
          return prev.slice(0, count);
        } else {
          return prev;
        }
      });
    }
  };

  useEffect(() => {
    const getContratos = async () =>{
      try {
        const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+seleItem?.empresa?.idEmpresa);
        console.log('contratos::', resp.data);
        setFilterContratos(resp.data);
      }catch(error){
        console.log(error);
      }
    }
    if(seleItem){
      console.log('seleItem:',seleItem)
      setFormData({empresa: seleItem?.empresa, tramos: seleItem?.tramos, id: seleItem?.id})
      // setFilteredTramos(seleItem?.tramos)
      getContratos();
      setTramos({})
      setFilteredTramos(seleItem?.tramos);
      setActualizar(false)
    }
  }, [seleItem])

  useEffect(() => {
    if (Array.isArray(ger)) {
      const vp = ger.filter(item => item.startsWith('VP'));
      setVicep(vp);
    }
  }, [ger]);
  
  const formatDate = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (dateRegex.test(dateString)) {
      return format(new Date(dateString), 'yyyy-MM-dd');
    }
    return dateString;
  };

  
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    // const updatedTramos = [...tramos];

    if (name === '') {
        return;
    } else if (name === 'fechaDesde') {
        const parsedDate = parseISO(value);
        const diaSemana = getDay(parsedDate);
        console.log('Día de la semana:', diaSemana);

        if (diaSemana === 5 || diaSemana === 6 || diaSemana === 0) {
            toast.error("La fecha seleccionada cae en viernes, sábado o domingo.");
            return false;
        } else {
            // if (updatedTramos[index].turno.jornadasLaborales === 5 && updatedTramos[index].turno.jornadasDescanso === 2) {
            if (tramos?.turno?.jornadasLaborales === 5 && tramos?.turno?.jornadasDescanso === 2) {
                if (diaSemana !== 1) {
                    toast.error("El turno seleccionado solo puede ser programado para los lunes.");
                    return false;
                }
                // updatedTramos[index]['fechaDesde'] = value;
                const fechaHasta = addDays(parsedDate, 5);
                // updatedTramos[index]['fechaHasta'] = format(fechaHasta, 'yyyy-MM-dd'); // Formato YYYY-MM-DD
                setTramos(prev => ({...prev, fechaDesde: parsedDate }) );
                setTramos(prev => ({...prev, fechaHasta: format(fechaHasta, 'yyyy-MM-dd') }) );
            // } else if (updatedTramos[index].turno.jornadasLaborales === 4 && updatedTramos[index].turno.jornadasDescanso === 3) {
            } else if (tramos?.turno?.jornadasLaborales === 4 && tramos?.turno?.jornadasDescanso === 3) {
                if (diaSemana !== 1) {
                    toast.error("El turno seleccionado solo puede ser programado para los lunes.");
                    return false;
                }
                const fechaHasta = addDays(parsedDate, 4);
                // updatedTramos[index]['fechaDesde'] = value;
                // updatedTramos[index]['fechaHasta'] = format(fechaHasta, 'yyyy-MM-dd'); // Formato YYYY-MM-DD
                setTramos(prev => ({...prev, fechaDesde: value }) );
                setTramos(prev => ({...prev, fechaHasta: format(fechaHasta, 'yyyy-MM-dd') }) );
            } else {
                // updatedTramos[index]['fechaDesde'] = value;
                setTramos(prev => ({...prev, fechaDesde: value }) );
            }
            // setTramos(updatedTramos);
        }
    } else {
        // updatedTramos[index][name] = value;
        // setTramos(updatedTramos);
        setTramos(prev => ({...prev, [name]: value }) );
    }
};

  
  const handleAgregarTramo = () => {
    const isIn = filteredTramos.findIndex(item => item.id === tramos.id);
  
    if (isIn !== -1) {
      // Actualiza el tramo existente
      const updatedTramos = filteredTramos.map((item, index) => 
        index === isIn ? { ...item, ...tramos } : item
      );
      setFilteredTramos(updatedTramos);
      setActualizar(false);
    } else {
      // Agrega un nuevo tramo con un ID generado
      const tramoWithId = {
        ...tramos,
        id: uuidv4()
      };
      setFilteredTramos([...filteredTramos, tramoWithId]);
      setActualizar(false);
    }
  
    // Limpia el estado de 'tramos'
    setTramos({
      comentarios: "",
      contrato: "",
      fechaDesde: "",
      fechaHasta: "",
      gerencia: "",
      nombre: "",
      siArea: "",
      trabajadoresDia: 0,
      trabajadoresNoche: 0,
    });
  };
  

  useEffect(() => {    
    console.log('tramos',tramos)
  }, [tramos])
  
  useEffect(() => {
    console.log('filteredTramos',filteredTramos);
    setFormData(prev => ({...prev, tramos: filteredTramos}))
  }, [filteredTramos])
  
  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });
  const axiosInstance = createAxiosInstance();
  const handleChangeEmpresa = async (event, newValue) => {
    if(newValue != null){
      try {
        setFormData({...formData, empresa: newValue});
        const resp = await axiosInstance.get('/api/v1/Solicitud/Contratos/'+newValue.idEmpresa);
        setFilterContratos(resp.data);
      } catch (error) {
        console.log('Error al traer contratos:', error.message);
      }
    } else{
      setFormData({...formData, empresa: 0})
    }
    
  };
  const handleChangeContrato = (event, newValue) => {
    if(newValue != null){
      setSelectedEmp(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['contrato'] = newValue;
      // setTramos(updatedTramos);
      setTramos(prev => ({...prev, contrato: newValue }) );
      setFormData({...formData, contrato: newValue});
    } else{
      setFormData({...formData, contrato: 0})
    }
    
  };
  const handleChangeTurno = (event, newValue) => {
    if(newValue != null){
      setSelectedTurno(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['turno'] = newValue;
      // updatedTramos[index]['fechaDesde'] = '';
      // updatedTramos[index]['fechaHasta'] = '';
      // setTramos(updatedTramos);
      setTramos(prev => ({...prev, turno: newValue, fechaDesde: '', fechaHasta: '' }) );
      setFormData({...formData, turno: newValue, fechaDesde: '', fechaHasta: ''});
    } else{
      setFormData({...formData, turno: 0})
    }
    
  };
  const handleChangeVice = (event, newValue) => {
    if(newValue != null){
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['vicepresidencia'] = newValue;
      // setTramos(updatedTramos);
      setTramos(prev => ({...prev, vicepresidencia: newValue}) );
    }
  };
  const handleChangeGerencia = (event, newValue) => {
    if(newValue != null){
      setSelectedGer(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['gerencia'] = newValue;
      // setTramos(updatedTramos);
      setFormData({...formData, gerencia: newValue});
      setTramos(prev => ({...prev, gerencia: newValue }) );
    } else{
      setFormData({...formData, gerencia: 0})
    }
    
  };
  const handleChangeArea = (event, newValue) => {
    if(newValue != null){
      setSelectedArea(newValue)
      // const updatedTramos = [...tramos];
      // updatedTramos[index]['siArea'] = newValue;
      // setTramos(updatedTramos);
      setFormData({...formData, area: newValue});
      setTramos(prev => ({...prev, siArea: newValue }) );
    } else{
      setFormData({...formData, area: 0})
    }
    
  };

 
  const requiredProperties = [
    "nombre",
    "vicepresidencia",
    "gerencia",
    "siArea",
    "contrato",
    "trabajadoresDia",
    "trabajadoresNoche",
    "fechaDesde",
    "fechaHasta",
    "comentarios"
  ];

  useEffect(() => {
    const allValid = formData.tramos.every(item =>
      requiredProperties.every(prop => item.hasOwnProperty(prop))
    );
    
    if (allValid) {
      setCheck(true)      
    } else {
      setCheck(false)
    }
  }, [formData.tramos])

  useEffect(() => {
    console.log(formData)
  }, [formData])
  
  

  const canSave = [check, formData.empresa.idEmpresa].every(Boolean) && addRequestStatus === 'idle';

  const handleEliminarTramo = () => {
    // const updatedTramos = [...tramos];
    // updatedTramos.splice(index, 1);
    const tramosFilteres = filteredTramos.filter(item => item.id !== tramos.id)
    setTramos({});
    setFilteredTramos(tramosFilteres)
    
  };
  
  const inputRefs = useRef([]);
//-------------------- NUEVO RENDER -------------------------------
// Función para crear una instancia de Date si la fecha es válida

const calcularTrabajadoresPorDia = () => {
  const trabajadoresPorDia = {};

  Array.isArray(filteredTramos) && filteredTramos.forEach(proyecto => {
    const fechaInicio = proyecto.fechaDesde;
    const fechaTermino = proyecto.fechaHasta;
    const trabajadoresDia = parseInt(proyecto.trabajadoresDia);
    const trabajadoresNoche = parseInt(proyecto.trabajadoresNoche);

    for (let fecha = new Date(fechaInicio); fecha <= new Date(fechaTermino); fecha.setDate(fecha.getDate() + 1)) {
      const fechaString = fecha.toISOString().split('T')[0];
      if (!trabajadoresPorDia[fechaString]) {
        trabajadoresPorDia[fechaString] = 0;
      }
      trabajadoresPorDia[fechaString] += trabajadoresDia + trabajadoresNoche;
    }
  });

  return trabajadoresPorDia;
};

const trabajadoresPorDiaFunct = calcularTrabajadoresPorDia();

const canAdd =  [
  tramos.comentarios,
  tramos.contrato,
  tramos.fechaDesde,
  tramos.fechaHasta,
  tramos.gerencia,
  tramos.nombre,
  tramos.siArea,
  tramos.trabajadoresDia,
  tramos.trabajadoresNoche ].every(Boolean) && addRequestStatus === 'idle';



  const handleSubmit = async () => {
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(updateForecastNotas(formData));
        if(resp.payload.status){
          setFormData(data);
          setFilteredTramos([]);
          setOpen(false);
          return false;
        }
        console.log(tramos);
      } catch (error) {
        console.error('Error al crear el registro', error.message);
      } finally{
        setAddRequestStatus('idle');
        setOpen2(false);
      }
    }
  }

  const handleAprobar = async () => {
    try {
      setOpen2(true);
      setAddRequestStatus('loading');
      let resp = await dispatch(aprobar(formData)); 
      if(resp.payload.data.success){
        setFormData(data);
        setFilteredTramos([]);
        setOpen(false);
        setValue2('1');
        return false;
      }
    } catch (error) {
      console.error('Error al crear el registro', error.message);
    } finally{
      setAddRequestStatus('idle');
      setOpen2(false);
    }
}

const handleRechazar = async () => {
  try {
    setOpen2(true);
    setAddRequestStatus('loading');
    let resp = await dispatch(rechazar(formData)); 
    if(resp.payload.data.success){
      setFormData(data);
      setFilteredTramos([]);
      setOpen(false);
      setValue2('1');
      return false;
    }
  } catch (error) {
    console.error('Error al crear el registro', error.message);
  } finally{
    setAddRequestStatus('idle');
    setOpen2(false);
  }
}


// CODIGO NUEVO PARA CORREGIR LA TAsBLA DE OCUPACION DIARIA
const [rowsNew, setRowsNew] = useState([]);
const [columnNew, setColumnNew] = useState([]);

useEffect(() => {
  if (filteredTramos.length <= 0 || !check) {
    console.log('entra aqui');
    setRowsNew([])
    setColumnNew([])
    return;
  }

  // Encontrar la fecha mínima (fechaDesde) y máxima (fechaHasta) en todos los registros
  let minFechaDesde = parseISO(filteredTramos[0].fechaDesde);
  let maxFechaHasta = parseISO(filteredTramos[0].fechaHasta);

  Array.isArray(filteredTramos) && filteredTramos.forEach(item => {
    const fechaDesde = parseISO(item.fechaDesde);
    const fechaHasta = parseISO(item.fechaHasta);

    if (fechaDesde < minFechaDesde) {
      minFechaDesde = fechaDesde;
    }
    if (fechaHasta > maxFechaHasta) {
      maxFechaHasta = fechaHasta;
    }
  });

  // Crear un array de fechas únicas desde minFechaDesde hasta maxFechaHasta
  const fechas = eachDayOfInterval({ start: minFechaDesde, end: maxFechaHasta });

  // Crear las columnas dinámicas basadas en las fechas encontradas
  const columns = [
    { field: 'nombre', headerName: '-', width: 150 },
    ...fechas.map(fecha => ({
      field: format(fecha, 'dd-MM-yyyy'),
      headerName: format(fecha, 'dd-MM-yyyy'),
      width: 110,
      editable: false, // Opcional: puedes permitir la edición si es necesario
    }))
  ];
  setColumnNew(columns);

  // Preparar las filas con los cálculos necesarios para cada fecha
  const row = { id: 0, nombre: 'Totales' };

  fechas.forEach(fecha => {
    const fechaActual = format(fecha, 'dd-MM-yyyy');
    let totalTrabajadoresDia = 0;
    let totalTrabajadoresNoche = 0;

    filteredTramos.forEach(item => {
      const fechaDesde = parseISO(item.fechaDesde);
      const fechaHasta = parseISO(item.fechaHasta);

      if (fecha >= fechaDesde && fecha <= fechaHasta) {
        totalTrabajadoresDia += parseInt(item.trabajadoresDia) || 0;
        totalTrabajadoresNoche += parseInt(item.trabajadoresNoche) || 0;
      }
    });

    const totalTrabajadores = totalTrabajadoresDia + totalTrabajadoresNoche;
    row[fechaActual] = totalTrabajadores.toString();
  });

  setRowsNew([row]);
}, [filteredTramos, check]);



  return (
    <div>
      <Dialog
        // fullScreen
        open={openEdit}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          // setOpenEdit(false);
        }}
      >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <DialogContent>
          <TabContext value={value2}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="lab API tabs example" sx={{
                    "& .MuiTab-root.Mui-selected": { color: "#FFF" },
                    "& .MuiTabs-indicator": { bgcolor: "var(--border-blue)" },
                    "& .MuiButtonBase-root" : { color:'#aeaeae' }
                  }} >
              <Tab label="Editar" value="1" />
              {
                (
                  !rol?.includes('Administrador de Contrato ESED (ACE)') &&
                  (seleItem?.estado !== 'Rechazado' && seleItem?.estado !== 'Aprobado') ||
                  rol?.includes('Administrador de RRHH (ARH)') && seleItem?.estado === 'Aprobado' )
                    ? <Tab label="Aprobar" value="2" />
                    : null
                  }
              </TabList>
            </Box>
          <TabPanel value="1">
            <DialogTitle style={{ display:'flex', gap:'5px', margin:'0', padding:'0'}}>
              <BrokenImageIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }} />
              <div style={{ display:'flex', flexDirection:'column' }}>
                <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1'  }}>Correcciones y retroalimentación del forecast</Typography>
                <span className={styles.subTitle}>Modifica los siguientes campos para realizar las correcciones.</span>
              </div>
            </DialogTitle>
              <Grid container={true} spacing={1}>
                <Grid item xs={12} sm={4} style={{ display:'flex', gap:'10px', paddingTop:'27px', flexDirection:'column' }} mt={2} mb={2} >
                <div style={{ display:'flex', justifyContent:'start', alignItems:'end', gap:'10px' }}>
                  <div style={{ display:'flex', flexDirection:'column', width:'100%' }}>
                    <label className={styles.label}>N° de meses</label>
                    <TextField
                      className={[styles.textField, styles.paddingCero]}
                      sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                          width: '100%',
                        '@media (min-width: 600px)': {
                          width: '30%',
                        },
                      }}
                      style={{ width:'100%' }}
                      variant="outlined"
                      name='buscar'
                      type="number"
                      defaultValue={1}
                      placeholder='N° de meses'
                      onChange={(e) => changeCalendarNumber(e)}
                    />
                  </div>
                </div>

                <div style={{ width:'100%' }}>
                    <label className={styles.label}>Empresa</label>
                    <Autocomplete
                    className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                      disablePortal
                      disableClearable
                      id="campamentos-autocomplete"
                      options={empresas}
                      name="empresaId"
                      value={formData?.empresa || null}
                      getOptionLabel={(option) => option.razonSocial}
                      onChange={(e, value)=> handleChangeEmpresa(e, value)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                
                </Grid>
                <Grid item  xs={12} sm={8} style={{ display:'flex', flexDirection:'column', height:'10px' }}>
                </Grid>
                {/* 
                {JSON.stringify(filteredTramos)}
              */} 
                <Grid item  xs={12} sm={4} >
                  <>
                  <div style={{ display:'flex', gap:'5px' }}>
                    <div style={{ width:'100%' }}>
                      <label className={styles.label}>Nombre</label>
                      <TextField
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        value={tramos.nombre || ''}
                        name='nombre'
                        type="text"
                        placeholder='Nombre del tramo'
                        onChange={(e) => handleChange(e)}
                        // inputRef={ el => inputRefs.current[index] = el } // Asigna una referencia al input
                      />
                    </div>
                    <div style={{ width:'100%' }}>
                        <label className={styles.label}>Vicepresidencia</label>
                        <Autocomplete
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          disablePortal
                          disableClearable
                          id="campamentos-autocomplete"
                          options={vicep || []}
                          name="vicepresidencia"
                          value={tramos?.vicepresidencia || null}
                          getOptionLabel={(option) => option}
                          onChange={(e, value)=> handleChangeVice(e, value)}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              <ListItemText>{option}</ListItemText>
                            </li>
                          )}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                  </div>
                  <div style={{ display:'flex', gap:'5px' }}>
                      <div style={{ width:'100%' }}>
                        <label className={styles.label}>Gerencia</label>
                        <Autocomplete
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          disablePortal
                          disableClearable
                          id="campamentos-autocomplete"
                          options={Array.isArray(ger) ? ger : []}
                          name="gerencia"
                          value={tramos?.gerencia || null}
                          getOptionLabel={(option) => option}
                          onChange={(e, value)=> handleChangeGerencia(e, value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                      <div style={{ width:'100%' }}>
                        <label className={styles.label}>SI-Área</label>
                        <Autocomplete
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          disablePortal
                          disableClearable
                          id="campamentos-autocomplete"
                          options={Array.isArray(siArea) ? siArea : []}
                          name="siArea"
                          value={tramos?.siArea || null}
                          getOptionLabel={(option) => option}
                          onChange={(e, value)=> handleChangeArea(e, value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                    </div>
                  <div style={{ display:'flex', gap:'5px' }}>
                      <div style={{ width:'100%' }}>
                      <label className={styles.label}>Contrato</label>
                      <Autocomplete
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          disablePortal
                          disableClearable
                          id="campamentos-autocomplete"
                          options={filterContratos || []}
                          name="contrato"
                          value={tramos?.contrato || null}
                          getOptionLabel={(option) => option}
                          onChange={(e, value)=> handleChangeContrato(e, value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                    {/*
                    <div style={{ width:'100%' }}>
                        <label className={styles.label}>Turnos</label>
                          <Autocomplete
                          className={styles.textField}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color: '#FFF',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color: '#FFF',
                            },
                          }}
                          disablePortal
                          disableClearable
                          id="campamentos-autocomplete"
                          options={turnos || []}
                          name="turno"
                          value={tramos?.turno || null}
                          getOptionLabel={(option) => option.descripcion}
                          onChange={(e, value)=> handleChangeTurno(e, value)}
                          renderInput={(params) => <TextField {...params} />}
                        /> 
                        </div>
                        */}
                    </div>
                    <div style={{ display:'flex', gap:'5px' }}>
                      <div>
                        <label className={styles.label}>Desde</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          variant="outlined"
                          fullWidth
                          value={tramos.fechaDesde || ''}
                          name='fechaDesde'
                          InputProps={{ inputProps: { min: 0 } }}
                          type="date"
                          onChange={(e) => handleChange(e)}
                          />
                      </div>
                      <div>
                        <label className={styles.label}>Hasta</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        value={tramos.fechaHasta || ''}
                        name='fechaHasta'
                        InputProps={{ inputProps: { min: 0 } }}
                        type="date"
                        onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div style={{ display:'flex', gap:'5px' }}>
                      <div>
                        <label className={styles.label}>N°Trabajadores día</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name='trabajadoresDia'
                        value={tramos.trabajadoresDia || ''}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <label className={styles.label}>N°Trabajadores noche</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        name='trabajadoresNoche'
                        value={tramos.trabajadoresNoche || ''}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        onChange={(e) => handleChange(e)}
                        />
                      </div>
                      </div>
                      <div style={{ width:'100%' }}>
                        <label className={styles.label}>Comentarios</label>
                        <TextField
                        className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                          variant="outlined"
                          fullWidth
                          value={tramos.comentarios || ''}
                          name='comentarios'
                          type="text"
                          placeholder='Ingrese un comentario sobre el tramo'
                          onChange={(e) => handleChange(e)}
                          // inputRef={ el => inputRefs.current[index] = el }
                        />
                    </div>
                    <div style={{ marginTop:'10px' }}>
                    { 
                      (
                        (rol.includes('Administrador de Contrato ESED (ACE)') && (seleItem?.estado !== 'Aprobado por CMDIC' && seleItem?.estado !== 'Aprobado por RRHH')) ||
                        (rol.includes('Administrador de RRHH (ARH)') && seleItem?.estado === 'Aprobado por CMDIC') ||
                        rol.includes('Administrador de Contrato CMDIC (ACC)')
                      )
                      && <Button 
                      className={ !actualizar ? styles.bgColorOrange : styles.bgColorBlue } 
                      variant="fulled" 
                      onClick={handleAgregarTramo}
                      style={{ fontSize:'12px', height:'50px', width: !actualizar ? '100%' : '80%',marginRight: !actualizar ? '0' : '5px'  }}>
                        { !actualizar ? 'Agregar tramo' : 'Actualizar tramo'}
                    </Button>}  

                    {  actualizar && 
                      (rol.includes('Administrador de Contrato ESED (ACE)') && (seleItem?.estado !== 'Aprobado por CMDIC'&& seleItem?.estado !== 'Aprobado por RRHH')) ||
                      (rol.includes('Administrador de RRHH (ARH)') && seleItem?.estado === 'Aprobado por CMDIC') &&
                      rol.includes('Administrador de Contrato CMDIC (ACC)') 
                    && <Button 
                          className={styles.bgColorDelete}
                          variant="outlined"
                          style={{ width:'5%', fontSize:'12px', height:'50px' }}
                          onClick={() => handleEliminarTramo()}
                        >
                          <DeleteIcon/>
                        </Button>}
                      </div>
                    </>
                  </Grid>
                {
                  numeroCalendario.map((item, index)=> (
                    <Grid item xs={12} sm={numeroCalendario.length == 1 ? 8 : 4}>
                      <Card style={{ backgroundColor:'var(--textfield-bg)', color:'#FFF', padding:'10px' }}>
                      <MyCalendar events={filteredTramos || []} trabajadoresPorDia={trabajadoresPorDiaFunct} inputRefs={inputRefs} setTramos={setTramos} setActualizar={setActualizar} defaultDate={moment().add(index, 'month').toDate()}/>
                      </Card>
                    </Grid>
                  ))
                }
                
              
              <Grid item xs={12} sm={12}>
                <label style={{ fontSize:'16px', fontWeight:'500' }} className={styles.label}>Notas:</label>
                <TextField
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  variant="outlined"
                  fullWidth
                  name='notas'
                  type="text"
                  value={formData?.notas}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={(e) => onChange(e)}
                />  
              </Grid>
              <Grid item xs={12} sm={12} style={{ displey:'flex', flexDirection:'column' }}>  
              
              {  rowsNew.length > 0 && <>
                <label style={{ fontSize:'16px', fontWeight:'500' }} className={styles.label}>Trabajadores totales por dia:</label>
                  <DataGrid
                    rows={rowsNew}
                    columns={columnNew}
                    pageSize={5} // Tamaño de la página
                    rowsPerPageOptions={[5, 10, 20]} // Opciones de tamaño de página
                    autoHeight // Altura automática
                    sx={{ 
                      '& .MuiDataGrid-cell': {
                        color: '#FFF',
                        borderBottom: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        color: '#FFF',
                        borderBottom: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        borderBottom: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-footerContainer': {
                        color: '#FFF',
                        borderTop: '1px solid #c6c6c6',
                      },
                      '& .MuiTablePagination-root': {
                        color: '#FFF',
                        borderTop: '1px solid #c6c6c6',
                      },
                      '& .MuiDataGrid-toolbarContainer': {
                        color: '#FFF',
                      },
                      '& .MuiDataGrid-menu': {
                        color: '#FFF',
                      },
                      '& .MuiTablePagination-actions': {
                        color: '#FFF', // Color for pagination actions
                      },
                      '& .MuiTablePagination-select': {
                        color: '#FFF', // Color for pagination select
                      },
                      '& .MuiTablePagination-selectLabel': {
                        color: '#FFF', // Color for pagination select label
                      },
                      '& .MuiTablePagination-displayedRows': {
                        color: '#FFF', // Color for displayed rows text
                      },
                    }}
                    slotProps={{
                      toolbar: {
                        sx: {
                          color: '#FFF',
                        },
                      },
                      pagination: {
                        sx: {
                          color: '#FFF',
                        },
                      },
                      menu: {
                        sx: {
                          color: '#FFF',
                        },
                      },
                    }}
                    classes={{ menuIcon: styles.menuIcon }}
                    style={{ maxHeight:'500px', color:'#FFF', }}
                  /> 
                </>
              }
                </Grid>
              

              </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container={true}>
                <Grid item xs={12} md={12}>
                  <label className={styles.label}>Empresa registrada en el forecast</label>
                  <TextField
                  className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: '#FFF',
                              },
                              '& .MuiInputLabel-root': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color: '#FFF',
                              },
                            }}
                    variant="outlined"
                    fullWidth
                    name='empresa'
                    type="text"
                    value={seleItem && seleItem?.empresa?.razonSocial}
                    // disabled
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <label className={styles.label}>Observaciones</label>
                  <TextField
                  className={styles.textField}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none',
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: '#FFF',
                              },
                              '& .MuiInputLabel-root': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: '#FFF',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color: '#FFF',
                              },
                            }}
                  variant="outlined"
                    fullWidth
                    name='observaciones'
                    type="text"
                    multiline
                    rows={2}
                    value={formData?.observaciones}
                    maxRows={4}
                    onChange={(e) => onChange(e)}
                  />
                  </Grid>
                <Grid item xs={12} md={12} style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} mt={2}>
                  <div style={{ width:'fit-content' }}>
                    <Button variant='contained' style={{ marginRight:'5px' }} className={styles.bgColor} onClick={handleAprobar}>Aprobar</Button>
                    <Button 
                      variant='contained' 
                      style={{ opacity: !formData.observaciones ? '0.7' : '1', backgroundColor: '#800e0e', color:'#FFF' }}
                      // className={styles.bgColorDelete}
                      onClick={handleRechazar}
                      disabled={!formData.observaciones}
                      >Rechazar
                      </Button>
                  </div>
                  {/*<Button variant='outlined' className={styles.bgColorOutlined} onClick={()=>setOpenEdit(false)}>Cerrar</Button> */}
                </Grid>
            </Grid>
        </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding:'0 16px' }}>
            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
               {((rol.includes('Administrador de Contrato ESED (ACE)') && seleItem?.estado !== 'Aprobado por CMDIC' && seleItem?.estado !== 'Aprobado por RRHH') ||
                (rol.includes('Administrador de RRHH (ARH)') && seleItem?.estado === 'Aprobado por CMDIC') ||
                rol.includes('Administrador de Contrato CMDIC (ACC)')) 
                && <Button 
                onClick={handleSubmit}
                className={styles.bgColorEdit}
                variant="contained" 
                disabled={!canSave}
                >
                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Actualizar'}
              </Button>} 
              <Button 
                onClick={()=>setOpenEdit(false)}
                className={styles.bgColorOutlined}
                variant="outlined" 
                >
                Cancelar
              </Button>
            </Grid>          
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Edit