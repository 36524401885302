import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/users/userSlice'
import rolReducer from './features/roles/rolSlice'
import empresaReducer from './features/empresas/empresaSlice'
import campamentoReducer from './features/campamentos/campamentoSlice'
import wingReducer from './features/wings/wingSlice'
import sectorReducer from './features/sectores/sectorSlice'
import forecastReducer from './features/forecast/forecastSlice'
import espacioReducer from './features/espacios/espacioSlice'
import motivoReducer from './features/motivos/motivoSlice'
import habitacionReducer from './features/habitaciones/habitacionSlice'
import reservasReducer from './features/reservas/reservaSlice'
import responsablesReducer from './features/responsables/responsableSlice'
import edificioReducer from './features/edificios/edificioSlice'
import estandarReducer from './features/estandares/estandarSlice'
import otroReducer from './features/otros/otroSlice'
import inventarioReducer from './features/inventario/inventarioSlice'
import loginReducer from './features/login/loginSlice'
import guardarropiaReducer from './features/guardarropia/guardarropiaSlice'
import reclamosSugerenciaReducer from './features/reclamos_sugerencias/reclamos_sugerenciaSlice'
import bodegaReducer from './features/bodegas/bodegaSlice'
import mermaReducer from './features/merma/mermaSlice'
import oficinaReducer from './features/oficinas/oficinaSlice'
import aseoReducer from './features/aseo/aseoSlice'
import budgetReducer from './features/budget/budgetSlice'

export const store = configureStore({
    reducer: {
        users: userReducer,
        roles: rolReducer,
        empresas: empresaReducer,
        campamentos: campamentoReducer,
        wings: wingReducer,
        sectores: sectorReducer,
        forecast: forecastReducer,
        espacios: espacioReducer,
        motivos: motivoReducer,
        habitaciones: habitacionReducer,
        reservas: reservasReducer,
        responsables: responsablesReducer,
        edificios: edificioReducer,
        otros: otroReducer,
        estandares: estandarReducer,
        inventarios: inventarioReducer,
        login: loginReducer,
        guardarropias: guardarropiaReducer,
        reclamosSugerencias: reclamosSugerenciaReducer,
        bodegas: bodegaReducer,
        mermas: mermaReducer,
        oficinas: oficinaReducer,
        aseo: aseoReducer,
        budget: budgetReducer,
    }
})