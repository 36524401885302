import styles from './components/components.module.css'
import { Box, Container } from "@mui/material"
import Header from "../../components/header/Header"
import TableCard from "../../components/tableCard/TableCard";
import TextsmsIcon from '@mui/icons-material/Textsms';
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchReclamosSugerenciasHuesped, fetchReclamosSugerenciasHuespedData, getAllReclamosSugerencias, getAllReclamosSugerenciasHuesped, getReclamosSugerenciasStatus } from "../../redux/features/reclamos_sugerencias/reclamos_sugerenciaSlice";
import SkeletonTable from "../../components/Skeleton";
import { Backdrop, CircularProgress } from '@mui/material';
import Create from "./components/Create";
import { fetchMotivos, getAllMotivos, getMotivosStatus } from "../../redux/features/motivos/motivoSlice";
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from '../../redux/features/campamentos/campamentoSlice';
import { fetchWings, getAllWings, getWingsStatus } from '../../redux/features/wings/wingSlice';
import { getAllOtros, getOtrosStatus } from '../../redux/features/otros/otroSlice';
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from '../../redux/features/edificios/edificioSlice';
import CreateRecepcionista from './components/CreateRecepcionista'
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../redux/features/habitaciones/habitacionSlice';

const ReclamosSugerencias = () => {
    const dispatch = useDispatch();
    const reclamos_sugerencias = useSelector(getAllReclamosSugerenciasHuesped);
    const status = useSelector(getReclamosSugerenciasStatus);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const motivos = useSelector(getAllMotivos);
    const motStatus = useSelector(getMotivosStatus);
    const campamentos = useSelector(getAllCampamentos);
    const camStatus = useSelector(getCampamentosStatus);
    // const wings = useSelector(getAllWings);
    // const winStatus = useSelector(getWingsStatus);
    // const otros = useSelector(getAllOtros);
    // const otrStatus = useSelector(getOtrosStatus);
    // const pabellones = useSelector(getAllEdificios);
    // const pabStatus = useSelector(getEdificiosStatus);
    // const habitaciones = useSelector(getAllHabitaciones);
    // const habStatus = useSelector(getHabitacionesStatus);

    useEffect(()=>{
      if(status === 'idle'){
        dispatch(fetchReclamosSugerenciasHuesped());
      }
      if(motStatus === 'idle'){
        dispatch(fetchMotivos());
      }
      // if(camStatus === 'idle'){
      //   dispatch(fetchCampamentos());
      // }
      // if(winStatus === 'idle'){
      //     dispatch(fetchWings());
      // }
      // if(pabStatus === 'idle'){
      //   dispatch(fetchEdificios());
      // }
      // if(habStatus === 'idle'){
      //   dispatch(fetchHabitaciones());
      // }
    },[]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Reclamos y Sugerencias"
          subtitle="En este módulo, podrás visualizar toda la información relacionada a tus reclamos y sugerencias registradas.">
          {<TextsmsIcon style={{ fontSize:'1.5rem' }} className={styles.iconBg}/>}
        </Header>

        <TableCard>
          <CreateRecepcionista setOpen2={setOpen2} open2={open2} motivos={motivos} campamentos={campamentos}
          />
          {/*wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros} */}
          { status == 'loading' ? <SkeletonTable/> : <Table reclamos={reclamos_sugerencias ? reclamos_sugerencias : []} setOpen3={setOpen3}/>
        }
        </TableCard>
      </Container>
    </Box>
  )
}

export default ReclamosSugerencias