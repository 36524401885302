import styles from './components.module.css'
import { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import TableHabitaciones from './TableHabitaciones'
import { addNewGrupoAseo } from '../../../../redux/features/aseo/aseoSlice';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  nombre: '',
  descripcion: '',
  habitaciones: [],
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Create = ({ open2, setOpen2, setOpen3, campamentos, wings, pabellones, habitaciones, otros }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); //variable para abrir y cerrar modal
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [selectedWing, setSelectedWing] = useState([]);
  const [selectedPabellon, setSelectedPabellon] = useState([]);
  const [selectedOtro, setSelectedOtro] = useState([]);
  const [pabellonWing, setPabellonWing] = useState(null);
  const [selectedHabitaciones, setSelectedHabitaciones] = useState([])
  const [filtroPabellones, setFiltroPabellones] = useState([]);
  const [filtrowings, setFiltroWings] = useState([]);  
  const [filtrootros, setFiltroOtros] = useState([]);
  const [defaultHabitaciones, setDefaultHabitaciones] = useState([]);

  const defaultRecarga = () => {
    setPabellonWing(null);
    setDefaultHabitaciones([]);
  };

  const handleChangePW = (event) => {
    setPabellonWing(event.target.value);
    setSelectedWing([]);
    setSelectedOtro([]);
    setSelectedPabellon([]);
    setFormData({...formData, pabellon: null, wing: null, otro: null })
  };

  const handleChangeCampamento = (event, newValue) => {
    defaultRecarga();
    setSelectedCampamento(newValue);
    if(newValue != null){
      setFormData({...formData, campamento: newValue.id});
      let newPabellones = pabellones.filter(item => item.campamentoId == newValue.id);
      setFiltroPabellones(newPabellones);
      let newWings = wings.filter(item => item.campamentoId == newValue.id);
      setFiltroWings(newWings);
      let newOtros = otros.filter(item => item.campamentoId == newValue.id);
      setFiltroOtros(newOtros);
    } else{
      setFormData({...formData, campamento: 0})
    }
  };

  const handleChangePabellon = (event, newValue) => {
    if(newValue != null){
      setDefaultHabitaciones(habitaciones)
      setFormData({...formData, pabellon: newValue.map(item => item.id), wing: null, otro: null })
      setSelectedPabellon(newValue);
      
      setFormData({...formData, pabellon: newValue.map(item => item.id), wing: null, otro: null })
      setSelectedWing([]);
      setSelectedOtro([]);
    } else{
      setFormData({...formData, pabellon: 0})
      }
  };

  const handleChangeWing = (event, newValue) => {
    if(newValue != null){
      setFormData({...formData, wing:  newValue.map(wing => wing.id), pabellon: null, otro: null })
      setSelectedPabellon([]);
      setSelectedOtro([]);
      setSelectedWing(newValue);
    } else{
      setFormData({...formData, wing: 0})
    }
  };

  const handleChangeOtro = (event, newValue) => {
    if(newValue != null){
      setSelectedPabellon([]);
      setSelectedWing([]);
      setFormData({...formData, wing: null, pabellon: null, otro: newValue.map(wing => wing.id) })
      setSelectedOtro(newValue);
    } else{
      setFormData({...formData, wing: 0})
    }
  };

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  // const canSave = [formData.nombre, formData.habitaciones].every(Boolean) && addRequestStatus === 'idle';
  const canSave = [formData.nombre].every(Boolean) && addRequestStatus === 'idle';

  const handleSubmit = async () => {
    if(canSave) {
      try {
        setOpen2(true);
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewGrupoAseo(formData));
        if(resp.payload.status === 'success'){
          setFormData(data);
          setOpen(false);
      }
      } catch (error) {
        console.error('Error al crear el grupo de habitaciones', error);
      } finally{
        setOpen2(false);
        setAddRequestStatus('idle');
      }
    }
  }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
        <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder registrar un grupo de habitaciones.</Typography>
        <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> {setOpen(true); defaultRecarga(); }}><CircleIcon className={styles.iconButton}/>Crear grupo</Button>
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <NightShelterIcon className={styles.iconBgCreate}  style={{ fontSize:'3rem', marginTop:'0px' }} />
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Crear grupo</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos y selecciona las habitaciones que desees asociar al grupo.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={3} style={{ overflowY:'auto' }}>{/* maxHeight:'400px',  */}
              <div>
                <label className={styles.label}>Nombre</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#FFF',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='nombre'
                  type="text"
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div>
                <label className={styles.label}>Campamento</label>
                <Autocomplete
                  className={styles.textField}
                  sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  disablePortal
                  disableClearable
                  id="campamentos-autocomplete"
                  name="campamento"
                  options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value)=> handleChangeCampamento(e, value)}
                  renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                />
              </div>
              <div>
                <label className={styles.label}>Pabellón, Wing, recinto.</label>
                <Select
                  className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pabellonWing || []}
                  label="Age"
                  onChange={handleChangePW}
                  style={{ width:'100%' }}
                >
                  <MenuItem value={1}>Pabellón</MenuItem>
                  <MenuItem value={2}>Wing</MenuItem>
                  <MenuItem value={3}>Recinto</MenuItem>
                </Select>
              </div>
              <div>
                {/* {selectedSector && ( */}
                {pabellonWing == 1 && ( 
                  <>
                      <label className={styles.label}>Pabellón</label>
                      <Autocomplete
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        disablePortal
                        disableClearable
                        id="sectores-autocomplete"
                        name="pabellon"
                        multiple
                        value={selectedPabellon || []}
                        options={filtroPabellones ? filtroPabellones : [] }
                        getOptionLabel={(option) => option.identificador}
                        onChange={handleChangePabellon}    
                        renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                    />
                  </>
                 )} 
                {/* {selectedCampamento && ( */}
                {pabellonWing == 2 && ( 
                  <>
                      <label className={styles.label}>Wing</label>
                      <Autocomplete
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        multiple
                        disablePortal
                        disableClearable
                        id="sectores-autocomplete"
                        name="wing"
                        value={selectedWing || []}
                        options={filtrowings ?  filtrowings : []}
                        getOptionLabel={(option) => option.nombre}
                        onChange={handleChangeWing}
                        renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                      />
                  </>
                )} 
                {pabellonWing == 3 && ( 
                  <>
                      <label className={styles.label}>Recinto</label>
                      <Autocomplete
                      className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '.MuiChip-label':{
                            color: 'white',
                          },
                          '.MuiSvgIcon-root':{
                            color: '#FFF !important',
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                        disablePortal
                        multiple
                        disableClearable
                        id="sectores-autocomplete"
                        name="wing"
                        value={selectedOtro || []}
                        options={filtrootros ?  filtrootros : [] }
                        getOptionLabel={(option) => option.nombre}
                        onChange={handleChangeOtro}    
                        renderInput={(params) => <TextField {...params} label="Selecciona un recinto" />}
                    />
                  </>
                )} 
              </div>
              <div>
                <label className={styles.label}>Piso (Opcional)</label>
                <TextField
                  className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                variant="outlined"
                fullWidth
                name='piso'
                type="number"
                onChange={(e) => onChange(e)}
                />
              </div>
              <div>
                <label className={styles.label}>Descripción (opcional)</label>
                <TextField
                className={styles.textField}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#FFF',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-clearIndicator': {
                            color: '#FFF',
                          },
                          '& .MuiAutocomplete-popupIndicator': {
                            color: '#FFF',
                          },
                        }}
                  variant="outlined"
                  fullWidth
                  name='descripcion'
                  type="text"
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </Grid>

            <Grid item  xs={12} sm={9} style={{ marginTop:'7px' }}>
              <TableHabitaciones habitaciones={defaultHabitaciones} setOpen3={setOpen3} setSelectedHabitaciones={setSelectedHabitaciones} selectedWing={selectedWing} selectedPabellon={selectedPabellon} selectedOtro={selectedOtro} setSelectedWing={setSelectedWing} setSelectedPabellon={setSelectedPabellon} setSelectedOtro={setSelectedOtro} formData={formData} setFormData={setFormData}/>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container={true} style={{ padding:'0 16px' }}>
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  // disabled={!canSave || selectedHabitaciones.length <= 0}>
                  disabled={!canSave}>
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Create