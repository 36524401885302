import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect } from 'react';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
useEffect(() => { console.log('seleItem:', seleItem) }, [seleItem]);

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
         backgroundColor:'#2d3037',
         color:'#FFF',
         borderRadius:'0'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" >
        {"Resumen Merma"} ID: {seleItem?.id}
      </DialogTitle>
      
      <DialogContent>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#212227', padding:'10px', borderRadius:'5px', border:'1px solid #0086ae', overflowX:'auto' }}>
          <Grid item xs={12} md={4}>
            <Typography align="left">Fecha:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fecha && format(seleItem?.fecha, 'dd-MM-yyyy')}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Usuario:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.usuario?.nombre} {seleItem?.usuario?.apellido}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Correo:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.usuario?.email}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">ID Espacio bodega:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.espacioBodega?.identificador}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Árticulo:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.producto?.nombre}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Codigo Árticulo:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.producto?.codigo}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Cantidad:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.cantidad}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Motivo:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.motivo}</strong></Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography align="left">Obervaciones:</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.observaciones}</strong></Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions >
        <Button onClick={handleClose} style={{ color:'#FFF' }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
