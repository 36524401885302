import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';

import { createAxiosInstance } from "../../../api/axios";
import moment from "moment";

const FOREACST_GERENCIAS_URL = '/api/v1/Forecast/Gerencias';
const FOREACST_AREAS_URL = '/api/v1/Forecast/Areas';
const FORECAST_URL = '/api/v1/Forecast';
const UPDATE_FORECAST_S_URL = '/api/v1/Forecast/';
const UPDATE_FORECAST_URL = '/Aprobar/'
const RECHAZAR_FORECAST_URL = '/Rechazar/'
const APROBAR_FORECAST_URL = '/Aprobar/'

const axiosInstance = createAxiosInstance();

// ACTIONS
export const fetchForecasts = createAsyncThunk("forecast/fetchForecast", async ()=> {
    try {
        const response = await axiosInstance.get(FORECAST_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchForecastGerencias = createAsyncThunk("forecast/fetchForecastGerencias", async ()=> {
    try {
        const response = await axiosInstance.get(FOREACST_GERENCIAS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchForecastAreas = createAsyncThunk("sector/fetchForecastAreas", async ()=> {
    try {
        const response = await axiosInstance.get(FOREACST_AREAS_URL);        
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const aprobar = createAsyncThunk("forecast/aprobar", async (forecast, {dispatch, rejectWithValue})=> {
    try {
        const response = await axiosInstance.put(UPDATE_FORECAST_S_URL + forecast.id, {
          empresaId: forecast.empresa.idEmpresa,
          notas: forecast.observaciones,
          tramos: forecast.tramos,
        });
  
        const responseAprobar = await axiosInstance.put(UPDATE_FORECAST_URL+forecast.id);
  
        if (response.status >= 200 && response.status < 300 && responseAprobar.status >= 200 && responseAprobar.status < 300) {
          toast.success('Forecast actualizado y aprobado correctamente.');
          dispatch(fetchForecasts());
          return { data: response.data, status: 'success' };
        }
  
        return rejectWithValue({ status: 'error' });
  
      } catch (e) {
        toast.error('Error al aprobar el forecast: ' + e.response?.data || e.message);
        return rejectWithValue({ message: e.message, status: 'error' });
      }
})

export const rechazarSolicitudForecast = createAsyncThunk("forecast/rechazarSolicitudForecast", async (data, {dispatch})=> {
    console.log('llega', data);
    try {
        const response = await axiosInstance.put(RECHAZAR_FORECAST_URL+data.id, data.razonRechazo);
        
        if (response.status === 200) {
            toast.success('Forecast rechazado correctamente.');
            dispatch(fetchForecasts());
            return { data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al rechazar el forecast: ' + e.response.data);
        return e.message;
    }
})
export const aprobarSolicitudForecast = createAsyncThunk("forecast/aprobarSolicitudForecast", async (data, {dispatch})=> {
    console.log('llega', data);
    try {
        const response = await axiosInstance.put(APROBAR_FORECAST_URL+data.id);
        
        if (response.status === 200) {
            toast.success('Forecast aprobado correctamente.');
            dispatch(fetchForecasts());
            return { data: response.data, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al aprobar el forecast: ' + e.response.data);
        return e.message;
    }
})

export const addForecast = createAsyncThunk("forecast/addForecast", async (forecast, { dispatch }) => {
    console.log('lo que llega en addForecast:', forecast);
    const mapped = forecast.tramos.map(item => ({
        ...item,
        trabajadoresDia: item.trabajadoresDia ?? 0,
        trabajadoresNoche: item.trabajadoresNoche ?? 0,
        idTurno: item.turno.idTurno ?? 0,
    }))
    
    try {
        const response = await axiosInstance.post(FORECAST_URL, {
            empresaId:forecast.empresa.idEmpresa,
            tramos:mapped,
            // correo: forecast.correo,
        });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Forecast creado correctamente.');
            dispatch(fetchForecasts());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al crear el forecast: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})
export const updateForecast = createAsyncThunk("forecast/updateForecast", async (forecast, { dispatch }) => {
    console.log('llega', forecast);
    // return;
    const mapped = forecast.tramos.map(item => ({
        ...item,
        trabajadoresDia: item.trabajadoresDia ?? 0,
        trabajadoresNoche: item.trabajadoresNoche ?? 0,
        idTurno: item.turno.idTurno ?? 0,
    }))
    try {
        const response = await axiosInstance.put(UPDATE_FORECAST_S_URL+forecast.id, {
            empresaId:forecast.empresa.idEmpresa,
            tramos:mapped,
            notas: forecast.notas,
            // correo: forecast.correo,
        });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Forecast actualizado correctamente.');
            dispatch(fetchForecasts());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al actualizar el forecast: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})

export const updateForecastNotas = createAsyncThunk("forecast/updateForecast", async (forecast, { dispatch }) => {
    console.log('llega', forecast);
    try {
        const response = await axiosInstance.put(UPDATE_FORECAST_S_URL+forecast.id, {
            empresaId:forecast.empresa.idEmpresa,
            tramos:forecast.tramos,
            notas:forecast.notas,
        });
        if (response.status >= 200 && response.status < 300) {
            toast.success('Forecast actualizado correctamente.');
            dispatch(fetchForecasts());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al actualizar el forecast: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})
export const rechazar = createAsyncThunk("forecast/rechazar", async (forecast, { dispatch }) => {
    console.log('llegaRechazo', forecast);
    try {
        const response = await axiosInstance.put(RECHAZAR_FORECAST_URL+forecast.id, forecast.observaciones);
        if (response.status >= 200 && response.status < 300) {
            toast.success('Forecast actualizado correctamente.');
            dispatch(fetchForecasts());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error' };
      } catch (e) {
        toast.error('Error al actualizar el forecast: ' + e.response.data);
        return {message: e.message, status: 'error'};
    }
})



// idle : no hay operaciones en curso
const initialState = {
    forecast: [],
    gerencias: [],
    areas: [],
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const forecastSlice = createSlice({
    name:"forecast",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchForecasts.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchForecasts.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.forecast) !== JSON.stringify(action.payload)) { //fetch
                state.forecast = action.payload;
            }
        })
        .addCase(fetchForecasts.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchForecastGerencias.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchForecastGerencias.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.gerencias) !== JSON.stringify(action.payload)) { //fetch
                state.gerencias = action.payload;
            }
        })
        .addCase(fetchForecastGerencias.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchForecastAreas.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchForecastAreas.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.areas) !== JSON.stringify(action.payload)) { //fetch
                state.areas = action.payload;
            }
        })
        .addCase(fetchForecastAreas.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addForecast.fulfilled, (state, action) => { //ADD
            state.status = 'succeeded'
            state.forecast.push(action.payload);
        })
        .addCase(rechazar.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(rechazar.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.gerencias = state.gerencias.filter(sector => sector.id !== action.payload.id);
                state.areas = state.areas.filter(sector => sector.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(rechazar.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(aprobar.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(aprobar.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.gerencias = state.gerencias.map(sector => {
                    if (sector.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return sector;
                });
            }else{
                state.status = 'failed';    
            }
        })
        .addCase(aprobar.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(rechazarSolicitudForecast.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(rechazarSolicitudForecast.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.forecast = state.forecast.map(forecast => {
                    if (forecast.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return forecast;
                });
            }else{
                state.status = 'failed';    
            }
        })
        .addCase(rechazarSolicitudForecast.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(aprobarSolicitudForecast.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(aprobarSolicitudForecast.fulfilled, (state, action) => {
            if(action.payload.status === 'success'){
                state.status = 'succeeded';
                state.forecast = state.forecast.map(forecast => {
                    if (forecast.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return forecast;
                });
            }else{
                state.status = 'failed';    
            }
        })
        .addCase(aprobarSolicitudForecast.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    }
})

export const getAllForecast = (state) => state.forecast.forecast;
export const getAllGerencia = (state) => state.forecast.gerencias;
export const getAllAreas = (state) => state.forecast.areas;
export const getforecastStatus = (state) => state.forecast.status;
export const getforecastError = (state) => state.forecast.error;

export const {} = forecastSlice.actions;
export default forecastSlice.reducer;