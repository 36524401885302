import React, { useCallback, useEffect, useState } from "react";
import styles from "./components.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { reservasTrabajadadoresRecepcionistaColumns } from "../../../../../lib/columns";
import Edit from "../Edit";
import Edit2 from "../Edit2";
import { createAxiosInstance } from "../../../../../api/axios";
import SkeletonTable from "../../../../../components/Skeleton";
import { rechazarSolicitud } from "../../../../../redux/features/reservas/reservaSlice";
import { useDispatch } from "react-redux";
import CustomToolbar from "../../../../../components/SlotsToolBar";
import { fetchHabitacionesDisponibles } from "../../../../../redux/features/habitaciones/habitacionSlice";
import AsignarHabitacionesEsed from "./AsignarHabitacionesEsed";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import TextRotationNoneIcon from "@mui/icons-material/TextRotationNone";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import { format } from "date-fns";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TextFormatIcon from '@mui/icons-material/TextFormat';
import AdjustIcon from '@mui/icons-material/Adjust';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VerDetallesSolicitud = ({
  open,
  open2,
  setOpen,
  setOpen2,
  seleItem,
  campamentos,
  wings,
  habitaciones,
  setSeleItem,
  pabellones,
  setLoaderList,
  loaderList,
}) => {
  const [trabajadores, setTrabajadores] = useState([]);
  const [check, setCheck] = useState(false);
  const [traStatus, setTraStatus] = useState(false);
  const [formData, setFormData] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = reservasTrabajadadoresRecepcionistaColumns();
  const [openEdit, setOpenEdit] = useState(false);
  const [openModalRechazar, setOpenModalRechazar] = useState(false);
  const [open3, setOpen3] = useState(false);
  const axiosInstance = createAxiosInstance();
  const [expanded, setExpanded] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const dispatch = useDispatch();
  const [filterHabitaciones, setFilterHabitaciones] = useState([]);
  const [trabajadoresLoaded, setTrabajadoresLoaded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const canSave = formData.razonRechazo && formData.id && addRequestStatus === "idle";

  const getTrabajadores = async () => {
    try {
      setLoaderList(true);
      if (!seleItem || !seleItem.id) return;

      const resp = await axiosInstance.get(
        `/api/v1/Solicitud/TrabajadoresSolicitud/${seleItem.id}`
      );
      console.log("detalles solicitud: ", resp.data);
      if (resp.data.length > 0) {
        // setSeleItem(prev => ({ ...prev, trabajadores: resp.data }));
        setTrabajadores(resp.data);
        // setCheck(true);
      }
      // setTrabajadoresLoaded(true); // Marca que los trabajadores ya se han cargado
    } catch (error) {
      console.error("Error al obtener trabajadores de la solicitud:", error);
    } finally {
      setExpanded("panel1");
      setLoaderList(false);
    }
  };

  const habitacionesDisponibles = async (fechaIngreso, fechaSalida) => {
    try {
      const resp = await dispatch(
        fetchHabitacionesDisponibles({
          FechaIngreso: fechaIngreso,
          FechaSalida: fechaSalida,
        })
      );
      console.log("habitacionesDisponibles", resp.payload);
      setFilterHabitaciones(resp.payload);
    } catch (error) {
      console.log("Errores al traer las habitaciones.");
    }
  };

  useEffect(() => {
    console.log("useEffect ejecutado: seleItem cambiado", seleItem);
    if (seleItem && seleItem.id) {
      console.log("seleItem solicitud: ", seleItem);
      setFormData((prev) => ({ ...prev, id: seleItem?.id })); // Usar prev para mantener el estado actual del formData
      getTrabajadores();
      habitacionesDisponibles(seleItem.fechaIngreso, seleItem.fechaSalida);
    }
  }, [seleItem]);

  const generarPropuesta = () => {
    setCheck(true);
  };

  const rechazar = async () => {
    setAddRequestStatus("loading");
    setLoaderList(true);
    try {
      const resp = await dispatch(rechazarSolicitud(formData));
      if (resp.payload.status === "success") {
        setFormData({});
        return true;
      }
    } catch (error) {
    } finally {
      setOpen(false);
      setOpenModalRechazar(false);
      setAddRequestStatus("idle");
      setLoaderList(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "#2d3037",
            color: "#FFF",
            borderRadius: "0",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar style={{ backgroundColor: "var(--border-blue)" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              RESERVA
            </Typography>
            <Button autoFocus color="inherit" onClick={() => setOpen(false)}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderList}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogContent>
        
        <Grid container={true} spacing={1}>
          <Grid item xs={12} md={4} style={{ textAlign: "start" }}>
            <Card style={{ padding: "16px", backgroundColor: "var(--inst-card-bg)", color: "#FFF", border: "1px solid var(--border-orange)" }}>
              
              <Grid item xs={12} md={12} style={{ marginBottom:'10px', backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column" }} >
                <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }} ><ApartmentIcon/> Empresa</label>
                <label className={styles.label} style={{ fontSize: "16px" }}><strong>{seleItem?.empresa?.razonSocial}</strong></label>
                <label className={styles.label}> Contrato: {seleItem?.contrato}</label>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginBottom:'5px', backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column" }} >
                <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)', display:'flex', alignItems:'center' }}><PersonIcon/> Solicitante</label>
                <label className={styles.label} style={{ fontSize: "16px" }}>{seleItem?.nombreSolicitante} {seleItem?.apellidoSolicitante}</label>
                <label className={styles.label} style={{ fontSize: "16px" }}>{seleItem?.emailSolicitante}</label>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column", marginBottom:'5px' }}
              >
                <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><AdjustIcon/> VP</label>
                <label className={styles.label}>{seleItem?.vicepresidencia}</label>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column", marginBottom:'5px' }}
              >
                <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><AdjustIcon/> Gerencia</label>
                <label className={styles.label}>{seleItem?.gerencia}</label>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column", marginBottom:'10px' }}
              >
                <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><AdjustIcon/> Si</label>
                <label className={styles.label}>{seleItem?.sI_Area}</label>
              </Grid>
              <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column" }}
                >
                  <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><GroupsIcon/>Trabajadores totales</label>
                  <label className={styles.label} style={{ display: "flex", alignItems: "center", gap: "5px" }}>{seleItem?.trabajadoresTotales}</label>
              </Grid>
              <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column" }}
                >
                  <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)', display:'flex', alignItems:'center', gap:'5px' }}><TextFormatIcon/> Observaciones</label>
                  <label className={styles.label}>{seleItem?.observaciones}</label>
              </Grid>
              <Grid container={true}>
                <Grid item xs={12} md={5} style={{ backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column", marginBottom:'5px', textAlign:'center' }}>
                  <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)' }}>Desde</label>
                  <label style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "5px", }}>
                    <CalendarMonthIcon /> {seleItem?.fechaIngreso && format(seleItem?.fechaIngreso, "dd-MM-yyyy")}
                  </label>
                </Grid>
                
                <Grid item xs={12} md={2} style={{ textAlign: "center", display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start', position:'relative', top:'6px', right:'-8px' }}>
                  <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)' }}></label>
                  <KeyboardDoubleArrowRightIcon />
                </Grid>

                <Grid item xs={12} md={5} style={{ backgroundColor: "var(--inst-card-bg)", display: "flex", flexDirection: "column", marginBottom:'5px', textAlign:'center' }}>
                  <label className={styles.label} style={{ fontSize: "12px", color:'var(--inst-orange)' }}>Hasta</label>
                  <label style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "5px" }}><CalendarMonthIcon />{seleItem?.fechaSalida && format(seleItem?.fechaSalida, "dd-MM-yyyy")}</label>
                </Grid>
              </Grid>
                
              <Button
                onClick={generarPropuesta}
                className={styles.bgColor}
                variant="contained"
                style={{ width: "100%", marginTop: "10px" }}
              >
                Generar Propuesta
              </Button>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <DataGrid
                components={{
                  Toolbar: CustomToolbar,
                }}
                getRowId={(row) => (row.id ? row.id : Math.random())}
                lots={{ toolbar: GridToolbar }}
                rows={trabajadores || []}
                columns={finalColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                localeText={{
                  ...esES.components.MuiDataGrid.defaultProps.localeText,
                  noRowsLabel: "No hay datos disponibles",
                  pagination: {
                    labelRowsPerPage: "Filas por página:",
                  },
                }}
                classes={{ menuIcon: styles.menuIcon }}
                style={{ maxHeight: "500px", color: "#FFF", border: "none" }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    color: "#FFF",
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    color: "#FFF",
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    color: "#FFF",
                    borderTop: "1px solid #c6c6c6",
                  },
                  "& .MuiTablePagination-root": {
                    color: "#FFF",
                    borderTop: "1px solid #c6c6c6",
                  },
                  "& .MuiDataGrid-toolbarContainer": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-menu": {
                    color: "#FFF",
                  },
                  "& .MuiTablePagination-actions": {
                    color: "#FFF", // Color for pagination actions
                  },
                  "& .MuiTablePagination-select": {
                    color: "#FFF", // Color for pagination select
                  },
                  "& .MuiTablePagination-selectLabel": {
                    color: "#FFF", // Color for pagination select label
                  },
                  "& .MuiTablePagination-displayedRows": {
                    color: "#FFF", // Color for displayed rows text
                  },
                }}
                slotProps={{
                  toolbar: {
                    sx: {
                      color: "#FFF",
                    },
                  },
                  pagination: {
                    sx: {
                      color: "#FFF",
                    },
                  },
                  menu: {
                    sx: {
                      color: "#FFF",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <AsignarHabitacionesEsed
            open={check}
            setOpen={setCheck}
            openDetalle={open}
            setOpenDetalle={setOpen}
            status={traStatus}
            trabajadores={trabajadores}
            pabellones={pabellones}
            wings={wings}
            listaHabitaciones={filterHabitaciones}
            seleItem={seleItem}
          />

          {check && (
            <Grid container={true} spacing={1}>
              <Grid item xs={12}>
                {/*<Edit openEdit={check} setOpenEdit={setCheck} seleItem={seleItem} setOpen3={setOpen3} habitaciones={habitaciones} campamentos={campamentos} wings={wings} setOpenModal={setOpen}/> */}

                {/*<Edit2 openEdit={check} setOpenEdit={setCheck} seleItem={seleItem} setOpen3={setOpen3} habitaciones={filterHabitaciones} campamentos={campamentos} wings={wings} setOpenModal={setOpen} pabellones={pabellones} trabajadores={trabajadores}/> */}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenModalRechazar(true)}
            className={styles.bgColorDelete}
            variant="contained"
          >
            Rechazar solicitud
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalRechazar}
        onClose={() => setOpenModalRechazar(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#2d3037",
            color: "#FFF",
            borderRadius: "0",
          },
        }}
      >
        <DialogTitle>
          Estás a punto de rechazar el estado de la solicitud {seleItem?.id}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escriba el motivo por el cual rechaza la solicitud.
          </DialogContentText>
          <Grid container={true} spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                className={styles.textField}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#FFF",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#FFF",
                  },
                  padding: "10px",
                }}
                margin="dense"
                id="razon"
                name="razonRechazo"
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                label="Razón"
                value={formData.razonRechazo}
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={rechazar}
            className={styles.bgColorDelete}
            variant="contained"
            disabled={!canSave}
          >
            {addRequestStatus == "loading" ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Rechazar"
            )}
          </Button>
          <Button
            onClick={() => setOpenModalRechazar(false)}
            className={styles.bgColorOutlined}
            variant="outlined"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default VerDetallesSolicitud;
