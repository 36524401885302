import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from 'react-toastify';
import { createAxiosFormDataInstance, createAxiosInstance } from "../../../api/axios";

const CAMPAMENTOS_URL = '/api/v1/Campamento';
const ADD_CAMPAMENTO_URL = '/api/v1/Campamento';
const UPDATE_CAMPAMENTO_URL = '/api/v1/Campamento/';
const GET_CAMPAMENTO_URL = '/api/v1/Campamento/';
const DELETE_CAMPAMENTO_URL = '/api/v1/Campamento/';
const axiosInstance = createAxiosInstance();
const axiosFormDataInstance = createAxiosFormDataInstance();

// ACTIONS
export const fetchCampamentos = createAsyncThunk("campamentos/fetchCampamentos", async ()=> {
    try {
        const response = await axiosInstance.get(CAMPAMENTOS_URL);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const fetchCampamento = createAsyncThunk("campamentos/fetchCampamento", async (id)=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.get(GET_CAMPAMENTO_URL+id);
        return response.data;
      } catch (e) {
        return e.message;
    }
})
export const deleteCampamento = createAsyncThunk("campamentos/deleteCampamento", async (id, {dispatch})=> {
    console.log('id', id);
    try {
        const response = await axiosInstance.delete(DELETE_CAMPAMENTO_URL+id);
        if (response.status === 200) {
            toast.success('Campamento eliminado correctamente.');
            dispatch(fetchCampamentos());
            return { id, status: 'success'};
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al eliminar el campamento: ' + e.response.data);
        return e.message;
    }
})
export const updateCampamento = createAsyncThunk("campamentos/updateCampamento", async (upCampamento, {dispatch})=> {
    console.log('id', upCampamento.id);
    try {
        const response = await axiosFormDataInstance.put(UPDATE_CAMPAMENTO_URL+upCampamento.id, {
            Nombre: upCampamento.nombre,
            Direccion: upCampamento.direccion,
            Descripcion: upCampamento.descripcion,
            latitud: upCampamento.latitud,
            longitud: upCampamento.longitud,
            FilePlano: upCampamento.FilePlano,
        });
        
        if (response.status === 200 ) {
            console.log('suceesssss')
            toast.success('Campamento actualizado correctamente.');
            dispatch(fetchCampamentos());
            return { data: response.data, status: 'success' };
        } 
        return { status: 'error'};
      } catch (e) {
        toast.error('Error al actualizar el campamento: ' + e.response.data);
        return e.message;
    }
})

export const addNewCampamento = createAsyncThunk("campamentos/createCampamentos", async (initialCampamento, { dispatch }) => {
    console.log(initialCampamento)
    try {
        const response = await axiosFormDataInstance.post(ADD_CAMPAMENTO_URL, {
            Nombre: initialCampamento.nombre,
            Direccion: initialCampamento.direccion,
            Descripcion: initialCampamento.descripcion,
            latitud: initialCampamento.latitud,
            longitud: initialCampamento.longitud,
            FilePlano: initialCampamento.FilePlano,
        });
        if (response.status == 200) {
            toast.success('Campamento creado correctamente.');
            dispatch(fetchCampamentos());
            return {data: response.data, status: 'success'};
        }
        return { status: 'error'} ;
    } catch (e) {
        toast.error('Error al crear el campamento: ' + e.response.data);
        console.log(e.message)
        return {message: e.message, status: 'error'};
    }
})


// idle : no hay operaciones en curso
const initialState = {
    campamentos: [],
    campamento: {},
    status: 'idle', //'idle'|'loading'|'succeeded'|'failed'
    error: null,
}

export const campamentoSlice = createSlice({
    name:"campamento",
    initialState,
    reducers: {},
    extraReducers(builder) { 
        builder
        .addCase(fetchCampamentos.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchCampamentos.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.campamentos) !== JSON.stringify(action.payload)) { //fetch
                state.campamentos = action.payload;
            }
        })
        .addCase(fetchCampamentos.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchCampamento.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(fetchCampamento.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (JSON.stringify(state.campamento) !== JSON.stringify(action.payload)) { //fetch
                state.campamento = action.payload;
            }
        })
        .addCase(fetchCampamento.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(addNewCampamento.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.campamentos.push(action.payload.data);
            } else {
                state.status = 'failed'
            }
        })
        
        .addCase(deleteCampamento.pending, (state, action) => {state.status = 'loading'} ) //fetch
        .addCase(deleteCampamento.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.campamentos = state.campamentos.filter(campamento => campamento.id !== action.payload.id);
            }else{
                state.status = 'failed';
            }
        })
        .addCase(deleteCampamento.rejected, (state, action) => { //fetch
            state.status = 'failed'
            state.error = action.error.message;
        })
        .addCase(updateCampamento.pending, (state, action) => {
            state.status = 'loading';
        })
        .addCase(updateCampamento.fulfilled, (state, action) => {
            if (action.payload.status === 'success') {
                state.status = 'succeeded';
                state.campamentos = state.campamentos.map(campamento => {
                    if (campamento.id === action.payload.id) {
                        return action.payload.data;
                    }
                    return campamento;
                });
            }else{
                state.status = 'failed';
            }
        })
        .addCase(updateCampamento.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
        
    }
})

export const getAllCampamentos = (state) => state.campamentos.campamentos;
export const fetchCampamentoData = (state) => state.campamentos.campamento;
export const getCampamentosStatus = (state) => state.campamentos.status;
export const getCampamentosError = (state) => state.campamentos.error;

export const {} = campamentoSlice.actions;
export default campamentoSlice.reducer;