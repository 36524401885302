import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from '../../../../../redux/features/campamentos/campamentoSlice'
import { useEffect } from 'react'
import SkeletonTable from '../../../../../components/Skeleton'
import Imagenes from './Imagenes'
import { fetchWings, getAllWings, getWingsStatus } from '../../../../../redux/features/wings/wingSlice'
import { fetchOtros, getAllOtros, getOtrosStatus } from '../../../../../redux/features/otros/otroSlice'
import { fetchEdificios, getAllEdificios, getEdificiosStatus } from '../../../../../redux/features/edificios/edificioSlice'
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../../../../redux/features/habitaciones/habitacionSlice'
import { getAllEspacios, getEspaciosStatus } from '../../../../../redux/features/bodegas/bodegaSlice'
import { fetchMotivos, getAllMotivos, getMotivosStatus } from '../../../../../redux/features/motivos/motivoSlice'
import { fetchEspacios } from '../../../../../redux/features/espacios/espacioSlice'
import Create from './Create'

const DashboardPersonalAseo = () => {
    const campamentos = useSelector(getAllCampamentos);
    const camStatus = useSelector(getCampamentosStatus)
    const dispatch = useDispatch()
    const [open2, setOpen2] = useState(false)

    const wings = useSelector(getAllWings);
    const winStatus = useSelector(getWingsStatus);
    const otros = useSelector(getAllOtros);
    const otStatus = useSelector(getOtrosStatus);
    const pabellones = useSelector(getAllEdificios);
    const pabStatus = useSelector(getEdificiosStatus);
    const habitaciones = useSelector(getAllHabitaciones);
    const habStatus = useSelector(getHabitacionesStatus);
    const espacios = useSelector(getAllEspacios);
    const espStatus = useSelector(getEspaciosStatus);
    const motivos = useSelector(getAllMotivos);
    const motStatus = useSelector(getMotivosStatus);
    
    // const wings = []
    // const otros = []
    // const pabellones = []
    // const habitaciones = []
    // const espacios = []
    // const motivos = []


    useEffect(()=>{
        if(motStatus === 'idle'){
          dispatch(fetchMotivos());
        }
        if(camStatus === 'idle'){
            dispatch(fetchCampamentos());
        }
        if(winStatus === 'idle'){
          dispatch(fetchWings());
        }
        if(pabStatus === 'idle'){
          dispatch(fetchEdificios());
        }
        if(habStatus === 'idle'){
          dispatch(fetchHabitaciones());
        }
        if(otStatus === 'idle'){
          dispatch(fetchOtros());
        }
        if(espStatus === 'idle'){
          dispatch(fetchEspacios());
        }
        
      },[]);


  return (
    <>
        <Create setOpen2={setOpen2} open2={open2} motivos={motivos} campamentos={campamentos} wings={wings} pabellones={pabellones} habitaciones={habitaciones} otros={otros} espacios={espacios}/>
        { camStatus === 'loading' ? <SkeletonTable/> : <Imagenes registros={campamentos}/> }

    </>
    //  <Map/>
  )
}

export default DashboardPersonalAseo