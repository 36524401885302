import { Box, Container } from "@mui/material"
import Header from "../../components/header/Header"
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import Create from "./componentsTraslado/Create";
import Table from "./componentsTraslado/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { fetchBodegas, fetchTraspasosBodegas, getAllBodegas, getAllTraspasos, getBodegasStatus, getTraspasosStatus } from "../../redux/features/bodegas/bodegaSlice";

const Traslado = () => {

  const dispatch = useDispatch();
  const traspasos = useSelector(getAllTraspasos);
  const bodegas = useSelector(getAllBodegas);
  const status = useSelector(getTraspasosStatus);
  const bodStatus = useSelector(getBodegasStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(()=>{
    if(status === 'idle'){
        dispatch(fetchTraspasosBodegas());
    }
    if(bodStatus === 'idle'){
    }
    dispatch(fetchBodegas());
  },[]);


  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de traspaso de bodegas"
          subtitle="En este módulo, podrás traspasar el inventario de una bodega a otra.">
          {<ExitToAppIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} bodegas={bodegas}/>
          { status == 'loading' ? <SkeletonTable/> : <Table registros={traspasos ? traspasos : []} setOpen3={setOpen3}/> }
        </TableCard>
      </Container>
    </Box>
  )
}
export default Traslado