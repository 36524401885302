import styles from './components/components.module.css';
import { Backdrop, Box, Button, Card, CircularProgress, Container, Grid, MenuItem, Select, TextField } from "@mui/material"
import Header from "../../../components/header/Header"
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { useEffect, useState } from "react";
import TableCard from "../../../components/tableCard/TableCard";
import SkeletonTable from "../../../components/Skeleton";
import Table from "./componentsReservas/Table";
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservas, getAllreservas, getReservasStatus } from '../../../redux/features/reservas/reservaSlice';
import { fetchHabitaciones, getAllHabitaciones, getHabitacionesStatus } from '../../../redux/features/habitaciones/habitacionSlice';
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from '../../../redux/features/campamentos/campamentoSlice';
import { fetchWings, getAllWings, getWingsStatus } from '../../../redux/features/wings/wingSlice';

const ReservasRecepcionista = () => {


  const [open3, setOpen3] = useState(false)
  const reservas = useSelector(getAllreservas)
  const status = useSelector(getReservasStatus)
  const habitaciones = useSelector(getAllHabitaciones)
  const habStatus = useSelector(getHabitacionesStatus)
  const campamentos = useSelector(getAllCampamentos)
  const camStatus = useSelector(getCampamentosStatus)
  const wings = useSelector(getAllWings)
  const winStatus = useSelector(getWingsStatus)
    const dispatch = useDispatch();

  useEffect(()=>{
    if(status == 'idle'){
      dispatch(fetchReservas());
    }
    if(habStatus == 'idle'){
      dispatch(fetchHabitaciones());
    }
    if(camStatus == 'idle'){
      dispatch(fetchCampamentos());
    }
    if(winStatus == 'idle'){
      dispatch(fetchWings());
    }
  },[]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Reservas Generadas por el Sistema"
          subtitle="Este módulo ofrece la capacidad de revisar las asignaciones hechas de forma automática por el sistema.">
          {<HolidayVillageIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>
        <TableCard>
        <Grid container={true} style={{ alignItems:'end' }} spacing={1}>
            <Grid item sx={12} sm={2}>
                <label className={styles.label}>Fecha de inicio</label>
                <TextField
                    variant="outlined"
                    fullWidth
                    name='inicio'
                    type="date"
                    // value={formData?.trabajador?.rut}
                    // onChange={(e) => onChange(e)}
                />            
            </Grid>
            <Grid item sx={12} sm={2}>
                <label className={styles.label}>Fecha de término</label>
                <TextField
                    variant="outlined"
                    fullWidth
                    name='termino'
                    type="date"
                    // value={formData?.trabajador?.rut}
                    // onChange={(e) => onChange(e)}
                />            
            </Grid>
            <Grid item sx={12} sm={4}>
                <label className={styles.label}>Rut</label>
                <TextField
                    variant="outlined"
                    fullWidth
                    name='termino'
                    type="text"
                    // value={formData?.trabajador?.rut}
                    // onChange={(e) => onChange(e)}
                />
            </Grid>
            <Grid item sx={12} sm={4}>
                <label className={styles.label}>Nombre</label>
                <TextField
                    variant="outlined"
                    fullWidth
                    name='termino'
                    type="text"
                    // value={formData?.trabajador?.rut}
                    // onChange={(e) => onChange(e)}
                />
            </Grid>
            <Grid item sx={12} sm={3}>
                <label className={styles.label}>Wing</label>
                <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                    //   value={item.jornada}
                      name='jornada'
                      style={{ width:'100%' }}
                    //   onChange={(e)=>onChange2(e,index, item)}
                      >
                        <MenuItem value='Diurna'>Wing1</MenuItem>
                        <MenuItem value='Nocturna'>Wing2</MenuItem>
                    </Select>
            </Grid>
            <Grid item sx={12} sm={3}>
                <label className={styles.label}>Cargo</label>
                <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                    //   value={item.jornada}
                      name='jornada'
                      style={{ width:'100%' }}
                    //   onChange={(e)=>onChange2(e,index, item)}
                      >
                        <MenuItem value='Diurna'>Electricista</MenuItem>
                        <MenuItem value='Diurna'>Soldador al arco</MenuItem>
                    </Select>
            </Grid>
            <Grid item sx={12} sm={3}>
                <label className={styles.label}>Jornada</label>
                <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                    //   value={item.jornada}
                      name='jornada'
                      style={{ width:'100%' }}
                    //   onChange={(e)=>onChange2(e,index, item)}
                      >
                        <MenuItem value='Diurna'>Diurna</MenuItem>
                        <MenuItem value='Diurna'>Nocturna</MenuItem>
                    </Select>
            </Grid>
            <Grid item sx={12} sm={3}>
                <label className={styles.label}>Turno</label>
                <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=""
                    //   value={item.jornada}
                      name='jornada'
                      style={{ width:'100%' }}
                    //   onChange={(e)=>onChange2(e,index, item)}
                      >
                        <MenuItem value='Diurna'>7x7</MenuItem>
                        <MenuItem value='Diurna'>8x6</MenuItem>
                    </Select>
            </Grid>
            <Grid item sx={12} sm={2}>
                <Button
                    // onClick={handleEdit}
                    className={styles.bgColor}
                    variant="contained" 
                    // disabled={!canSave}
                    >
                    Buscar
                </Button>
            </Grid>
        </Grid>        
            { status == 'loading' ? <SkeletonTable/> : <Table reservas={reservas ? reservas : []} setOpen3={setOpen3} 
            habitaciones={habitaciones} campamentos={campamentos} wings={wings} /> }
        </TableCard>

      </Container>
    </Box>
  )
}

export default ReservasRecepcionista