import { Backdrop, Box, Container } from "@mui/material"
import Header from "../../../components/header/Header"
import TableCard from "../../../components/tableCard/TableCard";
import Table from './components/Table'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from '@mui/material';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { fetchForecastAreas, fetchForecastGerencias, fetchForecasts, getAllAreas, getAllForecast, getAllGerencia, getforecastStatus } from "../../../redux/features/forecast/forecastSlice";
import { fetchEmpresas, getAllEmpresas } from "../../../redux/features/empresas/empresaSlice";
import { fetchTurnos, getAllTurnos, getTurnosStatus } from "../../../redux/features/users/userSlice";

const FeedBack = () => {

  const dispatch = useDispatch();
  const status = useSelector(getforecastStatus);
  const forecast = useSelector(getAllForecast);
  const empresas = useSelector(getAllEmpresas);
  const turnos = useSelector(getAllTurnos);
  const turnosStatus = useSelector(getTurnosStatus);
  const gerencia = useSelector(getAllGerencia);
  const siArea = useSelector(getAllAreas);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const contratos = [];
  const [tramos, setTramos] = useState([]);

  
  useEffect(() => {
    if(status == 'idle'){
      dispatch(fetchForecasts());
      dispatch(fetchForecastGerencias());
      dispatch(fetchForecastAreas());
      dispatch(fetchEmpresas());
    }
    if(turnosStatus === 'idle'){
      dispatch(fetchTurnos());
    }
  }, []);

  const data = {
    empresa: '',
    tramos: []
  }
  

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open3}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Correcciones y retroalimentación"
          subtitle="En este módulo, podrás ingresar una opinión de los registros del forecast.">
          {<BrokenImageIcon style={{ fontSize:'1.5rem' }}/>}
        </Header>

        <TableCard>
          {/* <Create setOpen2={setOpen2} open2={open2}/> */}
          { status == 'loading' ? <SkeletonTable/> : <Table solicitudes={forecast} setOpen3={setOpen3} siArea={siArea} empresas={empresas} ger={gerencia.length > 0 ? gerencia : []} data={data} tramos={tramos} setTramos={setTramos} contratos={contratos} open2={open2} setOpen2={setOpen2} turnos={turnos}/> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default FeedBack