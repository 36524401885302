import styles from './components.module.css'
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Container, CircularProgress, Backdrop } from "@mui/material"
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { useEffect, useState } from "react";
import Edit2 from './Edit2'
import TableReserva from './TableReserva'
import { fetchCampamentos, getAllCampamentos, getCampamentosStatus } from "../../redux/features/campamentos/campamentoSlice";
import { fetchReservas, getAllreservas, getReservasStatus } from "../../redux/features/reservas/reservaSlice";
import { fetchWings, getAllWings, getWingsStatus } from "../../redux/features/wings/wingSlice";
import SkeletonTable from "../../components/Skeleton";
import TableCard from "../../components/tableCard/TableCard";
import Header from "../../components/header/Header";

const ReservaCmdic = () => {
    const [open, setOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [seleItem, setSeleItem] = useState();
    const [check, setCheck] = useState(false);

    const campamentos = useSelector(getAllCampamentos);
    const camStatus = useSelector(getCampamentosStatus);
    // const reservas = useSelector(getAllreservas);
    const resStatus = useSelector(getReservasStatus);
    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    
    // const wings = useSelector(getAllWings);
    const winStatus = useSelector(getWingsStatus);

    const dispatch = useDispatch();

    const habitaciones = [
      {
        id: 23066,
        nroCamarotes: 0,
        nroCamas: 2,
        numero: 26,
        numeroTotalCamas: 2,
        wing: {
          id: 14,
          nombre: "A",
          descripcion: "",
          numeroPisos: 3,
          numeroHabitaciones: 0,
          generoHuesped: "Unisex",
          campamento: {
            id: 26,
            nombre: "Pioneros",
            direccion: "santiago",
            descripcion: null,
            plano: null,
            latitud: "-33.3660954",
            longitud: "-70.7514249"
          },
          campamentoId: 26,
          standard: [
            {
              id: 57,
              nombre: "INDIVIDUAL",
              descripcion: "",
              campamento: null,
              campamentoId: 26
            }
          ]
        },
        campamento: {
          id: 26,
          nombre: "Pioneros",
          direccion: "santiago"
        },
        campamentoId: 26,
        generoHuesped: "Solo hombres",
        standard: {
          id: 56,
          nombre: "COMPARTIDO",
          campamentoId: 26,
          descripcion: ""
        },
        tieneOxigeno: false,
        tipoCama: "Una plaza",
        tipoHuesped: "Solo hombres",
        trabajadores: [],
        pabellon: null
      },
      {
        id: 23065,
        nroCamarotes: 0,
        nroCamas: 2,
        numero: 25,
        numeroTotalCamas: 2,
        wing: {
          id: 14,
          nombre: "A",
          descripcion: "",
          numeroPisos: 3,
          numeroHabitaciones: 0,
          generoHuesped: "Unisex",
          campamento: {
            id: 26,
            nombre: "Pioneros",
            direccion: "santiago",
            descripcion: null,
            plano: null,
            latitud: "-33.3660954",
            longitud: "-70.7514249"
          },
          campamentoId: 26,
          standard: [
            {
              id: 57,
              nombre: "INDIVIDUAL",
              descripcion: "",
              campamento: null,
              campamentoId: 26
            }
          ]
        },
        campamento: {
          id: 26,
          nombre: "Pioneros",
          direccion: "santiago"
        },
        campamentoId: 26,
        generoHuesped: "Solo hombres",
        standard: {
          id: 56,
          nombre: "COMPARTIDO",
          campamentoId: 26,
          descripcion: ""
        },
        tieneOxigeno: false,
        tipoCama: "Una plaza",
        tipoHuesped: "Solo hombres",
        trabajadores: [],
        pabellon: null
      },
      
    ];
    const trabajadores = [
      {
        apellido: "ANTONIO SANCHEZ",
        cargo: "OPERADOR SULFUROS D",
        cargoId: "10001204",
        email: "sadas932@efkins.com",
        id: "gr0dse0-7ce1-48f0-b01b-538ec81615cc",
        nombre: "CARLOS",
        phoneNumber: "3124565",
        roles: ["Administrador de Contrato ESED (ACE)"],
        rut: "20061993-5",
        jornada: "Nocturno",
        turno: "4x3",
        suspendido: false,
        genero: "Hombre",
      },
      {
        apellido: "PEREZ FIGUEROA",
        cargo: "OPERADOR SULFUROS D",
        cargoId: "10001204",
        email: "gowoso3991@kinsef.com",
        id: "de095fe0-7ce1-48f0-b01b-538ec81615cb",
        nombre: "ESTEBAN ALEXANDER",
        phoneNumber: "34243242",
        roles: ["Administrador de Contrato ESED (ACE)"],
        rut: "87036199-2",
        jornada: "Diurna",
        turno: "4x3",
        suspendido: false,
        genero: "Hombre",
      }
    ];
    const wings = [
      {
        id: 14,
        nombre: "A",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 26,
          nombre: "Pioneros",
          direccion: "santiago",
          descripcion: null,
          plano: null,
          latitud: "-33.3660954",
          longitud: "-70.7514249"
        },
        campamentoId: 26,
        standard: [
          {
            id: 57,
            nombre: "INDIVIDUAL",
            descripcion: "",
            campamento: null,
            campamentoId: 26
          }
        ]
      },
      {
        id: 15,
        nombre: "B",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978"
        },
        campamentoId: 25,
        standard: [
          {
            id: 60,
            nombre: "ROTATIVO",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          }
        ]
      },
      {
        id: 16,
        nombre: "C",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978"
        },
        campamentoId: 25,
        standard: [
          {
            id: 61,
            nombre: "7X7",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          }
        ]
      },
      {
        id: 17,
        nombre: "D",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978"
        },
        campamentoId: 25,
        standard: [
          {
            id: 61,
            nombre: "7X7",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 63,
            nombre: "GERENTE",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          }
        ]
      },
      {
        id: 18,
        nombre: "E",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978"
        },
        campamentoId: 25,
        standard: [
          {
            id: 61,
            nombre: "7X7",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 62,
            nombre: "4X3",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 63,
            nombre: "GERENTE",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 64,
            nombre: "VIP",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          }
        ]
      },
      {
        id: 19,
        nombre: "F",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978"
        },
        campamentoId: 25,
        standard: [
          {
            id: 61,
            nombre: "7X7",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 62,
            nombre: "4X3",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 63,
            nombre: "GERENTE",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          }
        ]
      },
      {
        id: 20,
        nombre: "G",
        descripcion: "",
        numeroPisos: 3,
        numeroHabitaciones: 0,
        generoHuesped: "Unisex",
        campamento: {
          id: 25,
          nombre: "Coposa",
          direccion: "dsaadsa",
          descripcion: "descripción",
          plano: "/files/45e84205-47b0-428c-ab77-0ea62131c08a.png",
          latitud: "-33.5281715",
          longitud: "-70.5807978"
        },
        campamentoId: 25,
        standard: [
          {
            id: 61,
            nombre: "7X7",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 62,
            nombre: "4X3",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          },
          {
            id: 63,
            nombre: "GERENTE",
            descripcion: "",
            campamento: null,
            campamentoId: 25
          }
        ]
      }
    ];
    
    const reservas = [
      {
        fechaSolicitud:"2024-07-18T18:37:48.8264102",
        fechaIngreso:"2024-07-01T00:00:00",
        fechaSalida:"2024-07-30T00:00:00",
        nombreSolicitante:"Marcelo Rodolfo",
        apellidoSolicitante:"Aravena King",
        estado:"En curso",
      }
    ]
    useEffect(()=>{
        if(camStatus === 'idle'){
            dispatch(fetchCampamentos());
        }
        if(winStatus === 'idle'){
          dispatch(fetchWings());
        }
        if(resStatus === 'idle'){
            dispatch(fetchReservas());
          }
      },[]);

  return (
    <>
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
          <Header
            title="Gestíon de Reservas CMDIC"
            subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados a las reservas CMDIC generadas por el sistema.">
            {/* {<HolidayVillageIcon style={{ fontSize:'1.5rem' }} className={styles.iconBg}/>} */}
            <img src={process.env.REACT_APP_LOGO } alt="loginImg" style={{ width:'100px', marginRight: '10px' }} />
          </Header>
        
          <Edit2 openEdit={check} setOpenEdit={setCheck} seleItem={seleItem} setOpen3={setOpen3} habitaciones={habitaciones} campamentos={campamentos} wings={wings} setOpenModal={setOpen} trabajadores={trabajadores}/>
          
          <TableCard>
          { resStatus == 'loading' ? <SkeletonTable/> : <TableReserva reservas={reservas} addRequestStatus={addRequestStatus} setOpen3={setOpen3} open={check} setOpen={setCheck} setSeleItem={setSeleItem}/> }
          </TableCard>
          <Card mt={2} style={{ width:'100%', padding:'16px', backgroundColor: '#1d1d1b', color: '#fff' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <span style={{ fontSize: '12px' }}>Powered by</span>
              <img src={process.env.REACT_APP_LOGO_POWERED} alt="loginImg" style={{ width:'45px', marginLeft: '4px', filter: 'grayscale(100%)' }} />
            </div>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default ReservaCmdic