import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, DialogContent, DialogTitle, Grid, Slide, TextField, Typography, Dialog, DialogActions, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, DialogContentText, Paper, Divider, List, ListItem, ListItemText } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import TableAsignaciones from './TableAsignaciones';
import { toast } from 'react-toastify';
import { useHistory, useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3, habitaciones, campamentos, wings, selectCheck }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const [asignaciones, setAsignaciones] = useState([])
    const [trabajadores, setTrabajadores] = useState([])
    const [open, setOpen] = useState(false)
    
    const [modal, setModal] = useState(false); //---------- variable que abre el modal ----------

    const [trabajadoresNoAsignados, setTrabajadoresNoAsignados] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const [todosCompletos, setTodosCompletos] = useState(true);
    const [camps, setCamps] = useState(campamentos);
    const [wingsList, setWingsList] = useState([]);
    const [checkAutomatico, setCheckAutomatico] = useState(false);
    const [habitacionesList, setHabitacionesList] = useState([]);
    const history = useNavigate();

    useEffect(() => {
        setFormData(seleItem)
        if(seleItem){
            setAsignaciones(seleItem.reservas);
            const trab = seleItem.trabajadores.map(item => item)
            setTrabajadores(trab);

            const trabajadoresNoAsignados = seleItem.trabajadores.filter(trabajador => {
                return !seleItem.reservas.some(reserva => reserva.trabajador.rut === trabajador.rut);
            });
            setTrabajadoresNoAsignados(trabajadoresNoAsignados);
        }
    }, [seleItem])

    useEffect(() => {
        const rutAsignados = asignaciones.map(asignacion => asignacion.trabajador.rut);
        const trabajadoresNoAsignados = trabajadores.filter(trabajador => !rutAsignados.includes(trabajador.rut));
        setTrabajadoresNoAsignados(trabajadoresNoAsignados);
    }, [asignaciones]);
    
    useEffect(() => {
        const todosCompletos = asignaciones.every(asignacion => asignacion.trabajador && asignacion.habitacion);
        setTodosCompletos(todosCompletos);
    }, [asignaciones]);

    const agregarFormulario = () => {
        setAsignaciones(prevAsignaciones => [
            ...prevAsignaciones,
            {
                trabajador: {
                    nombre: '',
                    rut: '' 
                },
                habitacion: {
                    id: '',
                    numero: ''
                },
            }
        ]);
        setSearchTerm("");
    };
    
    
    const handleChangeTrabajador = (event, rut) => {
        const { value } = event.target;
        const trabajadorNuevo = trabajadores.find(item => item.rut === parseInt(value)); 
        const trabajadorYaAsignado = asignaciones.some(asignacion => asignacion.trabajador.rut === trabajadorNuevo.rut);
    
        if (!trabajadorYaAsignado) {
            const updatedAsignaciones = asignaciones.map(asignacion => {
                if (asignacion.trabajador.rut === rut) {
                    asignacion.trabajador = trabajadorNuevo;
                }
                return asignacion;
            });
            setAsignaciones(updatedAsignaciones);
        } else {
            toast.error('El trabajador ya ha sido asignado a una habitación.')
        }
    };
    
    const handleChangeCampamento = (event, rut) => {
        const { value } = event.target;
        const updatedAsignaciones = asignaciones.map(asignacion => {
            if (asignacion.trabajador.rut === rut) {
                const campamento = campamentos.find(item => item.id === parseInt(value));
                asignacion.campamentoId = campamento.id;
                asignacion.campamento = campamento;
                asignacion.wingId = '';
                asignacion.wing = {}
                asignacion.habitacion = '';
            }
            return asignacion;
        });
        const selectedWings = wings.filter(item => item.campamentoId == parseInt(value))
        setWingsList(selectedWings);
        setAsignaciones(updatedAsignaciones);
    };
    const handleChangeWing = (event, rut) => {
        const { value } = event.target;
        const updatedAsignaciones = asignaciones.map(asignacion => {
            if (asignacion.trabajador.rut === rut) {
                const wing = wings.find(item => item.id === parseInt(value));
                asignacion.wingId = wing.id;
                asignacion.wing = wing;
                asignacion.habitacion = {
                    numero: null
                };
            }
            return asignacion;
        });
        setAsignaciones(updatedAsignaciones);
    };

    const handleChangePiso = (event, rut) => {
        const { value } = event.target;
        const updatedAsignaciones = asignaciones.map(asignacion => {
            if (asignacion.trabajador.rut === rut) {
                // const piso = habitaciones.find(item => item.piso === parseInt(value));
                asignacion.piso = parseInt(value);
            }
            return asignacion;
        });
        setAsignaciones(updatedAsignaciones);
    };
    const handleChangeHabitacion = (event, rut) => {
        const { value } = event.target;
        const updatedAsignaciones = asignaciones.map(asignacion => {
            if (asignacion.trabajador.rut === rut) {
                const habitacion = habitaciones.find(item => item.numero === parseInt(value));
                asignacion.habitacion.numero = habitacion.numero;
            }
            return asignacion;
        });
        setAsignaciones(updatedAsignaciones);
    };
      
    const handleChangeCheckbox = (event, rut) => {
        const { checked } = event.target;
        const updatedAsignaciones = asignaciones.map(asignacion => {
            if (asignacion.trabajador.rut === rut) {
                asignacion.permanente = checked;
            }
            return asignacion;
        });
        setAsignaciones(updatedAsignaciones);
    };
    

    const asignarAutomaticamente = () => {
        // Filtrar solo los trabajadores que no están asignados
        const trabajadoresNoAsignados = trabajadores.filter(trabajador => !asignaciones.some(asignacion => asignacion.trabajador.rut === trabajador.rut));
    
        // Obtener las habitaciones disponibles
        const habitacionesDisponibles = habitaciones.filter(habitacion => !asignaciones.some(asignacion => asignacion.habitacion.numero === habitacion.numero));
    
        // Verificar si hay trabajadores no asignados y habitaciones disponibles
        if (trabajadoresNoAsignados.length > 0 && habitacionesDisponibles.length > 0) {
            // Iterar sobre los trabajadores no asignados y asignarlos a las habitaciones disponibles
            trabajadoresNoAsignados.forEach((trabajador, index) => {
                // Obtener la habitación correspondiente al índice (usando módulo para volver a empezar si nos quedamos sin habitaciones)
                const habitacion = habitacionesDisponibles[index % habitacionesDisponibles.length];
                const wing = wings.find(wing => wing.id === habitacion.wingId);
                const campamento = campamentos.find(campamento => campamento.id === wing.campamentoId);
                
                // const reservaTrabajador = asignaciones.find(reserva => reserva.trabajadores.some(t => t.rut === trabajador.rut));
                // const jornada = reservaTrabajador ? reservaTrabajador.reservas.find(reserva => reserva.trabajador.rut === trabajador.rut).jornada : '';
                // const turno = reservaTrabajador ? reservaTrabajador.reservas.find(reserva => reserva.trabajador.rut === trabajador.rut).turno : {};    

                const nuevaAsignacion = {
                    trabajador: trabajador,
                    habitacion: habitacion,
                    permanente: false,
                    piso: habitacion.piso,
                    campamento: campamento,
                    wing: wing,
                    // jornada: jornada,
                    // turno: turno,
                    campamentoId: campamento.id,
                    wingId: wing.id,
                };
                setAsignaciones(prevAsignaciones => [...prevAsignaciones, nuevaAsignacion]);
            });
            setCheckAutomatico(true);
        } else {
            console.log("No hay trabajadores no asignados o no hay habitaciones disponibles para asignar.");
        }
    };
    


    const canSave = [ asignaciones.length != 0, todosCompletos ].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        try {
            setModal(true);
            // setOpen3(true);
            // setAddRequestStatus('loading');
            
            // let resp = await dispatch(updateSector(formData))
            // if(resp.payload.status === 'success'){
            //     setOpen3(false);
            // }else{
            //     setOpen3(false);
            // }

        } catch (error) {
            console.error('Error al actualizar el campamento', error.message);
        } finally{
            // setAddRequestStatus('idle');
            // setOpenEdit(false);
        }
    }

    const openModalFun = () =>{
        setOpen3(true);
        setAddRequestStatus('loading');
        toast.success('Solicitud aprobada exitosamente!.')
        setTimeout(() => {
            setOpenEdit(false);
            history('/inicio');
        }, 1000);
    }

  return (
    <div>
    <Dialog
        fullScreen
        open={openEdit}
        maxwidth="100%"
        TransitionComponent={Transition}
        onBackdropClick={() => {
        setOpenEdit(false);
        }}
    >
        {
            seleItem && (
                <>
            <DialogTitle>
                <Typography variant='p'>Revisión reserva {formData?.id}</Typography>
                <span className={styles.subTitle}>A continuación podrá modificar las asignaciones de los trabajadores a una habitación.</span>
            </DialogTitle>
            {/* 
            { JSON.stringify(asignaciones) } 
            */}
              <DialogContent>
                {/*
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2}>
                            <Typography variant="h6" className={styles.textIcon}><ErrorIcon/>Estás a punto de actualizar el sector {seleItem?.nombre}</Typography>
                        </Grid>
                    </Grid>
                */}
                <Grid container={true} spacing={1} p={2} style={{ display:'flex', flexWrap:'wrap', justifyConten:'space-between', width:'100%' }}>
                    <Grid item xs={12} sm={4} mb={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name='otro'
                            value={searchTerm}
                            type="text"
                            placeholder="Buscar por nombre o rut de trabajador"
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </Grid>
                    <Grid item  xs={12} sm={8} mb={2} style={{ display:'flex',justifyContent:'end', gap:'10px' }}>
                        <Button 
                            onClick={asignarAutomaticamente}
                            className={styles.bgColorBlack}
                            variant="contained" 
                        >
                            Asignación automática
                        </Button>
                        <div style={{ display:'flex', flexDirection:'column' }}>
                            <Typography>Hay {trabajadoresNoAsignados.length} trabajadores no asignados.</Typography>
                            { trabajadoresNoAsignados.length != 0
                                &&
                            <Button 
                                onClick={()=>setOpen(true)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                            >
                            Revisar
                            </Button>
                        }
                        </div>
                    </Grid>
                </Grid>

                <Grid container={true} spacing={1} p={2}>
                    <Grid item xs={12} sm={4} mb={2} style={{ maxHeight:'300px', overflowY:'auto', padding:'0 10px' }}>
                        
                        {
                            asignaciones
                                .filter(item => {
                                    // Filtrar por término de búsqueda en nombre o rut del trabajador
                                    const workerName = item.trabajador.nombre.toLowerCase();
                                    const workerRut = item.trabajador.rut.toString();
                                    const searchTermLower = searchTerm.toLowerCase();
                                    return workerName.includes(searchTermLower) || workerRut.includes(searchTermLower);
                                })
                                .map((item, index) => (
                                <div style={{ borderBottom: '4px solid #333', paddingBottom:'10px' }}>
                                    <div>
                                        <label className={styles.label}>Trabajador</label>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item?.trabajador?.rut}
                                            onChange={(e) => handleChangeTrabajador(e, item.trabajador?.rut)}
                                            style={{ width:'100%' }}
                                        >
                                            {
                                                trabajadores && trabajadores?.map(trab => (
                                                    <MenuItem value={trab.rut}>{trab.nombre}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div>
                                        <label className={styles.label}>Campamento</label>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item?.campamentoId}
                                            onChange={(e) => handleChangeCampamento(e, item.trabajador?.rut)}
                                            style={{ width:'100%' }}
                                        >
                                        {
                                            camps && camps?.map(camp => (
                                                <MenuItem value={camp.id}>{camp.nombre}</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    </div>
                                    <div>
                                        <label className={styles.label}>Wing</label>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item?.wingId}
                                            onChange={(e) => handleChangeWing(e, item.trabajador?.rut)}
                                            style={{ width:'100%' }}
                                        >
                                        {
                                            wingsList && wingsList.length > 0 ? wingsList?.map(wing => (
                                                <MenuItem value={wing.id}>{wing.nombre}</MenuItem>
                                            )) : wings.filter(wing => wing.campamentoId === item?.campamentoId)
                                            .map(wing => (
                                                <MenuItem value={wing.id}>{wing.nombre}</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    </div>
                                    <div>
                                        <label className={styles.label}>Piso</label>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            name='piso'
                                            type="number"
                                            value={item?.piso}
                                            onChange={(e) => handleChangePiso(e, item.trabajador?.rut)}
                                            InputProps={{ inputProps: { min: 0 } }}
                                        />
                                    </div>
                                    <div>
                                        <piso className={styles.label}>Habitación</piso>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item?.habitacion?.numero}
                                            onChange={(e) => handleChangeHabitacion(e, item.trabajador?.rut)}
                                            style={{ width:'100%' }}
                                        >
                                        {
                                            habitaciones && habitacionesList.length > 0 ? habitacionesList?.map(hab => (
                                                <MenuItem value={hab.numero}>{hab.numero}</MenuItem>
                                            )) : habitaciones.filter(hab => hab.wingId === item?.wingId)
                                            .map(hab => (
                                                <MenuItem value={hab.numero}>{hab.numero}</MenuItem>
                                            ))
                                        }
                                        </Select>
                                    </div>
                                    <div>
                                        <label className={styles.label}>Permanente?</label>
                                        <FormGroup style={{ display:'flex', flexDirection:'row' }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={item?.permanente || false} onChange={(e) => handleChangeCheckbox(e, item.trabajador?.rut)} />}
                                                style={{ width:'fit-content' }}
                                                label="Si"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            ))
                        }
                        <Button 
                            onClick={agregarFormulario}
                            className={styles.bgColorOrange}
                            variant="contained" 
                        >
                            Asignar
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={8} mb={2} style={{ textAlign:'center' }}>
                    {/* {checkAutomatico && } */}
                    <Typography>Asignaciones sugeridas</Typography>
                        <TableAsignaciones asignaciones={asignaciones ? asignaciones : []} selectCheck={selectCheck}/>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                        <Button 
                            onClick={handleEdit}
                            className={styles.bgColor}
                            variant="contained" 
                            disabled={!canSave}
                        >
                            {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Aprobar'}
                        </Button>
                        <Button 
                            onClick={()=>setOpenEdit(false)}
                            className={styles.bgColorOutlined}
                            variant="outlined" 
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </DialogActions>
            </>
        )}
      </Dialog>

      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
            Trabajadores de la solicitud que no han sido asignados a una habitaciòn.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container={true}>
                <Grid item xs={12} md={6}>
                    <span>RUT</span>
                </Grid>
                <Grid item xs={12} md={6}>
                    <span>Nombre</span>
                </Grid>
            </Grid>
            
                {
                    trabajadoresNoAsignados.map((item)=>(
                        <Grid container={true}>
                            <Grid item xs={12} md={6}>
                                <Typography variant='span' style={{ display:'block', fontWeight:'500', color:'#333' }}>{item.rut}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='span' style={{ display:'block', fontWeight:'500', color:'#333' }}>{item.nombre}</Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={styles.bgColorOutlined} onClick={()=>setOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={modal}
        onClose={()=>setModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
            Resumen de la solicitud
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Paper>
                        <List>
                            {asignaciones.map(asignacion => (
                                <ListItem key={asignacion.id}>
                                    <ListItemText
                                        primary={asignacion.trabajador.nombre}
                                        secondary={`Campamento: ${asignacion.campamento.nombre}, Ala: ${asignacion.wing.nombre}, Piso: ${asignacion.piso}, Habitación ${asignacion.habitacion.numero}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
      
      

            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={openModalFun}
                    className={styles.bgColor}
                    variant="contained" 
                    disabled={!canSave}
                >
                    {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'OK'}
                </Button>

                <Button 
                    onClick={()=> setModal(false)}
                    className={styles.bgColorOutlined}
                    variant="outlined" 
                >
                    Cerrar
                </Button>
            </DialogActions>
          </Dialog>

    </div>
  );
}