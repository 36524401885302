import { Button, Chip, IconButton, Typography, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import {Badge} from "@mui/material";
import DangerousIcon from '@mui/icons-material/Dangerous';
import { RenderCell } from "./RenderCell";
import { RenderCell2 } from "./RenderCell2";
import { RenderCell3 } from "./RenderCell3";
import { RenderCell4 } from "./RenderCell4";
import { RenderCell5 } from "./RenderCell5";
import { format, max, min, parseISO } from "date-fns";
import SettingsIcon from '@mui/icons-material/Settings';
import AutoModeIcon from '@mui/icons-material/AutoMode';

export const campamentosColumns = (setOpen, setSeleItem, setOpenEdit, setOpenMore) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "direccion",
    headerName: "Dirección",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripcion",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {params.value}
      </div>
    )
  },
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
                onClick={function () {
                  setSeleItem(params.row);
                  setOpenMore(true);
                }}
              >
              <VisibilityIcon style={{ color:"var(--border-blue)" }}/>
            </IconButton>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon style={{ color:"#d52a2a" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const estandarColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.campamento?.nombre ?? "",
    renderCell: (params) => params.value,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const historicoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "fecha",
    headerName: "Fecha",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.fecha && format(row?.row?.fecha, 'dd-MM-yyyy')
  },
  {
    field: "estado",
    headerName: "Estado",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "usuario",
    headerName: "Usuario",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ whiteSpace: 'wrap' }}>
    {row?.row?.usuario ? row?.row?.usuario?.nombre + ' ' + row?.row?.usuario?.apellido : '--'}
    </div>
  },
  // {
  //   field: "estadoRRHH",
  //   headerName: "Estado RRHH",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "nota",
    headerName: "Observación",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
];
export const tramosColumns = () => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "siArea",
    headerName: "SiArea",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.row.fechaDesde ? format(params.row.fechaDesde, 'dd-MM-yyyy') : '-'}
      </div>
    ),
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.row.fechaHasta ? format(params.row.fechaHasta, 'dd-MM-yyyy') : '-'}
      </div>
    ),
  },
  {
    field: "trabajadoresDia",
    headerName: "N°Trabajadores día",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "trabajadoresNoche",
    headerName: "N°Trabajadores noche",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "comentarios",
    headerName: "comentarios",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap' }}>
        {params.value}
      </div>
    ),
  },
  
];
export const forecastColumns = (setOpen, setSeleItem, setOpenHistorico, rol) => [
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
    const estado = params.row?.estado || ''; // Manejar caso cuando estado es undefined

    const canEdit = (
      (rol.includes('Administrador de Contrato ESED (ACE)') && (estado.includes('Creado') || estado.includes('Con cambios aplicados'))) ||
      (rol.includes('Administrador de RRHH (ARH)') && estado === 'Aprobado por CMDIC') ||
      rol.includes('Administrador de Contrato CMDIC (ACC)')
    );

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenHistorico(true);
          }}
        >
          <VisibilityIcon style={{ color: "#0086ae" }} />
        </IconButton>
        {canEdit && (
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        )}
      </div>
    );
  },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado,
    renderCell: (params) => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row?.estado}
      </Box>
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.empresa?.razonSocial,
    renderCell: (params) => params.row?.empresa?.razonSocial
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.contrato).join(', '),
    renderCell: (params) => params.row?.tramos?.map((item, index, array) => (
      <span key={index}>
        {item.contrato}{index < array.length - 1 ? ', ' : ''}
      </span>
    ))
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.vicepresidencia).join(', '),
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>
            {item.vicepresidencia}
          </span>
        ))}
      </div>
    )
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.gerencia).join(', '),
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>
            {item.gerencia}
          </span>
        ))}
      </div>
    )
  },
  {
    field: "siArea",
    headerName: "SI Área",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.siArea).join(', '),
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>
            {item.siArea}
          </span>
        ))}
      </div>
    )
  },
  {
    field: "trabajadoresDia",
    headerName: "Total Trabajadores Día",
    minWidth: 180,
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresDia, 10), 0),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresDia, 10), 0);
      return sum;
    }
  },
  {
    field: "trabajadoresNoche",
    headerName: "Total Trabajadores Noche",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresNoche, 10), 0),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresNoche, 10), 0);
      return sum;
    }
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasDesde = tramos.map(item => item.fechaDesde ? parseISO(item.fechaDesde) : null).filter(date => date !== null);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, 'dd/MM/yyyy') : '';
    },
    renderCell: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasDesde = tramos.map(item => item.fechaDesde ? parseISO(item.fechaDesde) : null).filter(date => date !== null);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, 'dd/MM/yyyy') : '';
    }
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasHasta = tramos.map(item => item.fechaHasta ? parseISO(item.fechaHasta) : null).filter(date => date !== null);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, 'dd/MM/yyyy') : '';
    },
    renderCell: (params) => {
      const tramos = Array.isArray(params.row?.tramos) ? params.row.tramos : [];
      const fechasHasta = tramos.map(item => item.fechaHasta ? parseISO(item.fechaHasta) : null).filter(date => date !== null);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, 'dd/MM/yyyy') : '';
    }
  },
];

export const gestionForecastColumns = (setOpen, setSeleItem, setOpenModal, rol, setOpenModal2) => [
  {
    field: "opciones",
    headerName: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IconButton size="small" style={{ marginLeft: 5, color:'#0086ae' }}>
          <SettingsIcon fontSize="small" />
        </IconButton>
      </div>
    ),
    width: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            onClick={(event) => {
              if(!rol?.includes('Administrador de Contrato ESED (ACE)')){
                setSeleItem(params.row);
                event.stopPropagation();
                setOpenModal(true);
              }
            }}
          >
          {
            !rol?.includes('Administrador de Contrato ESED (ACE)') ?
              rol?.includes('Administrador de Contrato CMDIC (ACC)') && (params.row?.estado.includes('Creado') || params.row?.estado.includes('cambios aplicados'))
              ? <EditIcon style={{ color:"#f19d38" }}/>
              : rol?.includes('Administrador de RRHH (ARH)') && (params.row?.estado.includes('CMDIC'))
                ? <EditIcon style={{ color:"#f19d38" }}/>
                : <VisibilityIcon style={{ color:"#0086ae" }}/>
            :''
          }
          </IconButton>
          {(
              (rol?.includes('Administrador de Contrato CMDIC (ACC)') && params.row?.estado.includes('Creado')) ||
              (rol?.includes('Administrador de Contrato CMDIC (ACC)') && params.row?.estado.includes('Con cambios aplicados')) ||
              (rol?.includes('Administrador de RRHH (ARH)') && params.row?.estado.includes('Aprobado por CMDIC'))
            ) &&
          <IconButton
            onClick={(event) => {
                setSeleItem(params.row);
                event.stopPropagation();
                setOpenModal2(true);
            }}
          >
            <AutoModeIcon style={{ color:"#2d3037" }}/>
          </IconButton>}
        </>
      );
    },
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.vicepresidencia).join(', '),
    renderCell: (params) => (
      <Box
        sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.row.tramos.map((item, index) => (
          <span key={index} style={{ textAlign:'center' }}>
            {item.vicepresidencia}
          </span>
        ))}
      </Box>
    )
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.gerencia).join(', '),
    renderCell: (params) => (
      <Box
        sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.row.tramos.map((item, index) => (
          <span key={index} style={{ textAlign:'center' }}>
            {item.gerencia}
          </span>
        ))}
      </Box>
    )
  },
  {
    field: "siArea",
    headerName: "SI Área",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.siArea).join(', '),
    renderCell: (params) => (
       <Box
        sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.row.tramos.map((item, index) => (
          <span key={index} style={{ textAlign:'center' }}>
            {item.siArea}
          </span>
        ))}
      </Box>
    )
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.empresa?.razonSocial,
    renderCell: (params) => params.row?.empresa?.razonSocial
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.contrato).join(', '),
      renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params.row.tramos.map((item, index) => (
          <span key={index}>
            {item.contrato}
          </span>
        ))}
      </div>
    )
  },
  {
    field: "trabajadoresDia",
    headerName: "Total Trabajadores Día",
    minWidth: 180,
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresDia, 10), 0),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresDia, 10), 0);
      return sum;
    }
  },
  {
    field: "trabajadoresNoche",
    headerName: "Total Trabajadores Noche",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresNoche, 10), 0),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresNoche, 10), 0);
      return sum;
    }
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado,
    renderCell: (params) => {
      return (
        <div style={{ whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          {params.value}
        </div>
      );
    }
  },
  {
    field: "fecha_modificacion",
    headerName: "Última modificación",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const modificaciones = params.row?.modificacionesForecasts;
      const lastModification = modificaciones && modificaciones.length > 0 ? modificaciones[modificaciones.length - 1].fecha : null;
      const formattedDate = lastModification ? format(new Date(lastModification), 'dd-MM-yyyy') : format(new Date(params?.row?.fechaCreacion), 'dd-MM-yyyy');

      return formattedDate;
    },
    renderCell: (params) => {
      const modificaciones = params.row?.modificacionesForecasts;
      const lastModification = modificaciones && modificaciones.length > 0 ? modificaciones[modificaciones.length - 1].fecha : null;
      const formattedDate = lastModification ? format(new Date(lastModification), 'dd-MM-yyyy') : format(new Date(params?.row?.fechaCreacion), 'dd-MM-yyyy');

      return formattedDate;
    }
  },
];


export const categoriasColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.nombre || '',
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || '',
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color:"#f19d38" }}/>
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color:"#d52a2a" }}/>
        </IconButton>
      </div>
    ),
  },
];

export const notificationsSupervisorColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "desde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.bodegaOrigen?.nombre ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "hacia",
    headerName: "Hacia",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.bodegaDestino?.nombre ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "encargado",
    headerName: "Encargado",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.encardoDeBodega?.nombre + ' ' + params.row?.encardoDeBodega?.apellidos ?? '--',
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.montoTotal ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha ? format(new Date(params.row.fecha), 'dd-MM-yyyy') : '--',
    renderCell: (params) => params.value,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => <Chip label={params.row?.estado} sx={{ color: '#FFF' }} />,
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
     valueGetter: (params) => {
    const articulos = params.row?.articulos || [];
    return articulos.map((articulo) => `${articulo?.producto?.codigo}: ${articulo?.producto?.nombre}`).join(", ");
  },
    renderCell: (params) => {
      const articulos = params.row?.articulos || [];
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {articulos.map((articulo, index) => (
            <span key={index}>
              {articulo?.producto?.codigo}: {articulo?.producto?.nombre}
              {index < articulos.length - 1 ? ", " : ""}
            </span>
          ))}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const { row } = params;
      return (
        <div>
          {row.estado === 'Pendiente' && (
            <IconButton
              onClick={() => {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color: "#f19d38" }} />
            </IconButton>
          )}
        </div>
      );
    },
  },
];
export const notificationsColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "desde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.bodegaOrigen?.nombre ?? '',
    valueGetter: (params) => params.row.bodegaOrigen?.nombre,
  },
  {
    field: "hacia",
    headerName: "Hacia",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.bodegaDestino?.nombre ?? '',
    valueGetter: (params) => params.row.bodegaDestino?.nombre,
  },
  {
    field: "encargado",
    headerName: "Encargado de bodega",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.encardoDeBodega ? (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row?.encardoDeBodega.nombre}{' '}{params.row?.encardoDeBodega.apellidos}
      </Box>
    ) : '--',
    valueGetter: (params) => params.row.encardoDeBodega?.nombre + ' ' + params.row.encardoDeBodega?.apellidos,
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const date = params.row.fecha ? new Date(params.row.fecha) : '';
      return date ? format(date, 'dd-MM-yyyy') : '--';
    },
    valueGetter: (params) => {
      const date = params.row.fecha ? new Date(params.row.fecha) : '';
      return date ? format(date, 'dd-MM-yyyy') : '--';
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Chip sx={{ color: '#FFF' }} label={
        params.row?.estado === 'Aprobado' ? 'En tránsito' :
        params.row?.estado === 'Entregado' ? 'Recibido' :
        'Aprobado por supervisor'
      } />
    ),
    valueGetter: (params) => params.row?.estado === 'Aprobado' ? 'En tránsito' : params.row?.estado === 'Entregado' ? 'Recibido' : 'Aprobado por supervisor'
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const articulos = params.row?.articulos || [];
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {articulos.map((articulo, index) => (
            <span key={index}>
              {articulo?.producto?.codigo}: {articulo?.producto?.nombre}
              {index < articulos.length - 1 ? ", " : ""}
            </span>
          ))}
        </Box>
      );
    },
    valueGetter: (params) => params.row.articulos?.map(articulo => articulo.producto?.nombre).join(', '),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        {params.row.estado === 'Aprobar' &&
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
        }
      </div>
    ),
  },
];
export const trabajadoresReasignacionColumns = (setOpen, setSeleItem, setOpenEdit) => [
    {
      field: "rut",
      headerName: "Rut",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      minWidth:250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "apellido",
      headerName: "apellido",
      flex: 1,
      minWidth:250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Correo",
      flex: 1,
      minWidth:300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cargo",
      headerName: "Cargo",
      flex: 1,
      minWidth:250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rol",
      headerName: "Rol",
      flex: 1,
      minWidth:320,
      headerAlign: "center",
      align: "center",
      renderCell: (row) => (
        <Box display="flex" flexDirection="column">
        {row.row?.roles?.map((role, index) => (
          <span key={index}>
            {role}
          </span>
        ))}
      </Box>
      )
    },
    {
      field: "opciones",
      headerName: "Opciones",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const reasignacionColumns = (setOpen, setSeleItem, setOpenEdit) => [
    {
      field: "rut",
      headerName: "Rut",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "habitacion",
      headerName: "Habitación",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params?.row?.habitacion?.numero ?? ''
    },
    {
      field: "pabellon",
      headerName: "Pabellón",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params?.row?.habitacion?.pabellon?.identificador ?? ''
    },
    {
      field: "wing",
      headerName: "Wing",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params?.row?.habitacion?.wing?.nombre ?? ''
    },
    {
      field: "campamento",
      headerName: "campamento",
      flex: 1,
      minWidth:100,
      headerAlign: "center",
      align: "center",
      renderCell: (row) => {
        if (row?.row?.habitacion?.wing) {
          return row?.row?.habitacion?.wing?.campamento?.nombre;
        } else if (row?.row?.habitacion?.pabellon) {
          return row?.row?.habitacion?.pabellon?.campamento?.nombre;
        } else {
          return '';
        }
      }
    },
];
export const reasignacionHabitacionesColumns = (setOpen, setSeleItem, setOpenEdit) => [
    {
      field: "asignado",
      headerName: "Asignados",
      flex:1,
      minWidth:300,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.asignados && Array.isArray(row.row?.asignados) && (
        row.row?.asignados.map((item, index) => (
            <span key={index}>{item.trabajador.rut}</span>
          ))),
      renderCell: row => row.row?.asignados && Array.isArray(row.row?.asignados) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {row.row?.asignados.map((item, index) => (
            <span key={index}>{item.trabajador.nombre ?? item.trabajador.nombres} {item.trabajador.apellidos ?? item.trabajador.apellidoPaterno} ({item.trabajador.rut})</span>
          ))}
        </Box>)
    },
    {
      field: "empresa",
      headerName: "Empresa",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.asignados && Array.isArray(row.row?.asignados) && (
        row.row?.asignados.map((item, index) => (
            <span key={index}>{item.empresa ? item.empresa.razonSocial : '-'}</span>
          ))),
      renderCell: row => row.row?.asignados && Array.isArray(row.row?.asignados) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {row.row?.asignados.map((item, index) => (
            <span key={index}>{item.empresa ? item.empresa.razonSocial : '-'}</span>
          ))}
        </Box>)
    },
    {
      field: "contrato",
      headerName: "Contrato",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.asignados && Array.isArray(row.row?.asignados) && (
        row.row?.asignados.map((item, index) => (
            <span key={index}>{item.contrato}</span>
          ))),
      renderCell: row => row.row?.asignados && Array.isArray(row.row?.asignados) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {row.row?.asignados.map((item, index) => (
            <span key={index}>{item.contrato ?? item.contrato}</span>
          ))}
        </Box>)
    },
    {
      field: "numero",
      headerName: "Número",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "piso",
      headerName: "Piso",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row?.row?.pabellon ? row?.row?.piso : '',
      renderCell: row => row?.row?.pabellon ? row?.row?.piso : ''
    },
    {
      field: "pabellon",
      headerName: "Pabellón",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row?.row?.pabellon ? row?.row?.pabellon.identificador : '',
      renderCell: row => row?.row?.pabellon ? row?.row?.pabellon.identificador : ''
    },
    {
      field: "wing",
      headerName: "Wing",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row?.row?.wing ? row?.row?.wing.nombre : '',
      renderCell: row => row?.row?.wing ? row?.row?.wing.nombre : ''
    },
    {
      field: "numeroTotalCamas",
      headerName: "Total Camas",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row?.row?.numeroTotalCamas ? row?.row?.numeroTotalCamas : '',
      renderCell: row => row?.row?.numeroTotalCamas ? row?.row?.numeroTotalCamas : ''
    },
    {
      field: "disponibles",
      headerName: "Camas disponibles",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      valueGetter: row => (Array.isArray(row.row?.trabajadores) && row?.row?.numeroTotalCamas && row?.row?.trabajadores.length) ? (row?.row?.numeroTotalCamas - row?.row?.trabajadores.length) : row.row?.numeroTotalCamas,
      renderCell: row => (Array.isArray(row.row?.trabajadores) && row?.row?.numeroTotalCamas && row?.row?.trabajadores.length) ? (row?.row?.numeroTotalCamas - row?.row?.trabajadores.length) : row.row?.numeroTotalCamas
    },
    {
      field: "tipoHuesped",
      headerName: "Tipo Huésped",
      flex: 1,
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row?.row?.tipoHuesped ? row?.row?.tipoHuesped : '',
      renderCell: row => row?.row?.tipoHuesped ? row?.row?.tipoHuesped : ''
    },
    {
      field: "cargosReservados",
      headerName: "Cargos reservados",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      valueGetter: row => Array.isArray(row?.row?.cargosReservados) ? row.row.cargosReservados.join(', ') : '',
      renderCell: row => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {Array.isArray(row?.row?.cargosReservados) ? row.row.cargosReservados.join(', ') : ''}
        </Box>
      )
    }
];
export const reasignacionTrabajadoresColumns = (setOpen, setSeleItem, setOpenEdit) => [
    {
      field: "rut",
      headerName: "Rut",
      flex:1,
      minWidth:80,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.trabajador?.rut ?? '',
      renderCell: row => row.row?.trabajador?.rut ?? ''
    },
    {
      field: "nombreCompleto",
      headerName: "Nombre",
      flex: 1,
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.trabajador?.nombre ? row.row?.trabajador?.nombre : row.row?.trabajador?.nombres ? row.row?.trabajador?.nombres : '',
      renderCell: row => {
        let nombre = row.row?.trabajador?.nombre ? 
            row.row?.trabajador?.nombre : 
            row.row?.trabajador?.nombres ? 
            row.row?.trabajador?.nombres : ''
        return (
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          >
          {
          nombre
          }
          </Box>
          )
        }
    },
    {
      field: "jornada",
      headerName: "Jornada",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.jornada ?? '',
      renderCell: row => row.row?.jornada ?? ''
    },
    {
      field: "turno",
      headerName: "Turno",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.turno?.descripcion ?? '',
      renderCell: row => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      {row.row?.turno?.descripcion ?? ''}
      </Box>
    },
    {
      field: "contrato",
      headerName: "Contrato",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.contrato ?? '',
      renderCell: row => row.row?.contrato ?? ''
    },
    {
      field: "empresa",
      headerName: "Empresa",
      flex: 1,
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.empresa ? row.row?.empresa?.razonSocial : '',
      renderCell: row => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      {row.row?.empresa ? row.row?.empresa?.razonSocial : ''}
      </Box>
    },
     {
      field: "genero",
      headerName: "Género",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.trabajador?.genero ?? '',
      renderCell: row => row.row?.trabajador?.genero ?? ''
    },
    {
      field: "habitacion",
      headerName: "Habitación",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: row => row.row?.habitacion ? row.row?.habitacion?.numero : '',
      renderCell: row => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      {row.row?.habitacion ? row.row?.habitacion?.numero : ''}
      </Box>
    },
     {
      field: "pabellon",
      headerName: "Pabellón",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: row => {
        const identificador = row.row?.habitacion?.pabellon?.identificador || '';
        return <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      { identificador }
      </Box>
      }
    },
    {
      field: "wing",
      headerName: "Wing",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: row => {
        const identificador = row.row?.habitacion?.wing?.nombre || '';
        return <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      { identificador }
      </Box>
      }
    },
    {
      field: "tipo",
      headerName: "Tipo cargo",
      flex: 1,
      minWidth: 125,
      headerAlign: "center",
      align: "center",
      renderCell: row => row.row?.tipoCargo ? row.row?.tipoCargo : row.row?.trabajador?.tipoCargo ? row.row?.trabajador?.tipoCargo : '',
      renderCell: row => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        { row.row?.tipoCargo ? row.row?.tipoCargo : row.row?.trabajador?.tipoCargo ? row.row?.trabajador?.tipoCargo : '' }
      </Box>
    },
    {
      field: "fechaIngreso",
      headerName: "Ingreso",
      flex: 1,
      minWidth:100,
      headerAlign: "center",
      align: "center",
      renderCell: (row) => {
        if (row?.row?.fechaIngreso) {
          return format(row?.row?.fechaIngreso, 'dd-MM-yyyy')
        } else {
          return '';
        }
      }
    },
    {
      field: "fechaSalida",
      headerName: "Salida",
      flex: 1,
      minWidth:100,
      headerAlign: "center",
      align: "center",
      renderCell: (row) => {
        if (row?.row?.fechaSalida) {
          return format(row?.row?.fechaSalida, 'dd-MM-yyyy')
        } else {
          return '';
        }
      }
    },
];
export const historialReasignacionColumns = (setOpen, setSeleItem, setOpenEdit) => [
    {
      field: "rut",
      headerName: "Rut",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
      renderCell: row => row.row?.trabajador?.rut ?? ''
    },
    {
      field: "nombreCompleto",
      headerName: "Nombre",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: row => row.row?.trabajador?.nombre ? row.row?.trabajador?.nombre : row.row?.trabajador?.nombres ? row.row?.trabajador?.nombres : ''
    },
    {
      field: "habitacion",
      headerName: "Habitación",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      renderCell: row => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      {row.row?.habitacion ? row.row?.habitacion?.numero : ''}
      </Box>
    },
    {
      field: "pabellon",
      headerName: "Pabellón",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      renderCell: row => {
        const identificador = row.row?.habitacion?.pabellon?.identificador || '';

        return <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      { identificador }
      </Box>
      }
    },
    {
      field: "wing",
      headerName: "Wing",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      renderCell: row => {
        const identificador = row.row?.habitacion?.wing?.nombre || '';

        return <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      { identificador }
      </Box>
      }
    },
];

export const mermaColumns = (setOpen, setSeleItem, setOpenEdit, setOpenShow) => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.producto?.codigo,
    valueGetter: (params) => params.row.producto?.codigo,
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.producto?.descripcion,
    valueGetter: (params) =><Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row.producto?.descripcion}
      </Box>
  },
  {
    field: "cantidad",
    headerName: "Cantidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.cantidad,
  },
  {
    field: "motivo",
    headerName: "Motivo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.motivo,
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row.fecha ? format(row.row.fecha, 'dd-MM-yyyy') : '-'),
    valueGetter: (params) => params.row.fecha ? format(params.row.fecha, 'dd-MM-yyyy') : '-',
  },
  {
    field: "observaciones",
    headerName: "Observaciones",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.observaciones}
      </Box>
    ),
    valueGetter: (params) => params.row.observaciones,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#FFF" }} />
        </IconButton>
        {/* 
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton> 
        */}
      </div>
    ),
  },
];
export const salidaBodegaShowColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.codigo
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:180,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.nombre
  },
   {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:230,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.descripcion ? <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.producto?.descripcion}
      </Box> : '--'
  },
  {
    field: "precio",
    headerName: "Precio",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    renderCell: row => <div style={{ width:'100%', textAlign:'center' }}>
    ${row.row?.precio}
    </div>
  },
  {
    field: "cantidad",
    headerName: "Cantidad",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    renderCell: row => <div style={{ width:'100%', textAlign:'center' }}>
    {row.row?.cantidad}
    </div>
  },
];
export const ingresoBodegaShowColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.codigo
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:180,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.nombre
  },
   {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:230,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.descripcion ? <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.producto?.descripcion}
      </Box> : '--'
  },
  {
    field: "cantidadRecibida",
    headerName: "Cantidad",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    renderCell: row => <div style={{ width:'100%', textAlign:'center' }}>
    {row.row?.cantidadRecibida}
    </div>
  },
];
export const trasladoShowColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.codigo
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:180,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.nombre
  },
   {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:300,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.descripcion ? <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.producto?.descripcion}
      </Box> : '--'
  },
  {
    field: "cantidad",
    headerName: "Cantidad",
    flex:1,
    minWidth:60,
    headerAlign: "center",
  },
];
export const trasladoColumns = (setOpen, setSeleItem, setOpenEdit, setOpenShow) => [
  {
    field: "desde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.bodegaOrigen?.nombre ?? '',
    valueGetter: (params) => params.row.bodegaOrigen?.nombre,
  },
  {
    field: "hacia",
    headerName: "Hacia",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.bodegaDestino?.nombre ?? '',
    valueGetter: (params) => params.row.bodegaDestino?.nombre,
  },
  {
    field: "encargado",
    headerName: "Encargado",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.encardoDeBodega ? (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row.encardoDeBodega.nombre + ' ' + params.row.encardoDeBodega.apellidos}
      </Box>
    ) : '--',
    valueGetter: (params) => params.row.encardoDeBodega?.nombre + ' ' + params.row.encardoDeBodega?.apellidos,
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const date = params.row?.fecha ? new Date(params.row?.fecha) : '';
      return date ? format(date, 'dd/MM/yyyy') : '--';
    },
    valueGetter: (params) => {
      const date = params.row?.fecha ? new Date(params.row?.fecha) : '';
      return date ? format(date, 'dd/MM/yyyy') : '--';
    },
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const articulos = params.row?.articulos || [];
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {articulos.map((articulo, index) => (
            <span key={index}>
              {articulo?.producto?.codigo}: {articulo?.producto?.nombre}
              {index < articulos.length - 1 ? ", " : ""}
            </span>
          ))}
        </Box>
      );
    },
    valueGetter: (params) => params.row.articulos?.map(articulo => articulo.producto?.nombre).join(', '),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#FFF" }} />
        </IconButton>
      </div>
    ),
  },
];
export const ingresosColumns = (setOpen, setSeleItem, setOpenEdit, setOpenShow) => [
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.bodega?.nombre,
    valueGetter: (params) => params.row.bodega?.nombre,
  },
  {
    field: "numeroDocumento",
    headerName: "Documento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numeroDocumento,
    renderCell: (row) => row.row?.numeroDocumento,
  },
  {
    field: "tipoDocumento",
    headerName: "Tipo documento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tipoDocumento,
    renderCell: (row) => row.row?.tipoDocumento,
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
    renderCell: (row) => row.row?.montoTotal,
  },
  {
    field: "fechaIngreso",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      try {
        const date = parseISO(params.row?.fechaIngreso);
        return format(date, 'dd/MM/yyyy');
      } catch (error) {
        return 'Fecha inválida';
      }
    },
    renderCell: (params) => {
      try {
        const date = parseISO(params.row?.fechaIngreso);
        return format(date, 'dd/MM/yyyy');
      } catch (error) {
        return 'Fecha inválida';
      }
    },
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.ingresoArticulos?.map(pab => pab.producto?.codigo).join(', '),
    valueGetter: (params) => params.row.ingresoArticulos?.map(pab => pab.producto?.codigo).join(', '),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#FFF" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        {/* <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton> */}
      </div>
    ),
  },
];
export const salidasColumns = (setOpen, setSeleItem, setOpenEdit, setOpenShow) => [
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.bodega?.nombre ?? '',
    valueGetter: (params) => params.row.bodega?.nombre,
  },
  {
    field: "numeroDocumento",
    headerName: "Documento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numeroDocumento,
  },
  {
    field: "montoTotal",
    headerName: "Monto total (CLP)",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.montoTotal,
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      try {
        const date = parseISO(params.row.fecha);
        return format(date, 'dd/MM/yyyy');
      } catch (error) {
        return 'Fecha inválida';
      }
    },
    renderCell: (params) => {
      try {
        const date = parseISO(params.row.fecha);
        return format(date, 'dd/MM/yyyy');
      } catch (error) {
        return 'Fecha inválida';
      }
    },
    
  },
  {
    field: "articulos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const articulos = params.row?.salidaArticulos?.map(pab => pab.producto?.nombre).join(', ') ?? '';
      return (
        <Box
          sx={{
            wordWrap: "break-word",
            whiteSpace: "normal"
          }}
        >
          {articulos}
        </Box>
      );
    },
    valueGetter: (params) => params.row.salidaArticulos?.map(pab => pab.producto?.nombre).join(', '),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#FFF" }} />
        </IconButton>
      </div>
    ),
  },
];

export const espaciosDashboardColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "numero",
    headerName: "ID",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.numero || '',
    renderCell: (params) => params.row?.numero || '',
  },
  {
    field: "Campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.campamento?.nombre || '',
    renderCell: (params) => params.row?.campamento?.nombre || '',
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fila || '',
    renderCell: (params) => params.row?.fila || '',
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.nivel || '',
    renderCell: (params) => params.row?.nivel || '',
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    maxWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado || '',
    renderCell: (params) => params.row?.estado === 'Disponible'
      ? <span style={{ backgroundColor: "green", color: 'white', borderRadius: '5px', padding: '2px 4px' }}>{params.row?.estado}</span>
      : <span style={{ backgroundColor: "#212227", color: 'white', borderRadius: '5px', padding: '2px 4px' }}>{params.row?.estado}</span>,
  },
];

{/*  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  },   */}

export const espaciosBodegaColumns = (setOpen, setSeleItem, setOpenEdit, setOpenShow) => [
  {
    field: "identificador",
    headerName: "Identificador",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.identificador,
  },
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.bodega?.nombre,
    valueGetter: (params) => params.row.bodega?.nombre,
  },
  // {
  //   field: "pasilloHorizontal",
  //   headerName: "Pasillo Horizontal",
  //   flex: 1,
  //   minWidth: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "pasilloVertical",
  //   headerName: "Pasillo Vertical",
  //   flex: 1,
  //   minWidth: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "ubicacion",
  //   headerName: "Ubicación",
  //   flex: 1,
  //   minWidth: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "altura",
  //   headerName: "Altura",
  //   flex: 1,
  //   minWidth: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const bodegaColumns = (setOpen, setSeleItem, setOpenEdit) => [
  // {
  //   field: "codigo",
  //   headerName: "Código",
  //   flex: 1,
  //   minWidth: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.campamento?.nombre,
    valueGetter: (params) => params.row.campamento?.nombre,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.nombre,
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion,
    renderCell: params =>  <Box
      sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.row.descripcion}
      </Box>
  },
  {
    field: "latitud",
    headerName: "Latitud",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.latitud,
  },
  {
    field: "longitud",
    headerName: "Longitud",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.longitud,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];

export const sectorColumns = (setOpen, setSeleItem, setOpenEdit) => [
    // {
    //   field: "id",
    //   headerName: "id",
    //   minWidth:60,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "nombre",
      headerName: "Nombre",
      flex:1,
      minWidth:130,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "campamentoName",
    //   headerName: "Campamento",
    //   flex:1,
    //   minWidth:200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "descripcion",
      headerName: "Descripción",
      flex:1,
      minWidth:130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "opciones",
      headerName: "Opciones",
      width: 130,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon style={{ color:"#d52a2a" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
    
];

export const reservasAsignacionesEditColumns = (setOpen, setSeleItem, setOpenEdit) => [  
  {
    field: "trabajador",
    headerName: "RUT",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.rut
  },
  {
    field: "trabajadorNombre",
    headerName: "Nombre",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.nombre
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.campamento?.nombre
  },
  {
    field: "wing",
    headerName: "Wing",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.wing?.nombre
  },
  {
    field: "piso",
    headerName: "Piso",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.piso
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.habitacion?.numero
  },
  
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 130,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpenEdit(true);
  //           }}
  //         >
  //           <EditIcon style={{ color:"#f19d38" }}/>
  //         </IconButton>
  //       </div>
        
  //     );
  //   },
  // },
  
];

export const reservasAsignacionesColumns = (setOpen, setSeleItem, setOpenEdit) => [
  // {
  //   field: 'checkbox',
  //   headerName: 'Permanente?',
  //   width: 120,
  // },
  {
    field: "rut",
    headerName: "RUT",
    flex:1,
    minWidth:90,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row?.row?.rut,
    renderCell: row => row?.row?.rut
  },
  {
    field: "nombres",
    headerName: "Nombre",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.nombres ? params.row.nombres : params.row.nombre ?? '',
    renderCell: params => 
      <Box
        sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
            {params.row.nombres ? params.row.nombres : params.row.nombre ?? ''}
            </Box>
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex:1,
    minWidth:100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.habitacion?.numero,
    renderCell: (row) => row?.row?.habitacion?.numero
  },
  // {
  //   field: "numeroTotalCamas",
  //   headerName: "Camas",
  //   flex:1,
  //   minWidth:100,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (row) => row?.row?.habitacion?.numeroTotalCamas,
  //   renderCell: (row) => row?.row?.habitacion?.numeroTotalCamas
  // },
   {
    field: "piso",
    headerName: "Piso",
    flex:1,
    minWidth:60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.piso,
    renderCell: (row) => row?.row?.piso
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex:1,
    minWidth:90,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.pabellon ? row?.row?.habitacion?.pabellon?.identificador : '',
    renderCell: (row) => row?.row?.pabellon ? row?.row?.habitacion?.pabellon?.identificador : ''
  },
 {
    field: "wing",
    headerName: "Wing",
    flex:1,
    minWidth:90,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.wing ? row?.row?.habitacion?.wing?.nombre : '',
    renderCell: (row) => row?.row?.wing ? row?.row?.habitacion?.wing?.nombre : ''
  },
   {
    field: "jornada",
    headerName: "Jornada",
    flex:1,
    minWidth:90,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.jornada,
    renderCell: (row) => row?.row?.jornada
  },
   {
    field: "turno",
    headerName: "Turno",
    flex:1,
    minWidth:60,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.turno?.descripcion,
    renderCell: (row) => row?.row?.turno?.descripcion
  },
   {
    field: "genero",
    headerName: "Género",
    flex:1,
    minWidth:80,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.genero,
    renderCell: (row) => row?.row?.trabajador?.genero
  },
   {
    field: "tipoCargo",
    headerName: "Tipo Cargo",
    flex:1,
    minWidth:100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.tipoCargo,
    renderCell: (row) => <Box
      sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {row?.row?.trabajador?.tipoCargo}
      </Box>
  },
  {
    field: "acreditado",
    headerName: "Acreditación",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.acreditado ? 'Vigente' : 'No vigente',
    renderCell: (row) =>  row?.row?.trabajador?.acreditado ? <span style={{ color:'#004912', background:'#7cfb7c',padding:'5px 10px', borderRadius:'8px'}}>Vigente</span> : <span style={{ color:'red', background:'rgb(255, 207, 207)',padding:'3px 10px', borderRadius:'10px'}}>No Vigente</span>
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.campamento ? row?.row?.campamento?.nombre : '',
    renderCell: (row) => row?.row?.campamento ? row?.row?.campamento?.nombre : ''
  },
  
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 130,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpenEdit(true);
  //           }}
  //         >
  //           <EditIcon style={{ color:"#f19d38" }}/>
  //         </IconButton>
  //       </div>
        
  //     );
  //   },
  // },
  
];
export const reservasRecepcionistaColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "wing",
    headerName: "Wing",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.wing?.nombre
  },
  {
    field: "ingresoSalida",
    headerName: "Fecha ingreso y salida",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.fecha_entrada + ' - ' + row?.row?.fecha_salida
  },
  {
    field: "piso",
    headerName: "Piso",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.habitacion?.numero
  },
  {
    field: "trabajador",
    headerName: "Rut",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.rut
  },
  {
    field: "trabajadorNombre",
    headerName: "Nombre",
    flex:1,
    minWidth:230,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.nombre
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.cargo
  },
  {
    field: "jornada",
    headerName: "jornada",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.turno?.descripcion 
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
  
];

export const asignacionEspaciosColumns = (setOpen, setSeleItem, setOpenEdit, handleCellClick) => [
  {
    field: "numero",
    headerName: "ID",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.numero ?? 'N/A',
    renderCell: (params) => params.row?.espacioAlmacenamiento?.numero ?? 'N/A',
  },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.trabajador?.rut ?? 'N/A',
    renderCell: (params) => params.row?.trabajador?.rut ?? 'N/A',
  },
  {
  field: "nombre",
  headerName: "Nombre",
  flex: 1,
  minWidth: 280,
  headerAlign: "center",
  align: "center",
  valueGetter: (params) => {
    const nombre = params.row?.trabajador?.nombre ?? '';
    const apellidoPaterno = params.row?.trabajador?.apellidoPaterno ?? '';
    const apellidoMaterno = params.row?.trabajador?.apellidoMaterno ?? '';
    return `${nombre} ${apellidoPaterno} ${apellidoMaterno}`.trim() || 'N/A';
  },
  renderCell: (params) => {
    return (
      <div style={{ cursor: 'pointer', whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
        {params.value}
      </div>
    );
  },
},
 {
  field: "items",
  headerName: "Items",
  flex: 1,
  minWidth: 260,
  headerAlign: "center",
  align: "center",
  valueGetter: (params) => {
    return params.row?.items ? params.row.items.join(', ') : 'N/A';
  },
  renderCell: (params) => {
    const items = params.row?.items ? params.row.items.join(', ') : 'N/A';
    return (
      <div 
        style={{ 
          cursor: 'pointer', 
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflowWrap: 'break-word' 
        }} 
        onClick={() => handleCellClick(items)}
      >
        {items}
      </div>
    );
  },
},
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha ? format(new Date(params.row?.fecha), 'dd-MM-yyyy') : '-',
    renderCell: (params) => params.row?.fecha ? format(new Date(params.row?.fecha), 'dd-MM-yyyy') : '-',
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.campamento?.nombre ?? 'N/A',
    renderCell: (params) => params.row?.espacioAlmacenamiento?.campamento?.nombre ?? 'N/A',
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.fila ?? 'N/A',
    renderCell: (params) => params.row?.espacioAlmacenamiento?.fila ?? 'N/A',
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.nivel ?? 'N/A',
    renderCell: (params) => params.row?.espacioAlmacenamiento?.nivel ?? 'N/A',
  },
];

export const asignacionEspaciosRetirosColumns = (setOpen, setSeleItem, setOpenEdit, handleCellClick) => [
  {
    field: "numero",
    headerName: "ID",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.numero ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.trabajador?.rut ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 280,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const nombre = params.row?.trabajador?.nombre ?? '';
      const apellidoPaterno = params.row?.trabajador?.apellidoPaterno ?? '';
      const apellidoMaterno = params.row?.trabajador?.apellidoMaterno ?? '';
      return `${nombre} ${apellidoPaterno} ${apellidoMaterno}`.trim() || 'N/A';
    },
    renderCell: (params) => (
      <div style={{ cursor: 'pointer', whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "items",
    headerName: "Items",
    flex: 1,
    minWidth: 260,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.items ? params.row.items.join(', ') : 'N/A',
    renderCell: (params) => (
      <div 
        style={{ 
          cursor: 'pointer', 
          whiteSpace: 'normal', 
          wordWrap: 'break-word', 
          overflowWrap: 'break-word' 
        }} 
        onClick={() => handleCellClick(params.value)}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha ? format(new Date(params.row?.fecha), 'dd-MM-yyyy') : '-',
    renderCell: (params) => params.value,
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.campamento?.nombre ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.fila ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.espacioAlmacenamiento?.nivel ?? 'N/A',
    renderCell: (params) => params.value,
  },
];
// {
//   field: "tipo",
//   headerName: "Tipo solicitud",
//   flex:1,
//   minWidth:130,
//   headerAlign: "center",
//   align: "center",
//   renderCell: (row) => row.row?.tipo_solicitud == 1 ? <span style={{ backgroundColor:'green', padding:'2px 12px', borderRadius:'15px', color:'white' }}>Ingreso</span> : <span style={{ backgroundColor:'orange', padding:'2px 12px', borderRadius:'15px', color:'white' }}>Retiro</span>,
// },
// {
//   field: "opciones",
//   headerName: "Opciones",
//   width: 130,
//   sortable: false,
//   headerAlign: "center",
//   align: "center",
//   renderCell: (params) => {
//     return (
//       <div>
//         <IconButton
//           onClick={function () {
//             setSeleItem(params.row);
//             setOpenEdit(true);
//           }}
//         >
//           <EditIcon style={{ color:"#f19d38" }}/>
//         </IconButton>
//         <IconButton
//           onClick={function () {
//             setSeleItem(params.row);
//             setOpen(true);
//           }}
//         >
//           <DeleteIcon style={{ color:"#d52a2a" }}/>
//         </IconButton>
//       </div>
      
//     );
//   },
// },

export const almacenamientoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.campamento?.nombre,
    renderCell: (row) => row?.row?.campamento?.nombre,
  },
  {
    field: "fila",
    headerName: "Fila",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.value,
  },
  {
    field: "nivel",
    headerName: "Nivel",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.value,
  },
  {
    field: "numero",
    headerName: "ID",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.id,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.value,
    renderCell: (params) => (
      <span
        style={{
          padding: '4px 8px',
          borderRadius: '4px',
          color: '#fff',
          backgroundColor: params.value === 'Ocupado' ? '#d32f2f' : '#388e3c', 
        }}
      >
        {params.value}
      </span>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];

export const horariosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "horaInicio",
  //   headerName: "Inicio",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "horaTermino",
  //   headerName: "Término",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
  
];
export const vistaPersonalColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const campamentos = new Set();

      gruposHabitaciones.forEach(grupo => {
        grupo?.habitaciones?.forEach(habitacion => {
          if (habitacion?.pabellon?.campamento?.nombre) {
            campamentos.add(habitacion.pabellon.campamento.nombre);
          }
          if (habitacion?.wing?.campamento?.nombre) {
            campamentos.add(habitacion.wing.campamento.nombre);
          }
        });
      });

      return [...campamentos].join(', ');
    },
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
        {params.value.split(', ').map((campamento, index) => (
          <div key={index}>{campamento}</div>
        ))}
      </div>
    )
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const wingsSet = new Set();

      gruposHabitaciones.forEach(grupo => {
        grupo?.habitaciones?.forEach(habitacion => {
          if (habitacion?.wing?.nombre) {
            wingsSet.add(habitacion.wing.nombre);
          }
        });
      });

      return [...wingsSet].join(', ');
    },
    renderCell: (params) => (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {params.value.split(', ').map((wing, index) => (
          <span key={index} style={{ marginRight: '8px' }}>{wing}</span>
        ))}
      </div>
    )
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const pabellonesSet = new Set();

      gruposHabitaciones.forEach(grupo => {
        grupo?.habitaciones?.forEach(habitacion => {
          if (habitacion?.pabellon?.identificador) {
            pabellonesSet.add(habitacion.pabellon.identificador);
          }
        });
      });

      return [...pabellonesSet].join(', ');
    },
    renderCell: (params) => (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {params.value.split(', ').map((pabellon, index) => (
          <span key={index} style={{ marginRight: '8px' }}>{pabellon}</span>
        ))}
      </div>
    )
  },
  {
    field: "horario",
    headerName: "Horario",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.horarioAseo?.nombre,
    renderCell: (params) => params.value
  },
  {
    field: "rangoHorario",
    headerName: "Rango horario",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const horaInicio = params.row?.horarioAseo?.horaInicio || '';
      const horaTermino = params.row?.horarioAseo?.horaTermino || '';
      return `${horaInicio} - ${horaTermino}`;
    },
    renderCell: (params) => params.value
  },
  {
    field: "fechaInicioP",
    headerName: "Inicio planificacion",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.desde && format(params.row.desde, 'dd-MM-yyyy'),
    renderCell: (params) => params.value
  },
  {
    field: "fechaTerminoP",
    headerName: "Termino planificacion",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.hasta && format(params.row.hasta, 'dd-MM-yyyy'),
    renderCell: (params) => params.value
  },
  {
    field: "numeroCamas",
    headerName: "N°Camas",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const gruposHabitaciones = params.row?.gruposHabitaciones || [];
      const totalCamas = gruposHabitaciones.reduce((accumulator, currentGroup) => {
        currentGroup.habitaciones.forEach(habitacion => {
          accumulator += habitacion.numeroTotalCamas || 0;
        });
        return accumulator;
      }, 0);

      return totalCamas;
    },
    renderCell: (params) => params.value
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <IconButton
        onClick={() => {
          setSeleItem(params.row);
          setOpenEdit(true);
        }}
      >
        <VisibilityIcon style={{ color:"var(--border-blue)" }}/>
      </IconButton>
    )
  }
];

export const vistaPersonalPlanificacionEspecificaColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellonCampamentos = params.row?.pabellon?.map(p => p.campamento?.nombre).filter(Boolean) || [];
      const wingCampamentos = params.row?.wing?.map(w => w.campamento?.nombre).filter(Boolean) || [];
      const campamentoNombres = [...new Set([...pabellonCampamentos, ...wingCampamentos])];
      return campamentoNombres.join(', ') || '--';
    },
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
        {params.value}
      </div>
    )
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const wingNombres = params.row?.wing?.map(w => w.nombre).filter(Boolean) || [];
      return [...new Set(wingNombres)].join(', ') || '--';
    },
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
        {params.value}
      </div>
    )
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellonIdentificadores = params.row?.pabellon?.map(p => p.identificador).filter(Boolean) || [];
      return [...new Set(pabellonIdentificadores)].join(', ') || '--';
    },
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
        {params.value}
      </div>
    )
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha && format(params.row?.fecha, 'dd-MM-yyyy'),
    renderCell: (params) => params.value
  },
  // {
  //   field: "numeroCamas",
  //   headerName: "N°Camas",
  //   flex: 1,
  //   minWidth: 130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     const gruposHabitaciones = params.row?.gruposHabitaciones || [];
  //     const totalCamas = gruposHabitaciones.reduce((accumulator, currentGroup) => {
  //       currentGroup.habitaciones.forEach(habitacion => {
  //         accumulator += habitacion.numeroTotalCamas || 0;
  //       });
  //       return accumulator;
  //     }, 0);

  //     return totalCamas;
  //   }
  // },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   width: 130,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     <IconButton
  //       onClick={() => {
  //         setSeleItem(params.row);
  //         setOpenEdit(true);
  //       }}
  //     >
  //       <VisibilityIcon style={{ color:"var(--border-blue)" }}/>
  //     </IconButton>
  //   )
  // }
];


export const vistaPersonalHabitacionesColumns = (setOpen, setSeleItem, setOpenModalChange) => [
  {
    field: "numero",
    headerName: "N°",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.pabellon?.identificador
  },
  {
    field: "wing",
    headerName: "Wing",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.wing?.nombre
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenModalChange(true);
            }}
          >
            <VisibilityIcon style={{ color:"var(--border-blue)" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
  
];

export const planificacionEspecificaColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.nombre,
    renderCell: (params) => (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {params.value ?? ''}
      </div>
    ),
  },
  {
    field: "fecha",
    headerName: "Fecha",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha,
    renderCell: (params) => {
      try {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          return <div style={{ display: 'flex', flexWrap: 'wrap' }}></div>;
        } else {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {format(date, 'dd/MM/yyyy')}
            </div>
          );
        }
      } catch (error) {
        return <div style={{ display: 'flex', flexWrap: 'wrap' }}></div>;
      }
    },
  },
  {
    field: "hora",
    headerName: "Hora",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.fecha,
    renderCell: (params) => {
      try {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          return <div style={{ display: 'flex', flexWrap: 'wrap' }}></div>;
        } else {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {format(date, 'HH:mm')}
            </div>
          );
        }
      } catch (error) {
        console.error('Error rendering cell:', error);
        return <div style={{ display: 'flex', flexWrap: 'wrap' }}></div>;
      }
    },
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const row = params.row;
      if (row?.pabellon?.length > 0) {
        return row.pabellon[0]?.campamento?.nombre ?? "";
      } else if (row?.wing?.length > 0) {
        return row.wing[0]?.campamento?.nombre ?? "";
      } else if (row?.edificio?.length > 0) {
        return row.edificio[0]?.campamento?.nombre ?? "";
      }
      return "";
    },
    renderCell: (params) => (
      <Box
      sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.value}
      </Box>
    ),
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.wing?.[0]?.nombre ?? "",
    renderCell: (params) => (
      <Box
      sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.value}
      </Box>
    ),
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.map(pab => pab?.identificador).join(', ') ?? "",
    renderCell: (params) => (
      <Box
      sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.value}
      </Box>
    ),
  },
  {
    field: "edificio",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.edificio?.[0]?.nombre ?? "",
    renderCell: (params) => (
      <Box
      sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
        {params.value}
      </Box>
    ),
  },
  {
    field: "repetirPlanificacion",
    headerName: "Repetir",
    flex: 1,
    minWidth: 240,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.descripcion,
    renderCell: (params) => (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {params.value ?? ''}
      </div>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];

export const oficinaColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const pabellonCampamento = row.row?.pabellon?.campamento?.nombre;
      const wingCampamento = row.row?.wing?.campamento?.nombre;
      const edificioCampamento = row.row?.edificio?.campamento?.nombre;
      return pabellonCampamento || wingCampamento || edificioCampamento || "";
    },
    renderCell: (row) => row.value,
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.pabellon?.identificador ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.edificio?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numero",
    headerName: "Número",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "disponibilidad",
    headerName: "Disponibilidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "chapa",
    headerName: "Chapa",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>
        </div>
      );
    },
  },
];
export const oficinaRecepcionestaColumns = (setOpen, setSeleItem, setOpenEdit, setCheck) => [
  // {
  //   field: "campamento",
  //   headerName: "Campamento",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row.row?.campamento?.nombre,
  // },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.wing?.nombre,
    valueGetter: (params) => params.row.wing?.nombre || '',
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.pabellon?.identificador,
    valueGetter: (params) => params.row.pabellon?.identificador || '',
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.edificio?.nombre,
    valueGetter: (params) => params.row.edificio?.nombre || '',
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.piso || '',
  },
  {
    field: "numero",
    headerName: "Número",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.numero || '',
  },
  {
    field: "disponibilidad",
    headerName: "Disponibilidad",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      params.value === 'Ocupada' ? 
        <span style={{ 
          backgroundColor: '#bd1717', 
          color: 'white', 
          padding: '5px 10px', 
          borderRadius: '5px', 
          fontWeight: '400' 
        }}>
          {params.value}
        </span> : params.value === 'Disponible' ? 
        <span style={{ 
          backgroundColor: '#afbd22', 
          color: 'white', 
          padding: '5px 10px', 
          borderRadius: '5px', 
          fontWeight: '400' 
        }}>
          {params.value}
        </span> : 
        <span style={{ 
          backgroundColor: '#58595b', 
          color: 'white', 
          padding: '5px 10px', 
          borderRadius: '5px', 
          fontWeight: '400' 
        }}>
          {params.value}
        </span> 
    ),
    valueGetter: (params) => params.row.disponibilidad || '',
  },
  // {
  //     field: "nombre",
  //     headerName: "Nombre",
  //     flex:1,
  //     minWidth:230,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (row) => row.row?.ultimaReserva?.asignado?.nombre
  //   },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.ultimaReserva?.rut,
    valueGetter: (params) => params.row.ultimaReserva?.rut || '',
  },
  {
    field: "Hora_fecha_ocupación",
    headerName: "Inicio y término ocupación",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const inicioStr = params.row?.ultimaReserva?.fechaInicio;
      const terminoStr = params.row?.ultimaReserva?.fechaTermino;

      if (!inicioStr || !terminoStr) {
        return 'Fecha no disponible';
      }

      try {
        const inicio = parseISO(inicioStr);
        const termino = parseISO(terminoStr);
        return format(inicio, 'dd/MM/yyyy') + ' - ' + format(termino, 'dd/MM/yyyy');
      } catch (error) {
        console.error('Error al parsear o formatear las fechas:', error);
        return 'Fecha inválida';
      }
    },
    valueGetter: (params) => {
      const inicioStr = params.row?.ultimaReserva?.fechaInicio;
      const terminoStr = params.row?.ultimaReserva?.fechaTermino;
      if (!inicioStr || !terminoStr) {
        return 'Fecha no disponible';
      }
      try {
        const inicio = parseISO(inicioStr);
        const termino = parseISO(terminoStr);
        return format(inicio, 'dd/MM/yyyy') + ' - ' + format(termino, 'dd/MM/yyyy');
      } catch (error) {
        console.error('Error al parsear o formatear las fechas:', error);
        return 'Fecha inválida';
      }
    },
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 230,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || '',
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => <RenderCell4 row={params.row} setOpen={setOpen} setSeleItem={setSeleItem} setOpenEdit={setOpenEdit} setCheck={setCheck} />,
  },
];

export const gruposHabitacionesColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
            { params.row.nombre }
            </Box>
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const campamentos = params.row?.campamentos ?? [];
      return campamentos.length > 0
        ?  <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
            {campamentos.map((campamento) => campamento.nombre).join(', ')}
            </Box>
            : '----';
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "wingName",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const wings = params.row?.wings ?? [];
      return wings.length > 0
        ? wings.map((wing) => wing.identificador).join(', ')
        : '----';
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellones = params.row?.pabellones ?? [];
      return pabellones.length > 0
        ? pabellones.map((pabellon) => pabellon.identificador).join(', ')
        : '----';
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "recintoName",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const recintos = params.row?.recintos ?? [];
      return recintos.length > 0
        ? recintos.map((recinto) => recinto.nombre).join(', ')
        : '----';
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "cantidadHabitaciones",
    headerName: "N° Habitaciones",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const pabellones = params.row?.pabellones ?? [];
      const wings = params.row?.wings ?? [];
      const recintos = params.row?.recintos ?? [];
      return pabellones.length || wings.length || recintos.length || 0;
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "cantidadCamas",
    headerName: "N° Camas",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.camas ?? 0,
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];

export const oficinasColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
  
];
export const aseoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
  
];
export const planificacionDashboardColumns = (setOpen, setSeleItem, setAbrirEdit, setAbrirRepetir) => [
  {
    field: "franja.nombre",
    headerName: "Franja",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.horarioAseo?.nombre ?? '',
    renderCell: (params) => params.value,
  },
  {
    field: "desde",
    headerName: "Inicio",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const desde = parseISO(params.row?.desde);
      return format(desde, 'dd/MM/yyyy');
    },
    renderCell: (params) => {
      if (!params.row?.desde) {
        return '';
      }
      const desde = parseISO(params.row?.desde);
      return format(desde, 'dd/MM/yyyy');
    },
  },
  {
    field: "hasta",
    headerName: "Termino",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const hasta = parseISO(params.row?.hasta);
      return format(hasta, 'dd/MM/yyyy');
    },
    renderCell: (params) => {
      if (!params.row?.hasta) {
        return '';
      }
      const hasta = parseISO(params.row?.hasta);
      return format(hasta, 'dd/MM/yyyy');
    },
  },
  {
    field: "asignados",
    headerName: "Asignados",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "left",
    valueGetter: (params) => params.row?.trabajadores?.map(trabajador => `${trabajador.rut} (${trabajador.nombre})`).join(', ') ?? '',
    renderCell: (params) => (
      <div style={{ overflow: 'hidden' }}>
        {params.value}
      </div>
    ),
  },
];
export const habitacionesNoAsignadasColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row.pabellon) {
        return params.row.pabellon?.campamento?.nombre ?? '';
      } else if (params.row.wing) {
        return params.row.wing?.campamento?.nombre ?? '';
      } else if (params.row.edificio) {
        return params.row.edificio?.campamento?.nombre ?? '';
      }
      return '';
    },
    renderCell: (params) => params.value,
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row.pabellon?.identificador ?? '',
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row.wing?.nombre ?? '',
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row.recinto?.nombre ?? '',
  },
  {
    field: "numero",
    headerName: "Número",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "n_habitaciones_sin_asignacion",
  //   headerName: "N° Habitaciones",
  //   flex: 1,
  //   minWidth: 230,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (params) => {
  //     if (params.row.pabellon) {
  //       return params.row.pabellon?.numeroHabitaciones ?? '';
  //     } else if (params.row.wing) {
  //       return params.row.wing?.numeroHabitaciones ?? '';
  //     } else if (params.row.edificio) {
  //       return params.row.edificio?.numeroHabitaciones ?? '';
  //     }
  //     return '';
  //   },
  //   renderCell: (params) => params.value,
  // },
  {
    field: "numeroTotalCamas",
    headerName: "N° Camas",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
];

export const planificacionColumns = (setOpen, setSeleItem, setAbrirEdit, setAbrirRepetir) => [
  {
    field: "franja.nombre",
    headerName: "Franja",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.horarioAseo?.nombre,
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "desde",
    headerName: "Inicio",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const desde = params.row?.desde;
      const horaInicio = params.row?.horarioAseo?.horaInicio;
      if (!desde) {
        return '';
      }
      const fecha = format(new Date(desde), 'dd/MM/yyyy');
      return `${fecha} ${horaInicio}`;
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "hasta",
    headerName: "Termino",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const hasta = params.row?.hasta;
      const horaTermino = params.row?.horarioAseo?.horaTermino;
      if (!hasta) {
        return '';
      }
      const fecha = format(new Date(hasta), 'dd/MM/yyyy');
      return `${fecha} ${horaTermino}`;
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row?.gruposHabitaciones?.length > 0 && params.row.gruposHabitaciones[0]?.habitaciones?.length > 0) {
        const nombreCampamento = params.row.gruposHabitaciones[0].habitaciones[0]?.pabellon?.campamento?.nombre ||
          params.row.gruposHabitaciones[0].habitaciones[0]?.wing?.campamento?.nombre;
        return nombreCampamento ? nombreCampamento : '----';
      } else {
        return '----';
      }
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "wingName",
    headerName: "Wing",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row?.gruposHabitaciones?.length > 0) {
        const nombresWing = new Set(
          params.row.gruposHabitaciones
            .flatMap(grupo => grupo.habitaciones || [])
            .map(habitacion => habitacion.wing?.nombre)
            .filter(nombre => nombre !== undefined) // Filtrar nombres definidos
        );
        return nombresWing.size > 0 ? Array.from(nombresWing).join(', ') : '----';
      } else {
        return '----';
      }
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row?.gruposHabitaciones?.length > 0) {
        const numerosPabellon = new Set(
          params.row.gruposHabitaciones
            .flatMap(grupo => grupo.habitaciones || [])
            .map(habitacion => habitacion.pabellon?.identificador)
            .filter(identificador => identificador !== undefined)
        );
        return numerosPabellon.size > 0 ? Array.from(numerosPabellon).join(', ') : '----';
      } else {
        return '----';
      }
    },
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "recintoName",
    headerName: "Recinto",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (params.row?.gruposHabitaciones?.length > 0) {
        const nombresRecinto = new Set(
          params.row.gruposHabitaciones
            .flatMap(grupo => grupo.habitaciones || [])
            .map(habitacion => habitacion.recinto?.nombre)
            .filter(nombre => nombre !== undefined) // Filtrar nombres definidos
        );
        return nombresRecinto.size > 0 ? Array.from(nombresRecinto).join(', ') : '----';
      } else {
        return '----';
      }
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "habitaciones",
    headerName: "Total Habitaciones",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      if (!params.row?.gruposHabitaciones) {
        return 0;
      }
      const totalCamas = params.row.gruposHabitaciones.reduce((sum, item) => {
        if (!item.habitaciones) {
          return sum;
        }
        return sum + item.habitaciones.reduce((innerSum, habitacion) => innerSum + habitacion.numeroTotalCamas, 0);
      }, 0);
      return totalCamas;
    },
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "asignados",
    headerName: "Asignados",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.trabajadores,
    renderCell: (params) => 
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params.value?.length > 0 ? (
          params.value.map((item) => (
            <span key={item.rut}>
              {`${item.nombre} (${item.rut})`}
            </span>
          ))
        ) : (
          '----'
        )}
      </div>
  },
  {
    field: "tipoAseo",
    headerName: "Tipo aseo",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tipoAseo,
    renderCell: (params) => params.value || '----'
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <RenderCell
        row={params.row}
        setOpen={setOpen}
        setSeleItem={setSeleItem}
        setAbrirEdit={setAbrirEdit}
        setAbrirRepetir={setAbrirRepetir}
      />
    ),
  },
];

export const aseoDashboardColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreHuesped",
    headerName: "Nombre del Huésped",
    flex: 1,
    minWidth: 260,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const huesped = params.row?.huesped;
      const nombre = huesped?.nombre ?? '';
      const apellidoPaterno = huesped?.apellidoPaterno ?? '';
      const apellidoMaterno = huesped?.apellidoMaterno ?? '';
      return `${nombre} ${apellidoPaterno} ${apellidoMaterno}`;
    },
  },
  {
    field: "especialidad",
    headerName: "Especialidad",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.criticidadRequerimiento?.especialidad ?? '',
  },
  {
    field: "solicitud",
    headerName: "Solicitud",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.criticidadRequerimiento?.solicitud ?? '',
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "asignado",
    headerName: "Asignado",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row?.encargadoASeo ? `${params.row.encargadoASeo.nombre} ${params.row.encargadoASeo.apellido}` : '-'}
      </Box>
    ),
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color: "#f19d38" }} />
          </IconButton>
          {/*<IconButton
            onClick={() => {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color: "#d52a2a" }} />
          </IconButton>*/}
        </div>
      );
    },
  },
];

export const edificiosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  // {
  //   field: "id",
  //   headerName: "id",
  //   minWidth:60,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "identificador",
    headerName: "Identificador",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "generoHuesped",
    headerName: "Huesped",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sector.nombre",
    headerName: "Sector",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row.sector.nombre ? row.row.sector.nombre : '')
  },
  {
    field: "numero",
    headerName: "Numero",
    flex:1,
    minWidth:60,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroPisos",
    headerName: "N°Pisos",
    flex:1,
    minWidth:60,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroHabitaciones",
    headerName: "N°Habitaciones",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "standard.nombre",
    headerName: "Standard",
    flex:1,
    minWidth:60,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      row.row?.standard?.map((standard, index, array) => (
        <span key={standard.id}>
          {standard.nombre}{index < array.length - 1 ? ', ' : ' '}
        </span>
      ))
    )
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
  
];
export const habitacionGruposPlanificacionRepetirColumns = (setOpen, setSeleItem, setOpenEdit, handleDelete) => [
  // {
  //   field: "numero",
  //   headerName: "Numero",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
    
  // },
  // {
  //   field: "numeroTotalCamas",
  //   headerName: "N°Total Camas",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "desde",
    headerName: "Desde",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.desde ? format(row.row?.desde, 'dd-MM-yyyy') : '')
  },
  {
    field: "hasta",
    headerName: "Hasta",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.hasta ? format(row.row?.hasta, 'dd-MM-yyyy') : '')
  },
  {
    field: "grupo",
    headerName: "Grupo",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.grupo?.nombre ? row.row?.grupo?.nombre : '')
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      if (habitaciones && habitaciones.length > 0) {
        return habitaciones.reduce((total, habitacion) => total + habitacion.numeroTotalCamas, 0);
      } else {
        return '';
      }
    }
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      const pabellones = row.row?.grupo?.pabellones;
  
      const identificadoresPabellon = new Set();
  
      if (pabellones && pabellones.length > 0) {
        pabellones.forEach(pabellon => identificadoresPabellon.add(pabellon.identificador || pabellon.numero));
      }
  
      if (habitaciones && habitaciones.length > 0) {
        habitaciones.forEach(habitacion => {
          if (habitacion.pabellon) {
            identificadoresPabellon.add(habitacion.pabellon.numero || habitacion.pabellon.identificador);
          }
        });
      }
  
      return identificadoresPabellon.size > 0 ? 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      {Array.from(identificadoresPabellon).join(', ') }
      </Box>
      : '----';
    }
  },
  {
    field: "wingName",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      const wings = row.row?.grupo?.wings;
  
      const nombresWing = new Set();
  
      if (wings && wings.length > 0) {
        wings.forEach(wing => nombresWing.add(wing.nombre));
      }
  
      if (habitaciones && habitaciones.length > 0) {
        habitaciones.forEach(habitacion => {
          if (habitacion.wing) {
            nombresWing.add(habitacion.wing.nombre);
          }
        });
      }
  
      return nombresWing.size > 0 ? 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      {Array.from(nombresWing).join(', ') }
      </Box>
      : '----';
    }
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const habitaciones = row.row?.grupo?.habitaciones;
      if (habitaciones && habitaciones.length > 0) {
        return habitaciones[0]?.pabellon?.campamento?.nombre || '';
      } else if (row.row?.grupo?.campamentos) {
        return row.row?.grupo?.campamentos[0]?.nombre || '';
      } else {
        return '';
      }
    }
  },
  {
    field: "asignado",
    headerName: "Asignado",
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        {params.row?.ruts 
          ? params.row.ruts.map((rut, index) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
            <Typography key={index} variant="span">
              {rut}
            </Typography>
            </Box>
            ))
          : ''}
      </div>
    )
  },
    {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            {/*<IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>*/}
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon style={{ color:"#d52a2a" }}  onClick={()=>handleDelete(params.row?.id)}/>
            </IconButton>
          </div>
          
        );
      },
    },  
];
export const trabajadoresColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreCompleto",
    headerName: "Nombre",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "cargo",
  //   headerName: "Cargo",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
      renderCell: (params) => {
      return params.value ? "si" : "no"

    }
  },
];

const obtenerNombreCompleto = (trabajador) => {
  const nombres = trabajador.nombre ?? trabajador.nombres ?? '';
  const apellidoPaterno = trabajador.apellidoPaterno ?? (trabajador.apellidos ? trabajador.apellidos.split(' ')[0] : '');
  const apellidoMaterno = trabajador.apellidoMaterno ?? (trabajador.apellidos && trabajador.apellidos.split(' ').length > 1 ? trabajador.apellidos.split(' ')[1] : '');

  return `${nombres} ${apellidoPaterno} ${apellidoMaterno}`.trim();
};

export const trabajadoresResumenReservaColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.trabajador?.rut || 'N/A',
    renderCell: params => params.row.trabajador?.rut || 'N/A'
  },
   {
    field: "nombreCompleto",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const trabajador = params.row.trabajador;
      if (trabajador) {
        return obtenerNombreCompleto(trabajador);
      }
      return 'N/A';
    },
    renderCell: (params) => {
      const trabajador = params.row.trabajador;
      if (trabajador) {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          >
            {obtenerNombreCompleto(trabajador)}
          </Box>
        );
      }
      return 'N/A';
    },
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.turno?.descripcion || 'N/A',
    renderCell: params => params.row.turno?.descripcion || 'N/A'
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.jornada || 'N/A',
    renderCell: params => params.row.jornada || 'N/A'
  },
  {
    field: "ingreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.fechaIngreso ? format(new Date(params.row.fechaIngreso), 'dd-MM-yyyy') : '-',
    renderCell: params => params.row.fechaIngreso ? format(new Date(params.row.fechaIngreso), 'dd-MM-yyyy') : '-'
  },
  {
    field: "salida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.fechaSalida ? format(new Date(params.row.fechaSalida), 'dd-MM-yyyy') : '-',
    renderCell: params => params.row.fechaSalida ? format(new Date(params.row.fechaSalida), 'dd-MM-yyyy') : '-'
  },
];

export const trabajadoresAseoColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.rut,
  },
  {
    field: 'nombre',
    headerName: 'Nombre',
    flex: 1,
    minWidth: 160,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.row?.nombre,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: 'apellido',
    headerName: 'Apellidos',
    flex: 1,
    minWidth: 160,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.row?.apellido,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 200,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.row?.email,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.cargo,
  },
  {
    field: "enFaena",
    headerName: "En faena",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => (params.row?.enFaena ? 'Sí' : 'No'),
    renderCell: (params) => params.row?.enFaena ? 'Si' : 'No',
  },
  {
    field: "suspendido",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => (params.row?.suspendido ? 'Suspendido' : 'Activo'),
    renderCell: (params) => params.row?.suspendido ? 'Suspendido' : 'Activo',
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
     renderCell: (params) => {
      return (
        <div>
         {/* <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>*/}
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            {/*<DeleteIcon style={{ color:"#d52a2a" }}/>*/}
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
];
export const trabajadoresAseoCreateColumns = (setOpen, setSeleItem, handleDeleteItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.rut
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:180,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >{params?.value}</Box>
  },
  {
    field: "email",
    headerName: "Email",
    flex:1,
    minWidth:180,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.email
  },
  {
    field: "nCargo",
    headerName: "Cargo",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.nCargo
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex:1,
    minWidth:100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              handleDeleteItem(params.row.rut)
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
];
export const reservaEsedColumns = (setOpen, setSeleItem2, setOpenEdit, setOpenModal) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row?.row?.fechaSolicitud ? format(row?.row?.fechaSolicitud, 'dd-MM-yyyy'): '',
    renderCell: row => row?.row?.fechaSolicitud ? format(row?.row?.fechaSolicitud, 'dd-MM-yyyy'): ''
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'center' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'center' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "sI_Area",
    headerName: "Si-Area",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'center' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => params.row?.empresa?.razonSocial || '--',
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "trabajadoresDia",
  //   headerName: "N°Trabajadores día",
  //   flex: 1,
  //   minWidth: 160,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "trabajadoresNoche",
  //   headerName: "N°Trabajadores noche",
  //   flex: 1,
  //   minWidth: 180,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "trabajadoresTotales",
    headerName: "N°Trabajadores totales",
    flex: 1,
    minWidth: 180,
    headerAlign: "center",
    align: "center",
  },
  {
  field: "estado",
  headerName: "Estado",
  flex: 1,
  minWidth: 120,
  headerAlign: "center",
  align: "center",
  valueGetter: (params) => params.value,
  renderCell: (params) => {
    let color;
    switch (params.value) {
      case 'Programada':
        color = 'green';
        break;
      case 'Pendiente':
        color = '#0086ae';
        break;
      case 'Rechazada':
        color = '#bd1717';
        break;
      default:
        color = 'gray';
    }
    
    const badgeStyle = {
      backgroundColor: color,
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      textAlign: 'center',
    };
    
    return (
      <div style={badgeStyle}>
        {params.value}
      </div>
    );
  },
},
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <RenderCell5 row={params.row} setOpen={setOpen} setSeleItem={setSeleItem2} setOpenEdit={setOpenEdit} setOpenModal={setOpenModal}/>
    ),
  },
];

const CustomCell = ({ selectedNameByRuts }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {selectedNameByRuts?.length > 0 ? (
        selectedNameByRuts.map((item) => (
          <span key={item.rut}>
            {`${item.nombre} (${item.rut})`}
          </span>
        ))
      ) : (
        '----'
      )}
    </div>
  );
};

export const habitacionGruposPlanificacionColumns = (setOpen, setSeleItem, setOpenEdit, selectedNameByRuts, handleDelete ) => [
  // {
  //   field: "numero",
  //   headerName: "Numero",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
    
  // },
  // {
  //   field: "numeroTotalCamas",
  //   headerName: "N°Total Camas",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "grupo",
    headerName: "Grupo",
    minWidth:150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.grupo?.nombre ? row.row?.grupo?.nombre : '')
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth:150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.grupo?.camas ? row.row?.grupo?.camas : '')
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <>
          {row.row?.grupo?.pabellones?.length > 0 ? (
            <span>{row.row.grupo.pabellones[0].identificador}</span>
          ) : (
            '----'
          )}
        </>
      );
    },    
  },
  {
    field: "wingName",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <>
          {row.row?.grupo?.wing?.nombre ? (
            <span>{row.row.grupo.wing.nombre}</span>
          ) : (
            '----'
          )}
        </>
      );
    },
  },
  {
    field: "campamentoName",
    headerName: "Campamento",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <>
          {row.row?.grupo?.campamentos?.length > 0 ? (
            row.row.grupo.campamentos.map((campamento) => (
              <span key={campamento.id}>{campamento.nombre}, </span>
            ))
          ) : (
            '----'
          )}
        </>
      );
    },    
  },
  {
    field: 'asignado',
    headerName: 'Asignado',
    minWidth:500,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => <CustomCell selectedNameByRuts={selectedNameByRuts} />,
  },
  // {
  //   field: "asignado",
  //   headerName: "Asignado",
  //   minWidth: 300,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     return (
  //       <>
  //         {selectedNameByRuts?.length > 0 ? (
  //           selectedNameByRuts.map((item) => (
  //             <p key={item.rut}>
  //               {`${item.nombre} (${item.rut})`}
  //               <br />
  //             </p>
  //           ))
  //         ) : (
  //           '----'
  //         )}
  //       </>
  //     );
  //   },    
  // },
    {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            {/*<IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>*/}
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon style={{ color:"#d52a2a" }} onClick={()=>handleDelete(params.row?.id)}/>
            </IconButton>
          </div>
          
        );
      },
    },  
];
export const habitacionGruposColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "numero",
    headerName: "Numero",
    minWidth:150,
    headerAlign: "center",
    align: "center",
    
  },
  {
    field: "n_camas",
    headerName: "N°Total Camas",
    minWidth:150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pabellonName",
    headerName: "Pabellón",
    minWidth:150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.pabellon?.identificador ? row.row?.pabellon?.identificador : '')
  },
  {
    field: "wingName",
    headerName: "Wing",
    minWidth:150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.wing?.nombre ? row.row?.wing?.nombre : '')
  },
  {
    field: "recintoName",
    headerName: "Recinto",
    minWidth:150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.recinto?.nombre ? row.row?.recinto?.nombre : '')
  },
  // {
  //   field: "camapamentoName",
  //   headerName: "Campamento",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => (row.row?.pabellon?.campamento.nombre ? row.row?.pabellon?.campamento?.nombre : row.row?.wing?.campamento?.nombre)
  // },
  {
    field: "grupo",
    headerName: "Grupo asignado",
    minWidth:150,
    headerAlign: "center",
    align: "center",
  },
];
export const habitacionColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "pabellon",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.pabellon?.identificador ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "wing",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "numero",
    headerName: "Número",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "idChapa",
    headerName: "ID chapa",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.idChapa ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoCama",
    headerName: "Tipo cama",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamarotes",
    headerName: "N°Camarotes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 30,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoHuesped",
    headerName: "Huesped",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 620,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "productos",
    headerName: "Artículos",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.productos?.map(prod => prod.nombre).join(', ') ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno
        ? <CheckIcon style={{ color: 'green' }} />
        : <DangerousIcon style={{ color: 'red' }} />;
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const motivosColumns = (setOpen, setSeleItem) => [
    // {
    //   field: "id",
    //   headerName: "id",
    //   flex:1,
    //   minWidth:120,
    //   headerAlign: "center",
    //   align: "center",
      
    // },
    {
      field: "tipoRequerimiento",
      headerName: "Tipo de requerimiento",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prioridad",
      headerName: "Prioridad",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
];
export const aprobacionColumns = (setSeleItem, setOpenEdit) => [
  // {
  //   field: "fechaSolicitud",
  //   headerName: "Fecha Solicitud",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     const fecha = new Date(row.row.fechaSolicitud);
  //     const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
  //     return (
  //       <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //         {fechaFormateada}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   field: "fechaIngreso",
  //   headerName: "Ingreso",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     const fecha = new Date(row.row.fechaIngreso);
  //     const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
  //     return (
  //       <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //         {fechaFormateada}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   field: "fechaSalida",
  //   headerName: "Salida",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     const fecha = new Date(row.row.fechaSalida);
  //     const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
  //     return (
  //       <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
  //         {fechaFormateada}
  //       </div>
  //     );
  //   }
  // },  
  {
    field: "usuario",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
          { row.row?.usuario ? row.row?.usuario.nombre + ' ' + row.row?.usuario.apellido : '' }
        </div>
      );
    }
  },
  {
    field: "emailSolicitante",
    headerName: "Email",
    flex:1,
    minWidth:260,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
          { row.row?.usuario ? row.row?.usuario.email : '' }
        </div>
      );
    }
  },  
  {
    field: "empresa",
    headerName: "Empresa",
    flex:1,
    minWidth:260,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
          { row.row?.empresa ? row.row?.empresa.razonSocial : '' }
        </div>
      );
    }
  },
   {
    field: "estado",
    headerName: "Estado",
    flex:1,
    minWidth:150,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: row => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {row.row.estado}
        </div>
  },
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <VisibilityIcon style={{ color:"var(--border-blue)" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const feedbackColumns = (setOpen, setSeleItem, setOpenEdit, setOpenHistorico) => [
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.empresa?.razonSocial,
    renderCell: (params) => params.row?.empresa?.razonSocial
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.contrato).join(', '),
    renderCell: (params) => params.row?.tramos?.map((item, index, array) => (
      <span key={index}>
        {item.contrato}{index < array.length - 1 ? ', ' : ''}
      </span>
    ))
  },
  {
    field: "trabajadoresDia",
    headerName: "Total Trabajadores Día",
    minWidth: 180,
    flex: 1,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresDia, 10), 0),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresDia, 10), 0);
      return sum;
    }
  },
  {
    field: "trabajadoresNoche",
    headerName: "Total Trabajadores Noche",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresNoche, 10), 0),
    renderCell: (params) => {
      const sum = params.row?.tramos?.reduce((acc, item) => acc + parseInt(item.trabajadoresNoche, 10), 0);
      return sum;
    }
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const fechasDesde = params.row?.tramos?.map(item => item.fechaDesde && parseISO(item.fechaDesde)).filter(Boolean);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, 'dd/MM/yyyy') : '';
    },
    renderCell: (params) => {
      const fechasDesde = params.row?.tramos?.map(item => item.fechaDesde && parseISO(item.fechaDesde)).filter(Boolean);
      const fechaMinima = fechasDesde.length > 0 ? min(fechasDesde) : null;
      return fechaMinima ? format(fechaMinima, 'dd/MM/yyyy') : '';
    }
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const fechasHasta = params.row?.tramos?.map(item => item.fechaHasta && parseISO(item.fechaHasta)).filter(Boolean);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, 'dd/MM/yyyy') : '';
    },
    renderCell: (params) => {
      const fechasHasta = params.row?.tramos?.map(item => item.fechaHasta && parseISO(item.fechaHasta)).filter(Boolean);
      const fechaMaxima = fechasHasta.length > 0 ? max(fechasHasta) : null;
      return fechaMaxima ? format(fechaMaxima, 'dd/MM/yyyy') : '';
    }
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.vicepresidencia).join(', '),
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>
            {item.vicepresidencia}
          </span>
        ))}
      </div>
    )
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.gerencia).join(', '),
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params.row?.tramos?.map((item, index) => (
          <span key={index}>
            {item.gerencia}
          </span>
        ))}
      </div>
    )
  },
  {
    field: "siArea",
    headerName: "SI Área",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tramos?.map(item => item.siArea).join(', '),
    renderCell: (params) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {params?.row?.tramos?.map((item, index) => (
          <span key={index}>
            {item.siArea}
          </span>
        ))}
      </div>
    )
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado,
    renderCell: (params) => params.row?.estado
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "var(--border-orange)" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenHistorico(true);
          }}
        >
          <VisibilityIcon style={{ color: "var(--border-blue)" }} />
        </IconButton>
      </div>
    ),
  },
];

export const reservasColumns = (setOpen, setSeleItem) => [
    {
      field: "rut",
      headerName: "rut",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nombre",
      headerName: "nombre",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "descripcion",
      headerName: "turno",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jornada",
      headerName: "Jornada",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fechaIngreso",
      headerName: "Fecha de ingreso",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fechaSalida",
      headerName: "Fecha de salida",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    }
];
export const reservasEsedColumns = (setOpen, setSeleItem, handleDeleteItem) => [
  {
    field: "rut",
    headerName: "rut",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreCompleto",
    headerName: "nombre",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >{ row.row?.nombreCompleto ?  row.row?.nombreCompleto : row.row?.nombres ? row.row?.nombres : row.row?.nombre}
      </Box>
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =><Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >{ row.row?.apellidos ? row.row?.apellidos : row.row?.apellido ? row.row?.apellido : '--'}
      </Box>
  },
  {
    field: "turno",
    headerName: "turno",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.turno?.descripcion
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =><Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >{ row.row?.cargo?.nCargo ? row.row?.cargo?.nCargo : row.row?.cargo  ? row.row?.cargo : ''}
      </Box>
  },
  {
    field: "llegada",
    headerName: "Fecha de ingreso",
    flex:1,
    minWidth:160,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      const options = { timeZone: 'UTC' }; 
      return fecha.toLocaleDateString('es-ES', options); 
    },
  },
  {
    field: "salida",
    headerName: "Fecha de salida",
    flex:1,
    minWidth:160,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      const options = { timeZone: 'UTC' }; 
      return fecha.toLocaleDateString('es-ES', options); 
    },
  },
  {
    field: "genero",
    headerName: "Género",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "telefono",
    headerName: "Teléfono",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              // setSeleItem(params.row);
              // setOpen(true);
              handleDeleteItem(params.row.rut, params.row.nombreCompleto ?? params.row.nombres )
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  }
];
export const espaciosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "pabellonName",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.identificador ?? "",
    renderCell: (params) => params.value,
  },
  {
    field: "wingName",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.wing?.nombre ?? "",
    renderCell: (params) => params.value,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const wingColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.campamento?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.map((standard) => standard.nombre).join(', ') ?? "",
    renderCell: (row) =>
      row.row?.standard?.map((standard, index, array) => (
        <span key={standard.id}>
          {standard.nombre}
          {index < array.length - 1 ? ', ' : ' '}
        </span>
      )),
  },
  {
    field: "numeroHabitaciones",
    headerName: "N°Habitaciones",
    flex: 1,
    minWidth: 140,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "generoHuesped",
    headerName: "Huesped",
    flex: 1,
    minWidth: 160,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroPisos",
    headerName: "N°Pisos",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenEdit(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <DeleteIcon style={{ color: "#d52a2a" }} />
        </IconButton>
      </div>
    ),
  },
];
export const otrosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "campamentoName",
    headerName: "Campamento",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroPisos",
    headerName: "N°Pisos",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon style={{ color:"#d52a2a" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const huespedColumns = (setOpen, setSeleItem, setOpenEdit) => [
    {
      field: "rut",
      headerName: "Rut",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      minWidth:150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "apellido",
      headerName: "apellido",
      flex: 1,
      minWidth:150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Correo",
      flex: 1,
      minWidth:150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cargo",
      headerName: "Cargo",
      flex: 1,
      minWidth:150,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "genero",
    //   headerName: "Género",
    //   flex: 1,
    //   minWidth:120,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return params.value==1 ? "Femenino" : "Masculino"
  
    //   }
    // },
    // {
    //   field: "opciones",
    //   headerName: "Opciones",
    //   width: 100,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <IconButton
    //           onClick={function () {
    //             setSeleItem(params.row);
    //             setOpenEdit(true);
    //           }}
    //         >
    //           <EditIcon style={{ color:"#f19d38" }}/>
    //         </IconButton>
    //       </div>
          
    //     );
    //   },
    // },
];
export const usersColumns = (setOpen, setSeleItem, setOpenEdit) => [
    {
      field: "rut",
      headerName: "Rut",
      flex:1,
      minWidth:120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      minWidth:250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "apellido",
      headerName: "apellido",
      flex: 1,
      minWidth:250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Correo",
      flex: 1,
      minWidth:300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cargo",
      headerName: "Cargo",
      flex: 1,
      minWidth:250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rol",
      headerName: "Rol",
      flex: 1,
      minWidth:320,
      headerAlign: "center",
      align: "center",
      renderCell: (row) => (
        <Box display="flex" flexDirection="column">
        {row.row?.roles?.map((role, index) => (
          <span key={index}>
            {role}
          </span>
        ))}
      </Box>
      )
    },
    {
      field: "opciones",
      headerName: "Opciones",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const responsablesColumns = (setOpen, setSeleItem, setOpenEdit) => [
  // {
  //   field: "id",
  //   headerName: "id",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
    
  // },
  {
    field: "problema",
    headerName: "Problema",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "correo",
    headerName: "Correo",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpenEdit(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <DeleteIcon style={{ color:"#d52a2a" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
];
export const inventarioColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "ordenDeCompra",
    headerName: "Orden",
    flex:1,
    minWidth:80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "guiaDeDespacho",
    headerName: "Guía",
    flex:1,
    minWidth:80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sku",
    headerName: "SKU",
    flex:1,
    minWidth:80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreProveedor",
    headerName: "Proveedor",
    flex:1,
    minWidth:100,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaDeRegistro",
    headerName: "Fecha Registro",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      return fecha.toLocaleDateString();
    },
  },
  {
    field: "fechaDeExpiracion",
    headerName: "Fecha Expiración",
    flex:1,
    minWidth:140,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      const fecha = new Date(params.value);
      return fecha.toLocaleDateString();
    },
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "marca",
    headerName: "Marca",
    flex:1,
    minWidth:60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "modelo",
    headerName: "Modelo",
    flex:1,
    minWidth:80,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "stockMinimo",
    headerName: "Stock Mínimo",
    flex:1,
    minWidth:100,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "stockActual",
    headerName: "Stock Actual",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
 {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth:100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.value ? "Activado" : "Desactivado"
    }
  },
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon style={{ color:"#d52a2a" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const tomaInventarioColumns = (setOpen, setSeleItem) => [
  {
    field: "bodega",
    headerName: "Bodega",
    flex: 1,
    minWidth: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.bodega?.nombre ?? '',
    valueGetter: (params) => params.row.bodega?.nombre ?? '',
  },
  {
    field: "cantidad_articulos",
    headerName: "N° Artículos",
    flex: 1,
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const tomaInventarioArticulos = row.row?.tomaInventarioArticulos;
      return tomaInventarioArticulos ? tomaInventarioArticulos.length : '';
    },
    valueGetter: (params) => {
      const tomaInventarioArticulos = params.row.tomaInventarioArticulos;
      return tomaInventarioArticulos ? tomaInventarioArticulos.length : '';
    },
  },
  {
    field: "total_perdida",
    headerName: "Pérdida total",
    flex: 1,
    minWidth: 320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.montoTotal - row.row?.montoTotalTomaInventario >= 0
        ? row.row?.montoTotal - row.row?.montoTotalTomaInventario
        : 0,
    valueGetter: (params) =>
      params.row.montoTotal - params.row.montoTotalTomaInventario >= 0
        ? params.row.montoTotal - params.row.montoTotalTomaInventario
        : 0,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <IconButton
        onClick={() => {
          setSeleItem(params.row);
          setOpen(true);
        }}
      >
        <AddCircleOutlineIcon style={{ color: "#FFF" }} />
      </IconButton>
    ),
  },
];
export const articulosTomaColumns = (setOpen, setSeleItem, setOpenEdit, setChangeState) => [
  {
    field: "articulo",
    headerName: "Árticulo",
    flex:1,
    minWidth:130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.descripcion
  },
  {
    field: "cantidadInventariada",
    headerName: "Cantidad Inventariada",
    flex:1,
    minWidth:200,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "stockMinimo",
    headerName: "Stock",
    flex:1,
    minWidth:320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: row => row.row?.producto?.stockMinimo
  },
];
export const articulosColumns = (setOpen, setSeleItem, setOpenEdit, setChangeState) => [
  {
    field: "codigo",
    headerName: "Código",
    flex: 1,
    minWidth: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.codigo || '',
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.nombre || '',
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || '',  
  },
  {
    field: "tiempoDeReposicion",
    headerName: "Días promedio de reposición",
    flex: 1,
    minWidth: 240,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tiempoDeReposicion || '',
  },
  {
    field: "duracionMeses",
    headerName: "Duración (meses)",
    flex: 1,
    minWidth: 160,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.duracionMeses || '',
  },
  {
    field: "stockMinimo",
    headerName: "Stock Mínimo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.stockMinimo || '',
  },
  {
    field: "categoria",
    headerName: "Categoría",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.categoria || '',
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const estado = params.row.estado ? 'Activado' : 'Desactivado';
      return estado;
    },
    renderCell: (params) => {
      const estado = params.row.estado ? 'Activado' : 'Desactivado';
      const backgroundColor = params.row.estado ? '#afbd22' : '#bd1717';
      return (
        <Box sx={{
          backgroundColor: backgroundColor,
          color: '#FFF',
          padding: '5px',
          borderRadius: '8px',
          display: 'inline-block'
        }}>
          {estado}
        </Box>
      );
    },
  },
  {
    field: "opciones",
    headerName: "Opciones",
    width: 130,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <>
      <IconButton
        onClick={() => {
          setSeleItem(params.row);
          setOpenEdit(true);
        }}
      >
        <EditIcon style={{ color: "#f19d38" }} />
      </IconButton>
      <IconButton
        onClick={() => {
          setSeleItem(params.row);
          setOpen(true);
        }}
      >
        <DeleteIcon style={{ color: "#d52a2a" }} />
      </IconButton>
      </>
    ),
  },
];
export const solicitudesColumns = (setOpen, setSeleItem, setOpenDetalle, setValue) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSolicitud);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    }
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaIngreso);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    }
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSalida);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    }
  },  
  {
    field: "estado",
    headerName: "Estado",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row.nombreSolicitante;
      const apellido = row.row.apellidoSolicitante;
      const nombreCompleto = `${nombre} ${apellido}`;
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {nombreCompleto}
        </div>
      );
    }
  },
  {
    field: "emailSolicitante",
    headerName: "Email",
    flex:1,
    minWidth:260,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenDetalle(true);
                setValue(1);
              }}
            >
              <AddCircleOutlineIcon style={{ color:"#174300" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const historialSolicitudesColumns = (setOpen, setSeleItem, setOpenDetalle, setValue) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSolicitud);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    }
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaIngreso);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    }
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSalida);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    }
  },  
  {
    field: "estado",
    headerName: "Estado",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
 {
  field: "nombreSolicitante",
  headerName: "Solicitante",
  flex: 1,
  minWidth: 150,
  headerAlign: "center",
  align: "center",
  renderCell: (row) => {
    const nombre = row.row.nombreSolicitante;
    const apellido = row.row.apellidoSolicitante;
    const nombreCompleto = `${nombre} ${apellido}`;
    return (
      <div style={{ maxWidth: 200, whiteSpace: 'normal', wordBreak: 'break-word' }}>
        {nombreCompleto}
      </div>
    );
  }
},
  {
  field: "empresa",
  headerName: "Empresa",
  flex: 1,
  minWidth: 260,
  sortable: false,
  headerAlign: "center",
  align: "center",
  renderCell: (row) => {
    const razonSocial = row.row?.empresa?.razonSocial ?? '--';
    return (
      <div style={{ maxWidth: 260, whiteSpace: 'normal', wordBreak: 'break-word' }}>
        {razonSocial}
      </div>
    );
  }
},
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenDetalle(true);
                setValue(1);
              }}
            >
              <AddCircleOutlineIcon style={{ color:"#174300" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const requerimientosColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "especialidad",
    headerName: "Especialidad",
    flex:1,
    minWidth:200,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "solicitud",
    headerName: "Solicitud",
    flex:1,
    minWidth:400,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "prioridad",
    headerName: "Prioridad",
    flex:1,
    minWidth:60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
          <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpenEdit(true);
              }}
            >
              <EditIcon style={{ color:"#f19d38" }}/>
            </IconButton>
            <IconButton
              onClick={function () {
                setSeleItem(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon style={{ color:"#d52a2a" }}/>
            </IconButton>
          </div>
          
        );
      },
    },
];
export const trabajadoresReservaColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadorCMDIC",
    headerName: "Nombre",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
        return (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {
            row.row?.trabajadorCMDIC ? row.row?.trabajadorCMDIC?.nombre + ' '+ row.row?.trabajadorCMDIC?.apellidoPaterno + ' ' + row.row?.trabajadorCMDIC?.apellidoMaterno : row.row?.trabajadorESED ? row.row?.trabajadorESED?.nombre + ' '+ row.row?.trabajadorESED?.apellidoPaterno + ' ' + row.row?.trabajadorESED?.apellidoMaterno : ''
          }  
      </div>
        );
    }
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.turno.descripcion)
  },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {row.row?.trabajadorCMDIC ? row.row?.trabajadorCMDIC?.acreditado ? 'si':'no' : row.row?.trabajadorESED ? row.row?.trabajadorESED?.acreditado ? 'si' : 'no' : '' }
    </div>
      );
  }
  },
];
export const habitacionReservasColumns = (setOpen, setSeleItem) => [
  {
    field: "opciones",
    headerName: "Opciones",
    flex:1,
    minWidth:120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={function () {
              setSeleItem(params.row);
              setOpen(true);
            }}
          >
            <EditIcon style={{ color:"#f19d38" }}/>
          </IconButton>
        </div>
        
      );
    },
  },
  // {
  //   field: "pabellonName",
  //   headerName: "Pabellón",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
    
  // },
  // {
  //   field: "wingName",
  //   headerName: "Wing",
  //   minWidth:150,
  //   headerAlign: "center",
  //   align: "center",
    
  // },
  {
    field: "numero",
    headerName: "Numero",
    minWidth:40,
    headerAlign: "center",
    align: "center",
    
  },
  {
    field: "piso",
    headerName: "Piso",
    minWidth:20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth:60,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamarotes",
    headerName: "N°camarotes",
    minWidth:60,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth:40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoHuesped",
    headerName: "Huesped",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "standard.nombre",
    headerName: "Standard",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => ( row.row.standard.nombre )
  },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno == true ? <CheckIcon style={{ color:'green' }}/>: <DangerousIcon style={{ color:'red' }}/>;
    }
  },
];
export const vistaPreviaColumns = (setOpen, setSeleItem) => [
  
  {
    field: "trabajador.rut",
    headerName: "Rut",
    minWidth:60,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      params.row.trabajador.rut
    )
  },
  {
    field: "trabajador.nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      params.row.trabajador.nombre
    )
  },
  {
    field: "trabajador.acreditado",
    headerName: "Acreditación vigente",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      params.row.trabajador.acreditado ? 'si':'no'
    )
  },
  {
    field: "habitacion.numero",
    headerName: "N°Habitacion",
    flex:1,
    minWidth:200,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      params.row.habitacion.numeroHabitacion
    )
  },
  // {
  //   field: "habitacion.piso",
  //   headerName: "Piso",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     params.row.habitacion.piso
  //   )
  // },
  // {
  //   field: "habitacion.pabellonId",
  //   headerName: "Wing/Pabellón",
  //   flex:1,
  //   minWidth:200,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     params.row.habitacion.pabellonId
  //   )
  // },
  
];
const getBadgeColor = (estado) => {
  switch (estado) {
    case 'Pendiente':
      return 'default';
    case 'Aprobada':
      return 'success';
    case 'Rechazada':
      return 'error';
    case 'Programada':
      return 'primary';
    default:
      return 'default';
  }
};
export const estadoReservasColumns = (setOpen, setSeleItem) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "fechaSolicitud",
    headerName: "Fecha Solicitud",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSolicitud);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    },
    valueGetter: (params) => {
      const fecha = new Date(params.row.fechaSolicitud);
      return fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
    },
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaIngreso);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    },
    valueGetter: (params) => {
      const fecha = new Date(params.row.fechaIngreso);
      return fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
    },
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const fecha = new Date(row.row.fechaSalida);
      const fechaFormateada = fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return (
        <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {fechaFormateada}
        </div>
      );
    },
    valueGetter: (params) => {
      const fecha = new Date(params.row.fechaSalida);
      return fecha.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
    },
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      const estado = params.value;
      return (
        <Chip
          label={estado}
          color={getBadgeColor(estado)}
          style={{ fontWeight: '500' }}
        />
      );
    },
    valueGetter: (params) => params.row.estado || '',
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const nombre = row.row.nombreSolicitante;
      const apellido = row.row.apellidoSolicitante;
      const nombreCompleto = `${nombre} ${apellido}`;
      return (
        <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {nombreCompleto}
        </div>
      );
    },
    valueGetter: (params) => {
      const nombre = params.row.nombreSolicitante || '';
      const apellido = params.row.apellidoSolicitante || '';
      return `${nombre} ${apellido}`;
    },
  },
  {
    field: "emailSolicitante",
    headerName: "Email",
    flex: 1,
    minWidth: 260,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
        <div style={{ maxWidth: 260, whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {row.row.emailSolicitante}
        </div>
      );
    },
    valueGetter: (params) => params.row.emailSolicitante || '',
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "#0086ae" }} />
        </IconButton>
      );
    },
  },
];

export const trabajadoresEstadoReservaColumns = () => [
  {
    field: "rut",
    headerName: "Rut",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadorCMDIC",
    headerName: "Nombre",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
        return (
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {
                  row.row?.trabajadorCMDIC ? row.row?.trabajadorCMDIC?.nombre + ' '+ row.row?.trabajadorCMDIC?.apellidoPaterno + ' ' + row.row?.trabajadorCMDIC?.apellidoMaterno : row.row?.trabajadorESED ? row.row?.trabajadorESED?.nombre + ' '+ row.row?.trabajadorESED?.apellidoPaterno + ' ' + row.row?.trabajadorESED?.apellidoMaterno : ''
                }  
            </div>
        );
    }
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.turno?.descripcion)
  },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row.row?.trabajadorCMDIC ? row.row?.trabajadorCMDIC?.acreditado ? 'si':'no' : row.row?.trabajadorESED ? row.row?.trabajadorESED?.acreditado ? 'si' : 'no' : '' }
          </div>
      );
  }
  },
];

export const reclamosSugerenciasHuespedColumns = (setOpen, setSeleItem) => [
  {
    field: "especialidad",
    headerName: "Especialidad",
    flex: 1,
    minWidth: 160,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.criticidadRequerimiento?.especialidad ?? 'N/A',
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {params.value}
        </Box>
      );
    }
  },
  {
    field: "solicitud",
    headerName: "Solicitud",
    flex: 1,
    minWidth: 10,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.criticidadRequerimiento?.solicitud ?? 'N/A',
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {params.value}
        </Box>
      );
    }
  },
  // {
  //   field: "prioridad",
  //   headerName: "Prioridad",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (params) => params.row?.criticidadRequerimiento?.prioridad ?? 'N/A',
  //   renderCell: (params) => params.value,
  // },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.descripcion ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.tipo ?? 'N/A',
    renderCell: (params) => params.value,
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <VisibilityIcon style={{ color: "#1b401d" }} />
        </IconButton>
      );
    },
  },
];
export const reclamosSugerenciasRecepcionistaColumns = (setOpen, setSeleItem, setOpenShow) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 60,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.id,
  },
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) =>
      row.row?.habitacion?.pabellon?.campamento?.nombre ||
      row.row?.habitacion?.wing?.campamento?.nombre ||
      row.row?.habitacion?.edificio?.campamento?.nombre ||
      '',
    valueGetter: (params) =>
      params.row.habitacion?.pabellon?.campamento?.nombre ||
      params.row.habitacion?.wing?.campamento?.nombre ||
      params.row.habitacion?.edificio?.campamento?.nombre ||
      '',
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.habitacion?.wing?.nombre || '',
    valueGetter: (params) => params.row.habitacion?.wing?.nombre || '',
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.habitacion?.pabellon?.identificador || '',
    valueGetter: (params) => params.row.habitacion?.pabellon?.identificador || '',
  },
  {
    field: "edificio",
    headerName: "Edificio",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.habitacion?.edificio?.nombre || '',
    valueGetter: (params) => params.row.habitacion?.edificio?.nombre || '',
  },
  {
    field: "criticidadRequerimiento.solicitud",
    headerName: "Solicitud",
    flex: 1,
    minWidth: 320,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.criticidadRequerimiento?.solicitud || ''}
      </Box>,
    valueGetter: (params) => params.row.criticidadRequerimiento?.solicitud || '', 
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row.row?.habitacion?.numero || '',
    valueGetter: (params) => params.row.habitacion?.numero || '',
  },
  {
    field: "huesped",
    headerName: "Usuario",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        {(row.row?.huesped?.nombre || '') +
         ' ' +
         (row.row?.huesped?.apellidoPaterno || '') +
         ' ' +
         (row.row?.huesped?.apellidoMaterno || '')}
      </div>
    ),
    valueGetter: (params) =>
      `${params.row.huesped?.nombre || ''} ${params.row.huesped?.apellidoPaterno || ''} ${params.row.huesped?.apellidoMaterno || ''}`,
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.descripcion || '',
    renderCell: (params) => <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row.descripcion || ''}
      </Box>,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.estado || '',
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.tipo || '',
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
      <>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpenShow(true);
          }}
        >
          <VisibilityIcon style={{ color: "#FFF" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <EditIcon style={{ color: "#f19d38" }} />
        </IconButton>
        </>
      );
    },
  },
];

export const tableOficinasDashboardRecepcionistaColumns = (setOpen, setSeleItem) => [
  {
    field: "campamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return (
        params.row?.campamento?.nombre ||
        params.row?.pabellon?.campamento?.nombre ||
        params.row?.wing?.campamento?.nombre ||
        params.row?.edificio?.campamento?.nombre ||
        'N/A'
      );
    },
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.wing?.nombre || 'N/A',
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.pabellon?.identificador || 'N/A',
  },
  {
    field: "recinto",
    headerName: "Recinto",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.recinto?.nombre || 'N/A',
  },
  {
    field: "totalOficinas",
    headerName: "Oficinas totales",
    flex: 1,
    minWidth: 220,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalOficinasDisponibles",
    headerName: "Oficinas disponibles",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
    // {
  //     field: "opciones",
  //     headerName: "Opciones",
  //     flex:1,
  //     minWidth:120,
  //     sortable: false,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params) => {
  //       return (
  //           <IconButton
  //             onClick={function () {
  //               setSeleItem(params.row);
  //               setOpen(true);
  //             }}
  //           >
  //             <EditIcon style={{ color:"#f19d38" }}/>
  //           </IconButton>
  //       )
  //     },
  //   },
];

export const solicitudesHuespedColumns = (setOpen, setSeleItem) => [
  {
    field: "numeroHabitacion",
    headerName: "N° Habitación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.numero ?? '',
    renderCell: (params) => params.value,
  },
  {
    field: "identificadorPabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.pabellon?.identificador ?? '',
    renderCell: (params) => params.value,
  },
  {
    field: "nombreWing",
    headerName: "Wing",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.wing?.nombre ?? '--',
    renderCell: (params) => params.value,
  },
  {
    field: "nombreCampamento",
    headerName: "Campamento",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.pabellon?.campamento?.nombre ?? '',
    renderCell: (params) => params.value,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.estado ?? '',
    renderCell: (params) => params.value,
  },
  {
    field: "tipoJornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.turno?.tipoJornada ?? '',
    renderCell: (params) => params.value,
  },
  {
    field: "descripcionTurno",
    headerName: "Turno",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.turno?.descripcion ?? '',
    renderCell: (params) => params.value,
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <IconButton
  //           onClick={function () {
  //             setSeleItem(params.row);
  //             setOpen(true);
  //           }}
  //         >
  //           <AddCircleOutlineIcon style={{ color: "#174300" }} />
  //         </IconButton>
  //       </div>
  //     );
  //   },
  // },
];
export const tramosCMDICColumns = (setOpen, setSeleItem) => [
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row.row.nombre ?? ''}
    </div>
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row.row.contrato ?? ''}
    </div>
  },
  {
    field: "vicepresidencia",
    headerName: "Vicepresidencia",
    flex:1,
    minWidth:220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row.row.vicepresidencia ?? ''}
    </div>
  },
  {
    field: "gerencia",
    headerName: "Gerencia",
    flex:1,
    minWidth:220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row.row.gerencia ?? ''}
    </div>
  },
  {
    field: "siArea",
    headerName: "SI Area",
    flex:1,
    minWidth:220,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row.row.siArea ?? ''}
    </div>
  },
  {
    field: "fechaDesde",
    headerName: "Desde",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row.row.fechaDesde ? format(row.row.fechaDesde, 'dd-MM-yyyy') : ''}
    </div>
  },
  {
    field: "fechaHasta",
    headerName: "Hasta",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => <div style={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row.row.fechaHasta ? format(row.row.fechaHasta, 'dd-MM-yyyy') : ''}
    </div>
  },
  {
    field: "trabajadoresDia",
    headerName: "N°Trabajadores dia",
    flex:1,
    minWidth:220,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadoresNoche",
    headerName: "N°Trabajadores Noche",
    flex:1,
    minWidth:220,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "acreditado",
  //   headerName: "Acreditación Vigente",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => {
  //     return (
  //         <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //             {row.row?.trabajadorCMDIC ? row.row?.trabajadorCMDIC?.acreditado ? 'si':'no' : row.row?.trabajadorESED ? row.row?.trabajadorESED?.acreditado ? 'si' : 'no' : '' }
  //         </div>
  //     );
  // }
  // },
];
export const trabajadoresReservaCMDICColumns = (setOpen, setSeleItem) => [
  {
    field: "rut",
    headerName: "Rut",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadorCMDIC",
    headerName: "Nombre",
    flex: 1,
    minWidth: 300,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
        return (
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {
                  row.row?.trabajadorCMDIC ? row.row?.trabajadorCMDIC?.nombre + ' '+ row.row?.trabajadorCMDIC?.apellidoPaterno + ' ' + row.row?.trabajadorCMDIC?.apellidoMaterno : row.row?.trabajadorESED ? row.row?.trabajadorESED?.nombre + ' '+ row.row?.trabajadorESED?.apellidoPaterno + ' ' + row.row?.trabajadorESED?.apellidoMaterno : ''
                }  
            </div>
        );
    }
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "turno",
    headerName: "Turno",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (row.row?.turno?.descripcion)
  },
  {
    field: "acreditado",
    headerName: "Acreditación Vigente",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      return (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row.row?.trabajadorCMDIC ? row.row?.trabajadorCMDIC?.acreditado ? 'si':'no' : row.row?.trabajadorESED ? row.row?.trabajadorESED?.acreditado ? 'si' : 'no' : '' }
          </div>
      );
  }
  },
];
const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
};

export const reservasResepcionistaDashboardColumns = (setOpen, setSeleItem) => [
  // {
  //   field: "viceprecidencia",
  //   headerName: "Viceprecidencia",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "gerencia",
  //   headerName: "Gerencia",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "siArea",
  //   headerName: "Si-área",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "empresa",
  //   headerName: "Empresa",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "contrato",
  //   headerName: "Contrato",
  //   flex:1,
  //   minWidth:120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "numero_trabajadores",
  //   headerName: "N°trabajadores",
  //   flex:1,
  //   minWidth:220,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "numero_trabajadores_dia",
  //   headerName: "N°trabajadores dia",
  //   flex:1,
  //   minWidth:220,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "numero_trabajadores_noche",
  //   headerName: "N°trabajadores noche",
  //   flex:1,
  //   minWidth:220,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "fechaSolicitud",
    headerName: "Fecha solicitud",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSolicitud),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSalida),
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "observaciones",
    headerName: "Observaciones",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "estado",
    headerName: "Estado",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
  },
  {
      field: "opciones",
      headerName: "Opciones",
      flex:1,
      minWidth:120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <RenderCell3 row={params.row} setOpen={setOpen} setSeleItem={setSeleItem} />
    },
];
export const reservasCMDICColumns = (setOpen, setSeleItem) => [
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? '',
    renderCell: (row) => row.row?.wing?.nombre ?? ''
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.habitacion ? row.row?.habitacion?.piso : '',
    renderCell: (row) => row.row?.habitacion ? row.row?.habitacion?.piso : '',
  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.habitacion ? row.row?.habitacion?.numero : '',
    renderCell: (row) => row.row?.habitacion ? row.row?.habitacion?.numero : '',
  },
  {
    field: "rut",
    headerName: "Rut",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.trabajador ? row.row?.trabajador.rut : '',
    renderCell: (row) => row.row?.trabajador ? row.row?.trabajador.rut : '',
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.trabajador ? row.row?.trabajador.nombre + ' ' + row.row?.trabajador.apellido : '',
    renderCell: (row) => 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador.nombre + ' ' + row.row?.trabajador.apellido : ''}
      </Box>
  },
  {
    field: "cargo",
    headerName: "Cargo",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.trabajador ? row.row?.trabajador.cargo : '',
    renderCell: (row) => 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador.cargo : ''}
      </Box>
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.trabajador ? row.row?.trabajador.jornada : '',
    renderCell: (row) => 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador.jornada : ''}
      </Box>
  },
  {
    field: "turno",
    headerName: "Turno",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.trabajador ? row.row?.trabajador.turno : '',
    renderCell: (row) => 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {row.row?.trabajador ? row.row?.trabajador.turno : ''}
      </Box>
  },
  {
    field: "permanente",
    headerName: "Permanente",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.permanente ? 'Si' : 'No',
    renderCell: row => row.row?.permanente ? 'Si' : 'No'
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 120,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return (
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpen(true);
  //         }}
  //       >
  //         <AddCircleOutlineIcon style={{ color: "#FFF" }} />
  //       </IconButton>
  //     );
  //   },
  // },
];
export const reservasResepcionistaDashboardV2Columns = (setOpen, setSeleItem) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha solicitud",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSolicitud),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSalida),
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.nombreSolicitante + ' ' + params.row.apellidoSolicitante,
    renderCell: (params) => 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row.nombreSolicitante + ' ' + params.row.apellidoSolicitante}
      </Box>
  },
  {
    field: "observaciones",
    headerName: "Observaciones",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.observaciones
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.estado
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "#FFF" }} />
        </IconButton>
      );
    },
  },
];
export const reservasCMDICGeneralColumns = (setOpen, setSeleItem) => [
  {
    field: "fechaSolicitud",
    headerName: "Fecha solicitud",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSolicitud),
  },
  {
    field: "fechaIngreso",
    headerName: "Ingreso",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaIngreso),
  },
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => formatDate(params.row.fechaSalida),
  },
  {
    field: "nombreSolicitante",
    headerName: "Solicitante",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.nombreSolicitante + ' ' + params.row.apellidoSolicitante,
    renderCell: (params) => 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        {params.row.nombreSolicitante + ' ' + params.row.apellidoSolicitante}
      </Box>
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: params => params.row.estado
  },
  {
    field: "opciones",
    headerName: "Opciones",
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => {
            setSeleItem(params.row);
            setOpen(true);
          }}
        >
          <AddCircleOutlineIcon style={{ color: "#afbd22" }} />
        </IconButton>
      );
    },
  },
];
// <EditIcon style={{ color:"#f19d38" }}/>

export const reservasTrabajadadoresNoAsignadosRecepcionistaColumns = () => [
  {
    field: "rut",
    headerName: "RUT",
    flex:1,
    minWidth:100,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.rut,
    renderCell: (row) => row?.row?.trabajador?.rut
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? '--',
    renderCell: (row) => <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  >
  {row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? '--'}
  </Box>
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => {
      const apellidoPaterno = row?.row?.trabajador?.apellidoPaterno ?? row?.row?.trabajador?.apellidos ??'-';
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? '';

      return <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
      {`${apellidoPaterno} ${apellidoMaterno}`.trim()}
      </Box>
      
    }
  },  
  {
    field: "genero",
    headerName: "Género",
    flex:1,
    minWidth:80,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.genero
  },
  {
    field: "tipoCargo",
    headerName: "Tipo Cargo",
    flex:1,
    minWidth:120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.tipoCargo,
    renderCell: (row) => <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            }}
                          >{row?.row?.trabajador?.tipoCargo}</Box>
  },
  {
    field: "jornada",
    headerName: "Jornada",
    flex:1,
    minWidth:90,
    headerAlign: "center",
    align: "center",
  },  
  {
    field: "turno",
    headerName: "Turno",
    flex:1,
    minWidth:90,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.turno?.descripcion
  },  
  {
    field: "acreditado",
    headerName: "Acreditación",
    flex:1,
    minWidth:110,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.acreditado ? <span style={{ color:'#004912', background:'#7cfb7c',padding:'5px 10px', borderRadius:'8px'}}>Vigente</span>:<span style={{ color:'red', background:'rgb(255, 207, 207)',padding:'3px 10px', borderRadius:'10px'}}>No Vigente</span>
  },
  // {
  //   field: "Habitación",
  //   headerName: "Habitación",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.numero : '--'
  // },
  // {
  //   field: "piso",
  //   headerName: "Piso",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.piso : '--'
  // },
  // {
  //   field: "pabellon",
  //   headerName: "Pabellón",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.pabellon ? row?.row?.habitacion.pabellon?.identificador : '--' : '--'
  // },
  // {
  //   field: "wing",
  //   headerName: "Wing",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.wing ? row?.row?.habitacion.wing?.nombre : '--' : '--'
  // },
  {
    field: "fechaIngreso",
    headerName: "Llegada",
    flex:1,
    minWidth:100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaIngreso)
  },  
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex:1,
    minWidth:100,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaSalida)
  },  
];
export const reservasTrabajadadoresRecepcionistaColumns = () => [
  {
    field: "rut",
    headerName: "RUT", 
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.rut,
    renderCell: (row) => row?.row?.trabajador?.rut
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) =>row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? '--',
    renderCell: (row) =><Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  > {row?.row?.trabajador?.nombre ?? row?.row?.trabajador?.nombres ?? '--'}</Box>
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => {
      const apellidoPaterno = row?.row?.trabajador?.apellidoPaterno ?? row?.row?.trabajador?.apellidos ??'-';
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? '';
      return `${apellidoPaterno} ${apellidoMaterno}`.trim()
    },
    renderCell: (row) => {
      const apellidoPaterno = row?.row?.trabajador?.apellidoPaterno ?? row?.row?.trabajador?.apellidos ??'-';
      const apellidoMaterno = row?.row?.trabajador?.apellidoMaterno ?? '';
      return <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      }}
    >{`${apellidoPaterno} ${apellidoMaterno}`.trim()}</Box>
    }
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.empresa && <span >{row.row?.empresa ? row.row?.empresa.razonSocial : '-'}</span>,
    renderCell: row => row.row?.empresa && 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
        <span >{row.row?.empresa ? row.row?.empresa.razonSocial : '-'}</span>
      </Box>
  },
  {
    field: "contrato",
    headerName: "Contrato",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: row => row.row?.contrato && <span >{row?.row.contrato}</span>,
    renderCell: row => row.row?.contrato && 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
      >
          <span >{row.row?.contrato ?? row.row?.contrato}</span>
      </Box>

  },
  {
    field: "habitacion",
    headerName: "Habitación",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.numero || '',
    renderCell: (params) => params.row?.habitacion?.numero || ''
  },
  {
    field: "piso",
    headerName: "Piso",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.piso || '',
    renderCell: (params) => params.row?.habitacion?.piso || ''
  },
  {
    field: "pabellon",
    headerName: "Pabellón",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.pabellon?.identificador || '',
    renderCell: (params) => params.row?.habitacion?.pabellon?.identificador || ''
  },
  {
    field: "wing",
    headerName: "Wing",
    flex: 1,
    minWidth: 130,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row?.habitacion?.wing?.nombre || '',
    renderCell: (params) => params.row?.habitacion?.wing?.nombre || ''
  },    
  {
    field: "jornada",
    headerName: "Jornada",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.jornada,
    renderCell: (row) => row?.row?.jornada
  },  
  {
    field: "turno",
    headerName: "Turno",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.turno?.descripcion,
    renderCell: (row) => <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  >{row?.row?.turno?.descripcion}</Box>
  },  
  {
    field: "acreditado",
    headerName: "Acreditación",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.trabajador?.acreditado ? <span style={{ color:'#004912', background:'#7cfb7c',padding:'5px 10px', borderRadius:'8px'}}>Vigente</span>:<span style={{ color:'red', background:'rgb(255, 207, 207)',padding:'3px 10px', borderRadius:'10px'}}>No Vigente</span>
  },
  {
    field: "tipoCargo",
    headerName: "Tipo Cargo",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.tipoCargo,
    renderCell: (row) => <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  >{row?.row?.trabajador?.tipoCargo}</Box>
  },  
  {
    field: "genero",
    headerName: "Género",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row?.row?.trabajador?.genero,
    renderCell: (row) => row?.row?.trabajador?.genero
  },  
  // {
  //   field: "Habitación",
  //   headerName: "Habitación",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.numero : '--'
  // },
  // {
  //   field: "piso",
  //   headerName: "Piso",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.piso : '--'
  // },
  // {
  //   field: "pabellon",
  //   headerName: "Pabellón",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.pabellon ? row?.row?.habitacion.pabellon?.identificador : '--' : '--'
  // },
  // {
  //   field: "wing",
  //   headerName: "Wing",
  //   flex:1,
  //   minWidth:130,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (row) => row?.row?.habitacion ? row?.row?.habitacion?.wing ? row?.row?.habitacion.wing?.nombre : '--' : '--'
  // },
  {
    field: "fechaIngreso",
    headerName: "Llegada",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaIngreso)
  },  
  {
    field: "fechaSalida",
    headerName: "Salida",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => formatDate(row?.row?.fechaSalida)
  },  
];

export const itemsTrasladoColumns = () => [
  {
    field: "codigo",
    headerName: "Código",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => row?.row?.descripcion ?? '--'
  }, 
  {
    field: "precio",
    headerName: "Precio",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => '$'+row?.row?.precio ?? ''
  }, 
  {
    field: "cantidadTraspaso",
    headerName: "Cantidad traspaso",
    flex:1,
    minWidth:130,
    headerAlign: "center",
    align: "center",
  }, 
];


export const habitacionAsignacionRecepcionistaColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "pabellon",
    headerName: "Pabellón",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.pabellon?.identificador ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "wing",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "asignado",
    headerName: "Asignado",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row?.asignado ?? '';
    },
    renderCell: (params) => {
      const asignados = params.row?.asignado?.split(',') ?? [];
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {asignados.map((asignado, index, array) => (
            <span key={index}>
              {asignado}
              {index < array.length - 1 ? ', ' : ''}
            </span>
          ))}
        </Box>
      );
    }
},
{
  field: "numero",
  headerName: "Número",
  minWidth: 20,
  headerAlign: "center",
  align: "center",
},
{
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  >
    {row.row?.cargosReservados ?? ""}
  </Box>,
    renderCell: (row) => row.row?.cargosReservados ?? "",
  },
  {
    field: "tipoHuesped",
    headerName: "Tipo huésped",
    width: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadores",
    headerName: "Ocupantes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: row => row?.row?.trabajadores.length
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoCama",
    headerName: "Tipo cama",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamarotes",
    headerName: "N°Camarotes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 30,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "idChapa",
    headerName: "ID chapa",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.idChapa ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  // {
  //   field: "productos",
  //   headerName: "Artículos",
  //   flex: 1,
  //   minWidth: 120,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (row) => row.row?.productos?.map(prod => prod.nombre).join(', ') ?? "",
  //   renderCell: (row) => row.value,
  // },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno
        ? <CheckIcon style={{ color: 'green' }} />
        : <DangerousIcon style={{ color: 'red' }} />;
    },
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 100,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     <div>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpenEdit(true);
  //         }}
  //       >
  //         <EditIcon style={{ color: "#f19d38" }} />
  //       </IconButton>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpen(true);
  //         }}
  //       >
  //         <DeleteIcon style={{ color: "#d52a2a" }} />
  //       </IconButton>
  //     </div>
  //   ),
  // },
];
export const habitacionAsignacionCMICColumns = (setOpen, setSeleItem, setOpenEdit) => [
  {
    field: "wing",
    headerName: "Wing",
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.wing?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "asignado",
    headerName: "Asignado",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.row?.asignado ?? '';
    },
    renderCell: (row) => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
        {Array.isArray(row.row.asignado) && row.row.asignado.length > 0 ? (
          row.row.asignado.map((asignado, index, array) => (
            <span key={index}>
              {asignado}
              {index < array.length - 1 ? ', ' : ''}
            </span>
          ))
        ) : (
          <span>No asignado</span>
        )}        
        </Box>
      );
    }
},
{
  field: "numero",
  headerName: "Número",
  minWidth: 20,
  headerAlign: "center",
  align: "center",
},
{
    field: "piso",
    headerName: "Piso",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cargosReservados",
    headerName: "Cargo/s",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  >
    {row.row?.cargosReservados ?? ""}
  </Box>,
    renderCell: (row) => row.row?.cargosReservados ?? "",
  },
  {
    field: "tipoHuesped",
    headerName: "Tipo huésped",
    width: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "trabajadores",
    headerName: "Ocupantes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: row => row?.row?.trabajadores.length
  },
  {
    field: "numeroTotalCamas",
    headerName: "N°Total Camas",
    minWidth: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tipoCama",
    headerName: "Tipo cama",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamarotes",
    headerName: "N°Camarotes",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "nroCamas",
    headerName: "N°Camas",
    minWidth: 30,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "idChapa",
    headerName: "ID chapa",
    minWidth: 20,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.idChapa ?? "",
    renderCell: (row) => row.value,
  },
  {
    field: "standard",
    headerName: "Standard",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    valueGetter: (row) => row.row?.standard?.nombre ?? "",
    renderCell: (row) => row.value,
  },
  // {
  //   field: "productos",
  //   headerName: "Artículos",
  //   flex: 1,
  //   minWidth: 120,
  //   headerAlign: "center",
  //   align: "center",
  //   valueGetter: (row) => row.row?.productos?.map(prod => prod.nombre).join(', ') ?? "",
  //   renderCell: (row) => row.value,
  // },
  {
    field: "tieneOxigeno",
    headerName: "Oxígeno",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.row.tieneOxigeno
        ? <CheckIcon style={{ color: 'green' }} />
        : <DangerousIcon style={{ color: 'red' }} />;
    },
  },
  // {
  //   field: "opciones",
  //   headerName: "Opciones",
  //   flex: 1,
  //   minWidth: 100,
  //   sortable: false,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => (
  //     <div>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpenEdit(true);
  //         }}
  //       >
  //         <EditIcon style={{ color: "#f19d38" }} />
  //       </IconButton>
  //       <IconButton
  //         onClick={() => {
  //           setSeleItem(params.row);
  //           setOpen(true);
  //         }}
  //       >
  //         <DeleteIcon style={{ color: "#d52a2a" }} />
  //       </IconButton>
  //     </div>
  //   ),
  // },
];