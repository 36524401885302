import styles from './components.module.css';
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Popper, Select, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { ingresoBodegaShowColumns } from '../../../lib/columns';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';

const Show = ({ open3, setOpen3, handleClose, seleItem  }) => {
    
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = ingresoBodegaShowColumns();
  useEffect(() => { seleItem && console.log('seleItem:', seleItem) }, [seleItem]);

  return (
    <Dialog
      open={open3}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ backgroundColor: "#2d3037", color: "#FFF" }}
      >
        {"Resumen solicitud de ingreso"}
      </DialogTitle>
      
      <DialogContent style={{ backgroundColor: "#2d3037", color: "#FFF" }}>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#212227', borderRadius:'5px', border:'1px solid #0086ae', overflowX:'auto' }}>
            <Grid item xs={12} md={4}>
              <Typography align="left">ID:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.id}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Fecha Ingreso:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.fechaIngreso && format(seleItem?.fechaIngreso, 'dd-MM-yyyy') } { seleItem?.fechaIngreso && format(seleItem?.fechaIngreso, 'HH:mm')}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Tipo documento:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.tipoDocumento}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Órden de compra:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.ordenDeCompra}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Monto Total:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography><strong style={{ fontWeight: '500' }}>${seleItem?.montoTotal}</strong></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography align="left">Receptor:</Typography>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginBottom:'15px' }}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.receptor}</strong></Typography>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Typography align="left">Observaciones:</Typography>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginBottom:'15px' }}>
              <Typography><strong style={{ fontWeight: '500' }}>{seleItem?.observaciones}</strong></Typography>
            </Grid>
        </Grid>
        <Grid container={true} spacing={2} mt={2} style={{ backgroundColor:'#212227', borderRadius:'5px'}}>
            {seleItem?.imagen1 && 
            <Grid item xs={12} md={4}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen1}`} alt="img_plano" />
            </Grid>}
            {seleItem?.imagen2 && <Grid item xs={12} md={4}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen2}`} alt="img_plano" />
            </Grid>}
            {seleItem?.imagen3 && <Grid item xs={12} md={4} style={{ marginBottom:'10px' }}>
              <img style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}${seleItem?.imagen3}`} alt="img_plano" />
            </Grid>}
        </Grid>

        <Grid container={true} mt={2}>
          <Grid item xs={12} md={12}>
            <DataGrid
              getRowId={row=>row.id ? row.id : Math.random() }
              lots={{ toolbar: GridToolbar }}
              rows={seleItem?.ingresoArticulos}
              columns={finalColumns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15, 20]}
              localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
              noRowsLabel: "No hay datos disponibles",
              pagination: {
                  labelRowsPerPage: "Filas por página:",
              }
              }}
              classes={{ menuIcon: styles.menuIcon }}
              style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
              sx={{ 
                '& .MuiDataGrid-cell': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeader': {
                  color: '#FFF',
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-footerContainer': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiTablePagination-root': {
                  color: '#FFF',
                  borderTop: '1px solid #c6c6c6',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  color: '#FFF',
                },
                '& .MuiDataGrid-menu': {
                  color: '#FFF',
                },
                '& .MuiTablePagination-actions': {
                  color: '#FFF', // Color for pagination actions
                },
                '& .MuiTablePagination-select': {
                  color: '#FFF', // Color for pagination select
                },
                '& .MuiTablePagination-selectLabel': {
                  color: '#FFF', // Color for pagination select label
                },
                '& .MuiTablePagination-displayedRows': {
                  color: '#FFF', // Color for displayed rows text
                },
              }}
              slotProps={{
                toolbar:{
                  sx:{
                    color:'#FFF'
                  }
                },
                pagination: {
                  sx: {
                    color: '#FFF',
                  },
                },
                menu: {
                  sx: {
                    color: '#FFF',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#2d3037", color: "#FFF" }}>
        <Button onClick={handleClose} style={{ color: "#FFF" }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Show;
