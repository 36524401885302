import styles from './components.module.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText, MenuItem, OutlinedInput, Popper, Select, TextField, Typography } from '@mui/material';
import { addNewHabitacion } from '../../../../redux/features/habitaciones/habitacionSlice';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CircleIcon from '@mui/icons-material/Circle';

const data = {
  campamento: 0,
  pabellon: '',
  wing: '',
  tipo_area: '',
  numero: 0,
  piso: 0,
  descripcion: '',
  n_camas: '',
  cantidad_camarotes: 0,
  cantidad_camas_individuales: 0,
  tipo_huesped: 'Solo hombres',
  tipo_cargo: [ 'Administrador de contrato', 'Administrativo', 'Supervisor', 'Operador', 'Mantenedor' ],
  tipo_standar: '',
  idChapa: '',
  chapa_electronica: '',
  tieneOxigeno: false,
}
const tipo_huesped = [
  { label: "Solo mujeres", value: "Solo mujeres" },
  { label: "Solo hombres", value: "Solo hombres" },
  { label: "Unisex", value: "Unisex" },
];
const tipo_cargos = [ 'Administrador de contrato', 'Administrativo', 'Supervisor', 'Operador', 'Mantenedor' ];

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Create = ({ campamentos, open2, setOpen2, wings, pabellones, productos, standares }) => {
    
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCampamento, setSelectedCampamento] = useState(null);
  const [standars, setStandars] = useState([])
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedWing, setSelectedWing] = useState(null);
  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [selectedPabellon, setSelectedPabellon] = useState(null);
  const [pabellonWing, setPabellonWing] = useState(0);
  const [selectedStandar, setSelectedStandar] = useState(null);
  const [formData, setFormData] = useState(data);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [cargo, setCargo] = useState(tipo_cargos);
  const [sum, setSum] = useState(false);
  const [check, setCheck] = useState(false);

  const handleChangePW = (event) => {
    setPabellonWing(event.target.value);
  };

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value });

  const onChangeCheckbox = (e) => setFormData({...formData, [e.target.name]: e.target.checked });

  const handleChangeCampamento = (event, newValue) => {
    setSelectedCampamento(newValue);
    setStandars(standares.filter(item =>item.campamentoId == newValue.id));

    if(newValue != null){
      setFormData({...formData, campamento: newValue.id, tipo_standar: ''});
      setSelectedSector(null);
      setSelectedWing(null);
      setSelectedPabellon(null);
      setSelectedStandar(null);
    } else{
      setFormData({...formData, campamento: 0})
    }
    
  };
  const handleChangeSector = (event, newValue) => {
    if(newValue != null){
      setFormData({...formData, sector: newValue.id, wing: null})
      setSelectedSector(newValue);
      setSelectedPabellon(null);
      setSelectedWing(null);
    } else{
      setFormData({...formData, sector: 0})
    }
  };
  const onchangeProducto = (event, newValue) => {
    console.log(newValue)
    if(newValue != null){
      const prod = newValue.map((it)=> it.id)
      setFormData({...formData, productos: prod})
    } else{
      setFormData({...formData, productos: []})
    }
  };
  const handleChangePabellon = (event, newValue) => {
    console.log('pabellon',newValue);
    if(newValue != null){
      setSelectedWing(null);
      setSelectedStandar(standares.find((option) => option.id == newValue.standardId))
      setFormData({...formData, pabellon: newValue.id, wing: null, tipo_standar: newValue.standardId})
      setSelectedPabellon(newValue);
    } else{
      setFormData({...formData, pabellon: 0, tipo_standar: 0})
    }
  };
  const handleChangeWing = (event, newValue) => {
    console.log('wing',newValue);
    if(newValue != null){
      setSelectedSector(null);
      setSelectedPabellon(null);
      setSelectedStandar(standares.find((option) => option.id == newValue.standardId))
      setFormData({...formData, wing: newValue.id, pabellon: null, tipo_standar: newValue.standardId})
      setSelectedWing(newValue);
    } else{
      setFormData({...formData, wing: 0, tipo_standar: 0})
    }
  };
  const handleChangeTipo = (event, newValue, tipo) => {
    if(newValue != null){
      if(tipo == 'banios'){
        setFormData({...formData, tipo_banio: newValue.value});
      } else if(tipo == 'huesped'){
        console.log(newValue.value)
        setFormData({...formData, tipo_huesped: newValue.value});
      }
    } else{
      setFormData({...formData, tipo: 0})
    }
  };
  const handleChangeStandar = (event, newValue) => {
    if(newValue != null){
      setFormData({...formData, tipo_standar: newValue.id})
      setSelectedStandar(newValue);
    } else{
      setFormData({...formData, tipo_standar: 0})
    }
  };
  const handleChange = (event) => {
    const cargoValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setCargo(cargoValue);
    setFormData({...formData, tipo_cargo: cargoValue});
  };
  
  useEffect(() => {
    if(formData.cantidad_camas_individuales < 0 || formData.cantidad_camarotes < 0 || formData.numero < 0 || formData.n_camas < 0){
      toast.error('No se pueden ingresar números negativos.')
      setCheck(false);
    } else{
      setCheck(true);
    }
  }, [formData.cantidad_camas_individuales, formData.cantidad_camarotes, formData.numero, formData.n_camas])
  
  const canSave = formData.pabellon 
    ? [formData.campamento,
      //  formData.sector,
        formData.pabellon , formData.numero, 
        // formData.idChapa,
         formData.piso, formData.n_camas, check, formData.tipo_huesped, formData.tipo_cargo.length>0, formData.tipo_standar].every(Boolean) && addRequestStatus === 'idle'
  : [formData.campamento, formData.wing , formData.numero, 
    // formData.idChapa, 
    formData.piso, formData.n_camas, check, formData.tipo_huesped, formData.tipo_cargo, formData.tipo_standar].every(Boolean) && addRequestStatus === 'idle';
  

  useEffect(() => {
    if(formData.cantidad_camarotes && formData.cantidad_camas_individuales && formData.n_camas){
      let suma = parseInt(formData.cantidad_camarotes)*2 + parseInt(formData.cantidad_camas_individuales);
      if(suma > parseInt(formData.n_camas) || suma < parseInt(formData.n_camas)){
        toast.error('La suma de los camarotes y camas individuales no concuerda con el total de camas.')
      }
    }
  }, [formData.cantidad_camarotes, formData.cantidad_camas_individuales])
  

  const handleSubmit = async () => {
    
    let suma = parseInt(formData.cantidad_camarotes)*2 + parseInt(formData.cantidad_camas_individuales);
    if(suma > parseInt(formData.n_camas) || suma < parseInt(formData.n_camas)){
      toast.error('La suma de los camarotes y camas individuales no concuerda con el total de camas.')
      return false;
    }

    if(canSave) {
      try {
        setOpen2(true)
        setAddRequestStatus('loading');
        let resp = await dispatch(addNewHabitacion(formData));
        if(resp.payload.status == 'success'){
          setOpen(false);
          setOpen2(false)
          setFormData(data);
          return;
        }else{
          setOpen2(false)
        }
      } catch (error) {
        toast.error('Error al crear la habitación.');
        console.error('Error al crear el habitación', error);
      } finally{
        setAddRequestStatus('idle');
      }
    }
  }


  return (
    <div>
      <div style={{ display:'flex', gap:'5px', alignItems:'center', justifyContent:'space-between' }}>
          <Typography>El siguiente <strong>botón</strong> te mostrará un formulario para poder registrar una habitación.</Typography>
          <Button variant='contanied' size='large' className={styles.bgColor} onClick={()=> setOpen(true)}><CircleIcon className={styles.iconButton}/> Crear Habitación</Button>
        </div>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
           backgroundColor:'#2d3037',
           color:'#FFF',
           borderRadius:'0'
          }
        }}
        onBackdropClick={() => {
          setSelectedCampamento(null);
          setSelectedSector(null);
          setSelectedWing(null);
          setFormData(data);
          setOpen(false);
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open2}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle style={{ display:'flex', gap:'5px'}}>
          <MeetingRoomIcon className={styles.iconBgCreate} style={{ fontSize:'3rem', marginTop:'0px' }}/>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <Typography variant='span' style={{ fontSize:'24px', lineHeight:'1' }}>Crear habitación</Typography>
            <span className={styles.subTitle}>Rellena los siguientes campos para crear la habitación.</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Campamento</label>
              <Autocomplete
                disablePortal
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                id="campamentos-autocomplete"
                name="campamento"
                options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                getOptionLabel={(option) => option.nombre}
                disableClearable
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      height: '200px', 
                      overflowY: 'auto',
                    }}
                  />
                )}
                onChange={(e, value)=> handleChangeCampamento(e, value)}
                renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
              />
            </Grid>
            {/* { selectedCampamento &&  */}
            
              <Grid item  xs={12} sm={12}>
                <Typography><small><strong>Busca y selecciona un pabellón o un wing.</strong></small></Typography>
              </Grid>
              {/* } */}
            
            {/*
            {selectedCampamento && (
              <Grid item  xs={12} sm={12}>
                <label className={styles.label}>Sector</label>
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="sectores-autocomplete"
                  name="sector"
                  value={selectedSector}
                  options={selectedCampamento.sectores}
                  getOptionLabel={(option) => option.nombre}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        height: '200px', 
                        overflowY: 'auto',
                      }}
                    />
                  )}
                  onChange={(e, value) => handleChangeSector(e, value)}    
                  renderInput={(params) => <TextField {...params} label="Selecciona un sector" />}
                />
              </Grid>
            )}
            */}
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Pabellón o Wing</label>
              <Select
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pabellonWing}
                label="Age"
                onChange={handleChangePW}
                style={{ width:'100%' }}
              >
                <MenuItem value={1}>Pabellón</MenuItem>
                <MenuItem value={2}>Wing</MenuItem>
              </Select>
            </Grid>
            {/* {selectedSector && ( */}
            {pabellonWing == 1 && ( 
              <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>Pabellón</label>
                  <Autocomplete
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedPabellon}
                    options={pabellones ? pabellones : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: '200px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.identificador}
                    onChange={(e, value) => handleChangePabellon(e, value)}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un pabellón" />}
                />
              </Grid>
             )} 
            {/* {selectedCampamento && ( */}
            {pabellonWing == 2 && ( 
              <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>Wing</label>
                  <Autocomplete
                  className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="wing"
                    value={selectedWing}
                    options={wings ?  wings : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          height: '100px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value) => handleChangeWing(e, value)}    
                    renderInput={(params) => <TextField {...params} label="Selecciona un wing" />}
                />
              </Grid>
            )} 
            <Grid item  xs={12} sm={6}>
                <label className={styles.label}>Número</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='numero'
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => onChange(e)}
                />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Piso</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='piso'
                type="number"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Descripción (opcional)</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='descripcion'
                type="text"
                multiline
                rows={2}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
                <label className={styles.label}>ID chapa electrónica</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='idChapa'
                  type="text"
                  onChange={(e) => onChange(e)}
                />
            </Grid>
            {/* <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Chapa electrónica (opcional)</label>
              <TextField
                variant="outlined"
                fullWidth
                name='chapa_electronica'
                type="text"
                multiline
                rows={2}
                maxRows={4}
                onChange={(e) => onChange(e)}
              />
            </Grid> */}
            <Grid item  xs={12} sm={6}>
                <label className={styles.label}>Metros cuadrados</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='metrosCuadrados'
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => onChange(e)}
                />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Número de camas</label>
              <TextField
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                variant="outlined"
                fullWidth
                name='n_camas'
                type="number"
                placeholder="0"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            { (formData.n_camas && formData.n_camas > 0) &&
            <>
              <Grid item  xs={12} sm={6}>
                <label className={styles.label}>Número de camarotes (2 camas c/u)</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='cantidad_camarotes'
                  type="number"
                  placeholder='0'
                  defaultValue={0}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              <Grid item  xs={12} sm={6}>
                <label className={styles.label}>Número de camas individuales</label>
                <TextField
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  variant="outlined"
                  fullWidth
                  name='cantidad_camas_individuales'
                  type="number"
                  placeholder='0'
                  defaultValue={0}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => onChange(e)}
                />
              </Grid>
            </>
            }
            
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Tipo de huesped</label>
              <Autocomplete
              className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                disablePortal
                id="campamentos-autocomplete"
                options={tipo_huesped}
                name="tipo_huesped"
                getOptionLabel={(option) => option.label}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      height: '120px', 
                      overflowY: 'auto',
                    }}
                  />
                )}
                onChange={(e, value)=> handleChangeTipo(e, value, 'huesped')}
                renderInput={(params) => <TextField {...params}  />}
                defaultValue={tipo_huesped[1]} 
                disableClearable
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <label className={styles.label}>Tipo de cama</label>
              <Select
                className={styles.textField}
                style={{ width:'100%' }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name="tipoCama"
                onChange={(e) => onChange(e)}
              >
                <MenuItem value='Una plaza'>Una plaza</MenuItem>
                <MenuItem value='Plaza y media'>Plaza y media</MenuItem>
                <MenuItem value='Dos plazas'>Dos plazas</MenuItem>
                <MenuItem value='Queen'>Queen</MenuItem>
                <MenuItem value='King'>King</MenuItem>
              </Select>
            </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Tipo de cargos (Puede elegir más de 1)</label>
              <Select
              className={styles.textField}
              style={{ width:'100%' }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={cargo}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {tipo_cargos.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={cargo.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            
            {
              (selectedCampamento && (selectedWing || selectedPabellon)) ?
              <Grid item  xs={12} sm={12}>
                <label className={styles.label}>Tipo de standard</label>
                <Autocomplete
                className={styles.textField}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFF',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFF',
                  },
                }}
                  disablePortal
                  id="campamentos-autocomplete"
                  options={standars}
                  name="tipo_standar"
                  // value={selectedStandar}
                  value={selectedStandar}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        overflowY: 'auto',
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, value)=> handleChangeStandar(e, value)}
                  disableClearable
                  renderInput={(params) => <TextField {...params} label="Selecciona un tipo de standar" />}
                />
              </Grid>
              : <Grid item  xs={12} sm={12}>
                  <label className={styles.label} style={{ fontWeight:'600' }}>Seleccione un campamento y un recinto para ver los standard.</label>
                </Grid>
            }
            <Grid item  xs={12} sm={12}>
                  <label className={styles.label}>Busca un articulo del inventario</label>
                  <Autocomplete
                  className={styles.textField}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#FFF',
                    },
                    '& .MuiChip-deleteIcon': {
                      color: '#FFF',
                    },
                    '& .MuiChip-label': {
                      color: '#FFF',
                    },
                    '& .MuiButtonBase-root': {
                      backgroundColor: '#0086ae',
                    },
                  }}
                    disablePortal
                    disableClearable
                    id="sectores-autocomplete"
                    name="inventario"
                    multiple
                    // value={selectedArticulos || []}
                    options={productos ?  productos : [] }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          maxHeight: '200px', 
                          overflowY: 'auto',
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, value) => onchangeProducto(e, value)}    
                    renderInput={(params) => <TextField {...params} label="Selecciona articulos" />}
                />
              </Grid>
            <Grid item  xs={12} sm={12}>
              <label className={styles.label}>Cuenta con sistema fijo de oxígeno</label>
              <Checkbox 
                {...label} 
                checked={formData.tieneOxigeno} 
                name='tieneOxigeno'
                sx={{ 
                  color: 'white',
                  '&.Mui-checked': {
                    color: 'white',
                  },
                  '& .MuiSvgIcon-root': {
                    borderColor: 'white',
                  } }}
                onChange={(e) => onChangeCheckbox(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container={true} style={{ padding:'0 16px' }}>
              <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                <Button 
                  onClick={handleSubmit}
                  className={styles.bgColorModal}
                  variant="contained" 
                  disabled={!canSave}
                  >
                  {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Crear'}
                </Button>
                <Button 
                  onClick={()=> {
                    setSelectedCampamento(null);
                    setSelectedSector(null);
                    setSelectedWing(null);
                    setFormData(data);
                    setOpen(false);
                  }}
                  className={styles.bgColorOutlined}
                  variant="outlined" 
                  >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Create