import styles from './components/components.module.css'
import { Backdrop, Box, Container, CircularProgress } from "@mui/material"
import Header from "../../../components/header/Header"
import PersonIcon from '@mui/icons-material/Person';
import TableCard from "../../../components/tableCard/TableCard";
import SkeletonTable from "../../../components/Skeleton";
import Create from "../responsables/components/Create";
import Table from "../responsables/components/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchResponsables, getAllResponsables, getResponsablesStatus } from "../../../redux/features/responsables/responsableSlice";
import { useEffect, useState } from "react";

const Responsables = () => {
    
    const dispatch = useDispatch();
    const responsables = useSelector(getAllResponsables);
    const status = useSelector(getResponsablesStatus);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    useEffect(()=>{
        if(status == 'idle'){
          dispatch(fetchResponsables());
        }
      },[responsables]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
          <Backdrop
            sx={{ color: '#fff', zIndex: 10000000 }}
            open={open3}
          >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Gestíon de responsables"
          subtitle="En este módulo, se especifican las direcciones de correo electrónico de las personas a las que se enviarán notificaciones en diferentes escenarios.">
          {<PersonIcon style={{ fontSize:'1.5rem' }} className={styles.iconBg}/>}
        </Header>

        <TableCard>
            <Create responsables={responsables} setOpen2={setOpen2} open2={open2}/>
            { status == 'loading' ? <SkeletonTable/> : <Table responsables={responsables} setOpen3={setOpen3}/> }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Responsables