import React, { useEffect, useState } from 'react';
import styles from './components.module.css';
import { Card, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchCampamento } from '../../../redux/features/campamentos/campamentoSlice';

const Guarderia = ({ items }) => {
  const dispatch = useDispatch();
  const [filterItems, setFilterItems] = useState([]);

  const getCamp = async (campamentoId) => {
    try {
      const resp = await dispatch(fetchCampamento(campamentoId));
      return resp.payload;
    } catch (error) {
      console.error('Error fetching campamento:', error);
      return null;
    }
  };

  useEffect(() => {
    const updateFilterItems = async () => {
      try {
        const updatedItems = await Promise.all(items.map(async (storage) => {
          if (!storage.espacioAlmacenamiento) {
            return { ...storage, campamento: null };
          }
          const campamento = await getCamp(storage.espacioAlmacenamiento.campamentoId);
          return { ...storage, campamento };
        }));
        setFilterItems(updatedItems);
      } catch (error) {
        console.error('Error updating filter items:', error);
      }
    };
  
    updateFilterItems();
  }, [items]);
  

  return (
    <Card className={styles.card} style={{ backgroundColor:'var(--inst-card-bg)', padding:'16px', color:'#FFF', margin:'10px 0', border:'1px solid var(--border-blue)' }}>
      {filterItems.length > 0 ? filterItems.map((storage, storageIndex) => (
        <div key={storageIndex} style={{ maxHeight:'170px', overflowY:'auto' }}>
          {storage.items && storage.items.length !== 0 && (
            <>
            <Typography variant='h7' style={{ fontWeight: '500' }}>
              Equipamiento almacenado en {' '}
            </Typography>
            <br />
            <Typography variant='h7' style={{ fontWeight: '600' }}>
              Campamento {storage.campamento.nombre} {' '}
            </Typography>
            <br />
            <Typography variant='h7' style={{ fontWeight: '600' }}>
              Espacio N°{storage.espacioAlmacenamiento.numero}
            </Typography>
            <br />
            <Typography variant='h7' style={{ fontWeight: '600' }}>
              nivel {storage.espacioAlmacenamiento.nivel}
            </Typography>
            </>
          )}
          {storage.items && storage.items.length !== 0 ? (
            <ul style={{ margin:'0 5px' }}>
              {storage.items.map((item, index) => (
                <li key={index} style={{ fontSize:'14px', fontWeight:'500', borderBottom:'1px solid grey', padding:'5px 0' }}>{item}</li>
              ))}
            </ul>
          ) : (
            <span style={{ fontSize: '14px' }}>Sin elementos en guardarropía.</span>
          )}
        </div>
      )) : <Typography variant='h7' style={{ fontWeight: '600', fontSize:'14px'}}>Sin Equipamiento en Guardería.</Typography>
    }
    </Card>
  );
};

export default Guarderia;
