import styles from './components.module.css';
import { useEffect, useState } from "react";
import { habitacionGruposPlanificacionColumns } from "../../../../lib/columns";
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import Delete from './Delete'
import Edit from './Edit'


const Table = ({ habitaciones, setOpen3, setSelectedHabitaciones, selectedNameByRuts, handleDelete}) => {

  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const finalColumns = habitacionGruposPlanificacionColumns(setOpen, setSeleItem, setOpenEdit, selectedNameByRuts, handleDelete);
  const [rowHeight, setRowHeight] = useState(0);
  
  
  const handleRowSelectionChange = (selectionModel) => {
    console.log('Selection Model', selectionModel);
    setSelectedHabitaciones(selectionModel);  
  };

  const calculateRowHeight = (selectedNameByRuts) => {
    let res
    const numselectedNameByRuts = selectedNameByRuts.length;
    if (numselectedNameByRuts > 1){
      res = numselectedNameByRuts * 30;
    }else{
      res = 0
    }
    return res;
  };

  useEffect(() => {
    const newRowHeight = calculateRowHeight(selectedNameByRuts);
    setRowHeight(newRowHeight);
  }, [selectedNameByRuts]);

  return (
    <div style={{ marginTop:'16px' }}>
        {/*<Delete open={open} setOpen={setOpen} seleItem={seleItem} setOpen3={setOpen3}/>
        <Edit openEdit={openEdit} setOpenEdit={setOpenEdit} seleItem={seleItem} setOpen3={setOpen3}/> */}
        {/*
          {JSON.stringify(habitaciones)}
          */}
        <DataGrid
            getRowId={row=>row.id ? row.id : Math.random() }
            // checkboxSelection
            // onRowSelectionModelChange={handleRowSelectionChange}
            lots={{ toolbar: GridToolbar }}
            rows={habitaciones}
            columns={finalColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 20]}
            // isRowSelectable={(params) => params.row.grupo == true}
            localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
                labelRowsPerPage: "Filas por página:",
            }
            }}
            classes={{ menuIcon: styles.menuIcon }}
            style={{ maxHeight:'500px', color:'#FFF', border:'none' }}
            sx={{ 
              '& .MuiDataGrid-cell': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeader': {
                color: '#FFF',
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-footerContainer': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiTablePagination-root': {
                color: '#FFF',
                borderTop: '1px solid #c6c6c6',
              },
              '& .MuiDataGrid-toolbarContainer': {
                color: '#FFF',
              },
              '& .MuiDataGrid-menu': {
                color: '#FFF',
              },
              '& .MuiTablePagination-actions': {
                color: '#FFF', // Color for pagination actions
              },
              '& .MuiTablePagination-select': {
                color: '#FFF', // Color for pagination select
              },
              '& .MuiTablePagination-selectLabel': {
                color: '#FFF', // Color for pagination select label
              },
              '& .MuiTablePagination-displayedRows': {
                color: '#FFF', // Color for displayed rows text
              },
             }}
            slotProps={{
              toolbar:{
                sx:{
                  color:'#FFF'
                }
              },
              pagination: {
                sx: {
                  color: '#FFF',
                },
              },
              menu: {
                sx: {
                  color: '#FFF',
                },
              },
            }}
        />
    </div>
  )
}

export default Table