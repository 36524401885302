import styles from './components.module.css';
import { useState } from "react";
import { habitacionAsignacionCMICColumns } from "../../lib/columns";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { Card } from '@mui/material';
import SkeletonTable from '../../components/Skeleton'

const TableHabitaciones = ({rows, addRequestStatus, setOpen3, actualizarRegistroAutomatico }) => {
    const [seleItem, setSeleItem] = useState();
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const finalColumns = habitacionAsignacionCMICColumns(setOpen, setSeleItem);
    
    const handleRowSelectionChange = (selection) => {
      const selectedID = selection[0];
      console.log('selectedID',selectedID)
      if(selectedID){
        // actualizarRegistroAutomatico(selectedID);
      }
    };
    const rowsArray = Array.isArray(rows) ? rows : [];

  return (
    <div style={{ width:'100%', marginTop:'10px' }}>
    { addRequestStatus == 'loading' 
        ? <SkeletonTable/> 
        : <div style={{ backgroundColor:'#212227' }}>
        <DataGrid
          getRowId={row => row.id ? row.id : Math.random()}
          rows={rowsArray}
          rowHeight={80}
          onRowSelectionModelChange={handleRowSelectionChange}
          columns={finalColumns}
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay datos disponibles",
            pagination: {
              labelRowsPerPage: "Filas por página:",
            }
          }}
          classes={{ menuIcon: styles.menuIcon }}
          style={{ maxHeight: '500px', color: '#FFF', border: 'none' }}
          sx={{
            '& .MuiDataGrid-cell': {
              color: '#FFF',
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-columnHeader': {
              color: '#FFF',
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-footerContainer': {
              color: '#FFF',
              borderTop: '1px solid #c6c6c6',
            },
            '& .MuiTablePagination-root': {
              color: '#FFF',
              borderTop: '1px solid #c6c6c6',
            },
            '& .MuiDataGrid-toolbarContainer': {
              color: '#FFF',
            },
            '& .MuiDataGrid-menu': {
              color: '#FFF',
            },
            '& .MuiTablePagination-actions': {
              color: '#FFF', // Color for pagination actions
            },
            '& .MuiTablePagination-select': {
              color: '#FFF', // Color for pagination select
            },
            '& .MuiTablePagination-selectLabel': {
              color: '#FFF', // Color for pagination select label
            },
            '& .MuiTablePagination-displayedRows': {
              color: '#FFF', // Color for displayed rows text
            },
            '& .MuiDataGrid-iconButtonContainer': {
              color: '#FFF',
            },
            '& .MuiDataGrid-menuIcon': {
              color: '#FFF',
            },
            '& .MuiDataGrid-menuIconButton': {
              color: '#FFF',
            }
          }}
          slotProps={{
            toolbar: {
              sx: {
                color: '#FFF'
              }
            },
            pagination: {
              sx: {
                color: '#FFF',
              },
            },
            menu: {
              sx: {
                color: '#FFF',
              },
            },
          }}
        />
      
        </div>
     }
    </div>
  )
}

export default TableHabitaciones