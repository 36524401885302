import styles from './components/components.module.css'
import { Box, Container } from "@mui/material"
import Header from "../../components/header/Header"
import TableCard from "../../components/tableCard/TableCard";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import TableReservas from './components/TableReservas'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import SkeletonTable from "../../components/Skeleton";
import { Backdrop, CircularProgress } from '@mui/material';
import { fetchReservasHuesped, getAllReservasHuesped, getReservasStatus } from "../../redux/features/reservas/reservaSlice";

const DetallesReservas = () => {
    const dispatch = useDispatch();
    const reservas = useSelector(getAllReservasHuesped);
    const status = useSelector(getReservasStatus);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
  

    useEffect(()=>{
      if(status === 'idle'){
          dispatch(fetchReservasHuesped());
      }
      console.log('reservas',reservas);
    },[reservas]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header 
          title="Reservas"
          subtitle="En este módulo, podrás visualizar toda la información relacionada a tus reservas registradas.">
          {<EventAvailableIcon style={{ fontSize:'1.5rem' }} className={styles.iconBg}/>}
        </Header>

        <TableCard>
          { status == 'loading' ? <SkeletonTable/> : <TableReservas reservas={reservas.reservas ? reservas.reservas : []} setOpen3={setOpen3}/>
        }
        </TableCard>
      </Container>
    </Box>
  )
}

export default DetallesReservas