import styles from './components.module.css'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material"
import { useAuth } from '../../../context/AppContext';

const Hero = () => {
    const { info,  } = useAuth();
    const roles = info?.roles.join(' - ');
    console.log('info del usuario', info);
  return (
    <Grid container={true} className={styles.hero}>
      <Grid item xs={12} sm={8} style={{ padding:'20px', display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'  }}>
        <div style={{ display:'flex', flexDirection:'column' }}>
          <img src={process.env.PUBLIC_URL + '/logo_black.png' } alt="loginImg" style={{ width:'130px' }} />
          <span style={{ fontSize:'18px', fontWeight:'600' }}>¡Bienvenido <span style={{ color: '#9ca91c' }}>{info?.nombre}</span>!</span>
          <Typography variant='span'>Comienza a administrar y gestionar los siguientes módulos en la plataforma.</Typography>
        </div>
        <div style={{ marginLeft: '10%' }} className={styles.divLine}>
          <div style={{ position: 'absolute', top: '12px', bottom: '0px', borderRight: '1px solid white', height: '80%' }}></div>
        </div>
      </Grid>
      <Grid item xs={12} sm={3} p={2} className={styles.heroGrid}>
        <div style={{ display:'flex', flexDirection:'column' }}>
          <span style={{ fontSize:'14px' }}>Rol: {roles ? roles : ''}</span>
          <span style={{ fontSize:'14px' }}>Rut: {info?.rut}</span>
          {info?.contrato && <span style={{ fontSize:'14px' }}>Contrato: {info?.contrato}</span>}
          <span style={{ fontSize:'14px' }}>Telefóno: {info?.celular}</span>
        </div>
      </Grid>
    </Grid>
  )
}

export default Hero