import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch } from 'react-redux';
import { deleteStandar } from '../../../../redux/features/estandares/estandarSlice';

export default function Delete({ open, setOpen, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const dispatch = useDispatch();
  
    const handleDelete = async () => {
      try {
        setOpen3(true);
        setAddRequestStatus('loading');
        const resp = await dispatch(deleteStandar(seleItem.id))
      } catch (error) {
        console.error('Error al eliminar el estandar de habitación.', error.message);
      } finally{
        setAddRequestStatus('idle');
        setOpen(false);
        setOpen3(false);
      }
    }

  return (
    <div>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={() => setOpen(false)}
      >
        {seleItem && (
            <Box width={1} mt={0} className={styles.boxDelete}>
                <Container className={styles.containerDelete}>
                    <div className={styles.textContainer}>
                        <Typography variant="h6" className={styles.textIconDelete}><ErrorIcon/>Estás a punto de eliminar el estandar de habitación: {seleItem?.nombre}</Typography>
                        <Typography variant='span' className={styles.paragrap}>Está acción es irreversible, el registro será removido de la base de datos, <strong>¿Estas seguro de proceder?</strong></Typography>
                    </div>
                    <Grid container={true} spacing={1} mt={1}>
                        <Grid item xs={12} sm={12} className={styles.buttonContainerDelete}>
                            <Button
                                onClick={handleDelete}
                                className={styles.bgColorDelete}
                                variant="contained" 
                                >
                                {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> :'Eliminar'}
                            </Button>
                            <Button 
                                onClick={()=>setOpen(false)}
                                className={styles.bgColorOutlined}
                                variant="outlined" 
                                >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>

                </Container>                
            </Box>
        )}
      </Drawer>
    </div>
  );
}