import styles from './tableCard.module.css'
import { Card, CardContent } from "@mui/material"

const TableCard = ({ children }) => {
  return (
    <Card className={styles.cardContainter}>
        <CardContent className={styles.cardContent}>
            { children }
        </CardContent>
    </Card>
  )
}
export const TableCardDashboard = ({ children }) => {
  return (
    <Card className={styles.cardContainter}>
        <CardContent className={styles.optionsContainer}>
            { children }
        </CardContent>
    </Card>
  )
}

export default TableCard