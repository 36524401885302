import styles from './components.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Autocomplete, Backdrop, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Popper, TextField, Typography, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllSectores } from '../../../../redux/features/sectores/sectorSlice';
import { updateEdificio } from '../../../../redux/features/edificios/edificioSlice';
import { getAllEstandares } from '../../../../redux/features/estandares/estandarSlice';
import { getAllCampamentos } from '../../../../redux/features/campamentos/campamentoSlice';

const tipo_huesped = [
    { label: "Sólo mujeres", value: "Solo mujeres" },
    { label: "Sólo hombres", value: "Solo hombres" },
    { label: "Unisex", value: "Unisex" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            with: '100%'
        },
    },
};

export default function Edit({ openEdit, setOpenEdit, seleItem, setOpen3 }) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();
    const sectores = useSelector(getAllSectores);
    const campamentos = useSelector(getAllCampamentos);
    const standares = useSelector(getAllEstandares);
    const [standars, setStandars] = useState([])
    const [selectedStandar, setSelectedStandar] = useState(null);
    const [selectedCampamento, setSelectedCampamento] = useState(null);
    const [nombreFull, setNombreFull] = useState('');
    const [standarsList, setstandarsList] = useState([])

    useEffect(() => {
        setFormData(seleItem);
        if (seleItem && seleItem.standard) {
            setstandarsList(seleItem.standard.map(item => item.nombre));
        } else {
            setstandarsList([]);
        }
        setSelectedCampamento(seleItem?.campamentoId);
        setStandars(standares.filter(item => item.campamentoId == seleItem?.campamentoId));
    }, [seleItem])

    useEffect(() => {
        if (formData) {
            const selectedIds = standars.filter(item => standarsList.includes(item.nombre)).map(item => item.id);
            const selectedName = standars.filter(item => standarsList.includes(item.nombre));
            setFormData({ ...formData, standardId: selectedIds, standard: selectedName })
        }
    }, [formData])

    const handleChangeStandars = (event) => {
        const { value } = event.target;
        setstandarsList(value);
        setFormData((prevFormData) => ({ ...prevFormData, standarsList: value }));
    };

    const handleChangeCampamento = (event, newValue) => {
        setSelectedCampamento(newValue);
        setStandars(standares.filter(item => item.campamentoId == newValue.id));
        setNombreFull('')
        if (newValue != null) {
            setFormData({ ...formData, campamentoId: newValue.id, sectorId: 0, standardId: 0, standarsList: [] });
            //   setSelectedSector(null);
            setstandarsList([]);
        } else {
            setFormData({ ...formData, campamentoId: 0 })
        }
    };

    const handleChangeSector = (e, value) => setFormData({ ...formData, sectorId: value ? value.id : null });

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleChangeTipo = (event, newValue) => {
        if (newValue != null) {
            setFormData({ ...formData, generoHuesped: newValue.value });
        } else {
            setFormData({ ...formData, generoHuesped: 0 })
        }
    };

    const handleChangeStandar = (event, newValue) => {
        if (newValue != null) {
            // setFormData({...formData, standardId: newValue.id})
            setSelectedStandar(newValue);
            const selectedIds = newValue.map(item => item.id);
            setFormData({ ...formData, standardId: selectedIds, standard: newValue })
        } else {
            // setFormData({...formData, standardId: 0})
            setFormData({ ...formData, standardId: [] })
        }
    };



    const canSave = [(formData?.numeroPisos || formData?.numeroPisos == 0), formData?.sectorId,
    formData?.standard.length != 0,
    (formData?.numero || formData?.numero == 0),
    (formData?.numeroHabitaciones || formData?.numeroHabitaciones == 0),
    ].every(Boolean) && addRequestStatus === 'idle';

    const handleEdit = async () => {
        console.log(formData);

        try {
            setOpen3(true);
            setAddRequestStatus('loading');
            let resp = await dispatch(updateEdificio(formData));
            console.log(resp);
            if (resp.payload.status === 'success') {
                setOpen3(false);
            } else {
                setOpen3(false);
            }

        } catch (error) {
            // toast.error('Error al actualizar el pabellón.');
            console.error('Error al actualizar el pabellón', error.message);
        } finally {
            setAddRequestStatus('idle');
            setOpenEdit(false);

        }
    }

    return (
            <Drawer
                anchor='right'
                open={openEdit}
                PaperProps={
                    {
                      sx: {
                        width: { xs: '100%', sm: '75%', md: '50%' },
                        backgroundColor:'#2d3037',
                        color:'#FFF'
                      }
                    }
                }
                onClose={() => setOpenEdit(false)}
            >
                {seleItem && (
                    <>
                    <DialogTitle>
                    <Grid container={true} spacing={1} p={2}>
                        <Grid item  xs={12} sm={12} mb={2} style={{ display:'flex', gap:'5px'}}>
                            <ErrorIcon style={{ fontSize:'3rem' }} className={styles.iconBg}/>
                            <div style={{ display:'flex', flexDirection:'column' }}>
                                <Typography variant="h6" className={styles.textIconEdit}>Actualizar pabellón {seleItem?.nombre}</Typography>
                                <Typography variant="span" className={styles.textIconSpan}>Modifica los campos para actualizar el pabellón.</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </DialogTitle>     
                        <DialogContent>
                            <Grid container={true} spacing={1} p={2}>
                                <Grid item xs={12} sm={6}>
                                    <label className={styles.label}>Número</label>
                                    <TextField
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                        variant="outlined"
                                        fullWidth
                                        name='numero'
                                        type="number"
                                        value={formData?.numero}
                                        onChange={(e) => { onChange(e) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={styles.label}>Campamento</label>
                                    <Autocomplete
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                        disablePortal
                                        disableClearable
                                        id="campamentos-autocomplete"
                                        options={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos : []}
                                        name="campamento"
                                        getOptionLabel={(option) => option.nombre}
                                        onChange={(e, value) => handleChangeCampamento(e, value)}
                                        value={Array.isArray(campamentos) && campamentos.length > 0 ? campamentos.find(option => option.id === formData?.campamentoId) || null : null}
                                        renderInput={(params) => <TextField {...params} label="Selecciona un campamento" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={styles.label}>Sector</label>
                                    <Autocomplete
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                        disablePortal
                                        id="sectores-autocomplete"
                                        options={Array.isArray(sectores) && sectores.length > 0 ? sectores : []}
                                        name="sector"
                                        disableClearable
                                        getOptionLabel={(option) => option.nombre}
                                        value={Array.isArray(sectores) && sectores.length > 0 ? sectores.find(option => option.id === formData?.sectorId) || null : null}
                                        onChange={(e, value) => handleChangeSector(e, value)}
                                        renderInput={(params) => <TextField {...params} label="Selecciona un sector" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={styles.label}>N°Pisos</label>
                                    <TextField
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                        variant="outlined"
                                        fullWidth
                                        name='numeroPisos'
                                        type="number"
                                        value={formData?.numeroPisos}
                                        onChange={(e) => onChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={styles.label}>Número de habitaciones</label>
                                    <TextField
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                        variant="outlined"
                                        fullWidth
                                        name='numeroHabitaciones'
                                        type="number"
                                        value={formData?.numeroHabitaciones}
                                        onChange={(e) => onChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={styles.label}>Tipo de huesped</label>
                                    <Autocomplete
                                    className={styles.textField}
                                    sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                      '&:hover fieldset': {
                                        border: 'none',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#FFF',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#FFF',
                                    },
                                  }}
                                        disablePortal
                                        id="campamentos-autocomplete"
                                        options={tipo_huesped}
                                        name="tipo_huesped"
                                        getOptionLabel={(option) => option.label}
                                        value={formData?.generoHuesped ? tipo_huesped.find(option => option.value === formData?.generoHuesped) : null}
                                        onChange={(e, value) => handleChangeTipo(e, value, 'huesped')}
                                        renderInput={(params) => <TextField {...params} />}
                                        disableClearable
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <label className={styles.label}>Standard</label>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Selecciona 1 o más standard</InputLabel>
                                        <Select
                                        className={styles.textField}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                border: 'none',
                                              },
                                              '&:hover fieldset': {
                                                border: 'none',
                                              },
                                              '&.Mui-focused fieldset': {
                                                border: 'none',
                                              },
                                            },
                                            '& .MuiInputBase-input': {
                                              color: '#FFF',
                                            },
                                            '& .MuiInputLabel-root': {
                                              color: '#FFF',
                                            },
                                            '& .MuiChip-deleteIcon': {
                                              color: '#FFF',
                                            },
                                            '& .MuiChip-label': {
                                              color: '#FFF',
                                            },
                                            '& .MuiButtonBase-root': {
                                              backgroundColor: '#0086ae',
                                            },
                                          }}
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={standarsList}
                                            onChange={handleChangeStandars}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {standars.map((stnd) => (
                                                <MenuItem key={stnd.nombre} value={stnd.nombre}>
                                                    <Checkbox checked={standarsList.indexOf(stnd.nombre) > -1} />
                                                    <ListItemText primary={stnd.nombre} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container={true}>
                                <Grid item xs={12} sm={12} className={styles.buttonContainerEdit}>
                                    <Button
                                        onClick={handleEdit}
                                        className={styles.bgColorEdit}
                                        variant="contained"
                                        disabled={!canSave}
                                    >
                                        {addRequestStatus == 'loading' ? <CircularProgress color="inherit" size={24} /> : 'Actualizar'}
                                    </Button>
                                    <Button
                                        onClick={() => setOpenEdit(false)}
                                        className={styles.bgColorOutlined}
                                        variant="outlined"
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Drawer>
    );
}